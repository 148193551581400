import React, { Component } from 'react';

class OrderIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.549 25.55" {...this.props}>
        <g transform="translate(0 1.55)">
          <path fill="none" stroke={"currentColor"} className="a" d="M0,1.55H8.26l8.26,14.97H31.41L36.14,6.2" transform="translate(0 -1.55)" style={{strokeMiterlimit: 10, strokeWidth: "1.1px"}}/>
          <circle fill="currentColor" stroke="currentColor" className="b" cx="2.58" cy="2.58" r="2.58" transform="translate(15.6 18.84)"/>
          <circle fill="currentColor" className="b" cx="2.58" cy="2.58" r="2.58" transform="translate(27.23 18.84)"/>
        </g>
      </svg>
    );
  }
}

export default OrderIcon;
