import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { history } from 'helpers/store/root-reducer';

import { AppState } from '../../../helpers/store/models/AppState';
import {
  errorToastActions,
  selectErrorToast,
  selectSuccessToast, selectUserErrorToast,
  selectWarnToast,
  successToastActions, userErrorToastActions,
  warnToastActions,
  selectEmailConfirmSuccessToast,
  emailConfirmSuccessToastActions,
  selectWarnToastWithConfirm,
  warnToastWithConfirmActions,
  addedToComparatorToastActions,
  selectAddedToComparator
} from '../../../saga/toast/ducks';

const defaultSnackbarOptions: OptionsObject = {
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  preventDuplicate: true,
};

const ToastMessages = (props: DispatchToProps & StateToProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.toastSuccess !== '') {
      enqueueSnackbar(
        props.toastSuccess,
        {
          ...defaultSnackbarOptions,
          variant: 'success',
        }
      );
      props.setSuccessToast('');
    }
    if (props.toastWarn !== '') {
      enqueueSnackbar(
        props.toastWarn,
        {
          ...defaultSnackbarOptions,
          variant: 'warning',
        }
      );
      props.setWarnToast('');
    }
    if (props.toastError !== '') {
      enqueueSnackbar(
        props.toastError,
        {
          ...defaultSnackbarOptions,
          variant: 'error',
        }
      );
      props.setErrorToast('');
    }
    if (props.toastUserError !== '') {
      const action = (key: SnackbarKey) => (
        <Button onClick={() => { closeSnackbar(key) }} style={{ color: 'white' }}>
          OK
        </Button>
      );

      enqueueSnackbar(
        props.toastUserError,
        {
          ...defaultSnackbarOptions,
          persist: true,
          variant: 'error',
          action
        }
      );
      props.setUserErrorToast('');
    }
    if (props.toastEmailConfirmSuccess !== '') {
      const action = (key: SnackbarKey) => (
        <Button onClick={() => { closeSnackbar(key) }} style={{ color: 'white' }}>
          OK
        </Button>
      );

      enqueueSnackbar(
        props.toastEmailConfirmSuccess,
        {
          ...defaultSnackbarOptions,
          persist: true,
          variant: 'success',
          action
        }
      );
      props.setEmailConfirmSuccessToast('');
    }
    if (props.toastWarnWithConfirm !== '') {
      const action = (key: SnackbarKey) => (
        <Button onClick={() => { closeSnackbar(key) }} style={{ color: 'white' }}>
          OK
        </Button>
      );

      enqueueSnackbar(
        props.toastWarnWithConfirm,
        {
          ...defaultSnackbarOptions,
          persist: true,
          variant: 'warning',
          action
        }
      );
      props.setWarnToastWithConfirm('');
    }
    if (props.toastAddedToComparator !== '') {
      const action = (key: SnackbarKey) => (
        <>
          <Button onClick={() => { history.push(t("Routes.VehicleComparisonPage"));closeSnackbar(key); }} style={{ color: 'white' }}>
            {t("Results.DisplayComparator").toUpperCase()}
          </Button>
          <div className="close-snack">
            <IconButton onClick={() => closeSnackbar(key)}>
              <Close />
            </IconButton>            
          </div>
        </>
      );

      enqueueSnackbar(
        props.toastAddedToComparator,
        {
          ...defaultSnackbarOptions,
          persist: true,
          variant: 'success',
          action
        }
      );
      props.setAddedToComparator('');
    }
  }, [props, enqueueSnackbar, closeSnackbar, t]);

  return <></>;
};

interface StateToProps {
  toastSuccess: string;
  toastWarn: string;
  toastError: string;
  toastUserError: string;
  toastEmailConfirmSuccess: string;
  toastWarnWithConfirm: string;
  toastAddedToComparator: string;
}

interface DispatchToProps {
  setSuccessToast: (value: string) => void;
  setWarnToast: (value: string) => void;
  setErrorToast: (value: string) => void;
  setUserErrorToast: (value: string) => void;
  setEmailConfirmSuccessToast: (value: string) => void;
  setWarnToastWithConfirm: (value: string) => void;
  setAddedToComparator: (value: string) => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  toastSuccess: selectSuccessToast(state),
  toastWarn: selectWarnToast(state),
  toastError: selectErrorToast(state),
  toastUserError: selectUserErrorToast(state),
  toastEmailConfirmSuccess: selectEmailConfirmSuccessToast(state),
  toastWarnWithConfirm: selectWarnToastWithConfirm(state),
  toastAddedToComparator: selectAddedToComparator(state),
});

const mapDispatchToProps: DispatchToProps = {
  setSuccessToast: successToastActions,
  setWarnToast: warnToastActions,
  setErrorToast: errorToastActions,
  setUserErrorToast: userErrorToastActions,
  setEmailConfirmSuccessToast: emailConfirmSuccessToastActions,
  setWarnToastWithConfirm: warnToastWithConfirmActions,
  setAddedToComparator: addedToComparatorToastActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessages);
