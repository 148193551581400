import React from "react";
import { environment as env } from 'environments/environment';
import i18n from 'i18n';

const Recaptcha = require('react-recaptcha');

type renderTypes = 'onload' | 'explicit';
type themeTypes = 'light' | 'dark';
type sizeTypes = 'invisible';
type badgceTypes = 'bottomright' | 'bottomleft' | 'inline';

interface recaptchaProps {
  className?: string,
  onloadCallbackName?: string,
  elementID: string,
  onloadCallback?: () => void,
  verifyCallback: (response: any) => void,
  expiredCallback?: () => void,
  render?: renderTypes,
  sitekey?: string,
  theme?: themeTypes,
  type?: string,
  verifyCallbackName?: string,
  expiredCallbackName?: string,
  size?: sizeTypes,
  tabindex?: string,
  hl?: string,
  badge?: badgceTypes,
  ref?: any,
};

const recaptchav2 = React.forwardRef((props: recaptchaProps, ref: any) => {

  return (
    <>
      <div id={props.elementID}></div>
      <Recaptcha
        sitekey={env.RECAPTCHA_V3_SCORE_SITE_KEY}
        hl={i18n.language}
        size={'invisible'}
        {...props}
        ref={ref}
      />
    </>
  );
})

export default recaptchav2;