import axios from 'axios';
import qs from 'qs';
import _ from "lodash";

import { environment } from 'environments/environment';
import i18n from '../i18n';
import { history } from 'helpers/store/root-reducer';
import { logoutActions } from '../saga/user/ducks';
import { store } from './store/configure-store';
import locationKeys, { protectedRoutes } from './locations-keys';
import { errorToastActions, userErrorToastActions, warnToastActions } from '../saga/toast/ducks';
import { ExtendedAxiosResponse } from '../model/ExtendedAxiosResponse';


export const apiClient = axios.create({
  responseType: 'json',
  baseURL: `${environment.protocol}://${environment.host}${
    environment.port ? ':' + environment.port : ''
  }`,
  withCredentials: true,
  paramsSerializer: (params: any) => {
    return qs.stringify(params, {
      encode: false
    });
  },
});

export const axiosBadRequestSkip = {
  skipBadRequest: true,
} as any;

apiClient.interceptors.request.use((config) => {
  const state = store.getState();
  const isUserLogged = _.get(state, 'user.isLogged', null);
  const language = i18n.language || 'en';

  if (!isUserLogged && environment.basicAuthToken && environment.basicAuthToken !== '') {
    config.headers.common['Authorization'] = `Basic ${environment.basicAuthToken}`;
  }

  config.headers.common['Cache-Control'] = 'no-cache';

  config.headers.common['accept-language'] = language;

  config.headers.common['app-domain'] = environment.topLevelDomain;

  return config;
});

apiClient.interceptors.response.use(
  (response) => normalizeSuccessResponse(response),
  (error) => normalizeErrorResponse(error),
);

function normalizeSuccessResponse(response: any) {
  return {
    ...response,
    ok: true,
  };
}

const paymentErrorMap: Record<string, Record<string, string>> = {
  card_declined: {
    insufficient_funds: 'Payment.Errors.InsufficientFunds',
    fraudulent: 'Payment.Errors.PaymentRejected',
    default: 'Payment.Errors.CardDeclined',
  },
  expired_card: {
    default: 'Payment.Errors.ExpiredCard',
  },
  processing_error: {
    default: 'Payment.Errors.ProcessingError',
  },
  incorrect_number: {
    default: 'Payment.Errors.IncorrectCardNumber',
  },
  incorrect_cvc: {
    default: 'Payment.Errors.IncorrectCardCVC',
  },
}

const formatAxiosError = (error: any): ExtendedAxiosResponse => {
  return {
    ok: false,
    data: error.response?.data,
    status: error.response?.status,
    statusText: error.response?.statusText,
    headers: error.response?.headers,
    config: error.config,
    error,
  };
};

function normalizeErrorResponse(error: any) {
  if (!error?.response) {
    return formatAxiosError(error);
  }

  const { data, status, config } = error.response;

  if (status === 500 && data?.message) {
    store.dispatch(errorToastActions(data.message));
  } else if (status === 404) {
    if (config.url === "/get-offer-by-slug") {
      store.dispatch(errorToastActions(i18n.t('Errors.PageNotFound')));
      history.push(i18n.t('Routes.LandingPage'));
    } else if (data?.message) {
      store.dispatch(errorToastActions(data.message));
    }
  } else if (status === 402) {
    if (data?.data?.paymentError) {
      const { code, declineCode } = data.data.paymentError;

      store.dispatch(userErrorToastActions(i18n.t(
        paymentErrorMap[code]
          ? paymentErrorMap[code][declineCode] || paymentErrorMap[code]['default']
          : 'Payment.Errors.GeneralError'
      )));
    }
    // else if (data?.message) {
    //   store.dispatch(userErrorToastActions(data.message));
    // }
  } else if (status === 403) {
    if (!config.skipBadRequest) {
      if (data?.errors && data.errors[0]?.message) {
        store.dispatch(errorToastActions(data.errors[0].message));
      } else if (data?.message) {
        store.dispatch(errorToastActions(data.message));
      }
    }
  } else if (status === 400) {
    if (!config.skipBadRequest) {
      if (data?.errors && data.errors[0]?.message) {
        store.dispatch(errorToastActions(data.errors[0].message));
      } else if (data?.message) {
        store.dispatch(errorToastActions(data.message));
      }
    }
  } else if (status === 401) {
    localStorage.removeItem('logoutAfter');
    store.dispatch(logoutActions.success({}));

    if (
      data?.errors &&
      data.errors[0]?.message &&
      data.errors[0]?.type === 'auth.notAllowedToUseCurrentDomain'
    ) {
      store.dispatch(warnToastActions(data.errors[0].message));
    } else if (
      data?.errors &&
      data.errors[0]?.message &&
      data.errors[0]?.type !== 'auth.notAuthenticated'
    ) {
      store.dispatch(warnToastActions(data.errors[0].message));
    } else if (data?.message && (data.message !== 'NotAuthenticated')) {
      store.dispatch(errorToastActions(data.message));
    }

    const actLocationKey = locationKeys.find(key => key.pathname.includes(window.location.pathname));
    if (actLocationKey) {
      const foundedRoute = protectedRoutes.find(route => route === actLocationKey.key);
      if (foundedRoute) {
        history.push(i18n.t('Routes.Login'), { from: { pathname: history.location.pathname } });
      }
    } else if (
      data?.errors &&
      data.errors[0]?.message &&
      data.errors[0]?.type === 'auth.notAuthenticated' &&
      store.getState().user.isLogged
    ) {
      store.dispatch(warnToastActions(data.errors[0].message));
    }

  } else if (data?.message) {
    store.dispatch(errorToastActions(data.message));
  }

  if (environment.debug) {
    console.error(
      `Request failed with status code %c${status}\n`, 'font-weight: bold;',
      `${config.method.toUpperCase()} ${config.url}\n\n`,
      `Request:\n`,
      config.data,
      `\nResponse:\n`,
      data,
    );
  }

  return {
    ...error,
    ok: false,
  };
}
