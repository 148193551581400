import { apiClient, axiosBadRequestSkip } from 'helpers/api-client';

export interface AddPaymentMethodRequest {
  token: string;
  isDefault: boolean;
  country?: string;
}

export interface ChangePaymentMethodRequest {
  cardId: string;
  country?: string;
}

export const api = {
  getPaymentMethods: (country?: string) =>
    apiClient.get('/user/payment-methods', { params: { country } }),

  addPaymentMethod: (body: AddPaymentMethodRequest) =>
    apiClient.post('/user/payment-methods', body, axiosBadRequestSkip),

  changeDefaultPaymentMethod: (cardId: string, country?: string) =>
    apiClient.put(`/user/payment-methods/${cardId}`, {}, { params: { country } }),

  removePaymentMethod: (cardId: string, country?: string) =>
    apiClient.delete(`/user/payment-methods/${cardId}`, { params: { country } }),
};
