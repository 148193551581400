import React, { Component } from 'react';

class CheckIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14.073" height="10.216" viewBox="0 0 14.073 10.216" {...this.props}>
        <path fill="currentColor" className="a" d="M19.323,12.13l-8.841,9.01L5.25,15.909l1.341-1.341,3.891,3.881,7.548-7.524Z"
              transform="translate(-5.25 -10.925)"/>
      </svg>
    );
  }
}

export default CheckIcon;
