import React, { Component } from 'react';
import i18n from '../../../i18n';
import {
  warnToastActions,
  successToastActions
} from 'saga/toast/ducks';
import { store } from 'helpers/store/configure-store';

export default function (ComposedComponent: any) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online' && this.state.isDisconnected === true) {
        const webPing = setInterval(
          () => {
            fetch('https://www.google.com', {
              mode: 'no-cors',
            })
              .then(() => {
                this.setState({ isDisconnected: false }, () => {
                  store.dispatch(successToastActions(i18n.t('SuccessToasts.Online')));
                  return clearInterval(webPing);
                });
              })
              .catch(() => {
                this.setState({ isDisconnected: true });
                store.dispatch(warnToastActions(i18n.t('Errors.Connection')));
              } )
          }, 2000);
        return;
      } else if (condition === 'online' && this.state.isDisconnected === false) {
        return;
      }

      store.dispatch(warnToastActions(i18n.t('Errors.Connection')));
      return this.setState({ isDisconnected: true });
    }

    render() {

      return (
        <div>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}