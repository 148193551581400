import React, { Component } from 'react';

class UserIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 28" {...this.props}>
        <g transform="translate(-5.5 -4)" stroke="currentColor" fill="none">
          <path className="a" d="M30,31.5v-3a6,6,0,0,0-6-6H12a6,6,0,0,0-6,6v3"/>
          <path className="a" d="M24,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z"/>
        </g>
      </svg>
    );
  }
}

export default UserIcon;
