import { apiClient } from 'helpers/api-client';

interface PriceToImport {
  value: number,
  currencyName: string,
}
export interface OfferToImport {
  id?: string,
  itemStatusId?: string | null,
  marketTypeId?: string | null,
  userAddressId?: string | null,
  returnTOCId?: string | null,
  returnPolicyId?: string | null,
  mainCategoryId?: string | null,
  categoryId?: string | null,
  name?: string | null,
  description?: string | null,
  shortDescription?: string | null,
  count?: number | null,
  partNumber?: string | null,
  photos?: string[] | null,
  prices?: PriceToImport[] | null,
  phoneNumber?: string | null, 
}

export interface ImportOffersData {
  offers: Array<OfferToImport>,
  updateExisting?: boolean,
  skipErrors?: boolean,
  skipWithoutImage?: boolean,
  skipNotFound?: boolean,  
}

export interface CancelOffersImportRequestData {
  offersImportId: string
}

export const api = {
  createOffersImport: (offers: ImportOffersData) => apiClient.post('/offers/import/create-import', offers),
  getOffersImportStatus: (offersImportId: string) => apiClient.get(`/offers/import/get-import-status/${offersImportId}`),
  cancelOffersImport: (data: CancelOffersImportRequestData) => apiClient.post('/offers/import/cancel-import', data),
};
