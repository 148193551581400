export interface Location {
  pathname: string[];
  key: string;
}

const keys: Location[] = [
  {
    pathname: ["/prihlasenie", "/prihlaseni", "/login"],
    key: "Routes.Login",
  },
  {
    pathname: ["/registracia", "/registrace", "/register"],
    key: "Routes.Register",
  },
  {
    pathname: ["/nove-heslo", "/new-password"],
    key: "Routes.NewPassword",
  },
  {
    pathname: ["/resetovanie-hesla", "/resetovani-hesla", "/password-reset"],
    key: "Routes.PasswordReset",
  },
  {
    pathname: ["/zmena-emailu", "/email-change"],
    key: "Routes.EmailChange",
  },
  {
    pathname: ["/nova-ponuka", "/nova-nabidka", "/new-offer"],
    key: "Routes.NewOffer",
  },
  {
    pathname: ["/moj-ucet/spravy", "/muj-ucet/zpravy", "/my-account/chat"],
    key: "Routes.Chat"
  },
  {
    pathname: ["/moj-ucet/spravy/{{conversationId}}", "/muj-ucet/zpravy/{{conversationId}}", "/my-account/chat/{{conversationId}}"],
    key: "Routes.ChatDetail"
  },
  {
    pathname: ["/moj-ucet/spravy/nova-sprava/{{token}}", "/muj-ucet/zpravy/nova-zprava/{{token}}", "/my-account/chat/new-message/{{token}}"],
    key: "Routes.ChatNewMessage"
  },
  {
    pathname: ["/moj-ucet/osobne-info-a-adresy", "/muj-ucet/osobni-info-a-adresy", "/my-account/personal-info-and-addresses"],
    key: "Routes.AccountInfoAdresses"
  },
  {
    pathname: ["/moj-ucet/zmena-hesla", "/muj-ucet/zmena-hesla", "/my-account/change-password"],
    key: "Routes.AccountPasswordChange"
  },
  {
    pathname: ["/moj-ucet/moje-objednavky", "/muj-ucet/moje-objednavky", "/my-account/my-orders"],
    key: "Routes.AccountMyOrders"
  },
  {
    pathname: ["/moj-ucet/moje-ponuky", "/muj-ucet/moje-nabidky", "/my-account/my-offers"],
    key: "Routes.AccountMyOffers"
  },
  {
    pathname: ["/moj-ucet/moje-ponuky/predlzit/{{token}}", "/muj-ucet/moje-nabidky/prodlouzit/{{token}}", "/my-account/my-offers/prolong/{{token}}"],
    key: "Routes.AccountMyOffersProlong"
  },
  {
    pathname: ["/moj-ucet/hodnotenie-ponuk", "/muj-ucet/hodnoceni-ponuk", "/my-account/offer-reviews"],
    key: "Routes.AccountOfferReviews"
  },
  {
    pathname: ["/moj-ucet/kredity-transakcie-karty", "/muj-ucet/kredity-transakce-karty", "/my-account/credits-transactions-cards"],
    key: "Routes.AccountTransactions"
  },
  {
    pathname: ["/moj-ucet/vratenie-produktov/moje-ziadosti", "/muj-ucet/vraceni-produktu/moje-zadosti", "/my-account/product-returns/my-requests"],
    key: "Routes.AccountMyReturns"
  },
  {
    pathname: ["/moj-ucet/vratenie-produktov/prijate-ziadosti", "/muj-ucet/vraceni-produktu/prijate-zadosti", "/my-account/product-returns/received-requests"],
    key: "Routes.AccountReceivedReturns"
  },
  {
    pathname: ["/moj-ucet/faktury", "/muj-ucet/faktury", "/my-account/invoices"],
    key: "Routes.AccountInvoices"
  },
  {
    pathname: ["/marketplace/vysledky", "/marketplace/vysledky", "/marketplace/results"],
    key: "Routes.ResultsMarketplace"
  },
  {
    pathname: ["/nahradne-diely/vysledky", "/nahradni-dily/vysledky", "/spare-parts/results"],
    key: "Routes.ResultsSpareParts"
  },
  {
    pathname: ["/automoto/vysledky", "/automoto/vysledky", "/automoto/results"],
    key: "Routes.ResultsAutomoto"
  },
  {
    pathname: ["/produkt/detail/{{offerId}}", "/produkt/detail/{{offerId}}", "/product/detail/{{offerId}}"],
    key: "Routes.ProductDetail"
  },
  {
    pathname: ["/auto-moto/{{offerSlug}}", "/auto-moto/{{offerSlug}}", "/auto-moto/{{offerSlug}}"],
    key: "Routes.ProductDetailAutomoto"
  },
  {
    pathname: ["/moj-ucet/aktivacia/{{activationRouteParam}}", "/muj-ucet/aktivace/{{activationRouteParam}}", "/my-account/activation/{{activationRouteParam}}"],
    key: "Routes.AccountActivation",
  },
  {
    pathname: ["/ucet-odkaz/overit/{{reAuthLinkParam}}", "/ucet-odkaz/overit/{{reAuthLinkParam}}", "/account-link/reauth/{{reAuthLinkParam}}"],
    key: "Routes.AccountLinkReAuth",
  },
  {
    pathname: ["/cs", "/cs", "/cs"],
    key: "Routes.AddedToCart",
  },
  {
    pathname: ["/kosik", "/kosik", "/cart"],
    key: "Routes.Cart",
  },
  {
    pathname: ["/checkout", "/checkout", "/checkout"],
    key: "Routes.Checkout",
  },
  {
    pathname: ["/objednavka/detail/{{orderId}}", "/objednavka/detail/{{orderId}}", "/order/detail/{{orderId}}"],
    key: "Routes.OrderDetailPage",
  },
  {
    pathname: ["/noresults", "/noresults", "/noresults"],
    key: "Routes.NoResults",
  },
  {
    pathname: ["/objednavka/pridaj-hodnotenie/{{orderId}}", "/objednavka/pridej-hodnoceni/{{orderId}}", "/order/add-review/{{orderId}}"],
    key: "Routes.OrderAddReview",
  },
  {
    pathname: ["/objednavka/hodnotenie-dakujeme/{{orderId}}", "/objednavka/hodnoceni-dekujeme/{{orderId}}", "/order/review-thank-you/{{orderId}}"],
    key: "Routes.OrderReviewThank",
  },
  {
    pathname: ["/objednavka-vytvorena-dakujeme", "/objednavka-vytvorena-dekujeme", "/order-created-thank-you"],
    key: "Routes.OrderCreated",
  },
  {
    pathname: ["/moj-ucet/nastavenia-predaja", "/muj-ucet/nastaveni-prodeje", "/my-account/sales-settings"],
    key: "Routes.AccountSalesSettings",
  },
  {
    pathname: ["/watchlist/sledovane-ponuky", "/watchlist/sledovane-nabidky", "/watchlist/watched-offers"],
    key: "Routes.WatchList",
  },
  {
    pathname: ["/moj-ucet/prijate-objednavky", "/muj-ucet/prijate-objednavky", "/my-account/orders-received"],
    key: "Routes.AccountRecievedOrders",
  },
  {
    pathname: ["/moj-ucet", "/muj-ucet", "/my-account"],
    key: "Routes.AccountInfoAdresses"
  },
  {
    pathname: ["/obchod/{{userSlug}}", "/obchod/{{userSlug}}", "/store/{{userSlug}}"],
    key: "Routes.Store"
  },
  {
    pathname: ["/obchod/{{userSlug}}/hodnotenie", "/obchod/{{userSlug}}/hodnoceni", "/store/{{userSlug}}/rating"],
    key: "Routes.UserRatingPage"
  },
  {
    pathname: ["/prijata-objednavka/detail/{{orderId}}", "/prijata-objednavka/detail/{{orderId}}", "/received-order/detail/{{orderId}}"],
    key: "Routes.ReceivedOrderDetailPage"
  },
  {
    pathname: ["/nova-ziadost-o-vratenie/{{orderId}}", "/nova-zadost-o-vraceni/{{orderId}}", "/new-return-request/{{orderId}}"],
    key: "Routes.NewReturnRequest"
  },
  {
    pathname: ["/moj-ucet/vratenie-produktov/moje-ziadosti/{{requestId}}/detail", "/muj-ucet/vraceni-produktu/moje-zadosti/{{requestId}}/detail", "/my-account/product-returns/my-requests/{{requestId}}/detail"],
    key: "Routes.MyRefundRequestDetailPage"
  },
  {
    pathname: ["/moj-ucet/vratenie-produktov/prijate-ziadosti/{{requestId}}/detail", "/muj-ucet/vraceni-produktu/prijate-zadosti/{{requestId}}/detail", "/my-account/product-returns/received-requests/{{requestId}}/detail"],
    key: "Routes.ReceivedRefundRequestDetailPage"
  },
  {
    pathname: ["/automoto/porovnavac", "/automoto/srovnavac", "/automoto/comparator"],
    key: "Routes.VehicleComparisonPage"
  },
  {
    pathname: ["/moj-ucet/moje-ponuky/import", "/muj-ucet/moje-nabidky/import", "/my-account/my-offers/import"],
    key: "Routes.OffersImporter"
  },
  {
    pathname: [],
    key: "Routes.LandingPage",
  }
];

export const protectedRoutes = [
  'Routes.NewOffer',
  'Routes.Chat',
  'Routes.ChatDetail',
  'Routes.ChatNewMessage',
  'Routes.AccountInfoAdresses',
  'Routes.AccountPasswordChange',
  'Routes.AccountMyOffers',
  'Routes.AccountMyOrders',
  'Routes.AccountActivation',
  'Routes.AccountLinkReAuth',
  'Routes.OrderAddReview',
  'Routes.OrderReviewThank',
  'Routes.OrderCreated',
  'Routes.AccountSalesSettings',
  'Routes.WatchList',
  'Routes.AccountTransactions',
  'Routes.AccountRecievedOrders',
  'Routes.AccountInvoices',
  'Routes.AccountMyReturns',
  'Routes.AccountReceivedReturns',
  'Routes.ReceivedOrderDetailPage',
  'Routes.NewReturnRequest',
  'Routes.OffersImporter',
];

export default keys;
