import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { AppState } from "helpers/store/models/AppState";
import { api } from "./api";
import { UserAsSeller } from "model/UsersModel";
import {
  createActionType,
  createApiActionCreators,
  createLoadingStateReducer,
  createReducer,
  LoadingState,
  RequestActionTypes
} from "helpers/redux/redux-helpers";
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
export interface UsersState {
  seller: UserAsSeller | null,
  usersLoadingState: LoadingState,
}

/* ACTION TYPES */
export enum UsersActionTypes {
  GetSellerBySlug = "@@Users/GET_USERS",
}

/* ACTIONS */
export const getSellerBySlugActions = createApiActionCreators(UsersActionTypes.GetSellerBySlug);

/* REDUCERS */
const initialState: UsersState = {
  seller: null,
  usersLoadingState: LoadingState.success,
};

const seller = createReducer(initialState.seller, {
  [UsersActionTypes.GetSellerBySlug]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: UserAsSeller) => payload,
  },
});


const usersLoadingState = createLoadingStateReducer(initialState.usersLoadingState, {
  [UsersActionTypes.GetSellerBySlug]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});



export default combineReducers<UsersState>({
  seller,
  usersLoadingState
});

/* SELECTORS */
export const selectUsersState = (state: AppState) => state.users;

export const selectSeller = (state: AppState) => selectUsersState(state).seller;

export const selectUsersLoadingState = (state: AppState) => selectUsersState(state).usersLoadingState;

/* SAGAS */
function* getSellerBySlug({ payload }: any) {

  const resp: ExtendedAxiosResponse = yield call(api.getUserBySlug, payload.slug, payload.params);
  if (resp.ok) {
    yield put(getSellerBySlugActions.success(resp.data));
  } else {
    yield put(getSellerBySlugActions.failure());
  }
}


/* EXPORT */
export function* usersSaga() {
  yield takeLatest(
    createActionType(UsersActionTypes.GetSellerBySlug, RequestActionTypes.REQUEST),
    getSellerBySlug
  );
}
