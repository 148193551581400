import { apiClient } from 'helpers/api-client';
import { OfferInappropriateFlagIssueTypes } from 'model/OfferInappropriateFlag';
import qs from 'qs';

interface VehicleInfo {
  brand: any;
  model: any;
  modelEnums: any[];
}

export interface OfferInappropriateFlagCreateSchema {
  issueType: OfferInappropriateFlagIssueTypes,
  description?: string | null,
}

export const api = {
  getOffers: (params: any) => apiClient.get('offers', { params }),
  postOffer: (body: any) => apiClient.post('offers', body),
  deleteOffer: (body: any) => apiClient.delete(`/offers/${body}`),
  getOffer: (id: string, params?: any, skipBadRequest = false) => apiClient.get(`/offers/${id}`, {
    params,
    skipBadRequest,
  } as any),
  editOffer: (body: any) => apiClient.patch(`/offers/${body.offerId}`, body.payload),
  setOfferItemStatus: (body: any) => apiClient.post(`/offer/${body.offerId}/item-status`, body.payload),
  setOfferLocation: (body: any) => apiClient.post(`/offer/${body.offerId}/location`, body.payload),
  setOfferMarketType: (body: any) => apiClient.post(`/offer/${body.offerId}/market-type`, body.payload),
  getOfferDeliveryTypes: (body: any) => apiClient.get(`/offer/${body.offerId}/delivery-types`),
  setOfferDeliveryTypes: (offerId: string, body: any) => apiClient.post(`/offer/${offerId}/delivery-types`, body),
  deleteOfferDeliveryTypes: (body: any) => apiClient.delete(`/offer/${body.offerId}/delivery-types/${body.deliveryTypeId}`),
  setOfferPrices: (body: any) => apiClient.post(`/offer/${body.offerId}/prices`, body.payload),
  getOfferCustomEnums: (body: any) => apiClient.get(`/offer/${body.offerId}/custom-enums`),
  setOfferCustomEnums: (body: any) => apiClient.post(`/offer/${body.offerId}/custom-enums`, body.payload),
  setOfferPublishOption: (body: any) => apiClient.post(`/offer/${body.offerId}/publish-option`, body.payload),
  setOfferVehicleInfo: (body: any) => apiClient.post(`/offer/${body.offerId}/vehicle-info`, body.payload),
  setOfferPartNumber: (body: any) => apiClient.post(`/offer/${body.offerId}/part-number`, body.payload),
  setOfferStatus: (body: any) => apiClient.post(`/offer/${body.id}/status`, body.payload),
  duplicateOffer: (id: string) => apiClient.post(`/offer/${id}/duplicate`),
  getOfferPublicPhotos: (body: any) => apiClient.get(`/offer/photos?offerId=${body}`),
  getOfferId: (id: string, params?: any) => apiClient.get(`/offers/${id}?${params ? params : ''}`),
  searchForOffers: (params: any) => {
    let url = `/offer/search`;

    const sign = (url: string) => url.includes('?') ? '&' : '?';

    params.search && params.search.length && (url = `${url}?search=${encodeURIComponent(params.search)}`);
    (url = `${url}${sign(url)}$limit=${(params.limit || params.limit === 0) ? params.limit : 20}`);
    params.skip && (url = `${url}${sign(url)}$skip=${params.skip}`);
    if (params.categories) {
      params.categories.forEach((item: string, index: number) => url = `${url}${sign(url)}categories[${index}]=${item}`);
    }
    params.categoriesSlug && (url = `${url}${sign(url)}categoriesSlug[]=${params.categoriesSlug}`);
    params.deliveryTypes && (url = `${url}${sign(url)}deliveryTypes[]=${params.deliveryTypes}`);
    params.deliveryTypesSlug && (url = `${url}${sign(url)}deliveryTypesSlug[]=${params.deliveryTypesSlug}`);
    if (params.itemStatuses) {
      params.itemStatuses.forEach((item: string, index: number) => url = `${url}${sign(url)}itemStatuses[${index}]=${item}`);
    }
    params.itemStatusesSlug && (url = `${url}${sign(url)}itemStatusesSlug[]=${params.itemStatusesSlug}`);
    if (params.marketTypes && typeof params.marketTypes === 'string') {
      (url = `${url}${sign(url)}marketTypes[]=${params.marketTypes}`);
    }
    if (params.marketTypes && Array.isArray(params.marketTypes)) {
      params.marketTypes.forEach((type: string, index: number) => url = `${url}${sign(url)}marketTypes[${index}]=${type}`);
    }
    params.marketTypesSlug && (url = `${url}${sign(url)}marketTypesSlug[]=${params.marketTypesSlug}`);
    if (params.enumOptions) {
      params.enumOptions.forEach((item: string, index: number) => url = `${url}${sign(url)}enumOptions[${index}]=${item}`);
    }
    params.enumOptionsSlug && (url = `${url}${sign(url)}enumOptionsSlug[]=${params.enumOptionsSlug}`);
    params.enumOptionsGroups && (url = `${url}${sign(url)}${qs.stringify({ enumOptionsGroups: params.enumOptionsGroups })}`);
    params.price && params.price.from && (url = `${url}${sign(url)}price[from]=${params.price.from}`);
    params.price && params.price.to && (url = `${url}${sign(url)}price[to]=${params.price.to}`);
    params.mileage && params.mileage.from && (url = `${url}${sign(url)}mileage[from]=${params.mileage.from}`);
    params.mileage && params.mileage.to && (url = `${url}${sign(url)}mileage[to]=${params.mileage.to}`);
    params.enginePower && params.enginePower.from && (url = `${url}${sign(url)}enginePower[from]=${params.enginePower.from}`);
    params.enginePower && params.enginePower.to && (url = `${url}${sign(url)}enginePower[to]=${params.enginePower.to}`);
    params.year && params.year.from && (url = `${url}${sign(url)}year[from]=${params.year.from}`);
    params.year && params.year.to && (url = `${url}${sign(url)}year[to]=${params.year.to}`);
    if (params.brandsAndModels) {
      params.brandsAndModels.forEach((item: VehicleInfo, index: number) => url = `${url}${sign(url)}brandAndModel[${index}][brand]=${item.brand.toLowerCase()}${item.model ? `&brandAndModel[${index}][model]=${item.model.toLowerCase()}` : ""}`);
    }
    if (params.fuelTypes) {
      params.fuelTypes.forEach((item: string, index: number) => url = `${url}${sign(url)}fuelTypes[${index}]=${item}`);
    }
    params.userSlug && (url = `${url}${sign(url)}userSlug=${params.userSlug}`);

    params.verifiedSellersOnly && (url = `${url}${sign(url)}verifiedSellersOnly=${params.verifiedSellersOnly}`);

    params.recommendation !== undefined && (url = `${url}${sign(url)}recommendation=${params.recommendation}`);

    params.sort && (
      url = url + 
        sign(url) + 
        Object.entries(params.sort).map(([key, value]) => `$sort[${key}]=${value}`)
          .join('&')
    );

    (params.usePit !== undefined && typeof params.usePit === 'boolean') && (
      url = url + sign(url) + `usePit=${params.usePit}`
    );

    params.pitId && (url = url + sign(url) + `pitId=${params.pitId}`);

    (params.useSearchAfter !== undefined && typeof params.useSearchAfter === 'boolean') && (
      url = url + sign(url) + `useSearchAfter=${params.useSearchAfter}`
    );

    params.searchAfter && (url = url + sign(url) + `searchAfter${params.searchAfter}`);

    params.doNotDistinguishTopped && (url = url + sign(url) + `doNotDistinguishTopped=${params.doNotDistinguishTopped}`);
    
    params.useAndToFilter && (url = url + sign(url) + `useAndToFilter=${true}`);

    if (params.excludeOfferIds) {
      params.excludeOfferIds.forEach((id: string, index: number) => url = `${url}${sign(url)}excludeOfferIds[]=${id}`);
    }

    return apiClient.get(url, {
      headers: {
        'accept-language': params.language,
        'accept-currency': params.currency,
      } 
    });
  },
  getOfferBySlug: (params: any) => apiClient.get('/get-offer-by-slug', { params }),
  addTopOption: (id: string, params: any) => apiClient.post(`/offer/${id}/top-option`, params),
  setOfferReturnTOC: (id: string, params: any) => apiClient.post(`/offer/${id}/return-toc`, params),
  setOfferReturnPolicy: (id: string, params: any) => apiClient.post(`/offer/${id}/return-policy`, params),
  createOfferInappropriateFlag: (offerId: string, data: OfferInappropriateFlagCreateSchema) => apiClient.post(`/offer/${offerId}/inappropriate-flags`, data),
};
