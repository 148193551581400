import React, { Component } from 'react';

class WarningIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="28" viewBox="0 0 31 28" {...this.props}>
        <g transform="translate(37)">
          <path fill="currentColor" className="a" d="M13.765,3.021a2,2,0,0,1,3.469,0L29.28,24a2,2,0,0,1-1.735,3H3.454A2,2,0,0,1,1.72,24Z"
                transform="translate(-37)"/>
          <text fill="#F6CA6E" style={{ fontSize: "17px", fontFamily: "OpenSans-Bold, Open Sans", fontWeight: 700 }} className="b"
                transform="translate(-24 23)">
            <tspan x="0" y="0">!</tspan>
          </text>
        </g>
      </svg>
    );
  }
}

export default WarningIcon;
