import React from 'react';
import { Grid } from "@material-ui/core";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Provider } from 'react-redux';
import { store } from '../../../helpers/store/configure-store';
import { history } from 'helpers/store/root-reducer';
import qs from 'qs';

interface PopUpProps {
  title: string,
  children?: React.ReactNode;
  className?: string;
}

export interface SwalResult {
  preventGoBack: boolean;
}

const PopUp = (props: PopUpProps) => {
  return (
    <Grid container className={props.className || ''}>
      <h2>{props.title}</h2>

      <Grid container item xs={12} direction={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
        {props.children}
      </Grid>
      {/*<Grid container justifyContent={"flex-end"} alignItems={"center"} className={'buttonsHolder mt-40'}>*/}
      {/*  <h5 className="MuiLink-root mr-40" onClick={props.close}>{props.cancel}</h5>*/}
      {/*  <Button variant="contained" type="submit">{props.buttonText}</Button>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default PopUp;

interface EditPopUpOptions {
  customClass?: any;
  showCloseButton?: boolean;
  willClose?: () => void;
}

export const showEditPopUp = async (popUp: React.ReactNode, options: EditPopUpOptions = {}, routeQueryParams?: Record<string, string>) => {
  const params = {
    popUp: true,
    ...(routeQueryParams ? routeQueryParams : {}),
    ...(history.location.search !== '' ? JSON.parse(JSON.stringify(qs.parse(history.location.search.slice(1)))) : {}),
  }

  if (!history.location?.search?.includes('popUp=true')) {
    history.push({
      search: qs.stringify(params, { addQueryPrefix: true })
    });
  }
  
  withReactContent(Swal)
    .fire<SwalResult>({
      html:
        <Provider store={store}>
          {popUp}
        </Provider>,
      showConfirmButton: false,
      padding: '20px 30px 30px 30px',
      showClass: {
        popup: 'swal2-noanimation',
        backdrop: 'swal2-noanimation'
      },
      hideClass: {
        popup: '',
        backdrop: ''
      },
      ...options,
    })
    .then((result) => {
      // change history location search params when closing popup normally (not by navigation back in brower or programatically triggered)
      if (history.location.search.includes('popUp=true') && !(result.value && result.value.preventGoBack)) {
        history.goBack();
      }
    });
}
