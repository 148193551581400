import { apiClient } from 'helpers/api-client';

export const api = {
  getInvoices: (params: any) =>
    apiClient.get(
      '/users/invoices',
      {
        params: {
          $limit: 10,
          $sort: {
            createdAt: -1,
          },
          ...params,
        }
      }),
};
