
export interface OffersImport {
  id: string;
  userId: string;
  status: OffersImportStatus;
  toImportCount: number;
  alreadyProcessedCount: number;
  failedToImport?: Array<FailedToImport>;
  importedOffers?: Array<ImportedOffer>;
  createdAt?: Date;
  updatedAt?: Date;
  validationProgress?: OffersValidationProgress | null; //should exists only when offer import status is validtingData
}

interface OffersValidationProgress {
  totalValidationSteps: number,
  completedValidationSteps: number,
}

export enum OffersImportStatus {
  initialized = 'INITIALIZED',
  validatingData = 'VALIDATING_DATA',
  validationError = 'VALIDATION_ERROR',
  processing = 'PROCESSING',
  success = 'SUCCESS',
  error = 'ERROR',
  someOffersFailedToImport = 'SOME_OFFERS_FAILED_TO_IMPORT',
  cancelledByUser = 'CANCELLED_BY_USER'
}
export interface FailedToImport {
  index: number,
  id?: string,
  skipNotFound?: string,

  // if updaing but offer is not found and should be created, but some values are missing
  categoryIdNotDefiend?: string,
  mainCategoryIdNotDefiend?: string,
  marketTypeIdNotDefiend?: string,
  nameNotDefined?: string,
  countNotDefined?: string,

  // when image porcessing failed
  gettingImageFailed?: string,
  maxImagesReached?: string,
  imagesCountReduced?: string,

  // transaction error
  transactionRolledBack?: string,

  // verfications errors
  categoryNotFound?: string,
  categoryNotLast?: string,

  deliveryTypeNotFound?: string,
  deliveryTypePriceNotAllowed?: string,
  deliveryTypeCurrencyNameInvalid?: string,
  deliveryTypePriceMaximumCount?: string,
  deliveryTypePriceNotUserCurrency?: string,
  deliveryTypeCurrencyIdInvalid?: string,
  deliveryTypeDuplicateCurrency?: string,

  itemStatusNotFound?: string,
  marketTypeNotFound?: string,

  returnPolicyNotFound?: string,
  returnPolicyNotOwner?: string,

  returnTOCNotFound?: string,
  returnTOCNotOwner?: string,

  userAddressNotFound?: string,
  userAddressNotOwner?: string,

  offerPriceCurrencyNameInvalid?: string,
  offerPriceNotUserCurrency?: string,
}

export interface ImportedOffer {
  offerId: string,
  index: number,
}
