import { apiClient } from 'helpers/api-client';
import { CreateReturnTOCRequest, CreateReturnPolicyRequest } from '../../../model/ReturnConditions';

export const api = {
  getReturnTOC: (params: any) => apiClient.get('/seller/return-toc', { params }),
  getReturnTOCDetail: (id: string) => apiClient.get(`/seller/return-toc/${id}`),
  createReturnTOC: (params: CreateReturnTOCRequest) => apiClient.post('/seller/return-toc', params),
  updateReturnTOC: (id: string, params: CreateReturnTOCRequest) => apiClient.put(`/seller/return-toc/${id}`, params),
  deleteReturnTOC: (id: string) => apiClient.delete(`/seller/return-toc/${id}`),

  getReturnPolicies: (params: any) => apiClient.get('/seller/return-policy', { params }),
  getReturnPolicyDetail: (id: string) => apiClient.get(`/seller/return-policy/${id}`),
  createReturnPolicy: (params: CreateReturnPolicyRequest) => apiClient.post('/seller/return-policy', params),
  updateReturnPolicy: (id: string, params: CreateReturnPolicyRequest) => apiClient.put(`/seller/return-policy/${id}`, params),
  deleteReturnPolicy: (id: string) => apiClient.delete(`/seller/return-policy/${id}`),
};
