import React, { Component } from 'react';

class CloseIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...this.props}>
        <path fill="currentColor" stroke="none" className="a"
              d="M14.461,11.569h0l-3.64-3.64,3.64-3.64h0a.376.376,0,0,0,0-.53l-1.72-1.72a.376.376,0,0,0-.53,0h0l-3.64,3.64L4.93,2.038h0a.376.376,0,0,0-.53,0L2.68,3.757a.376.376,0,0,0,0,.53h0l3.64,3.64-3.64,3.64h0a.376.376,0,0,0,0,.53l1.72,1.72a.376.376,0,0,0,.53,0h0l3.64-3.64,3.64,3.64h0a.376.376,0,0,0,.53,0l1.72-1.72a.376.376,0,0,0,0-.53Z"
              transform="translate(-2.571 -1.928)"/>
      </svg>
    );
  }
}

export default CloseIcon;
