import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { combineReducers } from 'redux';
import _ from 'lodash';

import { loadStripe, PaymentIntentResult, Stripe } from '@stripe/stripe-js';

import { AppState } from 'helpers/store/models/AppState';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer,
  LoadingState,
  RequestActionTypes,
} from 'helpers/redux/redux-helpers';
import i18n from '../../i18n';
import { UserAddress } from '../../model/UserAddress';
import { CheckoutResponse, CheckoutResponseOffer, UpdatedCartItem } from '../../model/Checkout';
import { api } from './api';
import { getPaymentMethodsActions } from '../user/payment-method/ducks';
import { errorToastActions, warnToastWithConfirmActions } from '../toast/ducks';
import { environment } from '../../environments/environment';
import { getShoppingCartActions } from '../shoppingCart/ducs';
import { UserActionTypes } from "saga/user/ducks";
import { Price } from '../../model/Price';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

export enum PaymentStatus {
  created = 'CREATED',
  actionRequired = 'ACTION_REQUIRED',
  success = 'SUCCESS',
  error = 'ERROR',
}

export interface PaymentResult {
  paymentId: string;
  status: PaymentStatus;
  clientSecret: string | null;
}

export interface OrderPaymentResult extends PaymentResult {
  earnedCreditAmount?: number | null;
}

export interface SelectedData {
  deliveryTypeId?: string;
  deliveryPrice?: Price;
  note?: string;
  cartIdsString?: string;
}

/* STATE */
export interface CheckoutState {
  selectedDeliveryAddress: UserAddress | null;
  selectedInvoicingAddress: UserAddress | null;
  checkoutResponse: CheckoutResponse | null;
  checkoutSelectedData: Record<string, SelectedData>;
  orderRequestState: LoadingState;
  checkoutRequestState: LoadingState;
  confirmPaymentResponse: OrderPaymentResult | null,
}

/* ACTION TYPES */
export enum CheckoutActionTypes {
  SetDeliveryAddress = '@@Checkout/SET_DELIVERY_ADDRESS',
  SetInvoicingAddress = '@@Checkout/SET_INVOICING_ADDRESS',
  GetCheckoutResponse = '@@Checkout/GET_CHECKOUT_RESPONSE',
  CreateOrder = '@@Checkout/CREATE_ORDER',
  ConfirmOrder = '@@Checkout/CONFIRM_ORDER',
  UpdateSelectedData = '@@Checkout/UPDATE_SELECTED_DATA',
}

/* ACTIONS */
export const setDeliveryAddressAction = createActionCreator(CheckoutActionTypes.SetDeliveryAddress);
export const setInvoicingAddressAction = createActionCreator(CheckoutActionTypes.SetInvoicingAddress);
export const getCheckoutResponseActions = createApiActionCreators(CheckoutActionTypes.GetCheckoutResponse);
export const createOrderActions = createApiActionCreators(CheckoutActionTypes.CreateOrder);
export const confirmOrderActions = createApiActionCreators(CheckoutActionTypes.ConfirmOrder);
export const updateSelectedDataAction = createActionCreator(CheckoutActionTypes.UpdateSelectedData);

/* REDUCERS */
const initialState: CheckoutState = {
  selectedDeliveryAddress: null,
  selectedInvoicingAddress: null,
  checkoutResponse: null,
  checkoutSelectedData: {},
  orderRequestState: LoadingState.success,
  checkoutRequestState: LoadingState.loading,
  confirmPaymentResponse: null,
};

const selectedDeliveryAddress = createReducer(initialState.selectedDeliveryAddress, {
  [CheckoutActionTypes.SetDeliveryAddress]: (_state: UserAddress, payload: UserAddress) => payload,
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: UserAddress) => initialState.selectedDeliveryAddress,
  },
});

const selectedInvoicingAddress = createReducer(initialState.selectedInvoicingAddress, {
  [CheckoutActionTypes.SetInvoicingAddress]: (_state: UserAddress, payload: UserAddress) => payload,
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: UserAddress) => initialState.selectedInvoicingAddress,
  },
});

const checkoutResponse = createReducer(initialState.checkoutResponse, {
  [CheckoutActionTypes.GetCheckoutResponse]: {
    [RequestActionTypes.SUCCESS]: (_state: CheckoutResponse, payload: CheckoutResponse) => payload,
    [RequestActionTypes.FAILURE]: (_state: CheckoutResponse) => initialState.checkoutResponse,
  },
  [CheckoutActionTypes.CreateOrder]: {
    [RequestActionTypes.FAILURE]: (state: CheckoutResponse, payload?: Array<UpdatedCartItem>) => {

      if (state && payload && payload.length) {

        const updatedRepsoneData = state.responseData.map((responseData) => {

          const updatedGroupedItems = responseData.groupedItems.map((groupedItem) => {

            const updatedOffers = groupedItem.offers.map((checkoutOffer) => {
              
              const dataToUpdate = payload.find((updatedCartItem) => updatedCartItem.offerId === checkoutOffer.id);
              
              return ({
                ...checkoutOffer,
                count: dataToUpdate?.availableQuantity !== undefined ? dataToUpdate?.availableQuantity : checkoutOffer.count,
                countInCart: dataToUpdate?.quantity !== undefined ? dataToUpdate.quantity : checkoutOffer.countInCart,
                isAvailable: dataToUpdate?.isAvailable !== undefined ? dataToUpdate.isAvailable : checkoutOffer.isAvailable,
              });
            });

            return ({
              ...groupedItem,
              offers: updatedOffers
            });
          });

          return ({
            ...responseData,
            groupedItems: updatedGroupedItems
          })
        });
        
        return ({
          ...state,
          responseData: updatedRepsoneData
        })
      }

      return { ...state };
    }
  }
});

const checkoutSelectedData = createReducer(initialState.checkoutSelectedData, {
  [CheckoutActionTypes.GetCheckoutResponse]: {
    [RequestActionTypes.SUCCESS]: (state: Record<string, SelectedData>, payload: CheckoutResponse) =>
      payload.responseData.reduce((l1, seller) => ({
        ...l1,
        ...seller.groupedItems.reduce((l2, group) => {
          const cartIdsString = group.offers.map(offer => offer.shoppingCartId).join('|');
          const existingGroupKey = Object.keys(state).find(key => state[key].cartIdsString === cartIdsString);
          const singleDeliveryType = group.deliveryTypes.length === 1 ? group.deliveryTypes[0] : null;

          return {
            ...l2,
            [group.id]: {
              deliveryTypeId: singleDeliveryType?.deliveryTypeId,
              deliveryPrice: singleDeliveryType?.price,
              ...(existingGroupKey ? state[existingGroupKey] : {}),
              cartIdsString,
            }
          }
        }, {}),
      }), {}),
    [RequestActionTypes.FAILURE]: (_state: Record<string, SelectedData>) => initialState.checkoutSelectedData,
  },
  [CheckoutActionTypes.UpdateSelectedData]:
    (state: Record<string, SelectedData>, payload: {id: string, data: SelectedData}) => ({
      ...state,
      [payload.id]: {
        ...(state[payload.id] || {}),
        ...payload.data,
      }
    }),
});

const orderRequestState = createLoadingStateReducer(initialState.orderRequestState, {
  [CheckoutActionTypes.CreateOrder]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [CheckoutActionTypes.ConfirmOrder]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const checkoutRequestState = createLoadingStateReducer(initialState.checkoutRequestState, {
  [CheckoutActionTypes.GetCheckoutResponse]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const confirmPaymentResponse = createReducer(initialState.confirmPaymentResponse, {
  [CheckoutActionTypes.ConfirmOrder]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderPaymentResult | null, payload: OrderPaymentResult) => payload,
    [RequestActionTypes.REQUEST]: (_state: OrderPaymentResult | null) => initialState.confirmPaymentResponse,
    [RequestActionTypes.FAILURE]: (_state: OrderPaymentResult | null) => initialState.confirmPaymentResponse,
  },
  [CheckoutActionTypes.CreateOrder]: {
    [RequestActionTypes.REQUEST]: (_state: OrderPaymentResult | null) => initialState.confirmPaymentResponse,
    [RequestActionTypes.SUCCESS]: (_state: OrderPaymentResult, payload: OrderPaymentResult) => payload,
  },
});

export default combineReducers<CheckoutState>({
  selectedDeliveryAddress,
  selectedInvoicingAddress,
  checkoutResponse,
  checkoutSelectedData,
  orderRequestState,
  checkoutRequestState,
  confirmPaymentResponse,
});

/* SELECTORS */
const selectCheckoutState = (state: AppState) => state.checkout;

export const selectCheckoutDeliveryAddress = (state: AppState): UserAddress | null =>
  selectCheckoutState(state).selectedDeliveryAddress;

export const selectCheckoutInvoicingAddress = (state: AppState): UserAddress | null =>
  selectCheckoutState(state).selectedInvoicingAddress;

export const selectCheckoutResponse = (state: AppState): CheckoutResponse | null =>
  selectCheckoutState(state).checkoutResponse;

export const selectCheckoutSelectedData = (state: AppState): Record<string, SelectedData> =>
  selectCheckoutState(state).checkoutSelectedData;

export const selectOrderRequestState = (state: AppState): LoadingState =>
  selectCheckoutState(state).orderRequestState;

export const selectCheckoutRequestState = (state: AppState): LoadingState =>
  selectCheckoutState(state).checkoutRequestState;

export const selectOrderConfirmResult = (state: AppState): OrderPaymentResult | null =>
  selectCheckoutState(state).confirmPaymentResponse;

/* SAGAS */
function* getCheckoutResponse({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getCheckoutResponse);

  if (resp.ok) {
    yield put(getPaymentMethodsActions.request(resp.data.country));
    yield put(getCheckoutResponseActions.success(resp.data));
  } else {
    if (
      resp.response?.data?.errors &&
      resp.response.data.errors[0]?.message &&
      ((
        resp.response.data.errors[0]?.message &&
        payload?.hideBadRequestTypes?.length &&
        !payload.hideBadRequestTypes.includes(resp.response.data.errors[0].type)
      ) || (!payload?.hideBadRequestTypes))
    ) {

      if (resp.response.data.errors[0].type === 'checkout.cartUpdated' || resp.response.data.errors[0].type === 'checkout.productsInDifferentCurrency') {
        yield put(warnToastWithConfirmActions(resp.response.data.errors[0].message));
      } else {
        yield put(errorToastActions(resp.response.data.errors[0].message));
      }
    }

    yield put(getCheckoutResponseActions.failure());
    yield put(getShoppingCartActions.request());
    yield put(push(i18n.t('Routes.Cart')));
  }
}

function* processOrderPayment(data: OrderPaymentResult) {
  if (data.status === PaymentStatus.success) {
    yield put(createOrderActions.success(data));
    yield put(getShoppingCartActions.request());
    yield put(push(i18n.t('Routes.OrderCreated')));
  } else if (data.status === PaymentStatus.actionRequired) {
    const { checkoutResponse } = yield select(selectCheckoutResponse);

    const stripe: Stripe | null = yield call(
      loadStripe,
      environment.stripe[`publishableKey${checkoutResponse?.country || ''}`] ||
        environment.stripe.publishableKeySK
    );

    if (stripe && data.clientSecret) {
      const result: PaymentIntentResult = yield call(stripe.confirmCardPayment, data.clientSecret);

      if (result.error) {
        yield put(createOrderActions.failure());
        yield put(errorToastActions(i18n.t('Checkout.Errors.SecureFailed')));
      } else {
        yield put(confirmOrderActions.request({
          paymentId: data.paymentId,
          order: true
        }));
      }
    } else {
      yield put(createOrderActions.failure());
      yield put(errorToastActions(i18n.t('Checkout.Errors.CreateOrderFailed')));
    }
  } else {
    yield put(createOrderActions.failure());
    yield put(errorToastActions(i18n.t('Checkout.Errors.CreateOrderFailed')));
  }
}

function* createOrder() {
  yield delay(0);
  const delivery: UserAddress | null = yield select(selectCheckoutDeliveryAddress);
  const invoicing: UserAddress | null = yield select(selectCheckoutInvoicingAddress);
  const response: CheckoutResponse = yield select(selectCheckoutResponse);
  const selectedData: Record<string, SelectedData> = yield select(selectCheckoutSelectedData);

  const unavailableOffers = response.responseData.reduce((unavailableOffers: CheckoutResponseOffer[], responseData) => {
    const unavailalbeOffersInGroups = responseData.groupedItems.reduce((unavailableOffersInGroup: CheckoutResponseOffer[], groupedItems) => {
      const onlyUnavailable = groupedItems.offers.filter((offer) => offer.isAvailable === false);

      return [...unavailableOffersInGroup, ...onlyUnavailable];
    }, []);

    return [...unavailableOffers, ...unavailalbeOffersInGroups];
  }, []);

  if (!delivery) {
    yield put(createOrderActions.failure());
    yield put(errorToastActions(i18n.t('Checkout.Errors.DeliveryAddressRequired')));
    return;
  } else if (!invoicing) {
    yield put(createOrderActions.failure());
    yield put(errorToastActions(i18n.t('Checkout.Errors.InvoiceAddressRequired')));
    return;
  } else if (unavailableOffers.length) {
    yield put(createOrderActions.failure());
    yield put(warnToastWithConfirmActions(i18n.t('Checkout.Errors.OfferUpdated')));
    return;
  } else if (Object.values(selectedData).some((value: SelectedData) => !value.deliveryTypeId)) {
    yield put(createOrderActions.failure());
    yield put(errorToastActions(i18n.t('Checkout.Errors.DeliveryOptionsRequired')));
    return;
  }

  const resp: ExtendedAxiosResponse = yield call(
    api.createOrder,
    {
      checkoutResponseId: response.id,
      deliveryGroups: Object.keys(selectedData)
        .map(groupKey => ({
          id: groupKey,
          deliveryTypeId: selectedData[groupKey].deliveryTypeId,
          note: selectedData[groupKey].note || undefined
        })),
      deliveryAddressId: delivery.id,
      invoiceAddressId: invoicing.id,
    }
  );

  if (resp.ok) {
    yield call(processOrderPayment, resp.data);
  } else {
    if (resp.response && resp.response.status === 400 &&
      resp.response.data && resp.response.data.errors.length &&
      resp.response.data.errors[0].type === 'orders.offerUpdated'
    ) {
      yield put(warnToastWithConfirmActions(resp.response.data.errors[0].message));

      if (resp.response.data.errors[0].updatedCartItems && resp.response.data.errors[0].updatedCartItems.length) {
        yield put(createOrderActions.failure(resp.response.data.errors[0].updatedCartItems));
      }

      // yield put(getCheckoutResponseActions.request({
      //   hideBadRequestTypes: ['checkout.cartUpdated']
      // }));
    } else if (resp.response?.data?.errors && resp.response.data.errors[0]?.message) {
      yield put(errorToastActions(resp.response.data.errors[0].message));
      yield put(createOrderActions.failure());
    } else {
      yield put(createOrderActions.failure());
    }
  }
}

function* confirmOrder({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.confirmOrder, _.pick(payload, 'paymentId'));

  if (resp.ok) {
    yield put(confirmOrderActions.success(resp.data));
    if (payload.order) {
      yield call(processOrderPayment, resp.data);
    }
  } else {
    yield put(confirmOrderActions.failure());
  }
}

/* EXPORT */
export function* checkoutSaga() {
  yield takeLatest(
    createActionType(CheckoutActionTypes.GetCheckoutResponse, RequestActionTypes.REQUEST),
    getCheckoutResponse,
  );

  yield takeLatest(
    createActionType(CheckoutActionTypes.CreateOrder, RequestActionTypes.REQUEST),
    createOrder,
  );

  yield takeLatest(
    createActionType(CheckoutActionTypes.ConfirmOrder, RequestActionTypes.REQUEST),
    confirmOrder,
  );
}

