import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckIcon from 'assets/Icons/CheckIcon';
import { Box, Button } from '@material-ui/core';
import { initGA } from "helpers/other/googleAnalytics";
import { initFB } from "helpers/other/facebookPixel";
import { AppState } from 'helpers/store/models/AppState';
import { getHelpdeskUrl } from 'helpers/other/getHelpdeskUrl';
import StyledCheckbox from '../../../components/Checkbox/Checkbox';
import { 
  selectUserGDPRConsent, createGDPRConsentActions, updateGDPRConsentActions
} from 'saga/user/ducks';
import {
  getCookieConsentStateFromStorage,
  getAnalyticsCookieConsentStateFromStorage,
  getMarketingCookieConsentStateFromStorage
} from "helpers/other/cookies";

import PopUp from '../../../pages/MyAccount/PopUp';

import './CookieSettingsPopUp.scss';

const CookieSettingsPopUp = (props: StateToProps & DispatchToProps) => {
  const { t } = useTranslation();
  const [analyticCookie, setAnalyticCookie] = useState(getCookieConsentStateFromStorage() ? getAnalyticsCookieConsentStateFromStorage() : true);
  const [marketingCookie, setMarketingCookie] = useState(getCookieConsentStateFromStorage() ? getMarketingCookieConsentStateFromStorage() : true);

  const handleSendRequest = (analytics?: boolean, marketing?: boolean) => {
    const payload = {
      required: true,
      analytics: analytics !== undefined ? analytics : true,
      marketing: marketing !== undefined ? marketing : true
    }
    if (getCookieConsentStateFromStorage()) {
      props.updateGDPRConsent({
        id: getCookieConsentStateFromStorage().id,
        body: payload
      });
    } else {
      props.setGDPRConsent(payload);
    }
  }

  const handleSaveSettings = () => {
    handleSendRequest(analyticCookie, marketingCookie);
    if (analyticCookie) {
      initGA();
      initFB();
    }
    Swal.close();
  };

  const handleAcceptAll = () => {
    handleSendRequest();
    initGA();
    initFB();
    Swal.close();
  };

  return (
    <PopUp title={t("CookieBar.PopUp.Title")} className="cookies-settings">
      <Box width="100%" mb={2} className="cookies-settings__setting setting" display="flex" flexDirection="row">
        <div className="setting__check-icon setting__check-icon--basic">
          <CheckIcon />
        </div>
        <Box width="100%" ml={2} display="flex" flexDirection="column">
          <Box mb={2} display="flex" justifyContent="space-between">
            <span className="setting__title">
              {t("CookieBar.PopUp.NeccessaryCookies")}
            </span>
            <div className="setting__option setting__option--allowed">
              {t("CookieBar.PopUp.Allowed")}
            </div>
          </Box>
          <Box className="setting__desc" textAlign="start">
            {t("CookieBar.PopUp.NeccessaryCookiesDesc")}
          </Box>
        </Box>
      </Box>
      <Box width="100%" mb={2} className="cookies-settings__setting setting" display="flex" flexDirection="row">
        <div className={`setting__check-icon ${analyticCookie ? "setting__check-icon--checked" : ""}`}>
          <StyledCheckbox checked={analyticCookie} onChange={e => setAnalyticCookie(e.target.checked)}/>
        </div>
        <Box width="100%" mt={1} ml={2} display="flex" flexDirection="column">
          <Box mb={2} display="flex" justifyContent="space-between">
            <span className="setting__title">
              {t("CookieBar.PopUp.AnalyticCookies")}
            </span>
            <div className={`setting__option ${analyticCookie ? "setting__option--allowed" : ""}`}>
              {
                analyticCookie ? 
                t("CookieBar.PopUp.Allowed")
                :
                t("CookieBar.PopUp.Declined")
              }
            </div>
          </Box>
          <Box className="setting__desc" textAlign="start">
            {t("CookieBar.PopUp.AnalyticCookiesDesc")}
          </Box>
        </Box>
      </Box>
      <Box width="100%" mb={2} className="cookies-settings__setting setting" display="flex" flexDirection="row">
        <div className={`setting__check-icon ${marketingCookie ? "setting__check-icon--checked" : ""}`}>
          <StyledCheckbox checked={marketingCookie} onChange={e => setMarketingCookie(e.target.checked)}/>
        </div>
        <Box width="100%" mt={1} ml={2} display="flex" flexDirection="column">
          <Box mb={2} display="flex" justifyContent="space-between">
            <span className="setting__title">
              {t("CookieBar.PopUp.MarketingCookies")}
            </span>
            <div className={`setting__option ${marketingCookie ? "setting__option--allowed" : ""}`}>
              {
                marketingCookie ? 
                t("CookieBar.PopUp.Allowed")
                :
                t("CookieBar.PopUp.Declined")
              }
            </div>
          </Box>
          <Box className="setting__desc" textAlign="start">
            {t("CookieBar.PopUp.MarketingCookiesDesc")}
          </Box>
        </Box>
      </Box>
      <Box my={2} width="100%" display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <Button variant="outlined" className="cookies-settings__save" onClick={handleSaveSettings}>
          {t("CookieBar.PopUp.SaveSettings")}
        </Button>
        <Button variant="contained" className="cookies-settings__accept" onClick={handleAcceptAll}>
          {t("CookieBar.Accept")}
        </Button>
      </Box>
      <a
        href={getHelpdeskUrl(t("HelpdeskUrlConfigName.CookiesMoreInfo"))}
        target="_blank"
        rel="noopener noreferrer"
        className="cookies-settings__more-info link"
      >
        {t("CookieBar.PopUp.MoreInfo")}
      </a>
    </PopUp>
  );
};

interface StateToProps {
  userGDPRConsent: any;
}

interface DispatchToProps {
  setGDPRConsent: (payload: any) => void;
  updateGDPRConsent: (payload: any) => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  userGDPRConsent: selectUserGDPRConsent(state)
});

const mapDispatchToProps: DispatchToProps = {
  setGDPRConsent: createGDPRConsentActions.request,
  updateGDPRConsent: updateGDPRConsentActions.request
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieSettingsPopUp);
