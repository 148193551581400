import { apiClient } from '../../helpers/api-client';

export const api = {
  getOrders: (params: string) => apiClient.get(`orders/my?${params}`),
  getOrderDetail: (orderId: string, params?: any) => apiClient.get(`orders/my/${orderId}`, { params }),
  getSoldOrders: (params: string) => apiClient.get(`orders/sold?${params}`),
  getSoldOrderDetail: (id: string) => apiClient.get(`orders/sold/${id}`),
  postOrder: (body: any) => apiClient.post('orders/create', body),
  changeSoldOrderStatus: (id: string, body: any) => apiClient.post(`/orders/${id}/status/change`, body),
  stornoOrder: (id: string) => apiClient.post(`/orders/${id}/status/storno`, {}),
  confirmOrderReceived: (id: string) => apiClient.post(`/orders/${id}/status/confirm-received`, {}),
  postOrderRefundRequest: (params: any) => apiClient.post('/orders/refunds/request', params),
  getMyOrdersRefunds: (params: any) => apiClient.get('/orders/refunds/my', { params }),
  getReceivedOrdersRefunds: (params: any) => apiClient.get('/orders/refunds/sold', { params }),
  getMyRefundRequestDetail: (id: string, params: any) => apiClient.get(`/orders/refunds/my/${id}`, { params }),
  getReceivedRefundRequestDetail: (id: string, params: any) => apiClient.get(`/orders/refunds/sold/${id}`, { params }),
  acceptOrderRefundRequest: (id: string, params: any) => apiClient.post(`/orders/refunds/${id}/accept`, params),
  rejectOrderRefundRequest: (id: string, params: any) => apiClient.post(`/orders/refunds/${id}/reject`, params),
  getOrderStatusChangeLog: (orderId: string, params?: any) => apiClient.get(`/orders/${orderId}/status/log`, { params }),
};