import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from './api';
import { AppState } from 'helpers/store/models/AppState';
import {
  errorResponseActions,
  successResponseActions,
} from '../response/ducks';
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  createLoadingStateReducer,
  LoadingState,
  createActionCreator,
} from 'helpers/redux/redux-helpers';
import { combineReducers } from 'redux';
import i18n from '../../i18n';
import { successToastActions } from '../toast/ducks';
import { WatchList, WatchListItem, WatchListItemsPaginated } from '../../model/WatchList';
import { Offer, OffersList } from '../../model/Offer';
import { UserActionTypes } from 'saga/user/ducks';
import { getWatchListFromStorage, localWatchList, removeWatchListFromStorage } from 'helpers/other/watchList';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

export interface WatchListDetail {
  watchListId: string | null,
  offers: {
    total: number,
    limit: number,
    skip: number,
    data: Array<Offer>,
  }
}

export interface WatchListState {
  watchLists: WatchList[] | null,
  watchListDetail: WatchListDetail | null,
  watchListRequestState: LoadingState,
  watchedOffersIds: string[],
}

export enum WatchListActionsTypes {
  GetWatchLists = '@@WatchList/GetWatchList',
  CreateWatchList = '@@WatchList/CreateWatchList',
  UpdateWatchList = '@@WatchList/UpdateWatchList',
  DeleteWatchList = '@@WatchList/DeleteWatchList',
  MergeLocalWatchList = '@@WatchList/MergeLocalWatchList',

  GetWatchListItems = '@@WatchList/GetWatchListItems',
  CreateWatchListItem = '@@WatchList/CreateWatchListItem',
  DeleteWatchListItem = '@@WatchList/DeleteWatchListItem',

  GetWatchListItemsById = '@@WatchList/GetWatchListItemsById',
  RemoveWatchListItemFromState = '@@WatchList/RemoveWatchListItemFromState',

  SetWatchedOffersIds = '@@WatchList/SetWatchedOffersIds',
}

export const getWatchListsActions = createApiActionCreators(WatchListActionsTypes.GetWatchLists);
export const createWatchListActions = createApiActionCreators(WatchListActionsTypes.CreateWatchList);
export const updateWatchListActions = createApiActionCreators(WatchListActionsTypes.UpdateWatchList);
export const deleteWatchListActions = createApiActionCreators(WatchListActionsTypes.DeleteWatchList);
export const mergeLocalWatchListActions = createApiActionCreators(WatchListActionsTypes.MergeLocalWatchList);

export const getWatchListItemsActions = createApiActionCreators(WatchListActionsTypes.GetWatchListItems);
export const createWatchListItemActions = createApiActionCreators(WatchListActionsTypes.CreateWatchListItem);
export const deleteWatchListItemActions = createApiActionCreators(WatchListActionsTypes.DeleteWatchListItem);

export const getWatchListItemsByIdActions = createApiActionCreators(WatchListActionsTypes.GetWatchListItemsById);

export const removeWatchListItemFromStateAction = createActionCreator(WatchListActionsTypes.RemoveWatchListItemFromState);
export const setWatchedOffersIdsAction = createActionCreator(WatchListActionsTypes.SetWatchedOffersIds);

const initialState: WatchListState = {
  watchLists: null,
  watchListDetail: null,
  watchListRequestState: LoadingState.success,
  watchedOffersIds: [],
};

const watchLists = createReducer(initialState.watchLists, {
  [WatchListActionsTypes.GetWatchLists]: {
    [RequestActionTypes.SUCCESS]: (_state: WatchList[] | null, payload: WatchList[]) => payload,
    [RequestActionTypes.REQUEST]: (_state: WatchList[] | null) => null,
    [RequestActionTypes.FAILURE]: (_state: WatchList[] | null) => null,
  },
  [WatchListActionsTypes.CreateWatchList]: {
    [RequestActionTypes.SUCCESS]: (state: WatchList[] | null, payload: WatchList) => {
      if (state && state.length) {
        const newState = [...state];

        newState.push(payload);

        return newState;
      }

      return [payload];
    }
  },
  [WatchListActionsTypes.UpdateWatchList]: {
    [RequestActionTypes.SUCCESS]: (state: WatchList[] | null, payload: WatchList): WatchList[] | null => {
      if (state && state.length) {
        const newState = [...state];

        const originalWatchList = newState.find((watchList: WatchList) => watchList.id === payload.id);

        const indexOfOriginalWatchList = originalWatchList ? newState.indexOf(originalWatchList) : undefined;

        if (indexOfOriginalWatchList !== undefined && indexOfOriginalWatchList !== -1) {
          newState.splice(indexOfOriginalWatchList, 1, payload);
        }

        return newState;
      }

      return [payload];
    }
  },
  [WatchListActionsTypes.DeleteWatchList]: {
    [RequestActionTypes.SUCCESS]: (state: WatchList[] | null, payload: WatchList): WatchList[] | null => {
      if (state && state.length) {
        const newState = [...state];

        const watchListToDelete = newState.find((watchList: WatchList) => watchList.id === payload.id);

        const indexOfWatchListToDelete = watchListToDelete ? newState.indexOf(watchListToDelete) : undefined;

        if (indexOfWatchListToDelete !== undefined && indexOfWatchListToDelete !== -1) {
          newState.splice(indexOfWatchListToDelete, 1);
        }

        return newState;
      }

      return null;
    }
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: WatchList[] | null) => null,
  }
});

const watchListDetail = createReducer(initialState.watchListDetail, {
  [WatchListActionsTypes.GetWatchListItems]: {
    [RequestActionTypes.SUCCESS]: (_state: WatchListState['watchListDetail'], payload: { watchListId: string, watchListItems: WatchListItemsPaginated }): WatchListState['watchListDetail'] => {
      if (payload && payload.watchListId && payload.watchListItems) {

        const offers = {
          ...payload.watchListItems,
          data: payload.watchListItems.data.reduce((offers: Offer[], watchListItem: WatchListItem) => {
            if (watchListItem.offer) {
              offers.push(watchListItem.offer);
            }

            return offers;
          }, [])
        }

        return {
          watchListId: payload.watchListId,
          offers
        };

      }

      return null;
    }
  },
  [WatchListActionsTypes.CreateWatchListItem]: {
    [RequestActionTypes.SUCCESS]: (state: WatchListState['watchListDetail'], payload: WatchListItem ): WatchListState['watchListDetail'] => {
      if (state?.watchListId && state.offers && payload && payload.watchListId && payload.offer &&
        (state.watchListId === payload.watchListId)
      ) {
        const offersData = [...state.offers.data];
        payload.offer && offersData.push(payload.offer);

        return {
          ...state,
          offers: {
            ...state.offers,
            data: offersData 
          }
        };
      }

      return state ? { ...state } : null;
    }
  },
  [WatchListActionsTypes.DeleteWatchListItem]: {
    [RequestActionTypes.SUCCESS]: (state: WatchListState['watchListDetail'], payload: { watchListId: string, watchListItem: WatchListItem }): WatchListState['watchListDetail'] => {
      if (state?.watchListId && payload?.watchListId && payload.watchListItem?.offerId &&
        (state.watchListId === payload.watchListId)
      ) {
        const offersData = [...state.offers.data];

        const offerToRemove = offersData.find((offer: Offer) => offer.id === payload.watchListItem.offerId);

        const indexOfOfferToRemove = offerToRemove ? offersData.indexOf(offerToRemove) : undefined;

        if (indexOfOfferToRemove !== undefined && indexOfOfferToRemove !== -1) {
          offersData.splice(indexOfOfferToRemove, 1);

          return {
            ...state,
            offers: {
              ...state.offers,
              total: state.offers.total - 1,
              data: offersData 
            }
          };
        }

        return {
          ...state
        };
      }

      return null;
    }
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: WatchList[] | null) => null,
  },
  [WatchListActionsTypes.GetWatchListItemsById]: {
    [RequestActionTypes.SUCCESS]: (_state: WatchListState['watchListDetail'], payload: OffersList): WatchListState['watchListDetail'] => {
      if (payload.data){
        return {
          watchListId: null,
          offers: payload
        };
      }

      return null;
    }
  },
  [WatchListActionsTypes.RemoveWatchListItemFromState]: (state: WatchListState['watchListDetail'], payload: { offerIdToRemove: string }): WatchListState['watchListDetail'] => {
    if (state && state.watchListId === null && state.offers) {
      const offersData = [...state.offers.data];

      const offerToRemove = offersData.find((offer: Offer) => offer.id === payload.offerIdToRemove);

      const indexOfOfferToRemove = offerToRemove ? offersData.indexOf(offerToRemove) : undefined;

      if (indexOfOfferToRemove !== undefined && indexOfOfferToRemove !== -1) {
        offersData.splice(indexOfOfferToRemove, 1);

        return {
          ...state,
          offers: {
            ...state.offers,
            total: state.offers.total - 1,
            data: offersData 
          }
        };
      }

      return {
        ...state
      };
    }

    return null;
  },
  [WatchListActionsTypes.GetWatchLists]: {
    [RequestActionTypes.REQUEST]: (_state: WatchList[] | null) => null,
  }
});

const watchedOffersIds = createReducer(initialState.watchedOffersIds, {
  [WatchListActionsTypes.SetWatchedOffersIds]: (_state: WatchListState['watchedOffersIds'], payload: string[]) => payload,
  [WatchListActionsTypes.CreateWatchListItem]: {
    [RequestActionTypes.SUCCESS]: (state: WatchListState['watchedOffersIds'], payload: WatchListItem ): WatchListState['watchedOffersIds'] => {
      const newState = [ ...state];

      newState.push(payload.offerId);

      return newState;
    }
  },
  [WatchListActionsTypes.DeleteWatchListItem]: {
    [RequestActionTypes.SUCCESS]: (state: WatchListState['watchedOffersIds'], payload: { watchListId: string, watchListItem: WatchListItem }): WatchListState['watchedOffersIds'] => {
      const newState = state.filter((offerId: string) => offerId !== payload.watchListItem.offerId);

      return newState;
    }
  },
});

const watchListRequestState = createLoadingStateReducer(initialState.watchListRequestState, {
  [WatchListActionsTypes.GetWatchLists]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.CreateWatchList]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.UpdateWatchList]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.DeleteWatchList]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.MergeLocalWatchList]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.GetWatchListItems]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.CreateWatchListItem]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.DeleteWatchListItem]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [WatchListActionsTypes.GetWatchListItemsById]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ]
});

export default combineReducers<WatchListState>({
  watchLists,
  watchListDetail,
  watchListRequestState,
  watchedOffersIds
});

export const selectWatchListState = (state: AppState) => state.watchList;

export const selectWatchLists = (state: AppState): WatchListState['watchLists'] => selectWatchListState(state).watchLists;

export const selectWatchListDetail = (state: AppState): WatchListState['watchListDetail'] => selectWatchListState(state).watchListDetail;

export const selectWatchListRequestState = (state: AppState): WatchListState['watchListRequestState'] => selectWatchListState(state).watchListRequestState;

export const selectWatchedOffersIds = (state: AppState): WatchListState['watchedOffersIds'] => selectWatchListState(state).watchedOffersIds;

/* SAGAS */
function* getWatchLists({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getWatchLists, payload);

  if (resp.ok) {
    yield put(getWatchListsActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getWatchListsActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* createWatchList({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.createWatchList, payload);

  if (resp.ok) {
    yield put(createWatchListActions.success(resp.data));
    yield put(successResponseActions(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.WatchListSuccefullyCreated')));

  } else {
    yield put(createWatchListActions.failure());
    yield put(errorResponseActions(resp.response.data));
    // yield put(errorToastActions(i18n.t('Errors.WatchListFailedToCreate')));
  }
}

function* updateWatchList({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateWatchList, payload.watchListId, payload.params);

  if (resp.ok) {
    yield put(updateWatchListActions.success(resp.data));
    yield put(successResponseActions(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.WatchListSuccefullyUpdated')));

  } else {
    yield put(updateWatchListActions.failure());
    yield put(errorResponseActions(resp.response.data));
    // yield put(errorToastActions(i18n.t('Errors.WatchListFailedToUpdate')));
  }
}

function* deleteWatchList({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteWatchList, payload.watchListId);

  if (resp.ok) {
    yield put(deleteWatchListActions.success(resp.data));
    yield put(successResponseActions(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.WatchListSuccefullyDeleted')));

  } else {
    yield put(deleteWatchListActions.failure());
    yield put(errorResponseActions(resp.response.data));
    // yield put(errorToastActions(i18n.t('Errors.WatchListFailedToDelete')));
  }
}

function* mergeLocalWatchList() {
  const offerIds = getWatchListFromStorage(localWatchList);
  
  if (offerIds && offerIds.length){
    const resp: ExtendedAxiosResponse = yield call(api.mergeWatchList, { offerIds });

    if (resp.ok) {
      yield put(mergeLocalWatchListActions.success(resp.data));
      yield put(successResponseActions(resp.data));
      yield call(removeWatchListFromStorage);
    } else {
      yield put(mergeLocalWatchListActions.failure());
      yield put(errorResponseActions(resp.response.data));
    }
  }
}

function* getWatchListItems({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getWatchListItems, payload.watchListId, payload.params);

  if (resp.ok) {
    if ((payload.params.$limit && payload.params.$limit === -1) || Array.isArray(resp.data)){
      yield put(getWatchListItemsActions.success({
        watchListId: payload.watchListId,
        watchListItems: {
          total: resp.data.length,
          limit: 0,
          skip: 0,
          data: resp.data
        }
      }));
    } else {
      yield put(getWatchListItemsActions.success(resp.data));
    }

    yield put(successResponseActions(resp.data));
  } else {
    yield put(getWatchListItemsActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* createWatchListItem({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.createWatchListItem, payload.watchListId, payload.params);

  if (resp.ok) {
    yield put(createWatchListItemActions.success(resp.data));
    yield put(successResponseActions([{ message: "createWatchListItemSuccess" }, { data: resp.data }]));
    yield put(successToastActions(i18n.t('SuccessToasts.WatchListItemSuccefullyCreated').toString()));
  } else {
    yield put(createWatchListItemActions.failure());
    yield put(errorResponseActions(resp.response.data));
    // yield put(errorToastActions(i18n.t('Errors.WatchListItemFailedToCreate')));
  }
}

function* deleteWatchListItem({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteWatchListItem, payload.watchListId, payload.offerId);

  if (resp.ok) {
    yield put(deleteWatchListItemActions.success({
      watchListId: payload.watchListId,
      watchListItem: resp.data as WatchListItem
    }));
    yield put(successResponseActions(resp.data));

    if (payload.addedToCart) {
      yield put(successToastActions(i18n.t('SuccessToasts.WatchListItemSuccefullyMovedToCart')));
    } else {
      yield put(successToastActions(i18n.t('SuccessToasts.WatchListItemSuccefullyDeleted')));
    }

  } else {
    yield put(deleteWatchListItemActions.failure());
    yield put(errorResponseActions(resp.response.data));
    // yield put(errorToastActions(i18n.t('Errors.WatchListItemFailedToDelete')));
  }
}

function* getWatchListItemsById({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOffersById, payload.params);

  if (resp.ok) {

    if ((payload.params.$limit && payload.params.$limit === -1) || Array.isArray(resp.data)){
      yield put(getWatchListItemsByIdActions.success({
        total: resp.data.length,
        limit: 0,
        skip: 0,
        data: resp.data
      }));
    } else {
      yield put(getWatchListItemsByIdActions.success(resp.data));
    }

    yield put(successResponseActions(resp.data));
  } else {
    yield put(getWatchListItemsByIdActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

/* EXPORT */
export function* watchListSaga() {
  yield takeLatest(
    createActionType(WatchListActionsTypes.GetWatchLists, RequestActionTypes.REQUEST),
    getWatchLists,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.CreateWatchList, RequestActionTypes.REQUEST),
    createWatchList,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.UpdateWatchList, RequestActionTypes.REQUEST),
    updateWatchList,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.DeleteWatchList, RequestActionTypes.REQUEST),
    deleteWatchList,
  );

  yield takeLatest(
    createActionType(UserActionTypes.Login, RequestActionTypes.SUCCESS),
    mergeLocalWatchList,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.GetWatchListItems, RequestActionTypes.REQUEST),
    getWatchListItems,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.CreateWatchListItem, RequestActionTypes.REQUEST),
    createWatchListItem,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.DeleteWatchListItem, RequestActionTypes.REQUEST),
    deleteWatchListItem,
  );

  yield takeLatest(
    createActionType(WatchListActionsTypes.GetWatchListItemsById, RequestActionTypes.REQUEST),
    getWatchListItemsById,
  );
}
