import { ComponentType, lazy } from 'react';

const storageName = 'pageWasAlreadyForceRefreshed'

function retry<T extends ComponentType<any>>(lazyComponent: () => Promise<{ default: T }>, attemptsLeft: number = 3, timeout: number = 1500): Promise<{ default: T }> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then((component) => {
        window.localStorage.removeItem(storageName);
        resolve(component);
      })
      .catch((error) => {
        const pageWasAlreadyRefreshed = window.localStorage.getItem(storageName);

        if (!pageWasAlreadyRefreshed) {
          window.localStorage.setItem(storageName, 'true');
          return window.location.reload();
        }

        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }

          retry(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, timeout);
      });
  });
}

export function lazyWithRetry<T extends ComponentType<any>>(lazyComponent: () => Promise<{ default: T }>) {
  return lazy(() => retry(lazyComponent));
}
