import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import { api, OrderRatingsCreateRequest } from "./api";
import { AppState } from "helpers/store/models/AppState";
import { errorResponseActions, successResponseActions } from '../response/ducks';
import {
  createActionType,
  createApiActionCreators,
  createLoadingStateReducer,
  createReducer,
  LoadingState,
  RequestActionTypes,
} from "helpers/redux/redux-helpers";
import { combineReducers } from "redux";
import { OfferOverallRatings, SellerOverallRatings, SellerRatingsState, OfferRatingsState } from "model/Ratings";
import { successToastActions } from "saga/toast/ducks";
import i18n from "i18n";
import { push } from "connected-react-router";
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

export interface OfferRatingsCountState {
  offerId?: string,
  counts: Array<{count: number, rating: number }>
}

/* STATE */
export interface RatingsState {
  sellerOverallRatings: SellerOverallRatings | null,
  offerOverallRatings: OfferOverallRatings | null,
  sellerRatings: SellerRatingsState | null,
  offerRatings: OfferRatingsState | null,
  offerRatingsCounts: OfferRatingsCountState | null,
  ratingRequestState: LoadingState,
  createRatingsRequestState: LoadingState,
  getAllSellersOverallRatingsRequestState: LoadingState,
}

/* ACTION TYPES */
export enum RatingsActionsTypes {
  GetSellersOverallRatings = "@@Ratings/GET_SELLERS_OVERALL_RATINGS",
  GetAllSellersOverallRatings = "@@Ratings/GET_ALL_SELLERS_OVERALL_RATINGS", //same as 'GetSellersOverallRatings', only to specify response that contains all responses
  GetOffersOverallRatings = "@@Ratings/GET_OFFERS_OVERALL_RATINGS",
  GetSellerRatings = "@@Ratings/GET_SELLER_RATINGS",
  GetOfferRatings = "@@Ratings/GET_OFFER_RATINGS",
  CreateOrderRatings = "@@Ratings/CREATE_ORDER_RATINGS",
  GetOfferRatingsCounts = "@@Ratings/GET_OFFER_RATINGS_COUNTS",
}

/* ACTIONS */
export const getSellersOverallRatingsActions = createApiActionCreators(RatingsActionsTypes.GetSellersOverallRatings);

export const getAllSellersOverallRatingsActions = createApiActionCreators(RatingsActionsTypes.GetAllSellersOverallRatings);

export const getOffersOverallRatingsActions = createApiActionCreators(RatingsActionsTypes.GetOffersOverallRatings);

export const getSellerRatingsActions = createApiActionCreators(RatingsActionsTypes.GetSellerRatings);

export const getOfferRatingsActions = createApiActionCreators(RatingsActionsTypes.GetOfferRatings);

export const createOrderRatingsActions = createApiActionCreators(RatingsActionsTypes.CreateOrderRatings);

export const getOfferRatingsCountsActions = createApiActionCreators(RatingsActionsTypes.GetOfferRatingsCounts);

/* REDUCERS */
const initialState: RatingsState = {
  sellerOverallRatings: null,
  offerOverallRatings: null,
  sellerRatings: null,
  offerRatings: null,
  offerRatingsCounts: null,
  ratingRequestState: LoadingState.success,
  createRatingsRequestState: LoadingState.success,
  getAllSellersOverallRatingsRequestState: LoadingState.success,
};

const sellerOverallRatings = createReducer(initialState.sellerOverallRatings, {
  [RatingsActionsTypes.GetSellersOverallRatings]: {
    [RequestActionTypes.SUCCESS]: (_state: SellerOverallRatings | null, payload: SellerOverallRatings) => payload,
    [RequestActionTypes.REQUEST]: () => null,
    [RequestActionTypes.FAILURE]: () => null,
  }
});

const offerOverallRatings = createReducer(initialState.offerOverallRatings, {
  [RatingsActionsTypes.GetOffersOverallRatings]: {
    [RequestActionTypes.SUCCESS]: (_state: OfferOverallRatings | null, payload: OfferOverallRatings) => payload,
    [RequestActionTypes.REQUEST]: () => null,
    [RequestActionTypes.FAILURE]: () => null,
  }
});

const sellerRatings = createReducer(initialState.sellerRatings, {
  [RatingsActionsTypes.GetSellerRatings]: {
    [RequestActionTypes.SUCCESS]: (_state: SellerRatingsState | null, payload: SellerRatingsState) => payload,
    [RequestActionTypes.REQUEST]: () => null,
    [RequestActionTypes.FAILURE]: () => null,
  }
});

const offerRatings = createReducer(initialState.offerRatings, {
  [RatingsActionsTypes.GetOfferRatings]: {
    [RequestActionTypes.SUCCESS]: (_state: OfferRatingsState | null, payload: OfferRatingsState) => payload,
    [RequestActionTypes.REQUEST]: () => null,
    [RequestActionTypes.FAILURE]: () => null,
  }
});

const offerRatingsCounts = createReducer(initialState.offerRatingsCounts, {
  [RatingsActionsTypes.GetOfferRatingsCounts]: {
    [RequestActionTypes.SUCCESS]: (state: OfferRatingsCountState| null, payload: { offerId: string | null, count: number, rating: number }) => {
      if (payload.offerId && payload.rating && (state === null || state.offerId === payload.offerId)) {
        const newRatingCount = {
          rating: payload.rating,
          count: payload.count,
        };

        if (!state || !state.counts.length) {

          return ({
            offerId: payload.offerId,
            counts: [newRatingCount]
          });

        } else {

          const newRatingsCountsArray = [...state.counts];

          const currentRatingCount = newRatingsCountsArray.find((offerCount) => offerCount.rating === payload.rating);

          if (!currentRatingCount) {
            newRatingsCountsArray.push(newRatingCount);
          } else {
            const indexOfCurrentRatingCount = newRatingsCountsArray.indexOf(currentRatingCount);

            if (indexOfCurrentRatingCount > -1) {
              newRatingsCountsArray.splice(indexOfCurrentRatingCount, 1, newRatingCount);
            }
          }

          return({
            ...state,
            counts: newRatingsCountsArray
          });
        }

      }

      return null;
    },
    [RequestActionTypes.REQUEST]: () => null,
    [RequestActionTypes.FAILURE]: () => null,
  }
});

const ratingRequestState = createLoadingStateReducer(initialState.ratingRequestState, {
  [RatingsActionsTypes.GetSellersOverallRatings]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [RatingsActionsTypes.GetOffersOverallRatings]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [RatingsActionsTypes.GetSellerRatings]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [RatingsActionsTypes.GetOfferRatings]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [RatingsActionsTypes.GetOfferRatingsCounts]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const createRatingsRequestState = createLoadingStateReducer(initialState.createRatingsRequestState, {
  [RatingsActionsTypes.CreateOrderRatings]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const getAllSellersOverallRatingsRequestState = createLoadingStateReducer(initialState.getAllSellersOverallRatingsRequestState, {
  [RatingsActionsTypes.GetAllSellersOverallRatings]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});


export default combineReducers<RatingsState>({
  sellerOverallRatings,
  offerOverallRatings,
  sellerRatings,
  offerRatings,
  offerRatingsCounts,
  ratingRequestState,
  createRatingsRequestState,
  getAllSellersOverallRatingsRequestState
});

/* SELECTORS */
export const selectRatingsState = (state: AppState) => state.ratings;

export const selectSellerOverallRatings = (state: AppState) => selectRatingsState(state).sellerOverallRatings;

export const selectOfferOverallRatings = (state: AppState) => selectRatingsState(state).offerOverallRatings;

export const selectSellerRatings = (state: AppState) => selectRatingsState(state).sellerRatings;

export const selectOfferRatings = (state: AppState) => selectRatingsState(state).offerRatings;

export const selectOffersRatingsCounts = (state: AppState) => selectRatingsState(state).offerRatingsCounts;

export const selectRatingRequestState = (state: AppState) => selectRatingsState(state).ratingRequestState;

export const selectCreateRatingsRequestState = (state: AppState) => selectRatingsState(state).createRatingsRequestState;

export const selectGetAllSellersOverallRatingsRequestState = (state: AppState) => selectRatingsState(state).getAllSellersOverallRatingsRequestState;


/* SAGAS */
function* getSellersOverallRatings({ payload }: any) {
  if (payload.sellerIds.length > 1) {
    yield put(getAllSellersOverallRatingsActions.request());
  }

  const responses: ExtendedAxiosResponse[] = yield all(payload.sellerIds.map((sellerId: string) => call(api.getSellerOverallRatings, sellerId, payload.params)));

  if (responses.length > 0) {
    // if more than 1 item in request
    const successResponses = responses.filter((resp: any) => resp.ok);
    const errorResponses = responses.filter((resp: any) => !resp.ok);

    if (payload.sellerIds.length > 1) {
      const responseDataArray = successResponses.map((resp: any) => resp.data);
      yield put(getAllSellersOverallRatingsActions.success(responseDataArray));
    }

    yield all(successResponses.map((resp: any) => put(getSellersOverallRatingsActions.success(resp.data))));

    // error occured
    if (errorResponses.length !== 0) {
      yield all(errorResponses.map((resp: any) => put(errorResponseActions(resp.response.data))));

      if (!successResponses.length) {
        if (payload.sellerIds.length > 1) {
          yield put(getAllSellersOverallRatingsActions.failure());
        }

        yield put(getSellersOverallRatingsActions.failure());
      }
    }
  } else {
    if (payload.sellerIds.length > 1) {
      yield put(getAllSellersOverallRatingsActions.failure());
    }

    yield put(getSellersOverallRatingsActions.failure());
  }
}

function* getOffersOverallRatings({ payload }: any) {
  const responses: ExtendedAxiosResponse[] = yield all(payload.offerIds.map((offerId: string) => call(api.getOfferOverallRatings, offerId, payload.params)));

  if (responses.length > 0) {
    // if more than 1 item in request
    const successResponses = responses.filter((resp: any) => resp.ok);
    const errorResponses = responses.filter((resp: any) => !resp.ok);

    yield all(successResponses.map((resp: any) => put(getOffersOverallRatingsActions.success(resp.data))));

    // error occured
    if (errorResponses.length !== 0) {
      yield all(errorResponses.map((resp: any) => put(errorResponseActions(resp.response.data))));

      if (!successResponses.length) {
        yield put(getOffersOverallRatingsActions.failure());
      }
    }
  } 
}

function* getSellerRatings({ payload }: any) {

  const resp: ExtendedAxiosResponse = yield call(api.getSellerRatings, payload.params);

  if (resp.ok) {
    yield put(getSellerRatingsActions.success({
      sellerId: payload.params.sellerId ? payload.params.sellerId : null,
      sellerSlug: payload.params.sellerSlug ? payload.params.sellerSlug : null,
      ratings: resp.data
    }));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getSellerRatingsActions.failure());
  }
}

function* getOfferRatings({ payload }: any) {

  const resp: ExtendedAxiosResponse = yield call(api.getOfferRatings, payload.params);

  if (resp.ok) {
    yield put(getOfferRatingsActions.success({
      offerId: payload.params.offerId ? payload.params.offerId : null,
      offerSlug: payload.params.offerSlug ? payload.params.offerSlug : null,
      ratings: resp.data
    }));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getOfferRatingsActions.failure());
  }
}

function* createOrderRatings({ payload }: any) {

  const params: OrderRatingsCreateRequest = {
    orderId: payload.orderId,
    sellerRating: payload.sellerRatingParams,
    offersRatings: payload.offersRatingParams,
  }

  const resp: ExtendedAxiosResponse = yield call(api.createOrderRatings, params);

  if (resp.ok) {
    yield put(createOrderRatingsActions.success());
    yield put(successToastActions(i18n.t('SuccessToasts.OrderRatingSuccefullyCreated')));
    yield put(push(
      i18n.t('Routes.OrderReviewThank', { orderId: payload.orderId }).toString(),
      {
        earnedCredits: resp.data.earnedCredits
      }
    ));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(createOrderRatingsActions.failure());
  }

}

function* getOfferRatingsCounts({ payload }: any) {

  const params = {
    ...payload.params,
    $limit: 0
  }

  const resp: ExtendedAxiosResponse = yield call(api.getOfferRatings, params);

  if (resp.ok) {
    yield put(getOfferRatingsCountsActions.success({
      offerId: payload.params.offerId ? payload.params.offerId : null,
      count: resp.data.total,
      rating: payload.params?.generalRating
    }));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getOfferRatingsActions.failure());
  }
}

/* EXPORT */
export function* ratingsSaga() {
  yield takeLatest(
    createActionType(RatingsActionsTypes.GetSellersOverallRatings, RequestActionTypes.REQUEST),
    getSellersOverallRatings
  );

  yield takeLatest(
    createActionType(RatingsActionsTypes.GetOffersOverallRatings, RequestActionTypes.REQUEST),
    getOffersOverallRatings
  );

  yield takeLatest(
    createActionType(RatingsActionsTypes.GetSellerRatings, RequestActionTypes.REQUEST),
    getSellerRatings
  );

  yield takeLatest(
    createActionType(RatingsActionsTypes.GetOfferRatings, RequestActionTypes.REQUEST),
    getOfferRatings
  );

  yield takeEvery(
    createActionType(RatingsActionsTypes.GetOfferRatingsCounts, RequestActionTypes.REQUEST),
    getOfferRatingsCounts
  );

  yield takeLatest(
    createActionType(RatingsActionsTypes.CreateOrderRatings, RequestActionTypes.REQUEST),
    createOrderRatings
  );
}
