import i18n from 'i18next';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import user from 'saga/user/ducks';
import userPaymentMethod from 'saga/user/payment-method/ducks';
import userAddress from 'saga/user/address/ducks';
import toast from 'saga/toast/ducks';
import keys from 'helpers/locations-keys';
import offer from 'saga/offer/ducks';
import offerPhoto from 'saga/offer/photo/ducks';
import enumerations from 'saga/enumerations/ducks';
import shoppingCart from 'saga/shoppingCart/ducs';
import order from 'saga/order/ducks';
import checkout from 'saga/checkout/ducks';
import users from 'saga/user/users/ducks';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import response, { ResponseState } from 'saga/response/ducks';
import layout from 'saga/layout/ducks';
import publishOffer from 'saga/offer/publishOffer/ducks';
import userTransactions from 'saga/user/transactions/ducks';
import userInvoices from 'saga/user/invoices/ducks';
import userCredits from 'saga/user/credit/ducks';
import userReturnConditions from 'saga/user/returnConditions/ducks';
import comparator from 'saga/comparator/ducks';
import watchList from 'saga/watchList/ducks';
import ratings from 'saga/ratings/ducks';
import chat from 'saga/chat/ducks';
import importOffers from 'saga/offer/import/ducks';
import tables from 'saga/tables/ducks';
import { getAnalyticsCookieConsentStateFromStorage } from "./../other/cookies";
import prolongOffer from 'saga/offer/prolongOffer/ducks';

export const history = createBrowserHistory();

history.listen((location) => {
  if (getAnalyticsCookieConsentStateFromStorage()) {
    ReactGA.send({ hitType: "pageview",
      page: location.pathname + location.search });
    ReactPixel.pageView();
  }
});

const rootReducer = combineReducers({
  router: connectRouter(history),
  user,
  userPaymentMethod,
  userAddress,
  userTransactions,
  userInvoices,
  userCredits,
  userReturnConditions,
  response,
  toast,
  offer,
  publishOffer,
  prolongOffer,
  offerPhoto,
  importOffers,
  enumerations,
  shoppingCart,
  order,
  checkout,
  users,
  layout,
  comparator,
  watchList,
  ratings,
  chat,
  tables,
});

const initResponse: ResponseState = {
  success: '',
  warn: '',
  error: {
    className: '',
    code: 0,
    data: null,
    errors: [],
    message: '',
    name: ''
  },
};

export default (state: any, action: any) => {
  // similar fuctions can be found in file i18n.tsx
  // function to get params from path defined by key
  const getPathParamsIndexes = (path: string) => {
    const arrayOfPathParts = path.split('/');
    return arrayOfPathParts.reduce<Array<number>>((acc: Array<number>, pathPart: string, index: number) => {
      if (pathPart.includes('{{')) {
        acc.push(index)
      }
      return acc;
    }, [])
  }

  // function to get object of parameters applied to current path, based on key path......key path and current path have to be equal
  const getParametersObject = (currentPath: string, keyPath: string, indexesOfParams: Array<number>) => {
    const arrayOfCurrentPathParts = currentPath.split('/');
    const arrayOfKeyPathParameters = keyPath.split('/');

    const objectOfParameters = indexesOfParams.reduce((acc: any, value: number) => {
      const varName = arrayOfKeyPathParameters[value].replace(/{/g, '').replace(/}/g, '');
      const varValue = arrayOfCurrentPathParts[value]
      acc[varName] = varValue
      return acc;
    }, {})

    return objectOfParameters
  }

  const findCurrentPathKey = (currentPath: string) => {
    return keys.find(key => {
      const currentPathPartsArray = currentPath.split('/');

      return key.pathname.find(path => {
        const keyPathPartsWithoutParamsArray = path.split('/')
        const keyPathPartsWithoutParams = keyPathPartsWithoutParamsArray.reduce((acc: string, value: string) => value.includes('{{') ? acc : acc + value, '');

        const currentPathPartsWithoutParamsArray = path.split('/')
        const currentPathPartsWithoutParams = currentPathPartsWithoutParamsArray.reduce((acc: string, value: string, index: number) => value.includes('{{') ? acc : acc + currentPathPartsArray[index], '');

        return (keyPathPartsWithoutParams === currentPathPartsWithoutParams && keyPathPartsWithoutParamsArray.length === currentPathPartsArray.length ) ? true : false
      })
    })
  }

  if (action.type === 'LOGOUT') {
    return rootReducer(undefined, action);
  }

  if (state && action && action.type === '@@router/LOCATION_CHANGE') {
    if (state.router.action === 'PUSH' || state.router.action === 'POP') {
      state.response = initResponse;

      const key = findCurrentPathKey(history.location.pathname);

      if (key) {
        let link = '';
        const indexesOfParams = getPathParamsIndexes(key.pathname[0])
        if (indexesOfParams) {
          const pathParameters = getParametersObject(history.location.pathname, key.pathname[0], indexesOfParams);
          link = i18n.t(key.key, pathParameters);
        } else {
          link = i18n.t(key.key);
        }

        state.router.location.pathname = link;
      } else {
        state.router.location.pathname = i18n.t('Routes.LandingPage');
      }
    }
  }

  return rootReducer(state, action);
};
