import { apiClient } from 'helpers/api-client';
import { EditNameForm } from '../../views/pages/MyAccount/InfoAndAddresses/PopUp/ChangeNamePopUp';

export interface SmsVerifyRequestBody {
  code: string
}

export const api = {
  login: (params: any) => apiClient.post('/auth', params),
  logout: () => apiClient.delete('/auth'),
  register: (body: any, currency: string) => apiClient.post('/auth/register', body, { headers: {
    'accept-currency': currency,
  } }),
  forgotten: (body: any) => apiClient.post('/auth/forgotten', body),
  reset: (body: any) => apiClient.put(`${'/auth/reset-password/' + body.token}`, body.payload),
  confirmEmail: (body: any) => apiClient.get(`${'/auth/confirm/' + body}`),
  changeEmail: (body: any) => apiClient.post(`/users/change-email`, body,),
  changeEmailConfirm: (body: any) => apiClient.put(`/users/change-email/${body}`, {}),
  validatePhoneNumber: (body: any) => apiClient.post('/sms-validate', body),
  validateEmail: (body: any) => apiClient.post('/validate/user', body),
  validateCompany: (body: any) => apiClient.post('/validate/company', body),
  changePassword: (body: any) => apiClient.post('/auth/change-password/', body),
  resendConfirmEmail: (body: any) => apiClient.post('/auth/resend-verification', body),
  addStripeAccount: (userIp: string) => apiClient.post('/users/add-stripe-account', { userIp }),
  refreshAccountLink: (() => apiClient.post('/users/refresh-account-link', {})),
  updateSettings: ((id: string, data: any) => apiClient.patch(`/users/settings/${id}`, data)),
  addBankAccount: (data: any) => apiClient.post('/users/bank-account', data),
  changePhoneNumber: (smsRequestId: string, body: SmsVerifyRequestBody) => apiClient.patch(`/sms-validate/${smsRequestId}`, body),

  changeName: (userId: string, data: EditNameForm) =>
    apiClient.patch(`/users/profile/${userId}`, data),

  changePhone: (body: any) => apiClient.patch(`/users/profile/${body.userId}`, body.payload),
  getUser: () => apiClient.get('/users/profile'),
  closeNotification: (key: string) => apiClient.post('/notification/close', { key }),
  mergeShoppingCart: (payload: any) => apiClient.post('/users/merge-shopping-cart', { items: payload }),
  changeCurrency: (body: any) => apiClient.patch(`/users/profile/${body.userId}`, body.payload),
  getCheckoutGroups: () => apiClient.get('/checkout'),
  changeLanguage: (userId: string, body: any) => apiClient.patch(`/users/profile/${userId}`, body),
  setGDPRConsent: (body: any) => apiClient.post('/gdpr-consent', body),
  updateGDPRConsent: (id: string, body: any) => apiClient.patch(`/gdpr-consent/${id}`, body),
  disconnectSocialAuth: (strategy: string) => apiClient.delete(`/oauth/${strategy}`),
};
