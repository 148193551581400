import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { Box, Grid, Button, CircularProgress } from '@material-ui/core';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { Link, matchPath } from "react-router-dom";
import keys from 'helpers/locations-keys';
import { useLocation } from 'react-router-dom';
import { environment as env } from "environments/environment";
import { AppState } from "helpers/store/models/AppState";
import { getHelpdeskUrl } from 'helpers/other/getHelpdeskUrl';
import {
  OfferState,
  editOfferActions,
  duplicateOfferActions,
  selectOffersSearchResult,
  selectOfferState
} from "saga/offer/ducks";
import {
  selectCanSaveDraftBeRequested,
  selectSearchBarVisible,
  setSaveDraftRequestActions,
  setSearchBarVisibleActions,
} from 'saga/layout/ducks';
import CookieSettingsPopUp from '../CookiesBar/components/CookieSettingsPopUp';
import { showEditPopUp } from 'views/pages/MyAccount/PopUp';
import { selectCartItemsCount } from 'saga/shoppingCart/ducs';
import { selectComparatorOffers } from 'saga/comparator/ducks';
import { Offer } from 'model/Offer';
import { getWatchListFromStorage } from 'helpers/other/watchList';
import { WatchList } from 'model/WatchList';
import { selectIsLogged } from 'saga/user/ducks';
import { getWatchListItemsActions, getWatchListsActions, selectWatchedOffersIds, selectWatchListDetail, selectWatchLists, setWatchedOffersIdsAction, WatchListDetail } from 'saga/watchList/ducks';

const cart = require('../../../assets/cart.svg');
const logoSk = require(`../../../assets/logos/sk/logo-whitewhite.svg`);
const logoCz = require(`../../../assets/logos/cz/logo-whitewhite.svg`);
const user = require('../../../assets/user-filled.svg');
const addOffer = require('../../../assets/add-offer.svg');
const start = require('../../../assets/start.svg');
const save = require('../../../assets/save2.png');
const copy = require('../../../assets/file_copy.svg');
const filter = require('../../../assets/filter-automoto.svg');
const filterDark = require('../../../assets/filter-automoto-dark.svg');
const compare = require('../../../assets/compare-automoto.svg');
const zoom = require('../../../assets/zoom-black.svg');
const star = require('../../../assets/star-black.svg');

const logo = env.topLevelDomain === 'cz' ? logoCz : logoSk;

const Footer = (props: any & StateToProps & DispatchToProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [mobileFooter, setMobileFooter] = useState<string>('LP');
  const [mobileWidth, setMobileWidth] = useState(1000);
  const isDraft = !(props.offer.publishedAt && props.offer.status !== env.offer.status.denied);
  const [watchedItemsCount, setWatchedItemsCount] = useState<number>(0);
  const [watchListId, setWatchListId] = useState<string | null>(null);

  useEffect(() => {
    const key = keys.find(key => key.pathname.find(path => matchPath(location.pathname, {
      path: path.includes("{{") || path.includes("}}") ? path.replace("{{", ":").replace("}}", "") : path,
      exact: true,
      strict: false
    })))?.key;

    if (key === 'Routes.Cart' ||
      key === 'Routes.OrderReviewThank' ||
      key === 'Routes.OrderAddReview' ||
      key === 'Routes.NoResults' ||
      key === 'Routes.OrderDetailPage' ||
      key === 'Routes.Checkout' ||
      key === 'Routes.AddedToCart' ||
      key === 'Routes.ResultsMarketplace' ||
      key === 'Routes.SearchResults' ||
      key === 'Routes.ProductDetail') {
      setMobileFooter("SR")
    } else if (key === 'Routes.NewOffer') {
      setMobileFooter("AO")
    } else if (key === 'Routes.ProductDetailAutomoto' ||
      key === 'Routes.ResultsAutomoto' ||
      key === 'Routes.VehicleComparisonPage') {
      setMobileFooter("AM")
    } else if (key === 'Routes.Store') {
      const actCategory = localStorage.getItem('mainCategory');
      if (actCategory && actCategory === 'automoto') {
        setMobileFooter("AM")
      } else {
        setMobileFooter("LP")
      }
    } else {
      setMobileFooter("LP")
    }
  }, [location]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setMobileWidth(window.innerWidth);
  }

  const handleSaveDraft = () => {
    props.setSaveDraftRequest();
  }

  const handleDuplicateOffer = () => {
    props.duplicateOffer({
      id: props.offer.id
    });
  }

  const handleSearchIconClick = (event: any) => {
    event.stopPropagation();
    props.setSearchBarVisible(!props.isSearchBarVisible);
  }

  const toggleFilters = () => {
    const element = document.getElementById('carousel-filters');
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.click();
    }
  }

  const renderAutomotoLink = () => {
    if (!localStorage.getItem("storeUrl")) {
      return `${t('Routes.ResultsAutomoto').toString() + location.search + (location.pathname !== t('Routes.ResultsAutomoto').toString() ? "?filters=1" : "")}`;
    }
    return `${localStorage.getItem("storeUrl") + location.search + "?filters=1"}`;
  };

  const fetchWatchLists = () => {
    if (props.isUserLogged) {
      if (props.watchListDetail && props.watchListDetail.offers?.total > -1) {
        setWatchedItemsCount(props.watchListDetail.offers.total);
        setWatchListId(props.watchListDetail.watchListId);
        props.getWatchLists();
      } else {
        props.getWatchLists();
      }
    } else {
      const offerIds = getWatchListFromStorage();
      setWatchedItemsCount(offerIds.length);
    }
  }

  const handleWatchListsChange = () => {
    if (props.watchLists && props.watchLists.length) {

      const defaultWatchList = props.watchLists.find((watchList: WatchList) => watchList.isDefault)

      const watchListId = defaultWatchList ? defaultWatchList.id : props.watchLists[0].id;

      setWatchListId(watchListId);

      props.getWatchListItems({
        watchListId,
        params: {
          '$with': [
            'offer'
          ],
        }
      });
    }
  }

  const handleWatchListItemsChange = () => {
    if (props.watchListDetail && watchListId === props.watchListDetail?.watchListId) {
      const totalCount = props.watchListDetail.offers.total
      const offersIds = props.watchListDetail.offers.data.map((offer: Offer) => offer.id);

      setWatchedItemsCount(totalCount);
      props.setWatchedOffersIds(offersIds);
    } else if (props.watchListDetail && watchListId !== props.watchListDetail?.watchListId) {
      setWatchedItemsCount(0);
      props.setWatchedOffersIds([]);
    }
  }

  const handleWatchedOffersIdsChange = () => {
    setWatchedItemsCount(props.watchedOffersIds.length);
  }

  useEffect(fetchWatchLists, []);
  useEffect(handleWatchListsChange, [props.watchLists]);
  useEffect(handleWatchListItemsChange, [props.watchListDetail, watchListId]);
  useEffect(handleWatchedOffersIdsChange, [props.watchedOffersIds]);
  useEffect(fetchWatchLists, [props.isUserLogged]);

  const showSettingsPopUp = () => {
    showEditPopUp(
      <CookieSettingsPopUp />,
      {
        showCloseButton: true,
        customClass: "cookies-bar__pop-up"
      }
    );
  };

  return (
    <div id={'footer'} className="footer">
      <Container className="footer__container">
        <Grid container spacing={0} direction="row" className="footer__wrapper-desktop">
          <Grid container item md={3} alignItems="center" justifyContent="center">
            <img src={logo} alt="Company logo" width="188" height="35"/>
          </Grid>
          <Grid container item md={9} alignItems="baseline" className="footer__sections">
            <Box className="footer__section footer__section--empty" m={2}>
            </Box>
            <Box className="footer__section" m={2}>
              <h3 className='footer__section-title'>{t('Footer.Column1.Title')}</h3>
              <ul className="footer__link-list">
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Footer.Column1.Link1"))}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column1.Link1")}</a>
                </li>
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Footer.Column1.Link2"))}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column1.Link2")}</a>
                </li>
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Footer.Column1.Link3"))}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column1.Link3")}</a>
                </li>
                <li className="footer__link">
                  <span
                    className="pop-up redirect"
                    onClick={showSettingsPopUp}
                  >{t('CookieBar.PopUp.Title')}</span>
                </li>
              </ul>
            </Box>
            <Box className="footer__section" m={2}>
              <h3 className='footer__section-title'>{t('Footer.Column2.Title')}</h3>
              <ul className="footer__link-list">
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Footer.Column2.Link1"))}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column2.Link1")}</a>
                </li>
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Footer.Column2.Link2"))}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column2.Link2")}</a>
                </li>
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Footer.Column2.Link3"))}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column2.Link3")}</a>
                </li>
              </ul>
            </Box>
            <Box className="footer__section" m={2}>
              <h3 className='footer__section-title'>{t('Footer.Column3.Title')}</h3>
              <ul className="footer__link-list">
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={t("HelpdeskUrlConfigName.Footer.Column3.Link1")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column3.Link1")}</a>
                </li>
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={t("HelpdeskUrlConfigName.Footer.Column3.Link2")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column3.Link2")}</a>
                </li>
                <li className="footer__link">
                  <a
                    className="redirect"
                    href={t("HelpdeskUrlConfigName.Footer.Column3.Link3")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t("Footer.Column3.Link3")}</a>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row" className="footer__cart-view">
          <Grid container item alignItems="center" justifyContent="space-around">
            <Link to={t('Routes.LandingPage').toString()} className={'footer__link-to'}>
              <Box className={'footer__mobile-link'} m={1}>
                <img src={start} alt="Start icon" height="18" className={'icon'}/>
                {t('Footer.Home')}
              </Box>
            </Link>
            <Button onClick={handleSearchIconClick} className={'footer__link-to'}>
              <Box className={'footer__mobile-link'} m={1}>
                <img src={zoom} alt="Search icon" height="18" className={'icon'}/>
                {t('Footer.Search')}
              </Box>
            </Button>
            {mobileFooter === "AM" ? 
              <>
                <Link to={renderAutomotoLink()} className={'footer__link-to'} onClick={toggleFilters}>
                  <Box className={'footer__mobile-link'} m={1}>
                    {
                      (location.pathname === t('Routes.ResultsAutomoto').toString() || location.pathname.includes(t('Routes.Store').toString()))
                        ? (
                          <div className={'footer__mobile-link__mobile-filter'}>
                            <img src={filter} alt="Filter icon" height="18" className={'filter-icon'}/>
                            {props.offersSearchResult.data ? 
                              (props.offersSearchResult.total > 99 ? '99+' : props.offersSearchResult.total)
                              :
                              <CircularProgress
                                className={'spinner'}
                                size={10}
                              />
                            }
                          </div>
                        )
                        :<img src={filterDark} alt="Filter icon" height="18" className={'icon'}/>
                    }
                    {t('Footer.EditFilter')}
                  </Box>
                </Link>
                <Link to={t('Routes.VehicleComparisonPage').toString()} className={'footer__link-to'}>
                  <Box className={'footer__mobile-link'} m={1}>
                    <div className={`footer__mobile-link__holder`}>
                      <img src={compare} alt="Compare icon" height="18"/>
                      <div className={"footer__mobile-link__holder__compare"}>
                        {props.comparatorOffers.length}
                      </div>
                    </div>
                    {t('Footer.Compare')}
                  </Box>
                </Link>
              </> : 
              <Link to={t('Routes.Cart').toString()} className={'footer__link-to'}>
                <Box className={'footer__mobile-link footer__redPastel'} m={1}>
                  <img src={cart} alt="Cart icon" height="18" className={'icon'}/>
                  <div
                    className={`countInCart ${props.cartItemsCount > 9 && 'two-digits'} ${props.cartItemsCount > 99 && 'three-digits'}`}>{props.cartItemsCount > 99 ? '99+' : props.cartItemsCount}</div>
                  {t('Footer.Cart')}
                </Box>
              </Link>
            }
            {mobileFooter === "SR" &&
            <Link to={t('Routes.WatchList').toString()} className={'footer__link-to'}>
              <Box className={'footer__mobile-link'} m={1}>
                <img src={star} alt="Cart icon" height="18" className={'icon'}/>
                <div className={"watchedNumber"}>
                  {watchedItemsCount}
                </div>
                {t('Footer.Watch')}
              </Box>
            </Link>
            }
            {mobileFooter === "AO" && 
            (isDraft ?
              <Button
                onMouseDown={handleSaveDraft}
                className={'footer__link-to'}
                disabled={!props.canSaveDraftBeRequested}
              >
                <Box className={'footer__mobile-link'} m={1}>
                  <img src={save} alt="Add offer icon" height="18" className={'icon'}/>
                  {t('Footer.SaveDraft')}
                </Box>
              </Button>
              : 
              <Button
                onMouseDown={handleDuplicateOffer}
                className={'footer__link-to'}
              >
                <Box className={'footer__mobile-link'} m={1}>
                  <img src={copy} alt="Duplicate offer icon" height="18" className={'icon'}/>
                  {t('Footer.DuplicateOffer')}
                </Box>
              </Button>
            )}
            {mobileFooter === "LP" &&
            <Link to={t('Routes.NewOffer').toString()} className={'footer__link-to'}>
              <Box className={'footer__mobile-link'} m={1}>
                <img src={addOffer} alt="Add offer icon" height="18" className={'icon addOfferIcon'}/>
                {mobileWidth > 355 ? t('Footer.AddListing') : t('Footer.AddListingShort')}
              </Box>
            </Link>
            }
            <Link to={t('Routes.AccountMyOrders').toString()} className={'footer__link-to'}>
              <Box className={'footer__mobile-link'} m={1}>
                <img src={user} alt="User icon" height="18" className={'icon'}/>
                {t('Footer.Title')}
              </Box>
            </Link>
          </Grid>
        </Grid>
        <div className={'footer__copyright'}>
          {t("Footer.Copyright")}
          <a target="_blank" rel="noopener noreferrer" href="https://izardis.com" className="link">izardis.com</a>
          {`© 2020-${moment().year()}, ${env.appUrl}`}
        </div>
      </Container>
    </div>
  );
};

interface StateToProps {
  offer: OfferState;
  offersSearchResult: any,
  isSearchBarVisible: boolean;
  canSaveDraftBeRequested: boolean;
  cartItemsCount: number;
  comparatorOffers: Offer[];
  isUserLogged: boolean,
  watchLists: WatchList[] | null,
  watchListDetail: WatchListDetail | null,
  watchedOffersIds: string[],
}

interface DispatchToProps {
  setSaveDraftRequest: () => void;
  editOffer: (payload: any) => void;
  duplicateOffer: (payload: any) => void;
  setSearchBarVisible: (status: boolean) => void;
  getWatchLists: (payload?: any) => void, 
  getWatchListItems: (payload: { watchListId: string, params?: any }) => void,
  setWatchedOffersIds: (payload: string[]) => void,
}

const mapStateToProps = (state: AppState): StateToProps => ({
  offer: selectOfferState(state),
  isSearchBarVisible: selectSearchBarVisible(state),
  offersSearchResult: selectOffersSearchResult(state),
  canSaveDraftBeRequested: selectCanSaveDraftBeRequested(state),
  cartItemsCount: selectCartItemsCount(state),
  comparatorOffers: selectComparatorOffers(state),
  isUserLogged: selectIsLogged(state),
  watchLists: selectWatchLists(state),
  watchListDetail: selectWatchListDetail(state),
  watchedOffersIds: selectWatchedOffersIds(state),
});

const mapDispatchToProps: DispatchToProps = {
  editOffer: editOfferActions.request,
  duplicateOffer: duplicateOfferActions.request,
  setSearchBarVisible: setSearchBarVisibleActions,
  setSaveDraftRequest: setSaveDraftRequestActions.request,
  getWatchLists: getWatchListsActions.request,
  getWatchListItems: getWatchListItemsActions.request,
  setWatchedOffersIds: setWatchedOffersIdsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
