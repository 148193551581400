import { combineReducers } from 'redux';

import { AppState } from '../../helpers/store/models/AppState';
import {
  createActionCreator,
  createReducer,
} from '../../helpers/redux/redux-helpers';

/* STATE */
export interface ToastState {
  success: string;
  warn: string;
  error: string;
  userError: string;
  emailConfirmSuccess: string;
  warnWithConfirm: string;
  addedToComparator: string;
}

/* ACTION TYPES */
export enum ToastActionTypes {
  SuccessToast = '@@Toast/SUCCESS',
  WarnToast = '@@Toast/WARN',
  ErrorToast = '@@Toast/ERROR',
  UserErrorToast = '@@Toast/USER_ERROR',
  EmailConfirmSuccessToast = '@@Toast/EMAIL_CONFIRM_SUCCESS_TOAST',
  WarnToastWithConfirm = '@@Toast/WARN_WITH_CONFIRM',
  AddedToComparatorToast = '@@Toast/ADDED_TO_COMPARATOR',
}

/* ACTIONS */
export const successToastActions = createActionCreator(ToastActionTypes.SuccessToast);
export const warnToastActions = createActionCreator(ToastActionTypes.WarnToast);
export const errorToastActions = createActionCreator(ToastActionTypes.ErrorToast);
export const userErrorToastActions = createActionCreator(ToastActionTypes.UserErrorToast);
export const emailConfirmSuccessToastActions = createActionCreator(ToastActionTypes.EmailConfirmSuccessToast);
export const warnToastWithConfirmActions = createActionCreator(ToastActionTypes.WarnToastWithConfirm);
export const addedToComparatorToastActions = createActionCreator(ToastActionTypes.AddedToComparatorToast);

/* REDUCERS */
const initialState: ToastState = {
  success: '',
  warn: '',
  error: '',
  userError: '',
  emailConfirmSuccess: '',
  warnWithConfirm: '',
  addedToComparator: '',
};

const success = createReducer(initialState.success, {
  [ToastActionTypes.SuccessToast]: (state: string, payload: string) => payload,
});

const warn = createReducer(initialState.warn, {
  [ToastActionTypes.WarnToast]: (state: string, payload: string) => payload,
});

const error = createReducer(initialState.error, {
  [ToastActionTypes.ErrorToast]: (state: string, payload: string) => payload,
});

const userError = createReducer(initialState.error, {
  [ToastActionTypes.UserErrorToast]: (state: string, payload: string) => payload,
});

const emailConfirmSuccess = createReducer(initialState.emailConfirmSuccess, {
  [ToastActionTypes.EmailConfirmSuccessToast]: (state: string, payload: string) => payload,
});

const warnWithConfirm = createReducer(initialState.warnWithConfirm, {
  [ToastActionTypes.WarnToastWithConfirm]: (state: string, payload: string) => payload,
});

const addedToComparator = createReducer(initialState.addedToComparator, {
  [ToastActionTypes.AddedToComparatorToast]: (state: string, payload: string) => payload,
});

export default combineReducers<ToastState>({
  success,
  warn,
  error,
  userError,
  emailConfirmSuccess,
  warnWithConfirm,
  addedToComparator,
});

/* SELECTORS */
export const selectToastState = (state: AppState) => state.toast;

export const selectSuccessToast = (state: AppState) =>
  selectToastState(state).success;

export const selectWarnToast = (state: AppState) =>
  selectToastState(state).warn;

export const selectErrorToast = (state: AppState) =>
  selectToastState(state).error;

export const selectUserErrorToast = (state: AppState) =>
  selectToastState(state).userError;

export const selectEmailConfirmSuccessToast = (state: AppState) =>
  selectToastState(state).emailConfirmSuccess;

export const selectWarnToastWithConfirm = (state: AppState) =>
  selectToastState(state).warnWithConfirm;

export const selectAddedToComparator = (state: AppState) =>
  selectToastState(state).addedToComparator;

/* SAGAS */

/* EXPORT */
export function* toastSaga() {}
