import React, { Component } from "react";

class CircleCheckIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="#70b22d" id="Icon_ionic-md-checkmark-circle" data-name="Icon ionic-md-checkmark-circle" d="M13.375,3.375a10,10,0,1,0,10,10A10.029,10.029,0,0,0,13.375,3.375ZM11.322,18.707,6.2,13.582l1.438-1.437,3.692,3.692L19.12,8.043l1.438,1.438Z" transform="translate(-3.375 -3.375)"/>
      </svg>
    );
  }
}

export default CircleCheckIcon;