import { apiClient } from 'helpers/api-client';
import { ConfirmPaymentRequest } from 'saga/checkout/api';


export interface PurchaseUserCreditsRequest {
  amount: number,
}

export const api = {
  purchaseUserCredits: (params: PurchaseUserCreditsRequest) => apiClient.post('/users/credits/purchase', params),
  confirmPayment: (params: ConfirmPaymentRequest) => apiClient.post('/payments/confirm', params),
};
