import { apiClient } from 'helpers/api-client';
import { MergeWatchListSchema, WatchListCreateSchema, WatchListItemCreateSchema, WatchListUpdateSchema } from '../../model/WatchList';

export const api = {
  getWatchLists: (params?: any) => apiClient.get('/watch-list', { params }),
  // getWatchListDetail: (id: string) => apiClient.get(`/watch-list/${id}`),
  createWatchList: (params: WatchListCreateSchema) => apiClient.post('/watch-list', params),
  updateWatchList: (id: string, params: WatchListUpdateSchema) => apiClient.patch(`/watch-list/${id}`, params),
  deleteWatchList: (id: string) => apiClient.delete(`/watch-list/${id}`),
  mergeWatchList: (params: MergeWatchListSchema) => apiClient.post('/merge-watch-list', params),

  getWatchListItems: (watchListId: string, params?: any) => apiClient.get(`/watch-list/${watchListId}/item`, { params }),
  createWatchListItem: (watchListId: string, params: WatchListItemCreateSchema) => apiClient.post(`/watch-list/${watchListId}/item`, params),
  deleteWatchListItem: (watchListId: string, offerId: string) => apiClient.delete(`/watch-list/${watchListId}/item/${offerId}`),

  getOffersById: (params: any) => apiClient.get('/get-offer-by-ids', { params }),
};