import { combineReducers } from 'redux';

import { AppState } from '../../helpers/store/models/AppState';
import {
  createActionCreator,
  createReducer,
} from '../../helpers/redux/redux-helpers';
import { Offer } from '../../model/Offer';

/* STATE */
export interface ComparatorState {
  offers: Offer[],
  offerIds: string[]
}

/* ACTION TYPES */
export enum ComparatorActionTypes {
  SetOffers = '@@Comparator/SET_OFFERS',
  SetOfferIds = '@@Comparator/SET_OFFER_IDS',
}

/* ACTIONS */
export const setOffersAction = createActionCreator(ComparatorActionTypes.SetOffers);
export const setOfferIdsAction = createActionCreator(ComparatorActionTypes.SetOfferIds);


/* REDUCERS */
const initialState: ComparatorState = {
  offers: [],
  offerIds: []
};

const offers = createReducer(initialState.offers, {
  [ComparatorActionTypes.SetOffers]: (_state: Offer[], payload: Offer[]) => payload,
});

const offerIds = createReducer(initialState.offerIds, {
  [ComparatorActionTypes.SetOfferIds]: (_state: Offer[], payload: Offer[]) => payload,
});

export default combineReducers<ComparatorState>({
  offers,
  offerIds,
});

/* SELECTORS */
export const selectComparatorState = (state: AppState) => state.comparator;
export const selectComparatorOffers = (state: AppState) => selectComparatorState(state).offers;
export const selectComparatorOfferIds = (state: AppState) => selectComparatorState(state).offerIds;
