import React, { useState } from 'react';
import { InputAdornment, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const PasswordInput = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      label={props.label}
      type={showPassword ? 'text' : 'password'}
      name={props.name}
      inputRef={props.inputRef}
      value={props.value}
      onChange={props.handleChange}
      fullWidth
      error={props.error ? true : false}
      variant={"outlined"}
      className={props.className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex="-1"
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
              className={'visibilityIcon'}
            >
              {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
