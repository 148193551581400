import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "./api";

import {
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  LoadingState,
  createLoadingStateReducer,
  AppAction,
} from "helpers/redux/redux-helpers";
import { combineReducers } from "redux";
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

import { ProlongOfferWithTokenPayload } from "model/Offer";
import { successToastActions } from "saga/toast/ducks";
import i18n from "i18n";


/* STATE */
export interface ProlongOfferState {
  prolongActionRequestState: LoadingState,
}

/* ACTION TYPES */
export enum ProlongOfferActionsTypes {
  ProlongOfferWithToken = "@@Offer/PROLONG_OFFER_WITH_TOKEN",
}

/* ACTIONS */
export const prolongOfferWithTokenActions = createApiActionCreators(
  ProlongOfferActionsTypes.ProlongOfferWithToken
);

/* REDUCERS */
const initialState: ProlongOfferState = {
  prolongActionRequestState: LoadingState.success,
};

const prolongActionRequestState = createLoadingStateReducer(initialState.prolongActionRequestState, {
  [ProlongOfferActionsTypes.ProlongOfferWithToken]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<ProlongOfferState>({
  prolongActionRequestState
});

function* prolongOfferWithToken({ payload }: AppAction<ProlongOfferWithTokenPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.prolongOfferWithToken, payload);

  if (resp.ok) {
    yield put(successToastActions(i18n.t('SuccessToasts.OfferSuccessfullyProlonged')));
    yield put(prolongOfferWithTokenActions.success());
  } else {
    yield put(prolongOfferWithTokenActions.failure());
  }

}

/* EXPORT */
export function* prolongOfferSaga() {
  yield takeLatest(
    createActionType(ProlongOfferActionsTypes.ProlongOfferWithToken, RequestActionTypes.REQUEST),
    prolongOfferWithToken
  );
}
