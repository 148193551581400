import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { combineReducers } from 'redux';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash';
import moment from 'moment';
import i18n from 'i18next';

import { AppState } from 'helpers/store/models/AppState';
import {
  AppAction, createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from 'helpers/redux/redux-helpers';
import { api } from './api';
import { ProcessedFile } from 'model/Offer';
import { Conversation, ConversationMessage, CreateConversationPayload, SendMessagePayload } from 'model/Conversation';
import { PaginatedResponse, Pagination } from '../../model/General';
import { getUserActions, selectUser, UserActionTypes } from '../user/ducks';
import { User } from '../../types/User';
import { errorToastActions, successToastActions } from '../toast/ducks';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
export interface ChatState {
  conversations: Conversation[];
  conversationsPagination: Pagination;
  selectedConversation: Conversation | null;
  messages: ConversationMessage[];
  messagesPagination: Pagination;
}

/* ACTION TYPES */
export enum ChatActionTypes {
  CreateConversation = '@@Chat/CREATE_CONVERSATION',
  CreateConversationFromToken = '@@Chat/CREATE_CONVERSATION_FROM_TOKEN',
  GetConversationList = '@@Chat/GET_CONVERSATION_LIST',
  LoadMoreConversations = '@@Chat/LOAD_MORE_CONVERSATIONS',
  GetConversationDetail = '@@Chat/GET_CONVERSATION_DETAIL',
  SendMessage = '@@Chat/SEND_MESSAGE',
  LoadMoreMessages = '@@Chat/LOAD_MORE_MESSAGES',
  ReportConversation = '@@Chat/REPORT_CONVERSATION',
  MarkRead = '@@Chat/MARK_READ',
}

/* ACTIONS */
export const createConversationActions = createApiActionCreators(ChatActionTypes.CreateConversation);
export const createConversationFromTokenActions = createApiActionCreators(ChatActionTypes.CreateConversationFromToken);
export const getConversationListActions = createApiActionCreators(ChatActionTypes.GetConversationList);
export const loadMoreConversationsAction = createActionCreator(ChatActionTypes.LoadMoreConversations);
export const getConversationDetailActions = createApiActionCreators(ChatActionTypes.GetConversationDetail);
export const sendMessageActions = createApiActionCreators(ChatActionTypes.SendMessage);
export const loadMoreMessagesActions = createApiActionCreators(ChatActionTypes.LoadMoreMessages);
export const reportConversationActions = createApiActionCreators(ChatActionTypes.ReportConversation);
export const markConversationReadActions = createApiActionCreators(ChatActionTypes.MarkRead);

/* REDUCERS */
const initialState: ChatState = {
  conversations: [],
  conversationsPagination: {
    page: 0,
    pageSize: 10,
    next: false,
  },
  selectedConversation: null,
  messages: [],
  messagesPagination: {
    page: 0,
    pageSize: 20,
    next: false,
  },
};

const conversations = createReducer(initialState.conversations, {
  [ChatActionTypes.GetConversationList]: {
    [RequestActionTypes.SUCCESS]: (state: Conversation[], payload: PaginatedResponse<Conversation>) => {
      if (payload.skip === 0) {
        return payload.data;
      }

      return [...state, ...payload.data]
    },
    [RequestActionTypes.FAILURE]: (_state: Conversation[]) => initialState.conversations,
  },
  [ChatActionTypes.SendMessage]: {
    [RequestActionTypes.SUCCESS]: (state: Conversation[], payload: ConversationMessage & { conversationId: string }) => {
      const conversation = state.find(c => c.id === payload.conversationId);

      if (conversation) {
        return [
          {
            ...conversation,
            lastMessage: payload,
            updatedAt: moment().format(),
          },
          ...state.filter(c => c.id !== payload.conversationId),
        ]
      }

      return state;
    }
  },
  [ChatActionTypes.MarkRead]: {
    [RequestActionTypes.SUCCESS]: (state: Conversation[], updatedConversation: Conversation) => {
      const conversation = state.find(c => c.id === updatedConversation.id);
      const index = state.findIndex(c => c.id === updatedConversation.id);

      if (conversation?.lastMessage?.id && conversation.lastMessage?.id === updatedConversation.lastMessage?.id) {
        return [
          ...state.slice(0, index),
          {
            ...conversation,
            lastMessage: updatedConversation.lastMessage,
          },
          ...state.slice(index + 1),
        ]
      }

      return state;
    }
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.conversations,
  },
});

const conversationsPagination = createReducer(initialState.conversationsPagination, {
  [ChatActionTypes.GetConversationList]: {
    [RequestActionTypes.SUCCESS]: (state: Pagination, payload: PaginatedResponse<Conversation>) => ({
      ...state,
      next: payload.total > payload.limit + payload.skip,
    }),
    [RequestActionTypes.FAILURE]: (_state: Pagination) => initialState.conversationsPagination,
  },
  [ChatActionTypes.LoadMoreConversations]: (state: Pagination) => ({
    ...state,
    page: state.page + 1,
  }),
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.conversationsPagination,
  },
});

const selectedConversation = createReducer(initialState.selectedConversation, {
  [ChatActionTypes.GetConversationDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: Conversation | null, payload: Conversation) => omit(payload, 'messages'),
    [RequestActionTypes.FAILURE]: (_state: Conversation | null) => initialState.selectedConversation,
  },
  [ChatActionTypes.SendMessage]: {
    [RequestActionTypes.SUCCESS]: (state: Conversation, payload: ConversationMessage) => ({
      ...state,
      updatedAt: payload.createdAt,
    }),
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.selectedConversation,
  },
});

const messages = createReducer(initialState.messages, {
  [ChatActionTypes.GetConversationDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: ConversationMessage[], payload: Conversation) => payload.messages,
    [RequestActionTypes.FAILURE]: (_state: ConversationMessage[]) => initialState.messages,
  },
  [ChatActionTypes.SendMessage]: {
    [RequestActionTypes.SUCCESS]: (state: ConversationMessage[], payload: ConversationMessage) => {
      if (!payload.id || !payload.extId) {
        return [
          payload,
          ...state,
        ];
      }

      const index = state.findIndex(m => m.extId === payload.extId);

      if (index === -1) {
        return state;
      }

      return [
        ...state.slice(0, index),
        payload,
        ...state.slice(index + 1),
      ]
    },
    [RequestActionTypes.FAILURE]: (state: ConversationMessage[], payload: string) =>
      state.filter(m => m.extId !== payload),
  },
  [ChatActionTypes.LoadMoreMessages]: {
    [RequestActionTypes.SUCCESS]: (state: ConversationMessage[], payload: PaginatedResponse<ConversationMessage>) => [
      ...state,
      ...payload.data
    ],
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.messages,
  },
});

const messagesPagination = createReducer(initialState.messagesPagination, {
  [ChatActionTypes.GetConversationDetail]: {
    [RequestActionTypes.SUCCESS]: (state: Pagination, payload: Conversation) => ({
      ...state,
      page: 0,
      next: payload.messages.length === state.pageSize,
    }),
    [RequestActionTypes.FAILURE]: (_state: Pagination) => initialState.messagesPagination,
  },
  [ChatActionTypes.LoadMoreMessages]: {
    [RequestActionTypes.REQUEST]: (state: Pagination) => ({
      ...state,
      page: state.page + 1,
    }),
    [RequestActionTypes.SUCCESS]: (state: Pagination, payload: PaginatedResponse<ConversationMessage>) => ({
      ...state,
      next: payload.total > payload.limit + payload.skip,
    }),
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.messagesPagination,
  },
});

export default combineReducers<ChatState>({
  conversations,
  conversationsPagination,
  selectedConversation,
  messagesPagination,
  messages,
});

/* SELECTORS */
const selectChatState = (state: AppState) => state.chat;

export const selectChatConversationList = (state: AppState): Conversation[] =>
  selectChatState(state).conversations;

export const selectChatConversationsPagination = (state: AppState): Pagination =>
  selectChatState(state).conversationsPagination;

export const selectChatConversationDetail = (state: AppState): Conversation | null =>
  selectChatState(state).selectedConversation;

export const selectChatMessages = (state: AppState): ConversationMessage[] =>
  selectChatState(state).messages;

export const selectChatMessagesPagination = (state: AppState): Pagination =>
  selectChatState(state).messagesPagination;

/* SAGAS */
function* getConversationList() {
  const pagination: Pagination = yield select(selectChatConversationsPagination);

  const resp: ExtendedAxiosResponse = yield call(api.getConversationList, pagination.page, pagination.pageSize);

  if (resp.ok) {
    yield put(getConversationListActions.success(resp.data));
  } else {
    yield put(getConversationListActions.failure());
  }
}

function* getConversationDetail({ payload: id }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.getConversationDetail, id);

  if (resp.ok) {
    yield put(getConversationDetailActions.success(resp.data));
  } else {
    yield put(getConversationDetailActions.failure());
  }
}

function* sendMessage({ payload }: AppAction<SendMessagePayload>) {
  const conversation: Conversation = yield select(selectChatConversationDetail);
  const loggedUser: User = yield select(selectUser);
  const extId = uuid();

  yield put(sendMessageActions.success({
    extId,
    text: payload.text,
    sender: {
      id: loggedUser.id,
      name: loggedUser.name,
    },
    usersSeen: [],
    images: payload.files.map(f => ({ image: window.URL.createObjectURL(f), name: f.name })),
    createdAt: moment().format(),
    conversationId: conversation.id,
  }));

  if (payload.text === '' && payload.files.length > 0) {
    payload.text = '%%IMG%%';
  }

  const resp: ExtendedAxiosResponse = yield call(api.sendMessage, conversation.id, payload.text, extId);

  if (resp.ok) {
    if (payload.files.length === 0) {
      yield put(sendMessageActions.success({ ...resp.data, conversationId: conversation.id }));
      return;
    }
  } else {
    yield put(sendMessageActions.failure(extId));
    return;
  }

  const processed: ProcessedFile[] = yield Promise.all(
    payload.files.map(async (file: File, index: number) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onloadend = () => resolve({
          name: file.name,
          image: reader.result,
          isLast: (payload.files.length - 1 === index)
        });
      });
    })
  );

  let lastResp: ExtendedAxiosResponse;

  for (const file of processed) {
    lastResp = yield call(api.addMessageImage, conversation.id, resp.data.id, file.image, file.name, file.isLast || false);
    if (lastResp.ok) {
      yield put(sendMessageActions.success({ ...lastResp.data, conversationId: conversation.id }));
    } else if (payload.text === '%%IMG%%') {
      yield put(sendMessageActions.failure(extId));
    }
  }
}

function* getConversationMessages() {
  const conversation: Conversation = yield select(selectChatConversationDetail);
  const pagination: Pagination = yield select(selectChatMessagesPagination);

  if (!pagination.next) {
    return;
  }

  const resp: ExtendedAxiosResponse = yield call(api.getConversationMessages, conversation.id, pagination.page, pagination.pageSize);

  if (resp.ok) {
    yield put(loadMoreMessagesActions.success(resp.data));
  } else {
    yield put(loadMoreMessagesActions.failure());
  }
}

function* createConversation({ payload }: AppAction<CreateConversationPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createConversation, payload.type, payload.recipientId, payload.ref);

  if (resp.ok) {
    yield put(createConversationActions.success(resp.data));
    yield put(getConversationDetailActions.success(resp.data));
    yield put(push(i18n.t('Routes.Chat')))
  } else {
    yield put(createConversationActions.failure());
  }
}

function* createConversationFromToken({ payload: token }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.createConversationFromToken, token);

  if (resp.ok) {
    yield put(createConversationFromTokenActions.success(resp.data));
    yield put(getConversationDetailActions.success(resp.data));
  } else {
    yield put(createConversationFromTokenActions.failure());
  }
}

function* reportConversation({ payload: conversationId }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.reportConversation, conversationId);

  if (resp.ok) {
    yield put(successToastActions(i18n.t('SuccessToasts.ConversationReported')));
  } else {
    yield put(errorToastActions(i18n.t('ErrorToasts.UnableToReportConversation')));
  }
}

function* markRead({ payload: conversationId }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.markConversationRead, conversationId);

  if (resp.ok) {
    yield put(getUserActions.request());
    yield put(markConversationReadActions.success(resp.data));
  }
}

/* EXPORT */
export function* chatSaga() {
  yield takeLatest(
    createActionType(ChatActionTypes.CreateConversation, RequestActionTypes.REQUEST),
    createConversation,
  );

  yield takeLatest(
    createActionType(ChatActionTypes.CreateConversationFromToken, RequestActionTypes.REQUEST),
    createConversationFromToken,
  );

  yield takeLatest(
    createActionType(ChatActionTypes.GetConversationList, RequestActionTypes.REQUEST),
    getConversationList,
  );

  yield takeLatest(
    createActionType(ChatActionTypes.GetConversationDetail, RequestActionTypes.REQUEST),
    getConversationDetail,
  );

  yield takeEvery(
    createActionType(ChatActionTypes.SendMessage, RequestActionTypes.REQUEST),
    sendMessage,
  );

  yield takeLatest(
    createActionType(ChatActionTypes.LoadMoreMessages, RequestActionTypes.REQUEST),
    getConversationMessages,
  );

  yield takeLatest(
    ChatActionTypes.LoadMoreConversations,
    getConversationList,
  );

  yield takeLatest(
    createActionType(ChatActionTypes.ReportConversation, RequestActionTypes.REQUEST),
    reportConversation,
  );

  yield takeLatest(
    createActionType(ChatActionTypes.MarkRead, RequestActionTypes.REQUEST),
    markRead,
  );
}

