import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { AppState } from 'helpers/store/models/AppState';
import { showEditPopUp } from 'views/pages/MyAccount/PopUp';
import CookieSettingsPopUp from './components/CookieSettingsPopUp';
import { initGA } from "helpers/other/googleAnalytics";
import { initFB } from "helpers/other/facebookPixel";
import { getHelpdeskUrl } from 'helpers/other/getHelpdeskUrl';
import {
  getAnalyticsCookieConsentStateFromStorage,
  getCookieConsentStateFromStorage,
  setCookieConsentStateStorage
} from "helpers/other/cookies";
import { 
  selectUserGDPRConsent, createGDPRConsentActions
} from 'saga/user/ducks';
import { environment as env } from 'environments/environment';

const cookieImg = require('../../../assets/cookie-img.jpeg');

const CookiesBar = (props: StateToProps & DispatchToProps) => {
  const [hidden, setHidden] = useState(getCookieConsentStateFromStorage());
  const { t } = useTranslation();

  const launchSmartEmailing = () => {
    const scriptElem = document.createElement("script");
    scriptElem.type = 'text/javascript';
    scriptElem.async = true;

    const scriptText = document.createTextNode(
      "(function(sm,a,rt,e,ma,il,i,ng){a._se=a._se||[];for(ng=0;ng<ma.length;ng++){i=sm.createElement(rt);"
      + "il=sm.getElementsByTagName(rt)[0];i.async=1;i.src=e+ma[ng]+'.js';il.parentNode.insertBefore(i,il);}})"
      + " (document,window,'script','https://app.smartemailing.cz/js/tracking/',['tracker']);"
      + "  _se.push(['init', '" + env.smartEmailingGuid + "']);"
      + "  _se.push(['visit', { url: 'https://" + env.appUrl + "/',visit_type: 'home'}]);"
    );

    scriptElem.appendChild(scriptText);
    document.body.appendChild(scriptElem);
  }

  useEffect(() => {
    if (getAnalyticsCookieConsentStateFromStorage()) {
      initGA();
      initFB();
      launchSmartEmailing();
    }
  }, []);

  useEffect(() => {
    if (props.userGDPRConsent) {
      setCookieConsentStateStorage(JSON.stringify(props.userGDPRConsent));
      setHidden(true);
    }
  }, [props.userGDPRConsent]);

  const handleAcceptAll = () => {
    initGA();
    initFB();
    props.setGDPRConsent({
      required: true,
      analytics: true,
      marketing: true
    });
    launchSmartEmailing();
    setHidden(true);
  }

  const handleClosePopUp = () => {
    if (getCookieConsentStateFromStorage()) {
      setHidden(true);
    }
  }

  const showSettingsPopUp = () => {
    showEditPopUp(
      <CookieSettingsPopUp />,
      {
        showCloseButton: true,
        customClass: "cookies-bar__pop-up",
        willClose: () => handleClosePopUp()
      }
    );
  };

  return (
    !hidden ?
    <div className="cookies-bar">
      <div className="cookies-bar__wrapper">
        <img className="cookies-bar__image" src={cookieImg} alt="Cookies img" />
        <Box className="cookies-bar__desc desc" display="flex" flexDirection="column" paddingX={2}>
          <h3 className="desc__label">{t("CookieBar.Title")}</h3>
          <div className="desc__content">
            <Trans
              i18nKey="CookieBar.Desc"
              components={{
                Link: 
                  <a
                    href={getHelpdeskUrl(t("HelpdeskUrlConfigName.CookiesMoreInfo"))}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  > </a>
              }}
            />
          </div>
        </Box>
        <Box className="cookies-bar__buttons-wrapper" display="flex" flexDirection="column" textAlign="center">
          <Button variant="contained" className="cookies-bar__button" onClick={handleAcceptAll}>
            {t("CookieBar.Accept")}
          </Button>
          <span className="link cookies-bar__settings" onClick={showSettingsPopUp}>
            {t("CookieBar.Settings")}
          </span>
        </Box>
      </div>
    </div>
    : 
    <></>
  );
};

interface StateToProps {
  userGDPRConsent: any;
}

interface DispatchToProps {
  setGDPRConsent: (payload: any) => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  userGDPRConsent: selectUserGDPRConsent(state)
});

const mapDispatchToProps: DispatchToProps = {
  setGDPRConsent: createGDPRConsentActions.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookiesBar);
