import { call, put, takeLatest, takeEvery, all } from "redux-saga/effects";
import { api } from "./api";
import { logoutActions, UserActionTypes } from '../user/ducks';
import { UserAddressActionTypes } from '../user/address/ducks';
import { push } from 'connected-react-router';
import { AppState } from "helpers/store/models/AppState";
import { errorResponseActions, successResponseActions } from '../response/ducks';
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  LoadingState,
  createLoadingStateReducer,
  createActionCreator,
} from "helpers/redux/redux-helpers";
import i18n from '../../i18n';
import { ErrorResponse } from "types/Error";
import { successToastActions, errorToastActions } from "saga/toast/ducks";
import { combineReducers } from "redux";
import { Price } from "model/Price";
import { HitsResults, Offer, OffersList } from "model/Offer";
import { ReturnPolicy, ReturnTOC } from "model/ReturnConditions";
import { OfferDeliveryType } from "model/DeliveryTypesModel";
import { RatingsActionsTypes } from "saga/ratings/ducks";
import { OfferOverallRatings, SellerOverallRatings } from "model/Ratings";
import Swal from "sweetalert2";
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
/* IMPORTANT WARNING - When you change offerState it's necessary to properly update function 'handleOfferChange' in file AddOffer.tsx in order to prevent creation of empty offers.*/
export interface OfferState {
  id: string;
  userId: string;
  companyId: string;
  mainCategoryId: string;
  categoryId: string;
  itemStatusId?: string;
  itemStatus?: any;
  marketTypeId: string;
  marketType?: any;
  userAddressId: string;
  userAddress?: any;
  returnTOCId: string;
  returnTOC?: ReturnTOC;
  returnPolicyId: string;
  returnPolicy?: ReturnPolicy;
  name: string;
  slug: string;
  description: string;
  shortDescription: string;
  prices: Price[];
  count: number | string;
  phoneNumber: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
  partNumber?: string;
  autoEnums?: any[];
  publishOption?: any;
  topOption?: any;
  vehicleInfo?: any;
  categoryTree?: any[];
  deliveryTypes?: any[];
  offers: OffersList;
  carouselOffers: any[];
  offersSearchResult: any[];
  offersSearchResultSearchBar: any[];
  myOffersCountsByCategory: any;
  userOffersCount: number;
  offerRequestState: LoadingState;
  searchOffersRequestState: LoadingState;
  duplicateOfferRequestState: LoadingState;
  searchedOffersCountsByCategory: any;
  offerDetail: Offer | null;
  createOfferInappropriateFlagRequestState: LoadingState;
}

/* ACTION TYPES */
export enum OfferActionsTypes {
  PostOffer = "@@Offer/POST_OFFER",
  DeleteOffer = "@@Offer/DELETE_OFFER",
  GetOffer = "@@Offer/GET_OFFER",
  EditOffer = "@@Offer/EDIT_OFFER",
  GetOffers = "@@Offer/GET_OFFERS",
  DeleteOffers = "@@Offer/DELETE_OFFERS",
  SetOfferItemStatus = "@@Offer/SET_OFFER_ITEM_STATUS",
  SetOfferLocation = "@@Offer/SET_OFFER_LOCATION",
  SetOfferMarketType = "@@Offer/SET_OFFER_MARKET_TYPE",
  SetOfferDeliveryTypes = "@@Offer/SET_OFFER_DELIVERY_TYPES",
  DeleteOfferDeliveryTypes = "@@Offer/DELETE_OFFER_DELIVERY_TYPES",
  GetOfferCustomEnums = "@@Offer/GET_OFFER_CUSTOM_ENUMS",
  SetOfferCustomEnums = "@@Offer/SET_OFFER_CUSTOM_ENUMS",
  SetOfferPublishOption = "@@Offer/SET_OFFER_PUBLISH_OPTION",
  SetOfferVehicleInfo = "@@Offer/SET_OFFER_VEHICLE_INFO",
  SetOfferPartNumber = "@@Offer/SET_OFFER_PART_NUMBER",
  SetOfferPrices = "@@Offer/SET_OFFER_PRICES",
  SetOfferStatus = "@@Offer/SET_OFFER_STATUS",
  DuplicateOffer = "@@Offer/DUPLICATE_OFFER",
  GetOfferId = "@@Offer/GET_OFFER_ID",
  SearchForOffers = "@@Offer/SEARCH_FOR_OFFERS",
  SearchForOffersSearchBar = "@@Offer/SEARCH_FOR_OFFERS_IN_SEARCHBAR",
  GetOfferBySlug = "@@Offer/GET_OFFER_BY_SLUG",
  GetCarouselOffers = "@@Offer/GET_CAROUSEL_OFFERS",
  RemoveCarouselOffers = "@@Offer/REMOVE_CAROUSEL_OFFERS",
  GetMyOffersCountsByCategory = "@@Offer/GET_MY_OFFERS_COUNTS_BY_CATEGORY",
  GetUserOffersCount = "@@Offer/GET_USER_OFFERS_COUNT",
  AddTopOption = "@@Offer/ADD_TOP_OPTION",
  GetSearchedOffersCountsByCategory = "@@Offer/GET_SEARCHED_OFFERS_COUNTS_BY_CATEGORY",
  SetOfferReturnTOC = "@@Offer/SET_OFFER_RETURN_TOC",
  SetOfferReturnPolicy = "@@Offer/SET_OFFER_RETURN_POLICY",
  CreateOfferInappropriateFlag = "@@Offer/CREATE_OFFER_INAPPROPRIATE_FLAG",
}

/* ACTIONS */
export const getOfferActions = createApiActionCreators(
  OfferActionsTypes.GetOffer,
);
export const postOfferActions = createApiActionCreators(
  OfferActionsTypes.PostOffer,
);
export const deleteOfferActions = createApiActionCreators(
  OfferActionsTypes.DeleteOffer,
);
export const getOffersActions = createApiActionCreators(
  OfferActionsTypes.GetOffers,
);
export const deleteOffersActions = createApiActionCreators(
  OfferActionsTypes.DeleteOffers,
);
export const editOfferActions = createApiActionCreators(
  OfferActionsTypes.EditOffer,
);
export const setOfferItemStatusActions = createApiActionCreators(
  OfferActionsTypes.SetOfferItemStatus,
);
export const setOfferLocationActions = createApiActionCreators(
  OfferActionsTypes.SetOfferLocation,
);
export const setOfferMarketTypeActions = createApiActionCreators(
  OfferActionsTypes.SetOfferMarketType,
);
export const setOfferDeliveryTypesActions = createApiActionCreators(
  OfferActionsTypes.SetOfferDeliveryTypes,
);
export const deleteOfferDeliveryTypesActions = createApiActionCreators(
  OfferActionsTypes.DeleteOfferDeliveryTypes,
);
export const getOfferCustomEnumsActions = createApiActionCreators(
  OfferActionsTypes.GetOfferCustomEnums,
);
export const setOfferCustomEnumsActions = createApiActionCreators(
  OfferActionsTypes.SetOfferCustomEnums,
);
export const setOfferPublishOptionActions = createApiActionCreators(
  OfferActionsTypes.SetOfferPublishOption,
);
export const setOfferVehicleInfoActions = createApiActionCreators(
  OfferActionsTypes.SetOfferVehicleInfo,
);
export const setOfferPartNumberActions = createApiActionCreators(
  OfferActionsTypes.SetOfferPartNumber,
);
export const setOfferPricesActions = createApiActionCreators(
  OfferActionsTypes.SetOfferPrices,
);
export const setOfferStatusActions = createApiActionCreators(
  OfferActionsTypes.SetOfferStatus,
);
export const duplicateOfferActions = createApiActionCreators(
  OfferActionsTypes.DuplicateOffer,
);
export const getOfferIdActions = createApiActionCreators(
  OfferActionsTypes.GetOfferId,
);
export const searchForOfferActions = createApiActionCreators(
  OfferActionsTypes.SearchForOffers,
);
export const searchForOffersSearchBarActions = createApiActionCreators(
  OfferActionsTypes.SearchForOffersSearchBar,
);
export const getOfferBySlugActions = createApiActionCreators(
  OfferActionsTypes.GetOfferBySlug,
);
export const getCarouselOffersActions = createApiActionCreators(
  OfferActionsTypes.GetCarouselOffers,
);
export const getMyOffersCountsByCategoryActions = createApiActionCreators(
  OfferActionsTypes.GetMyOffersCountsByCategory,
);
export const getUserOffersCountActions = createApiActionCreators(
  OfferActionsTypes.GetUserOffersCount,
);
export const addTopOptionActions = createApiActionCreators(
  OfferActionsTypes.AddTopOption,
);
export const getSearchedOffersCountsByCategoryActions = createApiActionCreators(
  OfferActionsTypes.GetSearchedOffersCountsByCategory,
);
export const setOfferReturnTOCActions = createApiActionCreators(
  OfferActionsTypes.SetOfferReturnTOC,
);
export const setOfferReturnPolicyActions = createApiActionCreators(
  OfferActionsTypes.SetOfferReturnPolicy,
);
export const removeCarouselOffersAction = createActionCreator(
  OfferActionsTypes.RemoveCarouselOffers,
);
export const createOfferInappropriateFlagActions = createApiActionCreators(
  OfferActionsTypes.CreateOfferInappropriateFlag,
);

/* REDUCERS */
const initialState: OfferState = {
  id: '',
  userId: '',
  companyId: '',
  mainCategoryId: '',
  categoryId: '',
  itemStatusId: '',
  marketTypeId: '',
  userAddressId: '',
  returnTOCId: '',
  returnPolicyId: '',
  name: '',
  slug: '',
  description: '',
  shortDescription: '',
  count: '1',
  prices: [],
  phoneNumber: '',
  status: '',
  createdAt: '',
  updatedAt: '',
  publishedAt: '',
  offers: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0
  },
  carouselOffers: [],
  offersSearchResult: [],
  offersSearchResultSearchBar: [],
  myOffersCountsByCategory: {},
  userOffersCount: 0,
  offerRequestState: LoadingState.success,
  searchOffersRequestState: LoadingState.success,
  duplicateOfferRequestState: LoadingState.success,
  searchedOffersCountsByCategory: {},
  offerDetail: null,
  createOfferInappropriateFlagRequestState: LoadingState.success,
};

const id = createReducer(initialState.id, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.id,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.id,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.id,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
});

const userId = createReducer(initialState.userId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userId || (payload.user && payload.user.id),
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userId || (payload.user && payload.user.id),
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userId || (payload.user && payload.user.id),
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
});

const companyId = createReducer(initialState.companyId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.companyId ? payload.companyId : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.companyId ? payload.companyId : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.companyId,
  },
});

const mainCategoryId = createReducer(initialState.mainCategoryId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.mainCategoryId,
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.mainCategoryId,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.mainCategoryId,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
});

const categoryId = createReducer(initialState.categoryId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.categoryId ? payload.categoryId : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.categoryId ? payload.categoryId : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.categoryId,
  },
});

const itemStatusId = createReducer(initialState.itemStatusId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.itemStatusId ?
      payload.itemStatusId : payload.itemStatus ? payload.itemStatus.id : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.itemStatusId ?
      payload.itemStatusId : payload.itemStatus ? payload.itemStatus.id : '',
  },
  [OfferActionsTypes.SetOfferItemStatus]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.itemStatusId ?
      payload.itemStatusId : payload.itemStatus ? payload.itemStatus.id : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.itemStatusId,
  },
});

const itemStatus = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.itemStatus ? payload.itemStatus : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.itemStatus ? payload.itemStatus : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const marketTypeId = createReducer(initialState.marketTypeId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.marketTypeId ?
      payload.marketTypeId : payload.marketType ? payload.marketType.id : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.marketTypeId ?
      payload.marketTypeId : payload.marketType ? payload.marketType.id : '',
  },
  [OfferActionsTypes.SetOfferMarketType]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.marketTypeId ?
      payload.marketTypeId : payload.marketType ? payload.marketType.id : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.marketTypeId,
  },
});

const marketType = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.marketType ? payload.marketType : null,
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (state: any, payload: any) => {
      if (payload.marketType) {
        return payload.marketType;
      }

      if (payload.marketTypeId && state && state.id === payload.marketTypeId) {
        return { ...state };
      }

      return null;
    }
  },
  [OfferActionsTypes.SetOfferMarketType]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.marketType ? payload.marketType : null,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const userAddressId = createReducer(initialState.userAddressId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userAddressId ?
      payload.userAddressId : payload.userAddress ? payload.userAddress.id : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userAddressId ?
      payload.userAddressId : payload.userAddress ? payload.userAddress.id : '',
  },
  [OfferActionsTypes.SetOfferLocation]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userAddressId ?
      payload.userAddressId : payload.userAddress ? payload.userAddress.id : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userAddressId,
  },
  [UserAddressActionTypes.GetAddresses]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) =>  _state || (payload && payload.length && payload[payload.length - 1].id),
  },
});

const userAddress = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userAddress ? payload.userAddress : '',
  },
  [OfferActionsTypes.SetOfferLocation]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.userAddress ? payload.userAddress : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const returnTOCId = createReducer(initialState.returnTOCId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnTOCId ?
      payload.returnTOCId : payload.returnTOC ? payload.returnTOC.id : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnTOCId ?
      payload.returnTOCId : payload.returnTOC ? payload.returnTOC.id : '',
  },
  [OfferActionsTypes.SetOfferReturnTOC]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnTOCId ?
      payload.returnTOCId : payload.returnTOC ? payload.returnTOC.id : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnTOCId,
  },
});

const returnTOC = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnTOC ? payload.returnTOC : '',
  },
  [OfferActionsTypes.SetOfferReturnTOC]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnTOC ? payload.returnTOC : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});


const returnPolicyId = createReducer(initialState.returnPolicyId, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnPolicyId ?
      payload.returnPolicyId : payload.returnPolicy ? payload.returnPolicy.id : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnPolicyId ?
      payload.returnPolicyId : payload.returnPolicy ? payload.returnPolicy.id : '',
  },
  [OfferActionsTypes.SetOfferReturnPolicy]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnPolicyId ?
      payload.returnPolicyId : payload.returnPolicy ? payload.returnPolicy.id : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnPolicyId
  },
});

const returnPolicy = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnPolicy ? payload.returnPolicy : '',
  },
  [OfferActionsTypes.SetOfferReturnPolicy]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.returnPolicy ? payload.returnPolicy : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const name = createReducer(initialState.name, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.name ? payload.name : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.name ? payload.name : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.name,
  },
});

const slug = createReducer(initialState.slug, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.slug ? payload.slug : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.slug ? payload.slug : '',
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.slug ? payload.slug : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.slug,
  },
});

const description = createReducer(initialState.description, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.description ? payload.description : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.description ? payload.description : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.description,
  },
});

const shortDescription = createReducer(initialState.shortDescription, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.shortDescription ? payload.shortDescription : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.shortDescription ? payload.shortDescription : '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.shortDescription,
  },
});

const count = createReducer(initialState.count, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => typeof payload.count === 'number' ? payload.count : '1',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) =>  typeof payload.count === 'number' ? payload.count : '1',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '1',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.count
  },
});

const prices = createReducer(initialState.prices, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.prices,
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.prices,
  },
  [OfferActionsTypes.SetOfferPrices]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.prices,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.prices ? payload.prices : _state,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => [],
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.prices ? payload.prices : [],
  },
});

const phoneNumber = createReducer(initialState.phoneNumber, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.phoneNumber ? payload.phoneNumber : '',
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.phoneNumber ? payload.phoneNumber : '',
  },
  [UserActionTypes.ChangePhoneNumber]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => {
      if (payload.refType === 'Offer') {
        _state = '+' + payload.phoneNumber;
        return _state;
      }
      return _state;
    },
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.phoneNumber ? payload.phoneNumber : '',
  },
});

const status = createReducer(initialState.status, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.status,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.status,
  },
  [OfferActionsTypes.SetOfferStatus]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.status,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.status,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
});

const createdAt = createReducer(initialState.createdAt, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.createdAt,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.createdAt,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.createdAt,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
});

const updatedAt = createReducer(initialState.updatedAt, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.updatedAt,
  },
  [OfferActionsTypes.EditOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.updatedAt,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.updatedAt,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.updatedAt,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
});

const publishedAt = createReducer(initialState.publishedAt, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.publishedAt ? payload.publishedAt : '',
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => '',
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.publishedAt ? payload.publishedAt : null,
  },
});

const categoryTree = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.categoryTree ? payload.categoryTree : [],
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => [],
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => [],
  },
});

const deliveryTypes = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.deliveryTypes ? payload.deliveryTypes : null,
  },
  [OfferActionsTypes.SetOfferDeliveryTypes]: {
    [RequestActionTypes.SUCCESS]: (state: any, payload: OfferDeliveryType[]) => {
      if (state && state.length) { 
        const newState = [...state];

        if (newState) {
          payload.forEach((newOfferDeliveryType) => {
            const currentDeliveryType = newState?.find((deliveryType: OfferDeliveryType) => deliveryType.deliveryTypeId === newOfferDeliveryType.deliveryTypeId);

            if (currentDeliveryType) {
              const currentDeliveryTypeIndex = newState.indexOf(currentDeliveryType);
  
              newState.splice(currentDeliveryTypeIndex, 1)
              newState.push(newOfferDeliveryType);
  
              return newState;
            }

            newState.push(newOfferDeliveryType);
          });
        }

        return newState;
      }

      return payload;
    }
  },
  [OfferActionsTypes.DeleteOfferDeliveryTypes]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => {
      const types = [..._state];
      const index = _state.findIndex((type: any) => type.deliveryType.name === payload.deliveryType.name);
      types.splice(index, 1);
      return types.length > 0 ? types : null;
    },
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const autoEnums = createReducer(null, {
  [OfferActionsTypes.GetOfferCustomEnums]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
  [OfferActionsTypes.SetOfferCustomEnums]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => {
      let tmp = [..._state];
      if (payload.data) {
        const index = tmp.findIndex((e: any)=> e.enumId === payload.data.enumId);
        if (index > -1) {
          tmp.splice(index, 1);
        }
        return tmp;
      } else {
        const foundedEnum = tmp.find((e: any)=> e.enumId === payload.enumId);
        if (foundedEnum) {
          foundedEnum.checkedOptions = foundedEnum.checkedOptions.concat(payload.checkedOptions);
          return tmp;
        }
        tmp.push(payload);
        return tmp;
      }
    },
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const publishOption = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => {
      if ("activePublishOption" in payload && payload.activePublishOption !== null) {
        return payload.activePublishOption;
      } else if ("selectedPublishOption" in payload) {
        return payload.selectedPublishOption;
      }
      return null;
    }
  },
  [OfferActionsTypes.SetOfferPublishOption]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.publishOption ? payload.publishOption : null,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const topOption = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => {
      if ("activeTopOption" in payload && payload.activeTopOption !== null) {
        return payload.activeTopOption;
      } else if ("selectedTopOption" in payload) {
        return payload.selectedTopOption;
      }
      return null;
    }
  },
  [OfferActionsTypes.AddTopOption]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
  [OfferActionsTypes.DuplicateOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.topOption ? payload.topOption : null,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const vehicleInfo = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.vehicleInfo ? payload.vehicleInfo : null,
  },
  [OfferActionsTypes.SetOfferVehicleInfo]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
});

const partNumber = createReducer(null, {
  [OfferActionsTypes.GetOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.partNumber ? payload.partNumber : null,
  },
  [OfferActionsTypes.SetOfferPartNumber]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.partNumber,
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => null,
  },
  [OfferActionsTypes.PostOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.partNumber ? payload.partNumber : null,
  },
});

const offers = createReducer(initialState.offers, {
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: OffersList, payload: OffersList) => payload,
  },
  [OfferActionsTypes.AddTopOption]: {
    [RequestActionTypes.SUCCESS]: (state: OffersList, payload: any) => {
      if (state.data && state.data.length > 0) {
        const currentData = state.data.map((offer: Offer) => {
          return offer.id !== payload.id ? offer : {
            ...offer,
            selectedTopOption: payload.selectedTopOption
          }
        });

        return {
          ...state,
          data: currentData,
        }
      } else {
        return {
          data: [],
          limit: 0,
          skip: 0,
          total: 0
        };
      }
    },
  },
});

const offersSearchResult = createReducer(initialState.offersSearchResult, {
  [OfferActionsTypes.SearchForOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
    [RequestActionTypes.REQUEST]: (_state: any) => [],
  },
  [RatingsActionsTypes.GetAllSellersOverallRatings]: {
    [RequestActionTypes.SUCCESS]: (state: OffersList | any, payload: SellerOverallRatings[]) => {
      if (state?.data?.length) {

        const newData = state.data.map((offer: Offer) => {

          const offerSellerRating = payload.find((sellerOverallRating: SellerOverallRatings) =>
            (offer.userId && sellerOverallRating.sellerId && offer.userId === sellerOverallRating.sellerId) ||
            (offer.user.slug && sellerOverallRating.sellerSlug && offer.user.slug === sellerOverallRating.sellerSlug)
          );

          if (offerSellerRating) {

            return({
              ...offer,
              user: {
                ...(offer.user ? offer.user : undefined),
                rating: offerSellerRating
              }
            });
          }

          return offer;
        });
        
        return ({
          ...state,
          data: newData
        });

      }

      return { ...state };
    },
  },
  [RatingsActionsTypes.GetOffersOverallRatings]: {
    [RequestActionTypes.SUCCESS]: (state: OffersList | any, payload: OfferOverallRatings) => {
      if (state?.data?.length) {

        const newData = state.data.map((offer: Offer) => {
          if ((offer.id && payload.offerId && offer.id === payload.offerId) || (offer.slug && payload.offerSlug && offer.slug === payload.offerSlug)) {
            return({
              ...offer,
              rating: {
                ...payload
              }
            });
          }

          return offer;
        });
        
        return ({
          ...state,
          data: newData
        });

      }

      return { ...state };
    },
  }
});

const offersSearchResultSearchBar = createReducer(initialState.offersSearchResultSearchBar, {
  [OfferActionsTypes.SearchForOffersSearchBar]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  }
});

const searchOffersRequestState = createLoadingStateReducer(initialState.searchOffersRequestState, {
  [OfferActionsTypes.SearchForOffers]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const carouselOffers = createReducer(initialState.carouselOffers, {
  [OfferActionsTypes.GetCarouselOffers]: {
    [RequestActionTypes.SUCCESS]: (state: any[], payload: { data: OffersList['data'], carouselId: string, pitId?: string, hitsResults?: HitsResults }) => {
      const currentCarousel = state.find((carousel: any) => carousel.carouselId === payload.carouselId);
      if (currentCarousel !== undefined) {
        const tmpState = [...state];
        const newState = tmpState.map((carousel: any) => {
          if (carousel.carouselId === payload.carouselId) {
            return {
              ...carousel,
              pitId: payload.pitId,
              hitsResults: carousel.hitsResults.slice().concat(payload.hitsResults),
              data: carousel.data.slice().concat(payload.data)
            }
          } else {
            return carousel;
          }
        });
        return newState;
      } else {
        const tmpState = [...state];
        tmpState.push({
          carouselId: payload.carouselId,
          pitId: payload.pitId,
          hitsResults: payload.hitsResults,
          data: payload.data
        })
        return tmpState;
      }
    }
  },
  [OfferActionsTypes.RemoveCarouselOffers]: (state: any[], payload: { carouselId: string }) => {
    if(payload.carouselId) {
      const tmpState = [...state];

      const currentCarousel = tmpState.find((carousel: any) => carousel.carouselId === payload.carouselId);

      if (currentCarousel) {
        const indexToRemove = tmpState.indexOf(currentCarousel);

        if (indexToRemove >= 0) {
          tmpState.splice(indexToRemove, 1);
        }
      }
      
      return tmpState;
    }

    return [...state];
  }
});

const myOffersCountsByCategory = createReducer(initialState.myOffersCountsByCategory, {
  [OfferActionsTypes.GetMyOffersCountsByCategory]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: { category: string, count: number }) => {
      return {
        ..._state,
        [payload.category]: payload.count,
      }
    },
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => {
      return ({})
    },
  },
});

const userOffersCount = createReducer(initialState.userOffersCount, {
  [OfferActionsTypes.GetUserOffersCount]: {
    [RequestActionTypes.SUCCESS]: (_state: number, payload: any) => {
      return payload.total;
    },
  },
});

const offerRequestState = createLoadingStateReducer(initialState.offerRequestState, {
  [OfferActionsTypes.EditOffer]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferItemStatus]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferLocation]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferMarketType]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferDeliveryTypes]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.DeleteOfferDeliveryTypes]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferCustomEnums]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferPublishOption]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferVehicleInfo]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferPartNumber]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferPrices]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SetOfferStatus]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.DuplicateOffer]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OfferActionsTypes.SearchForOffers]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const duplicateOfferRequestState = createLoadingStateReducer(initialState.duplicateOfferRequestState, {
  [OfferActionsTypes.DuplicateOffer]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const searchedOffersCountsByCategory = createReducer(initialState.searchedOffersCountsByCategory, {
  [OfferActionsTypes.GetSearchedOffersCountsByCategory]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: { category: string, count: number }) => {
      return ({
        ..._state,
        [payload.category]: payload.count,
      });
    },
    [RequestActionTypes.FAILURE]: (_state: any) => {
      return ({});
    },
  },
  [OfferActionsTypes.SearchForOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => {
      return ({});
    },
  },
});

const offerDetail = createReducer(initialState.offerDetail, {
  [OfferActionsTypes.GetOfferBySlug]: {
    [RequestActionTypes.SUCCESS]: (_state: Offer | null, payload: Offer) => payload,
    [RequestActionTypes.REQUEST]: (_state: any) => null
  },
  [OfferActionsTypes.SearchForOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: Offer | null) => null,
  },
  [RatingsActionsTypes.GetSellersOverallRatings]: {
    [RequestActionTypes.SUCCESS]: (state: Offer | null, payload: SellerOverallRatings) => {
      if (
        state && (
          (state.userId && payload.sellerId && state.userId === payload.sellerId) ||
          (state.user?.slug && payload.sellerSlug && state.user.slug === payload.sellerSlug)
        )
      ) {

        return({
          ...state,
          user: {
            ...(state.user ? state.user : undefined),
            rating: payload
          }
        });
        
      }

      return { ...state };
    },
  },
});

const createOfferInappropriateFlagRequestState = createLoadingStateReducer(initialState.createOfferInappropriateFlagRequestState, {
  [OfferActionsTypes.CreateOfferInappropriateFlag]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<OfferState>({
  id,
  userId,
  companyId,
  mainCategoryId,
  categoryId,
  itemStatusId,
  itemStatus,
  marketTypeId,
  marketType,
  userAddressId,
  userAddress,
  returnTOCId,
  returnTOC,
  returnPolicyId,
  returnPolicy,
  name,
  slug,
  description,
  shortDescription,
  count,
  prices,
  phoneNumber,
  status,
  createdAt,
  updatedAt,
  publishedAt,
  categoryTree,
  deliveryTypes,
  autoEnums,
  publishOption,
  topOption,
  vehicleInfo,
  partNumber,
  offers,
  offersSearchResult,
  offersSearchResultSearchBar,
  carouselOffers,
  myOffersCountsByCategory,
  userOffersCount,
  offerRequestState,
  searchOffersRequestState,
  duplicateOfferRequestState,
  searchedOffersCountsByCategory,
  offerDetail,
  createOfferInappropriateFlagRequestState
});

/* SELECTORS */
export const selectOfferState = (state: AppState) => state.offer;

export const selectOfferId = (state: AppState) => selectOfferState(state).id;

export const selectOffers = (state: AppState): OffersList  => selectOfferState(state).offers;

export const selectOffersSearchResult = (state: AppState) => selectOfferState(state).offersSearchResult;

export const selectOffersSearchResultSearchBar = (state: AppState) => selectOfferState(state).offersSearchResultSearchBar;

export const selectCarouselOffers = (state: AppState) => selectOfferState(state).carouselOffers;

export const selectMyOffersCountsByCategory = (state: AppState) => selectOfferState(state).myOffersCountsByCategory;

export const selectUserOffersCount = (state: AppState) => selectOfferState(state).userOffersCount;

export const selectOfferRequestState = (state: AppState) => selectOfferState(state).offerRequestState;

export const selectSearchOffersRequestState = (state: AppState) => selectOfferState(state).searchOffersRequestState;

export const selectDuplicateOfferRequestState = (state: AppState) => selectOfferState(state).duplicateOfferRequestState;

export const selectSearchedOffersCountsByCategory = (state: AppState) => selectOfferState(state).searchedOffersCountsByCategory;

export const selectOfferDetail = (state: AppState) => selectOfferState(state).offerDetail;

export const selectCreateOfferInappropriateFlagRequestState = (state: AppState): LoadingState => selectOfferState(state).createOfferInappropriateFlagRequestState;

let forbiddenError: ErrorResponse =
  {
    className: '',
    code: 0,
    data: null,
    errors: [],
    message: '',
    name: ''
  };

i18n.on('loaded', function (loaded) {
  forbiddenError.message = i18n.t('Errors.Connection');
});

/* SAGAS */
function* getOffer({ payload }: any) {

  const params = {
    ...payload.params
  }

  const resp: ExtendedAxiosResponse = yield call(api.getOffer, payload.id, params);
  //
  if (resp.ok) {
    yield put(getOfferActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getOfferActions.failure());
  }
}

function* editOffer({ payload }: any) {
  let newPayload = { ...payload }
  delete newPayload.offerId

  const resp: ExtendedAxiosResponse = yield call(api.editOffer, {
    offerId: payload.offerId,
    payload: newPayload
  });
  //
  if (resp.ok) {
    yield put(editOfferActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(editOfferActions.failure());
  }
}

function* postOffer({ payload }: any) {
  if (payload.deleteId) {
    yield call(deleteOffer, { payload: { body: payload.deleteId } });
  }
  delete payload.deleteId;
  const resp: ExtendedAxiosResponse = yield call(api.postOffer, payload);
  //
  if (resp.ok) {
    yield put(postOfferActions.success(resp.data));
    yield put(successResponseActions([{ message: "postOfferSuccess" }, { data: resp.data }]));
  } else {
    yield put(postOfferActions.failure());
  }
}

function* deleteOffer({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteOffer, payload.body);
  if (resp.ok) {
    yield put(deleteOfferActions.success(resp.data));
    if (!localStorage.getItem('logoutAfter')) {
      yield put(successResponseActions(resp.data));
    } else {
      yield put(logoutActions.request({ delete: true }));
    }
    if (payload.refresh) {
      payload.refresh();
    }
  } else {
    yield put(errorResponseActions([{ message: 'deletingOfferError' }, { data: resp.response.data }]));
    yield put(deleteOfferActions.failure());
    if (payload.refresh) {
      payload.refresh();
    }
    if (localStorage.getItem('logoutAfter')) {
      localStorage.removeItem('logoutAfter');
    }
  }
}

function* getOffers({ payload }: any) {
  const params = {
    '$limit': 24,
    ...payload
  }
  const resp: ExtendedAxiosResponse = yield call(api.getOffers, params);
  //
  if (resp.ok) {
    yield put(getOffersActions.success(resp.data));
    yield put(successResponseActions([{ message: "getOffers" }, { data: resp.data }]));
  } else {
    yield put(getOffersActions.failure());
  }
}

function* deleteOffers({ payload }: any) {
  let deleteError = false;
  for (var i = 0; i< payload.length; i++) {
    const resp: ExtendedAxiosResponse = yield call(api.deleteOffer, payload[i]);
    if (!resp.ok) {
      deleteError = resp.response.data;
    }
  }
  if (deleteError) {
    yield put(errorToastActions(i18n.t('Errors.SomeOffersNotDeleted')));
    yield put(errorResponseActions(deleteError));
  } else {
    yield put(successToastActions(i18n.t('SuccessToasts.AllOffersDeleted')));
  }
  yield put(deleteOffersActions.success());
  yield put(successResponseActions('Offers deleted successfully'));
}

function* setOfferItemStatus({ payload }: any) {
  let newPayload = { ...payload }
  delete newPayload.offerId

  const resp: ExtendedAxiosResponse = yield call(api.setOfferItemStatus, {
    offerId: payload.offerId,
    payload: newPayload,
  });
  //
  if (resp.ok) {
    yield put(setOfferItemStatusActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferItemStatusActions.failure());
  }
}

function* setOfferLocation({ payload }: any) {
  let newPayload = { ...payload }
  delete newPayload.offerId

  const resp: ExtendedAxiosResponse = yield call(api.setOfferLocation, {
    offerId: payload.offerId,
    payload: newPayload,
  });
  //
  if (resp.ok) {
    yield put(setOfferLocationActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferLocationActions.failure());
  }
}

function* setOfferMarketType({ payload }: any) {
  let newPayload = { ...payload }
  delete newPayload.offerId

  const resp: ExtendedAxiosResponse = yield call(api.setOfferMarketType, {
    offerId: payload.offerId,
    payload: newPayload,
  });
  //
  if (resp.ok) {
    yield put(setOfferMarketTypeActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferMarketTypeActions.failure());
  }
}

function* setOfferDeliveryTypes({ payload }: any) {

  const deliveryTypesToCreate = payload.map((payloadData: any) => {
    if (payloadData.data.allowEditPrice) {
      return {
        offersId: payloadData.offerId,
        data: {
          deliveryTypeId: payloadData.data.id,
          prices: [{
            value: Number(payloadData.data.chosenCurrencyPrices.value),
            vat: payloadData.data.chosenCurrencyPrices.vat,
            currencyId: payloadData.data.chosenCurrencyPrices.currency.id,
          }, ...(
            payloadData.data.secondaryCurrencyPrices && !isNaN(payloadData.data.secondaryCurrencyPrices.value) ? [{
              value: Number(payloadData.data.secondaryCurrencyPrices.value),
              vat: payloadData.data.secondaryCurrencyPrices.vat,
              currencyId: payloadData.data.secondaryCurrencyPrices.currency.id,
            }] : []
          )]
        }
      }
    } else {
      return {
        offersId: payloadData.offerId,
        data: {
          deliveryTypeId: payloadData.data.id,
        }
      }
    }
  });

  const responses: ExtendedAxiosResponse[] = yield all(deliveryTypesToCreate.map((deliveryTypeToCreate: any) => call(
    api.setOfferDeliveryTypes,
    deliveryTypeToCreate.offersId,
    deliveryTypeToCreate.data
  )));

  const successResponses = responses.filter((resp: any) => resp.ok);
  const errorResponses = responses.filter((resp: any) => !resp.ok);

  if (successResponses.length !== 0) {

    const successResponsesDatas = successResponses.map((resp: any) => resp.data);

    yield put(setOfferDeliveryTypesActions.success(successResponsesDatas));
    yield put(successResponseActions(successResponsesDatas));
  }

  if (errorResponses.length !== 0) {
    yield put(setOfferDeliveryTypesActions.failure());
  }
}

function* deleteOfferDeliveryTypes({ payload }: any) {
  let newPayload = { ...payload }
  delete newPayload.offerId

  const resp: ExtendedAxiosResponse = yield call(api.deleteOfferDeliveryTypes, {
    offerId: payload.offerId,
    deliveryTypeId: payload.data.id
  });
  //
  if (resp.ok) {
    yield put(deleteOfferDeliveryTypesActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(deleteOfferDeliveryTypesActions.failure());
  }
}

function* getOfferCustomEnums({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOfferCustomEnums, {
    offerId: payload.offerId,
  });
  //
  if (resp.ok) {
    yield put(getOfferCustomEnumsActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getOfferCustomEnumsActions.failure());
  }
}

function* setOfferCustomEnums({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferCustomEnums, {
    offerId: payload.offerId,
    payload: payload.body,
  });
  //
  if (resp.ok) {
    if (!payload.body.uncheck) {
      yield put(setOfferCustomEnumsActions.success(resp.data));
    } else {
      yield put(setOfferCustomEnumsActions.success({
        data: resp.data,
        uncheck: true
      }));
    }
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferCustomEnumsActions.failure());
  }
}

function* setOfferPublishOption({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferPublishOption, {
    offerId: payload.offerId,
    payload: payload.body,
  });
  //
  if (resp.ok) {
    yield put(setOfferPublishOptionActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferPublishOptionActions.failure());
  }
}

function* setOfferVehicleInfo({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferVehicleInfo, {
    offerId: payload.offerId,
    payload: payload.body,
  });
  //
  if (resp.ok) {
    yield put(setOfferVehicleInfoActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferVehicleInfoActions.failure());
  }
}

function* setOfferPartNumber({ payload }: any) {
  let newPayload = { ...payload };
  delete newPayload.offerId;

  const resp: ExtendedAxiosResponse = yield call(api.setOfferPartNumber, {
    offerId: payload.offerId,
    payload: newPayload
  });
  //
  if (resp.ok) {
    yield put(setOfferPartNumberActions.success(resp.data));
  } else {
    yield put(setOfferPartNumberActions.failure());
  }
}

function* setOfferPrices({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferPrices, {
    offerId: payload.offerId,
    payload: payload.body,
  });
  //
  if (resp.ok) {
    yield put(setOfferPricesActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(setOfferPricesActions.failure());
  }
}

function* setOfferStatus({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferStatus, payload.body);
  //
  if (resp.ok) {
    yield put(setOfferStatusActions.success(resp.data));
    yield put((successToastActions(payload.toastSuccessMessage)));
    if (payload.refresh) {
      payload.refresh();
    }
  } else {
    yield put(setOfferStatusActions.failure());
    yield put((errorToastActions(payload.toastErrorMessage)));
    if (payload.refresh) {
      payload.refresh();
    }
  }
}

function* duplicateOffer({ payload }: any) {
  const { redirect, id } = payload;

  const resp: ExtendedAxiosResponse = yield call(api.duplicateOffer, id);
  //
  if (resp.ok) {
    yield put(duplicateOfferActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.OfferDuplicated')));
    if (redirect === undefined || redirect === true) {
      yield put(push({
        pathname: i18n.t('Routes.AccountMyOffers')
      }));
    }
  } else {
    yield put(duplicateOfferActions.failure());
  }
}

function* getOfferId({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOfferId, payload.id, payload.params ? payload.params : null);
  //
  if (resp.ok) {
    yield put(getOfferIdActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getOfferIdActions.failure());
  }
}

function* searchForOffer({ payload }: any) {
  const { redirect, state } = payload;
  delete payload.state;
  delete payload.redirect;
  const resp: ExtendedAxiosResponse = yield call(api.searchForOffers, payload);

  if (resp.ok) {
    yield put(searchForOfferActions.success(resp.data));
    yield put(successResponseActions([{ message: 'searchForOffersSuccess' }, { data: resp.data }]));
    if (redirect) {
      if (resp.data.data && resp.data.data.length > 0) {
        yield put(push(redirect, state));
      } else {
        yield put(push(redirect + (redirect.includes("?") ? "&" : "?") + 'ref=noresult', state));
      }
    }
  } else {
    yield put(searchForOfferActions.failure());
  }
}

function* searchForOffersSearchBar({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.searchForOffers, payload);

  if (resp.ok) {
    yield put(searchForOffersSearchBarActions.success(resp.data));
    yield put(successResponseActions([{ message: 'searchForOffersSearchBarSuccess' }, { data: resp.data }]));
  } else {
    yield put(searchForOffersSearchBarActions.failure());
  }
}

function* getOfferBySlug({ payload }: any) {
  const params = {
    slug: payload.slug,
    ...payload.params,
  }
  const resp: ExtendedAxiosResponse = yield call(api.getOfferBySlug, params);

  if (resp.ok) {
    yield put(getOfferBySlugActions.success(resp.data));
    yield put(successResponseActions(resp.data));
  } else {
    yield put(getOfferBySlugActions.failure());
  }
}

function* getCarouselOffers({ payload }: any) {
  const { carouselId } = payload;
  const params = {
    ...payload
  }
  delete params.carouselId;

  const resp: ExtendedAxiosResponse = yield call(api.searchForOffers, params);

  if (resp.ok) {
    yield put(getCarouselOffersActions.success({
      data: resp.data.data,
      carouselId,
      pitId: resp.data.pitId,
      hitsResults: resp.data.hitsResults,
    }));
    yield put(successResponseActions([{ message: "getCarouselOffers" }, { data: resp.data }]));
  } else {
    yield put(getCarouselOffersActions.failure());
  }
}

function* getMyOffersCountsByCategory({ payload }: any) {

  const params = {
    '$limit': 0,
    ...(payload.category ? { mainCategoryId: payload.category } : undefined)
  };

  const resp: ExtendedAxiosResponse = yield call(api.getOffers, params);

  if (resp.ok) {
    yield put(getMyOffersCountsByCategoryActions.success({
      category: payload.category,
      count: resp?.data?.total
    }));
  } else {
    yield put(getMyOffersCountsByCategoryActions.failure());
  }
}

function* getUserOffersCount({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.searchForOffers, payload);

  if (resp.ok) {
    yield put(getUserOffersCountActions.success(resp.data));
  } else {
    yield put(getUserOffersCountActions.failure());
  }
}

function* addTopOption({ payload }: any) {
  const params = {
    ...payload,
  }
  delete params.id

  const resp: ExtendedAxiosResponse = yield call(api.addTopOption, payload.id, params);

  if (resp.ok) {
    yield put(addTopOptionActions.success(resp.data));
  } else {
    yield put(addTopOptionActions.failure());
  }
}

function* getSearchedOffersCountsByCategory({ payload }: any) {
  const params = {
    ...payload,
    limit: 0,
  }

  const resp: ExtendedAxiosResponse = yield call(api.searchForOffers, params);

  if (resp.ok) {
    yield put(getSearchedOffersCountsByCategoryActions.success({
      category: payload.categories[0],
      count: resp?.data?.total
    }));
  } else {
    yield put(getSearchedOffersCountsByCategoryActions.failure());
  }
}

function* setOfferReturnTOC({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferReturnTOC, payload.id, payload.body);

  if (resp.ok) {
    yield put(setOfferReturnTOCActions.success(resp.data));
  } else {
    yield put(setOfferReturnTOCActions.failure());
  }
}


function* setOfferReturnPolicy({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setOfferReturnPolicy, payload.id, payload.body);

  if (resp.ok) {
    yield put(setOfferReturnPolicyActions.success(resp.data));
  } else {
    yield put(setOfferReturnPolicyActions.failure());
  }
}

function* createOfferInappropriateFlag({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.createOfferInappropriateFlag, payload.offerId, payload.data);

  if (resp.ok) {
    yield put(createOfferInappropriateFlagActions.success(resp.data));
    yield call(Swal.close);
    yield put(successToastActions(i18n.t('SuccessToasts.OfferSuccefullyReported')));
  } else {
    yield put(createOfferInappropriateFlagActions.failure());
  }
}

/* EXPORT */
export function* offerSaga() {
  yield takeLatest(
    createActionType(OfferActionsTypes.PostOffer, RequestActionTypes.REQUEST),
    postOffer
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.DeleteOffer, RequestActionTypes.REQUEST),
    deleteOffer
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.GetOffers, RequestActionTypes.REQUEST),
    getOffers
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.DeleteOffers, RequestActionTypes.REQUEST),
    deleteOffers
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.GetOffer, RequestActionTypes.REQUEST),
    getOffer
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.EditOffer, RequestActionTypes.REQUEST),
    editOffer
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferItemStatus, RequestActionTypes.REQUEST),
    setOfferItemStatus
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferLocation, RequestActionTypes.REQUEST),
    setOfferLocation
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferMarketType, RequestActionTypes.REQUEST),
    setOfferMarketType
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.SetOfferDeliveryTypes, RequestActionTypes.REQUEST),
    setOfferDeliveryTypes
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.DeleteOfferDeliveryTypes, RequestActionTypes.REQUEST),
    deleteOfferDeliveryTypes
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.GetOfferCustomEnums, RequestActionTypes.REQUEST),
    getOfferCustomEnums
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.SetOfferCustomEnums, RequestActionTypes.REQUEST),
    setOfferCustomEnums
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferPublishOption, RequestActionTypes.REQUEST),
    setOfferPublishOption
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferVehicleInfo, RequestActionTypes.REQUEST),
    setOfferVehicleInfo
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferPartNumber, RequestActionTypes.REQUEST),
    setOfferPartNumber
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferPrices, RequestActionTypes.REQUEST),
    setOfferPrices
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SetOfferStatus, RequestActionTypes.REQUEST),
    setOfferStatus
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.DuplicateOffer, RequestActionTypes.REQUEST),
    duplicateOffer
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.GetOfferId, RequestActionTypes.REQUEST),
    getOfferId
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.GetOfferBySlug, RequestActionTypes.REQUEST),
    getOfferBySlug,
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SearchForOffers, RequestActionTypes.REQUEST),
    searchForOffer
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.SearchForOffersSearchBar, RequestActionTypes.REQUEST),
    searchForOffersSearchBar
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.GetCarouselOffers, RequestActionTypes.REQUEST),
    getCarouselOffers
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.GetMyOffersCountsByCategory, RequestActionTypes.REQUEST),
    getMyOffersCountsByCategory
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.GetUserOffersCount, RequestActionTypes.REQUEST),
    getUserOffersCount
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.AddTopOption, RequestActionTypes.REQUEST),
    addTopOption
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.GetSearchedOffersCountsByCategory, RequestActionTypes.REQUEST),
    getSearchedOffersCountsByCategory
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.SetOfferReturnTOC, RequestActionTypes.REQUEST),
    setOfferReturnTOC
  );
  yield takeEvery(
    createActionType(OfferActionsTypes.SetOfferReturnPolicy, RequestActionTypes.REQUEST),
    setOfferReturnPolicy
  );
  yield takeLatest(
    createActionType(OfferActionsTypes.CreateOfferInappropriateFlag, RequestActionTypes.REQUEST),
    createOfferInappropriateFlag
  );
}
