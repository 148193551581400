import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { AppState } from 'helpers/store/models/AppState';
import { api } from './api';
import {
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer, LoadingState,
  RequestActionTypes,
} from 'helpers/redux/redux-helpers';
import i18n from '../../../i18n';
import { successToastActions } from 'saga/toast/ducks';
import { PaymentMethod } from '../../../helpers/store/models/PaymentMethod';
import { getUserActions, selectUser } from '../ducks';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
export interface UserPaymentMethodState {
  customerPaymentMethods: PaymentMethod[];
  createError: string | null;
  requestState: LoadingState;
}

/* ACTION TYPES */
export enum UserPaymentMethodActionTypes {
  GetPaymentMethods = '@@UserPaymentMethod/GET_PAYMENT_METHODS',
  AddPaymentMethod = '@@UserPaymentMethod/ADD_PAYMENT_METHOD',
  ChangeDefaultPaymentMethod = '@@UserPaymentMethod/CHANGE_DEFAULT_PAYMENT_METHOD',
  RemovePaymentMethod = '@@UserPaymentMethod/REMOVE_PAYMENT_METHOD',
}

/* ACTIONS */
export const getPaymentMethodsActions = createApiActionCreators(UserPaymentMethodActionTypes.GetPaymentMethods);
export const addPaymentMethodActions = createApiActionCreators(UserPaymentMethodActionTypes.AddPaymentMethod);
export const changeDefaultPaymentMethodAction =
  createApiActionCreators(UserPaymentMethodActionTypes.ChangeDefaultPaymentMethod);
export const removePaymentMethodActions = createApiActionCreators(UserPaymentMethodActionTypes.RemovePaymentMethod);

/* REDUCERS */
const initialState: UserPaymentMethodState = {
  customerPaymentMethods: [],
  createError: null,
  requestState: LoadingState.success,
};

const customerPaymentMethods = createReducer(initialState.customerPaymentMethods, {
  [UserPaymentMethodActionTypes.GetPaymentMethods]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentMethod[], payload: PaymentMethod[]) => payload,
    [RequestActionTypes.FAILURE]: (_state: PaymentMethod[]) => [],
  },
});

const createError = createReducer(initialState.createError, {
  [UserPaymentMethodActionTypes.AddPaymentMethod]: {
    [RequestActionTypes.REQUEST]: (_state: string | null) => null,
    [RequestActionTypes.FAILURE]: (_state: string | null, payload: string | null) => payload || null,
  },
});

const requestState = createLoadingStateReducer(initialState.requestState, {
  [UserPaymentMethodActionTypes.GetPaymentMethods]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserPaymentMethodActionTypes.AddPaymentMethod]: [RequestActionTypes.REQUEST, RequestActionTypes.FAILURE],
  [UserPaymentMethodActionTypes.ChangeDefaultPaymentMethod]: [RequestActionTypes.REQUEST, RequestActionTypes.FAILURE],
  [UserPaymentMethodActionTypes.RemovePaymentMethod]: [RequestActionTypes.REQUEST, RequestActionTypes.FAILURE],
});

export default combineReducers<UserPaymentMethodState>({
  customerPaymentMethods,
  createError,
  requestState,
});

/* SELECTORS */
export const selectUserPaymentMethodState = (state: AppState): UserPaymentMethodState =>
  state.userPaymentMethod;

export const selectUserPaymentMethods = (state: AppState): PaymentMethod[] =>
  selectUserPaymentMethodState(state).customerPaymentMethods;

export const selectCreatePaymentMethodError = (state: AppState): string | null =>
  selectUserPaymentMethodState(state).createError;

export const selectUserDefaultPaymentMethods = (state: AppState): Record<string, PaymentMethod | null> => {
  const user = selectUser(state);

  if (!user) {
    return {};
  }

  const paymentMethods = selectUserPaymentMethods(state);

  return user.stripeAccounts?.reduce((acc, account) => ({
    ...acc,
    [account.country]: account.defaultCardId
      ? paymentMethods.find(paymentMethod => paymentMethod.id === account.defaultCardId) || null
      : null
  }), {}) || {};
}

export const selectUserPaymentMethodsRequestState = (state: AppState) =>
  selectUserPaymentMethodState(state).requestState;

/* SAGAS */
function* getPaymentMethods({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getPaymentMethods, payload);

  if (resp.ok) {
    yield put(getPaymentMethodsActions.success(resp.data));
  } else {
    yield put(getPaymentMethodsActions.failure());
  }
}

function* addPaymentMethod({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.addPaymentMethod, payload);

  if (resp.ok) {
    yield put(getUserActions.request());
    yield put(getPaymentMethodsActions.request(payload.country));
    yield put(successToastActions(i18n.t('Checkout.CreditCardAddedSuccess')));
  } else {
    const { status, data } = resp.response;

    if (status === 400 && data.errors && data.errors[0]?.path === 'token') {
      yield put(addPaymentMethodActions.failure(data.errors[0].message));
    } else {
      yield put(addPaymentMethodActions.failure());
    }
  }
}

function* changeDefaultPaymentMethod({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.changeDefaultPaymentMethod, payload.cardId, payload.country);

  if (resp.ok) {
    yield put(getUserActions.request());
    yield put(getPaymentMethodsActions.request(payload.country));
    yield put(successToastActions(i18n.t('Checkout.DefaultCardChangedSuccess')));
  } else {
    yield put(changeDefaultPaymentMethodAction.failure());
  }
}

function* removePaymentMethod({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.removePaymentMethod, payload.cardId, payload.country);

  if (resp.ok) {
    yield put(getUserActions.request());
    yield put(getPaymentMethodsActions.request(payload.country));
    yield put(successToastActions(i18n.t('MyAccount.CreditTransactionsCards.CardRemovedSuccess')));
  } else {
    yield put(removePaymentMethodActions.failure());
  }
}

/* EXPORT */
export function* userPaymentMethodSaga() {
  yield takeLatest(
    createActionType(UserPaymentMethodActionTypes.GetPaymentMethods, RequestActionTypes.REQUEST),
    getPaymentMethods,
  );

  yield takeLatest(
    createActionType(UserPaymentMethodActionTypes.AddPaymentMethod, RequestActionTypes.REQUEST),
    addPaymentMethod,
  );

  yield takeLatest(
    createActionType(UserPaymentMethodActionTypes.ChangeDefaultPaymentMethod, RequestActionTypes.REQUEST),
    changeDefaultPaymentMethod,
  );

  yield takeLatest(
    createActionType(UserPaymentMethodActionTypes.RemovePaymentMethod, RequestActionTypes.REQUEST),
    removePaymentMethod,
  );
}
