import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { api } from "./api";
import { AppState } from "helpers/store/models/AppState";
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  LoadingState,
  createLoadingStateReducer,
  createActionCreator,
} from "helpers/redux/redux-helpers";
import { combineReducers } from "redux";
import { ExtendedAxiosResponse } from "model/ExtendedAxiosResponse";
import { OffersImport, OffersImportStatus } from '../../../model/OffersImport';

/* STATE */
export interface ImportOffersState {
  importOffersResposne: OffersImport | null,
  importOffersRequestState: LoadingState,
}

/* ACTION TYPES */
export enum ImportOffersActionsTypes {
  ImportOffers = "@@Offer/IMPORT_OFFERS",
  GetImportOffersStatus = "@@Offer/GET_OFFER_IMPORT_STATUS",
  ClearImportResponse = "@@Offer/CLEAR_IMPORT_RESPONSE",
  CancelOffersImport = "@@Offer/CANCEL_OFFERS_IMPORT",
}

/* ACTIONS */
export const importOffersActions = createApiActionCreators(ImportOffersActionsTypes.ImportOffers);
export const getOffersImportStatusActions = createApiActionCreators(ImportOffersActionsTypes.GetImportOffersStatus);
export const clearImportResponseAction = createActionCreator(ImportOffersActionsTypes.ClearImportResponse, RequestActionTypes.SUCCESS);
export const cancelOffersImportActions = createApiActionCreators(ImportOffersActionsTypes.CancelOffersImport);

/* REDUCERS */
const initialState: ImportOffersState = {
  importOffersResposne: null,
  importOffersRequestState: LoadingState.success,
};

const importOffersResposne = createReducer(initialState.importOffersResposne, {
  [ImportOffersActionsTypes.ImportOffers]: {
    [RequestActionTypes.REQUEST]: (_state: null | OffersImport) => null,
    [RequestActionTypes.SUCCESS]: (_state: null | OffersImport, payload: OffersImport | null) => payload,
    [RequestActionTypes.FAILURE]: (_state: null | OffersImport, payload: OffersImport | null) => payload,
  },
  [ImportOffersActionsTypes.GetImportOffersStatus]: {
    [RequestActionTypes.SUCCESS]: (_state: null | OffersImport, payload: OffersImport | null) => payload,
  },
  [ImportOffersActionsTypes.ClearImportResponse]: {
    [RequestActionTypes.SUCCESS]: (_state: null | OffersImport) => null,
  },
});

const importOffersRequestState = createLoadingStateReducer(initialState.importOffersRequestState, {
  [ImportOffersActionsTypes.ImportOffers]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [ImportOffersActionsTypes.ClearImportResponse]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<ImportOffersState>({
  importOffersResposne,
  importOffersRequestState
});

/* SELECTORS */
export const selectImportOffersState = (state: AppState) => state.importOffers;

export const selectImportOffersResponse = (state: AppState): OffersImport | null => selectImportOffersState(state).importOffersResposne;

export const selectImportOffersRequestState = (state: AppState): LoadingState => selectImportOffersState(state).importOffersRequestState;

function* getImportStatus(payload: { id: string }): any {
  const resp: ExtendedAxiosResponse<OffersImport> | undefined = yield call(api.getOffersImportStatus, payload.id);

  if (resp?.ok) {

    yield put(getOffersImportStatusActions.success(resp.data));

    if (resp.data?.status && [OffersImportStatus.initialized, OffersImportStatus.processing, OffersImportStatus.validatingData].includes(resp.data.status)) {
      yield delay([OffersImportStatus.initialized, OffersImportStatus.validatingData].includes(resp.data.status) ? 2000 : 10000);

      const actualResponse = yield select(selectImportOffersResponse);

      if (actualResponse && actualResponse.status === OffersImportStatus.cancelledByUser) {
        yield put(importOffersActions.failure(actualResponse));
        return;
      }

      yield call(getImportStatus, payload);
    } else if (resp.data?.status && [
      OffersImportStatus.success,
      OffersImportStatus.someOffersFailedToImport,
      OffersImportStatus.error,
      OffersImportStatus.validationError
    ].includes(resp.data.status)) {
      yield put(importOffersActions.success(resp.data));
    } else if (resp.data?.status && [
      OffersImportStatus.cancelledByUser
    ].includes(resp.data.status)) {
      yield put(importOffersActions.failure(resp.data));
    } else {
      yield put(importOffersActions.failure(resp.data));
    }

  } else if (resp?.data) {
    yield put(importOffersActions.failure(resp.data));
    yield put(getOffersImportStatusActions.failure());
  } else {
    yield put(importOffersActions.failure(null));
    yield put(getOffersImportStatusActions.failure());
  }
}

function* importOffers({ payload }: any) {
  const resp: ExtendedAxiosResponse<OffersImport> | undefined = yield call(api.createOffersImport, payload.data);

  if (resp?.ok && resp.data?.id) {
    yield put(getOffersImportStatusActions.success(resp.data));

    yield call(getImportStatus, { id: resp.data.id });
  } else {
    yield put(importOffersActions.failure(null));
  }
}

function* cancelOffersImport({ payload }: any) {
  const resp: ExtendedAxiosResponse<OffersImport> | undefined = yield call(api.cancelOffersImport, payload.data);

  if (resp?.ok) {
    yield put(cancelOffersImportActions.success());
  } else {
    yield put(cancelOffersImportActions.failure());
  }
}

/* EXPORT */
export function* importOffersSaga() {
  yield takeLatest(
    createActionType(ImportOffersActionsTypes.ImportOffers, RequestActionTypes.REQUEST),
    importOffers
  );
  yield takeLatest(
    createActionType(ImportOffersActionsTypes.CancelOffersImport, RequestActionTypes.REQUEST),
    cancelOffersImport
  );
}
