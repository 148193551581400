import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { AppState } from 'helpers/store/models/AppState';
import { api } from './api';
import {
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer,
  RequestActionTypes,
  LoadingState,
} from 'helpers/redux/redux-helpers';
import { UserActionTypes } from '../ducks';
import { errorResponseActions } from 'saga/response/ducks';
import { PaginatedResponse } from '../../../model/General';
import { Invoice } from '../../../model/UserInvoices';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
export interface UserInvoicesState {
  invoices: PaginatedResponse<Invoice>,
  requestState: LoadingState,
}

/* ACTION TYPES */
export enum UserInvoicesActionTypes {
  GetInvoices = '@@UserInvoices/GET_INVOICES',
}

/* ACTIONS */
export const getInvoicesActions = createApiActionCreators(UserInvoicesActionTypes.GetInvoices);

/* REDUCERS */
const initialState: UserInvoicesState = {
  invoices: {
    data: [],
    limit: 10,
    skip: 0,
    total: 0
  },
  requestState: LoadingState.success,
};

const invoices = createReducer(initialState.invoices, {
  [UserInvoicesActionTypes.GetInvoices]: {
    [RequestActionTypes.SUCCESS]: (_state: PaginatedResponse<Invoice>, payload: PaginatedResponse<Invoice>) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.invoices,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoices,
  }
});

const requestState = createLoadingStateReducer(initialState.requestState, {
  [UserInvoicesActionTypes.GetInvoices]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<UserInvoicesState>({
  invoices,
  requestState,
});

/* SELECTORS */
const selectUserInvoicesState = (state: AppState): UserInvoicesState => state.userInvoices;

export const selectInvoices = (state: AppState): PaginatedResponse<Invoice> => selectUserInvoicesState(state).invoices;

export const selectInvoicesRequestState = (state: AppState): LoadingState => selectUserInvoicesState(state).requestState;

/* SAGAS */
function* getInvoices({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getInvoices, payload);

  if (resp.ok) {
    yield put(getInvoicesActions.success(resp.data));
  } else {
    yield put(getInvoicesActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

/* EXPORT */
export function* userInvoicesSaga() {
  yield takeLatest(
    createActionType(UserInvoicesActionTypes.GetInvoices, RequestActionTypes.REQUEST),
    getInvoices,
  );
}
