import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from './api';
import { AppState } from 'helpers/store/models/AppState';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from 'helpers/redux/redux-helpers';
import { combineReducers } from 'redux';
import { Photo, ProcessedFile } from 'model/Offer';
import imageCompression from 'browser-image-compression';
import { environment } from '../../../environments/environment';
import { OfferActionsTypes } from '../ducks';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

const COMPRESSION_OPTIONS = {
  maxSizeMB: environment.offer.imageUploadSettings.maxSizeMB,
  maxWidthOrHeight: environment.offer.imageUploadSettings.maxWidthOrHeight,
  useWebWorker: true
}

/* STATE */
export interface OfferPhotoState {
  photos: Photo[];
}

/* ACTION TYPES */
export enum OfferPhotoActionsTypes {
  ClearOfferPhotos = '@@OfferPhoto/CLEAR_OFFER_PHOTOS',
  GetOfferPhotos = '@@OfferPhoto/GET_OFFER_PHOTOS',
  CreateOfferPhotos = '@@OfferPhoto/CREATE_OFFER_PHOTOS',
  PatchOfferPhoto = '@@OfferPhoto/PATCH_OFFER_PHOTO',
  DeleteOfferPhoto = '@@OfferPhoto/DELETE_OFFER_PHOTO',
}

/* ACTIONS */
export const clearOfferPhotosAction = createActionCreator(
  OfferPhotoActionsTypes.ClearOfferPhotos,
);
export const getOfferPhotosActions = createApiActionCreators(
  OfferPhotoActionsTypes.GetOfferPhotos,
);
export const createOfferPhotosActions = createApiActionCreators(
  OfferPhotoActionsTypes.CreateOfferPhotos,
);
export const patchOfferPhotoActions = createApiActionCreators(
  OfferPhotoActionsTypes.PatchOfferPhoto,
);
export const deleteOfferPhotoActions = createApiActionCreators(
  OfferPhotoActionsTypes.DeleteOfferPhoto,
);

/* REDUCERS */
const initialState: OfferPhotoState = {
  photos: [],
};

const photos = createReducer(initialState.photos, {
  [OfferPhotoActionsTypes.GetOfferPhotos]: {
    [RequestActionTypes.SUCCESS]: (_state: Photo[], payload: Photo[]) =>
      payload.sort((a, b) => a.order - b.order),
  },
  [OfferPhotoActionsTypes.DeleteOfferPhoto]: {
    [RequestActionTypes.SUCCESS]: (state: Photo[], payload: Photo) =>
      state.filter((item: Photo) => item.id !== payload.id),
  },
  [OfferPhotoActionsTypes.CreateOfferPhotos]: {
    [RequestActionTypes.FAILURE]: (state: Photo[], payload: Photo) =>
      [...state, { ...payload, status: 'error' }],
  },
  [OfferActionsTypes.DeleteOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: Photo[]) => [],
  },
  [OfferActionsTypes.GetOffers]: {
    [RequestActionTypes.SUCCESS]: (_state: Photo[]) => [],
  },
  [OfferPhotoActionsTypes.ClearOfferPhotos]: (_state: Photo[]) => [],
});

export default combineReducers<OfferPhotoState>({
  photos,
});

/* SELECTORS */
const selectOfferPhotoState = (state: AppState) => state.offerPhoto;

export const selectOfferPhotos = (state: AppState) => selectOfferPhotoState(state).photos;

/* SAGAS */
function* getOfferPhotos({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOfferPhotos, payload);

  if (resp.ok) {
    yield put(getOfferPhotosActions.success(resp.data));
  } else {
    yield put(getOfferPhotosActions.failure());
  }
}

function* createOfferPhotos({ payload }: any) {
  const compressed: ProcessedFile[] = yield Promise.all(
    payload.photos.map(async (newFile: any) => {
      const reader = new FileReader();

      if (!newFile.file.type.includes('svg')) {
        const compressed = await imageCompression(newFile.file, COMPRESSION_OPTIONS);
        reader.readAsDataURL(compressed);
      } else {
        reader.readAsDataURL(newFile.file);
      }

      return new Promise((resolve) => {
        reader.onloadend = () =>
          resolve({
            offerId: payload.offerId,
            name: newFile.name,
            image: reader.result,
            isTitleImage: newFile.isTitleImage,
          });
      });
    })
  );

  for (const photo of compressed) {
    const resp: ExtendedAxiosResponse = yield call(api.createOfferPhoto, photo);

    if (!resp.ok) {
      yield put(createOfferPhotosActions.failure(photo));
    }
  }
}

function* patchOfferPhoto({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.patchOfferPhoto, payload.photoId, payload.body);

  if (resp.ok) {
    yield put(patchOfferPhotoActions.success(resp.data));
  } else {
    yield put(patchOfferPhotoActions.failure());
  }
}

function* deleteOfferPhoto({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteOfferPhoto, payload);

  if (resp.ok) {
    yield put(deleteOfferPhotoActions.success(resp.data));
  } else {
    yield put(deleteOfferPhotoActions.failure());
  }
}

/* EXPORT */
export function* offerPhotoSaga() {
  yield takeLatest(
    createActionType(OfferPhotoActionsTypes.GetOfferPhotos, RequestActionTypes.REQUEST),
    getOfferPhotos
  );
  yield takeLatest(
    createActionType(OfferPhotoActionsTypes.CreateOfferPhotos, RequestActionTypes.REQUEST),
    createOfferPhotos
  );
  yield takeLatest(
    createActionType(OfferPhotoActionsTypes.PatchOfferPhoto, RequestActionTypes.REQUEST),
    patchOfferPhoto
  );
  yield takeLatest(
    createActionType(OfferPhotoActionsTypes.DeleteOfferPhoto, RequestActionTypes.REQUEST),
    deleteOfferPhoto
  );
}
