import React, { ReactElement, forwardRef, useImperativeHandle } from "react";

interface DropdownProps {
  button: ReactElement;
  content: ReactElement | string;
  contentClassName?: string;
  disabled?: boolean;
}

const Dropdown = forwardRef((props: DropdownProps, ref) => {
  const [visible, setVisible] = React.useState(false);

  const { disabled } = props;

  useImperativeHandle(ref, () => ({ handleLeave }));

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;

    setVisible(true);
    document.documentElement.style.setProperty(
      "--overlayHeight",
      `${document.documentElement.scrollHeight - 424}px`
    );
  };

  const handleLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setVisible(false);
    document.documentElement.style.setProperty("--overlayHeight", `${0}px`);
  };

  return (
    <div
      className={`dropdown ${visible ? "visible" : ""}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <div
        onClick={handleLeave}
        onDragExit={handleLeave}
        onMouseOver={handleHover}
        className={`dropdown-buttonWrapper ${disabled ? "disabled" : ""}`}
      >
        {props.button}
      </div>
      <div
        className={`dropdown__content ${props.contentClassName}`}
        onClick={handleLeave}
      >
        {props.content}
      </div>
      <div className={"dropdown__overlay"} onMouseEnter={handleLeave}></div>
    </div>
  );
});

export default Dropdown;
