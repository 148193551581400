export const localWatchList = 'defaultWatchList';

export const getWatchListFromStorage = (watchListName: string = localWatchList): string[] => {
  const watchList = localStorage.getItem(watchListName);
  if (watchList) {
    return JSON.parse(watchList);
  }

  return [];
}

export const setWatchListInStorage = (offerIds: string[], watchListName: string = localWatchList) => {
  const jsonObject = JSON.stringify(offerIds);

  localStorage.setItem(watchListName, jsonObject);

  return 1;
}

export const removeWatchListFromStorage = (watchListName: string = localWatchList) => {
  localStorage.removeItem(watchListName);
}
  