import { call, put, takeLatest, select, delay } from "redux-saga/effects";
import { api } from "./api";
import { selectUser } from '../../user/ducks';
import { push } from 'connected-react-router';
import { AppState } from "helpers/store/models/AppState";
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  LoadingState,
  createLoadingStateReducer,
} from "helpers/redux/redux-helpers";
import i18n from '../../../i18n';
import { successToastActions, errorToastActions } from "saga/toast/ducks";
import { combineReducers } from "redux";
import { PaymentRequiredResponse, PaymentResult, PaymentStatus } from 'model/Payment';
import { environment as env } from 'environments/environment';
import { loadStripe, PaymentIntentResult, Stripe } from '@stripe/stripe-js';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';
import { SwalResult } from "views/pages/MyAccount/PopUp";

export enum PaymentResponseStatus {
  initialized = 'INITIALIZED',
  success = 'SUCCESS',
  failure = 'FAILURE',
}

/* STATE */
export interface PublishOfferState {
  actionResponse: PaymentRequiredResponse | PaymentResponseStatus | null,
  actionRequestState: LoadingState,
  confirmPaymentResponse: PaymentResult | null,
  clearPaymentResponsesState: LoadingState,
}

/* ACTION TYPES */
export enum PublishOfferActionsTypes {
  PublishOffer = "@@Offer/PUBLISH_OFFER",
  ConfirmPayment = "@@Offer/CONFIRM_PAYMENT",
  AddTopOptionToPublishedOffer = "@@Offer/ADD_TOP_OPTION_TO_PUBLISHED_OFFER",
  ClearPaymentResponses = "@@Offer/CLEAR_PAYMENT_RESPONSES"
}

/* ACTIONS */
export const publishOfferActions = createApiActionCreators(
  PublishOfferActionsTypes.PublishOffer,
);
export const addTopOptionToPublishedOfferActions = createApiActionCreators(
  PublishOfferActionsTypes.AddTopOptionToPublishedOffer,
);
export const confirmPaymentActions = createApiActionCreators(
  PublishOfferActionsTypes.ConfirmPayment,
);
export const clearPaymentResponsesActions = createApiActionCreators(
  PublishOfferActionsTypes.ClearPaymentResponses,
);


/* REDUCERS */
const initialState: PublishOfferState = {
  actionResponse: PaymentResponseStatus.initialized,
  actionRequestState: LoadingState.success,
  confirmPaymentResponse: null,
  clearPaymentResponsesState: LoadingState.success,
};

const actionResponse = createReducer(initialState.actionResponse, {
  [PublishOfferActionsTypes.PublishOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentRequiredResponse | null) => PaymentResponseStatus.success,
    [RequestActionTypes.FAILURE]: (_state: PaymentRequiredResponse | null, payload: PaymentRequiredResponse | null) => payload,
  },
  [PublishOfferActionsTypes.AddTopOptionToPublishedOffer]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentRequiredResponse | null) => PaymentResponseStatus.success,
    [RequestActionTypes.FAILURE]: (_state: PaymentRequiredResponse | null, payload: PaymentRequiredResponse | null) => payload,
  },
  [PublishOfferActionsTypes.ClearPaymentResponses]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentRequiredResponse | null) => initialState.actionResponse,
  }
});

const actionRequestState = createLoadingStateReducer(initialState.actionRequestState, {
  [PublishOfferActionsTypes.PublishOffer]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [PublishOfferActionsTypes.AddTopOptionToPublishedOffer]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [PublishOfferActionsTypes.ClearPaymentResponses]: [
    RequestActionTypes.SUCCESS
  ]
});

const confirmPaymentResponse = createReducer(initialState.confirmPaymentResponse, {
  [PublishOfferActionsTypes.ConfirmPayment]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentResult | null, payload: PaymentResult) => payload,
    [RequestActionTypes.FAILURE]: (_state: PaymentResult | null) => null,
  },
  [PublishOfferActionsTypes.ClearPaymentResponses]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentResult | null) => initialState.confirmPaymentResponse,
  }
});

const clearPaymentResponsesState = createLoadingStateReducer(initialState.clearPaymentResponsesState, {
  [PublishOfferActionsTypes.ClearPaymentResponses]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ]
});

export default combineReducers<PublishOfferState>({
  actionResponse,
  actionRequestState,
  confirmPaymentResponse,
  clearPaymentResponsesState
});

/* SELECTORS */
export const selectPublishOfferState = (state: AppState) => state.publishOffer;

export const selectActionResponse = (state: AppState): PaymentRequiredResponse | PaymentResponseStatus | null => selectPublishOfferState(state).actionResponse;

export const selectActionRequestState = (state: AppState): LoadingState => selectPublishOfferState(state).actionRequestState;

export const selectClearPaymentResponsesState = (state: AppState): LoadingState => selectPublishOfferState(state).clearPaymentResponsesState;

const preventGoBackSwalResult = { 
  value: { 
    preventGoBack: true
  }
} as SweetAlertResult<SwalResult>;

function* publishOffer({ payload }: any) {
  const { id, redirectSuccess } = payload || {};
  const params = {
    ...payload
  }
  delete params.id;
  delete params.redirectSuccess;

  const actionType = publishOfferActions;

  const resp: ExtendedAxiosResponse = yield call(api.publishOffer, id, params);

  if (resp.ok) {
    // without payment
    yield put(actionType.success());
    yield put(successToastActions(i18n.t('SuccessToasts.ConceptPublished')));
    yield call(Swal.close, preventGoBackSwalResult);
    if (redirectSuccess && typeof redirectSuccess === 'string') {
      yield call(clearPaymentResponses);
      yield put(push(redirectSuccess));
    }
  } else if (!resp.ok && resp.response.status === 402 && resp.response.data.data) {
    // payment required
    yield put(actionType.failure(resp.response.data.data as PaymentRequiredResponse));
    if (resp.response.data.data?.payment !== undefined) {
      yield call(processPayment, resp.response.data.data as PaymentRequiredResponse, actionType, redirectSuccess);
    }    
  } else {
    yield put(actionType.failure(null));
  }
}

function* addTopOptionToPublishedOffer({ payload }: any) {
  const { redirectSuccess } = payload || {};
  const params = {
    ...payload,
  }
  delete params.id

  const actionType = addTopOptionToPublishedOfferActions;

  const resp: ExtendedAxiosResponse = yield call(api.addTopOption, payload.id, params);

  if (resp.ok) {
    // without payment
    yield put(actionType.success());
    yield put(successToastActions(i18n.t('SuccessToasts.OfferTopped')));
    yield call(Swal.close, preventGoBackSwalResult);
    if (redirectSuccess && typeof redirectSuccess === 'string') {
      yield call(clearPaymentResponses);
      yield put(push(redirectSuccess));
    }
  } else if (!resp.ok && resp.response.status === 402 && resp.response.data.data) {
    // payment required
    yield put(actionType.failure(resp.response.data.data as PaymentRequiredResponse));
    if (resp.response.data.data?.payment !== undefined) {
      yield call(processPayment, resp.response.data.data as PaymentRequiredResponse, actionType, redirectSuccess);
    }    
  } else {
    yield put(actionType.failure(null));
  }
}

function* processPayment(responseData: PaymentRequiredResponse, actionType: ReturnType<typeof createApiActionCreators>, redirectSuccess?: string) {
  if (responseData.payment) {
    if (responseData?.payment?.status === PaymentStatus.success) {
      yield put(actionType.success());
      yield put(successToastActions(i18n.t('SuccessToasts.ConceptPublished')));
      yield call(Swal.close, preventGoBackSwalResult);
      if (redirectSuccess && typeof redirectSuccess === 'string') {
        yield call(clearPaymentResponses);
        yield put(push(redirectSuccess));
      }
    } else if (responseData?.payment?.status === PaymentStatus.actionRequired) {

      const { user } = yield select(selectUser);

      const stripe: Stripe | null = yield call(
        loadStripe,
        env.stripe[`publishableKey${user?.country || ''}`] || env.stripe.publishableKeySK
      );

      if (stripe && responseData?.payment?.clientSecret) {
        const result: PaymentIntentResult = yield call(stripe.confirmCardPayment, responseData.payment.clientSecret);

        if (result.error) {
          yield put(publishOfferActions.failure(null));
          yield put(errorToastActions(i18n.t('Checkout.Errors.SecureFailed')));
        } else {
          yield call(confirmPayment, {
            paymentId: responseData.payment.paymentId,
            redirectSuccess
          }, actionType);
        }
      } else {
        yield put(actionType.failure(null));
        yield put(errorToastActions(i18n.t('PaymentPopUp.Error.PublishOfferFailed')));
      }
    } else {
      yield put(actionType.failure(null));
      yield put(errorToastActions(i18n.t('PaymentPopUp.Error.PublishOfferFailed')));
    }
  } else {
    yield put(actionType.failure(null));
    yield put(errorToastActions(i18n.t('PaymentPopUp.Error.PublishOfferFailed')));
  }
}

function* confirmPayment(payload: any, actionType: ReturnType<typeof createApiActionCreators>) {
  const { redirectSuccess } = payload || {};
  const params = {
    ...payload,
  }
  delete params.redirectSuccess;

  const resp: ExtendedAxiosResponse = yield call(api.confirmPayment, params);

  if (resp.ok) {
    const { data } = resp;

    yield put(confirmPaymentActions.success(data));
    if (data.status === PaymentStatus.success) {
      yield put(actionType.success());
      yield put(successToastActions(i18n.t('SuccessToasts.ConceptPublished')));
      yield call(Swal.close, preventGoBackSwalResult);
      if (redirectSuccess && typeof redirectSuccess === 'string') {
        yield call(clearPaymentResponses);
        yield put(push(redirectSuccess));
      }
    } else if (data.status !== PaymentStatus.success) {
      yield put(actionType.failure(null));
      yield put(errorToastActions(i18n.t('PaymentPopUp.Error.PublishOfferFailed')));
    }
  } else {
    yield put(confirmPaymentActions.failure());
    yield put(actionType.failure(null));
    yield put(errorToastActions(i18n.t('PaymentPopUp.Error.PublishOfferFailed')));
  }
}

function* clearPaymentResponses() {
  yield delay(0);
  yield put(clearPaymentResponsesActions.success());
}

/* EXPORT */
export function* publishOfferSaga() {
  yield takeLatest(
    createActionType(PublishOfferActionsTypes.PublishOffer, RequestActionTypes.REQUEST),
    publishOffer
  );

  yield takeLatest(
    createActionType(PublishOfferActionsTypes.AddTopOptionToPublishedOffer, RequestActionTypes.REQUEST),
    addTopOptionToPublishedOffer
  );

  yield takeLatest(
    createActionType(PublishOfferActionsTypes.ClearPaymentResponses, RequestActionTypes.REQUEST),
    clearPaymentResponses
  )
}
