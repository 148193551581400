import { apiClient } from 'helpers/api-client';

export const api = {
  getOfferPhotos: (offerId: string) =>
    apiClient.get(`/offer/photos`, { params: { offerId } }),

  createOfferPhoto: (body: any) =>
    apiClient.post(`/offer/photos`, body),

  patchOfferPhoto: (photoId: string, payload: any) =>
    apiClient.patch(`/offer/photos/${photoId}`, payload),

  deleteOfferPhoto: (photoId: string) =>
    apiClient.delete(`/offer/photos/${photoId}`),
};
