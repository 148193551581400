export const cookieSettingsLocalStorage = 'cookieConsent';

export const getCookieConsentStateFromStorage = () => {
  const state = localStorage.getItem(cookieSettingsLocalStorage);
  return state ? JSON.parse(state) : false;
};

export const setCookieConsentStateStorage = (
  state: string
) => {
  localStorage.setItem(cookieSettingsLocalStorage, state);
};

export const removeCookieConsentStateFromStorage = () => {
  localStorage.removeItem(cookieSettingsLocalStorage);
};

export const getAnalyticsCookieConsentStateFromStorage = (): boolean => {
  const state = localStorage.getItem(cookieSettingsLocalStorage);
  return state && JSON.parse(state).analytics ? true : false;
};

export const getMarketingCookieConsentStateFromStorage = (): boolean => {
  const state = localStorage.getItem(cookieSettingsLocalStorage);
  return state && JSON.parse(state).marketing ? true : false;
};

