import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { AppState } from 'helpers/store/models/AppState';
import { api } from './api';
import {
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer,
  RequestActionTypes,
  LoadingState,
} from 'helpers/redux/redux-helpers';

// models
import { UserTransactionsPaginated } from 'model/UserTransactions';
import { UserBalance } from 'model/UserBalance';
import { UserActionTypes } from '../ducks';
import { errorResponseActions } from 'saga/response/ducks';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
export interface UserTransactionsState {
  transactions: UserTransactionsPaginated,
  getTransactionsRequestState: LoadingState,
  balance: UserBalance[] | null,
}

/* ACTION TYPES */
export enum UserTransactionsActionTypes {
  GetTransactions = '@@UserTransactions/GET_TRANSACTIONS',
  GetCreditTransactions = '@@UserTransactions/GET_CREDIT_TRANSACTIONS',
  GetUserBalance = '@@UserTransactions/GET_USER_BALANCE',
}

/* ACTIONS */
export const getTransactionsActions = createApiActionCreators(UserTransactionsActionTypes.GetTransactions);
export const getCreditTransactionsActions = createApiActionCreators(UserTransactionsActionTypes.GetCreditTransactions);
export const getUserBalanceActions = createApiActionCreators(UserTransactionsActionTypes.GetUserBalance);

/* REDUCERS */
const initialState: UserTransactionsState = {
  transactions: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0
  },
  getTransactionsRequestState: LoadingState.success,
  balance: null,
};

const transactions = createReducer(initialState.transactions, {
  [UserTransactionsActionTypes.GetTransactions]: {
    [RequestActionTypes.SUCCESS]: (_state: UserTransactionsPaginated, payload: UserTransactionsPaginated) => payload,
    [RequestActionTypes.FAILURE]: (_state: UserTransactionsPaginated) => {
      return ({
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      });
    },
  },
  [UserTransactionsActionTypes.GetCreditTransactions]: {
    [RequestActionTypes.SUCCESS]: (_state: UserTransactionsPaginated, payload: UserTransactionsPaginated) => payload,
    [RequestActionTypes.FAILURE]: (_state: UserTransactionsPaginated) => {
      return ({
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      });
    },
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: UserTransactionsPaginated) => initialState.transactions,
  }
});

const balance = createReducer(initialState.balance, {
  [UserTransactionsActionTypes.GetUserBalance]: {
    [RequestActionTypes.SUCCESS]: (_state: UserBalance[] | null, payload: UserBalance[]) => payload,
    [RequestActionTypes.FAILURE]: (_state: UserBalance[] | null) => null,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: UserBalance[] | null) => initialState.balance,
  }
});

const getTransactionsRequestState = createLoadingStateReducer(initialState.getTransactionsRequestState, {
  [UserTransactionsActionTypes.GetTransactions]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserTransactionsActionTypes.GetCreditTransactions]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<UserTransactionsState>({
  transactions,
  balance,
  getTransactionsRequestState,
});

/* SELECTORS */
export const selectUserTransactionsState = (state: AppState): UserTransactionsState => state.userTransactions;
export const selectTransactions = (state: AppState): UserTransactionsPaginated => selectUserTransactionsState(state).transactions;
export const selectUserBalance = (state: AppState): UserBalance[] | null => selectUserTransactionsState(state).balance;
export const selectGetTransactionsRequestState = (state: AppState): LoadingState => selectUserTransactionsState(state).getTransactionsRequestState;

/* SAGAS */
function* getTransactions({ payload }: any) {
  const params = {
    '$limit': 10,
    '$sort': {
      'createdAt': -1,
    },
    ...payload
  }

  const resp: ExtendedAxiosResponse = yield call(api.getTransactions, params);

  if (resp.ok) {
    yield put(getTransactionsActions.success(resp.data));
  } else {
    yield put(getTransactionsActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* getCreditTransactions({ payload }: any) {
  const params = {
    '$limit': 10,
    '$sort': {
      'createdAt': -1,
    },
    ...payload
  }

  const resp: ExtendedAxiosResponse = yield call(api.getCreditTransactions, params);
  if (resp.ok) {
    yield put(getCreditTransactionsActions.success(resp.data));
  } else {
    yield put(getCreditTransactionsActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* getUserBalance() {
  const resp: ExtendedAxiosResponse = yield call(api.getUserBalance);
  if (resp.ok) {
    yield put(getUserBalanceActions.success(resp.data));
  } else {
    yield put(getUserBalanceActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

/* EXPORT */
export function* userTransactionsSaga() {
  yield takeLatest(
    createActionType(UserTransactionsActionTypes.GetTransactions, RequestActionTypes.REQUEST),
    getTransactions,
  );

  yield takeLatest(
    createActionType(UserTransactionsActionTypes.GetCreditTransactions, RequestActionTypes.REQUEST),
    getCreditTransactions,
  );

  yield takeLatest(
    createActionType(UserTransactionsActionTypes.GetUserBalance, RequestActionTypes.REQUEST),
    getUserBalance,
  );
}
