import { ComponentType } from 'react';
import Login from 'views/pages/Login/Login';
import { lazyWithRetry } from 'helpers/other/lazyWithRetry';
export interface Route {
  path: string;
  exact: boolean;
  name?: string;
  protected?: boolean;
  component: ComponentType | any;
}

const routes: Route[] = [
  {
    path: 'Routes.Login',
    exact: true,
    component: Login,
  },
  {
    path: 'Routes.Register',
    exact: true,
    component: lazyWithRetry(() => import('views/pages/Register/Register')),
  },
  {
    path: 'Routes.NewPassword',
    exact: true,
    component: lazyWithRetry(() => import('views/pages/NewPassword/NewPassword')),
  },
  {
    path: 'Routes.PasswordReset',
    exact: true,
    component: lazyWithRetry(() => import('views/pages/PasswordReset/PasswordReset')),
  },
  {
    path: 'Routes.NewOffer',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/AddOffers/AddOffers')),
  },
  {
    path: 'Routes.Chat',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/Chat/Chat')),
  },
  {
    path: 'Routes.ChatDetail',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/Chat/Chat')),
  },
  {
    path: 'Routes.ChatNewMessage',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/Chat/Chat')),
  },
  {
    path: 'Routes.AccountActivation',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/AccountActivation/AccountActivation')),
  },
  {
    path: 'Routes.AccountInfoAdresses',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/InfoAndAddresses/InfoAndAddresses')),
  },
  {
    path: 'Routes.AccountPasswordChange',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/PasswordChange/PasswordChange')),
  },
  {
    path: 'Routes.AccountMyOffers',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOffers/MyOffers')),
  },
  {
    path: 'Routes.AccountMyOffersProlong',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOffers/OfferProlongation')),
  },
  {
    path: 'Routes.SearchResults',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/SearchResultPage/SearchResultPage')),
  },
  {
    path: 'Routes.AccountMyOrders',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOrders/MyOrders')),
  },
  {
    path: 'Routes.ResultsMarketplace',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/SearchResultPage/SearchResultPage')),
  },
  {
    path: 'Routes.ResultsAutomoto',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/SearchResultPage/SearchResultPage')),
  },
  {
    path: 'Routes.ResultsSpareParts',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/SearchResultPage/SearchResultPage')),
  },
  {
    path: 'Routes.ProductDetailAutomoto',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/ProductDetail/Main')),
  },
  {
    path: 'Routes.AccountLinkReAuth',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/StripeAccountLinkReauth/StripeAccountLinkReauth')),
  },
  {
    path: 'Routes.AddedToCart',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/Cart/AddedToCart/AddedToCart')),
  },
  {
    path: 'Routes.Cart',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/Cart/Cart/Cart')),
  },
  {
    path: 'Routes.Checkout',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/Cart/Checkout/Checkout')),
  },
  {
    path: 'Routes.EmailChange',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/LandingPage/LandingPage')),
  },
  {
    path: 'Routes.OrderDetailPage',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOrders/OrderDetail/OrderDetail')),
  },
  {
    path: 'Routes.NoResults',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/SearchResultPage/NoResults/NoResults')),
  },
  {
    path: 'Routes.OrderAddReview',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOrders/OrderAddReview/OrderAddReview')),
  },
  {
    path: 'Routes.OrderReviewThank',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOrders/OrderReviewThank/OrderReviewThank')),
  },
  {
    path: 'Routes.OrderCreated',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyOrders/OrderCreated/OrderCreated')),
  },
  {
    path: 'Routes.AccountSalesSettings',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/SalesSettings/SalesSettings')),
  },
  {
    path: 'Routes.WatchList',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/WatchListPage/WatchListPage')),
  },
  {
    path: 'Routes.AccountTransactions',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/CreditTransactionsCard/CreditTransactionCard')),
  },
  {
    path: 'Routes.AccountRecievedOrders',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyReceivedOrders/MyReceivedOrders')),
  },
  {
    path: 'Routes.AccountInvoices',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/Invoices/Invoices')),
  },
  {
    path: 'Routes.Store',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/SearchResultPage/SearchResultPage')),
  },
  {
    path: 'Routes.UserRatingPage',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/UserRatingPage/UserRatingPage')),
  },
  {
    path: 'Routes.AccountMyReturns',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/ProductReturns/ProductReturns')),
  },
  {
    path: 'Routes.AccountReceivedReturns',
    exact: true,
    protected: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/ProductReturns/ProductReturns')),
  },
  {
    path: 'Routes.ReceivedOrderDetailPage',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/MyReceivedOrders/ReceivedOrderDetail/ReceivedOrderDetail')),
  },
  {
    path: 'Routes.NewReturnRequest',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/ProductReturns/CreateReturnRequest/CreateReturnRequest')),
  },
  {
    path: 'Routes.MyRefundRequestDetailPage',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/ProductReturns/ReturnRequestDetailPage/ReturnRequestDetailPage')),
  },
  {
    path: 'Routes.ReceivedRefundRequestDetailPage',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/ProductReturns/ReturnRequestDetailPage/ReturnRequestDetailPage')),
  },
  {
    path: 'Routes.VehicleComparisonPage',
    protected: false,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/VehicleComparisonPage/VehicleComparisonPage')),
  },
  {
    path: 'Routes.OffersImporter',
    protected: true,
    exact: true,
    component: lazyWithRetry(() => import('views/pages/MyAccount/OffersImporter/OffersImporter')),
  },
  {
    path: 'Routes.SocialLogin',
    exact: true,
    component: lazyWithRetry(() => import('views/pages/LandingPage/LandingPage')),
  },
  {
    path: 'Routes.ProductDetail',
    exact: true,
    protected: false,
    component: lazyWithRetry(() => import('views/pages/ProductDetail/Main')),
  },
  {
    path: 'Routes.LandingPage',
    exact: true,
    component: lazyWithRetry(() => import('views/pages/LandingPage/LandingPage')),
  },  
  {
    path: '*',
    protected: false,
    exact: false,
    component: null,
  },
];

export default routes;
