import { apiClient } from 'helpers/api-client';
import { Currency } from "../../model/Currency";
import { CategoriesModel } from "../../model/CategoriesModel";
import { DeliveryTypesModel } from "../../model/DeliveryTypesModel";
import { ItemStatusesModel } from "../../model/ItemStatusesModel";
import { MarketTypesModel } from "../../model/MarketTypesModel";
import { CategoryModel } from 'model/CategoryModel';

export interface GetCategoriesRequestParams {
  $with?: Array<string>,
  $limit?: number,
  parentId?: string | null
}

// getCategories: (params?: GetCategoriesRequestParams): Promise<CategoriesModel | Array<CategoryModel>> => apiClient.get('/enum/categories/', { params }),

// getCategories: (params?: GetCategoriesRequestParams): Promise<CategoriesModel> => apiClient.get('/enum/categories/?$with[]=subCategories&$limit=-1&parentId=null', { params }),
// getSubCategories: (parentId: string): Promise<CategoriesModel> => apiClient.get(`/enum/categories/?$limit=-1&parentId=${parentId}`),

export type GetPublishOptionsParams = {
  "$limit": number;
  categoryId?: string;
}

export const api = {
  getCategories: (params?: GetCategoriesRequestParams): Promise<CategoriesModel | Array<CategoryModel>> => apiClient.get('/enum/categories/', { params }),
  getCurrencies: (): Promise<Currency> => apiClient.get('/enum/currencies?$limit=-1' ),
  getDeliveryTypes: (): Promise<DeliveryTypesModel> => apiClient.get('/enum/delivery-types?$limit=-1'),
  getItemStatuses: (params: any): Promise<ItemStatusesModel> => apiClient.get('/enum/item-statuses', { params }),
  getMarketTypes: (): Promise<MarketTypesModel> => apiClient.get('/enum/market-types?$limit=-1'),
  getFuelTypes: (): Promise<MarketTypesModel> => apiClient.get('/enum/auto/fuel-types?$limit=-1'),
  getCarCharacteristics: (): Promise<any> => apiClient.get('/enum/custom?$limit=-1&$with[]=options&categories.id=26824140-8fdd-4db4-bb9e-6e9fd7f578f6'),
  getCarBasicCharacteristics: (): Promise<any> => apiClient.get('/enum/custom?$limit=-1&$with[]=options&categories.id=26824140-8fdd-4db4-bb9e-6e9fd7f578f6'),
  getCarDetailCharacteristics: (): Promise<any> => apiClient.get('/enum/auto/general?$limit=-1&tag=detailed_eq&$with[]=options'),
  getVehicleVariants: (params: string): Promise<any> => apiClient.get(`/enum/auto/vehicle-variants?${params}`),
  getVehicleVariant: (id: string): Promise<any> => apiClient.get(`/enum/auto/vehicle-variants/${id}`),
  getBrands: (categoryId: string): Promise<any> => apiClient.get(`/enum/auto/vehicle-models?$limit=-1&categoryId=${categoryId}&$with[]=models`),
  getModels: (parentId: string): Promise<any> => apiClient.get(`/enum/auto/vehicle-models?$limit=-1${parentId ? '&parentId=' + parentId : ''}`),
  getCarEquipment: (id: string): Promise<any> => apiClient.get(`/enum/custom/${id}`),
  getPublishOptions: (params?: GetPublishOptionsParams): Promise<any> => apiClient.get('/enum/publish-options', { params }),
  getAllCustomEnums: (categoryId = null): Promise<any> => apiClient.get(`/enum/custom/?$with[]=options&$limit=50${categoryId ? `&categories.id=${categoryId}` : ''}`),
  getOfferEnumerations: (offerId: string): Promise<any> => apiClient.get(`/offer/${offerId}/custom-enums`),
  getReturnItemExceptions: (params?: any): Promise<any> => apiClient.get('/seller/return-items-exceptions', params),
  getTopOptions: (params?: any): Promise<any> => apiClient.get('/enum/top-options', { params }),
}