import React, { useState, useEffect, memo, useRef, useMemo, useCallback } from 'react';
import { AppState } from 'helpers/store/models/AppState';
import { connect } from 'react-redux';
import {
  selectUser,
  logoutActions,
  selectIsLogged,
  getUserActions,
  selectUserSettings,
  changeCurrencyActions,
  handleCurrencyChanged,
  changeLanguageActions,
} from 'saga/user/ducks';
import {
  getMyOffersCountsByCategoryActions,
  selectMyOffersCountsByCategory,
  selectOffersSearchResultSearchBar,
  searchForOffersSearchBarActions
} from "saga/offer/ducks";
import { errorToastActions, warnToastWithConfirmActions } from "saga/toast/ducks";
import { store } from 'helpers/store/configure-store';
import { User } from "../../../types/User";
import {
  Box,
  List,
  Grid,
  Badge,
  Drawer,
  Button,
  Divider,
  Collapse,
  TextField,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popper,
} from '@material-ui/core';
import Dropdown from '../Dropdown/Dropdown';
import Container from '../Container/Container';
import OauthButtons from '../OauthButtons/OauthButtons';
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory, useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OrderIcon from "../../../assets/Icons/OrderIcon";
import ListIcon from "../../../assets/Icons/ListIcon";
import UserIcon from "../../../assets/Icons/UserIcon";
import {
  getCategoriesActions,
  getCurrenciesActions,
  selectCurrencies,
  selectCategories,
} from 'saga/enumerations/ducks';
import {
  selectCartItemsCount,
  updateCartItemsCount,
} from "../../../saga/shoppingCart/ducs";
import { showEditPopUp } from 'views/pages/MyAccount/PopUp';
import CookieSettingsPopUp from '../CookiesBar/components/CookieSettingsPopUp';
import { environment as env } from 'environments/environment';
import { CategoryModel } from 'model/CategoryModel';
import qs from 'qs';
import {
  selectCanLanguageBeChanged,
  selectSearchBarVisible,
  setSearchBarVisibleActions
} from 'saga/layout/ducks';
import parse from 'html-react-parser';
import { getWatchListItemsActions, getWatchListsActions, selectWatchedOffersIds, selectWatchListDetail, selectWatchLists, setWatchedOffersIdsAction, WatchListDetail } from 'saga/watchList/ducks';
import { WatchList } from 'model/WatchList';
import { getWatchListFromStorage } from 'helpers/other/watchList';
import { sendGAEvent } from "helpers/other/googleAnalytics";
import { sendFBStandardEvent, sendFBCustomEvent } from "helpers/other/facebookPixel";
import { getHelpdeskUrl } from 'helpers/other/getHelpdeskUrl';

const cart = require('../../../assets/cart.svg');
const plus = require('../../../assets/plus.svg');
const loginWhite = require('../../../assets/arrow-forward-white.svg');
const logout = require('../../../assets/logout.svg');
const logoSk = require(`../../../assets/logos/sk/logo-blackwhite.svg`);
const logoCz = require(`../../../assets/logos/cz/logo-blackwhite.svg`);
const flag = require('../../../assets/slovakia-flag.svg');
const hamburger = require('../../../assets/hamburger.svg');
const czFlag = require('../../../assets/czech-republic-flag.png');
const ukFlag = require('../../../assets/united-kingdom-flag.png');
const plFlag = require('../../../assets/polish_flag.svg');
const zoom = require('../../../assets/zoom.svg');
const star = require('../../../assets/star.svg');
const userFilled = require('../../../assets/user-filled.svg');
const ptIcon = require('../../../assets/logos/logo-icon.png');
const start = require('../../../assets/start.svg');
const offers = require('../../../assets/offers.svg');

const logo = env.topLevelDomain === 'cz' ? logoCz : logoSk;

interface LangButton {
  lang: string;
  imgSrc: any;
  imgAlt: string;
  key: string;
}

const Header = (props: DispatchToProps & StateToProps) => {
  const {t} = useTranslation();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(env.defaultCurrency);
  const [hamburgerLangOpen, setHamburgerLangOpen] = useState(false);
  const [itemsCount, setItemsCount] = useState<any>(0);
  const [searchOptions, setSearchOptions] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState('');
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [categoryToSearchId, setCategoryToSearchId] = useState<string | undefined>(undefined);
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const [canBePopperVisible, setCanBePopperVisible] = useState(false);
  const [watchListId, setWatchListId] = useState<string | null>(null);
  const location = useLocation();
  const history = useHistory();  

  const headerSearchBarInputRef = useRef<HTMLInputElement>();
  const headerDropdownRef = useRef<any>();

  const {
    isSearchBarVisible,
    offersSearchResult,
    setSearchBarVisible,
  } = props;

  const langs = useMemo(() => {
    const initOpt = (lang: string) => {
      switch (lang) {
        case 'sk': return {imgSrc: flag, imgAlt: 'Slovak flag', key: 'Header.Languages.Slovak'};
        case 'cs': return {imgSrc: czFlag, imgAlt: 'Czech flag', key: 'Header.Languages.Czech'};
        case 'pl': return {imgSrc: plFlag, imgAlt: 'Polish flag', key: 'Header.Languages.Polish'};
        default: return {imgSrc: ukFlag, imgAlt: 'UK flag', key: 'Header.Languages.English'};
      }
    }

    return [
      {
        lang: i18n.language,
        ...(initOpt(i18n.language))
      },
      {
        lang: i18n.language !== 'cs' ? 'cs' : 'sk',
        ...(initOpt(i18n.language !== 'cs' ? 'cs' : 'sk'))
      },
      {
        lang: i18n.language !== 'pl' ? 'pl' : 'sk',
        ...(initOpt(i18n.language !== 'pl' ? 'pl' : 'sk'))
      },
      {
        lang: i18n.language !== 'en' ? 'en' : 'sk',
        ...(initOpt(i18n.language !== 'en' ? 'en' : 'sk'))
      }
    ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const otherDomain = env.appUrl === "PredamTi.sk" ? "ProdamTi.cz" : "PredamTi.sk";

  useEffect(() => {
    const loc = window.location;
    const errorMessage = new URLSearchParams(loc.search).get('error');

    if (errorMessage && loc.pathname === t("Routes.SocialLogin")) {
      if (errorMessage === "email-used") {
        store.dispatch(warnToastWithConfirmActions(t("Header.OauthError.EmailUsed")));
      } else if (errorMessage === "wrong-domain") {
        store.dispatch(errorToastActions(t("Header.OauthError.WrongDomain", {domain: otherDomain })));
      } else {
        store.dispatch(errorToastActions(t("Header.OauthError.General")));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  useEffect(() => {
    const resultsRoutes = [t('Routes.SearchResults'), t('Routes.ResultsMarketplace'), t('Routes.ResultsAutomoto')];
    if (resultsRoutes.includes(history.location.pathname) && !resultsRoutes.includes(location.pathname)) {
      setSearchText('');
      setSearchOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toggleHamburger = () => {
    if (hamburgerOpen) {
      setHamburgerOpen(false);
      resetHamburger();
    } else {
      setHamburgerOpen(true);
      handleSearchBarClose();
    }
  };

  const resetHamburger = () => {
    setSettingsOpen(false);
    setCurrencyOpen(false);
    setHamburgerLangOpen(false);
  };

  const handleSearchBarClose = useCallback(() => {
    if ((isSearchBarVisible && searchBarOpen) || (searchBarOpen && isSearchBarVisible && width >= 500)) {
      setSearchBarOpen(false);
      setSearchBarVisible(false);
    }
  }, [isSearchBarVisible, searchBarOpen, setSearchBarVisible, width]);

  useEffect(() => {
    if (isSearchBarVisible) {
      if (window.pageYOffset >= 300) {
        setSearchText('');
        setSearchBarOpen(true);
      } else {
        headerSearchBarInputRef?.current?.focus();
        setSearchBarVisible(false);
      }
    }
  }, [isSearchBarVisible, setSearchBarVisible])

  const handleLogout = () => {
    props.logout();
    if (hamburgerOpen) {
      toggleHamburger();
    }
  };

  const collapseHamburgerLang = () => {
    setHamburgerLangOpen(!hamburgerLangOpen);
  };

  const handleLangChange = (lang: string) => {
    changeLanguage(lang);
    toggleHamburger();
    resetHamburger();
  };

  const changeLanguage = (lang: string) => {
    if (props.user && props.user.id) {
      props.changeLanguage({
        userId: props.user.id,
        body: {
          language: lang
        }
      });
    }

    i18n.changeLanguage(lang);
  }

  const collapseCurrency = () => {
    setCurrencyOpen(!currencyOpen);
  };

  const handleCurrencyChange = (currency: any, isHamburger = false) => {
    const { name, id } = currency;
    setSelectedCurrency(name);
    if (!props.user) {
      localStorage.setItem('currency', name);
    } else {
      props.changeCurrency({
        userId: props.user?.id,
        currencyId: id,
      });
    }
    sendGAEvent('Header', `Currency changed to ${name}`);
    sendFBCustomEvent(`Header currency changed to ${name}`);
    props.initCurrencyChange(name);
    if (isHamburger) {
      toggleHamburger();
      resetHamburger();
    }
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  useEffect(() => {
    setHamburgerOpen(false);
    if (!props.isUserLogged) {
      setItemsCount(0);
    }
  }, [props.isUserLogged]);

  const fetchCurrencies = () => {
    if (props.currencies.length === 0) {
      props.getCurrencies();
    }
  };

  useEffect(() => {
    fetchCurrencies();
    const searchText = new URLSearchParams(window.location.search).get('k');
    if (searchText?.length) {
      setSearchText(searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.isUserLogged && props.getUser();
    if (!props.isUserLogged) {
      const cartItems = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
      let count = 0;
      cartItems.forEach((item: any) => {
        count += item.quantity
      });
      props.updateShoppingCartItemsCount(count);

      let currency = localStorage.getItem('currency') || env.defaultCurrency;
      setSelectedCurrency(currency);
      props.initCurrencyChange(currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItemsCount(props.cartItemsCount);
  }, [props.cartItemsCount]);

  const { user, currencies, canLanguageBeChanged } = props;
  useEffect(() => {
    if (props.user?.currency && props.currencies?.length) {
      const { currency } = props.user;
      const selectedCurrency = props.currencies.find((item: any) => item.id === currency.id);
      setSelectedCurrency(selectedCurrency?.name || env.defaultCurrency);
      props.initCurrencyChange(selectedCurrency?.name || env.defaultCurrency);
    }
    if (props.user?.shoppingCart) {
      const { shoppingCart } = props.user;
      let count = 0;
      shoppingCart.forEach((item: any) => {
        count += item.quantity
      });
      setItemsCount(count);
      props.updateShoppingCartItemsCount(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currencies]);

  useEffect(() => {
    setSearchOptions(offersSearchResult.data || []);
  }, [offersSearchResult]);

  const togglePopper = useCallback((value: string) => {

    // set value whether the last request was to poen or to close popper based on the value
    if (value) {
      setCanBePopperVisible(true);
    } else {
      setCanBePopperVisible(false);
    }

    const popper = document.getElementsByClassName('searchBar-popper')[0];
    const popperOverlay = document.getElementById('popper-overlay');

    if (value === '' || searchOptions.length === 0) {
      if (popper) {
        popper.classList.toggle('d-none', true);
        popper.classList.remove('drawerActive');
      }
      if (popperOverlay) {
        popperOverlay.classList.toggle('d-none', true);
      }
    } else {
      if (popper) {
        popper.classList.toggle('d-none', false);
        if (searchBarOpen) {
          popper.classList.add('drawerActive');
        } else {
          popper.classList.remove('drawerActive');
        }
      }
      if (popperOverlay) {
        document.documentElement.style.setProperty('--popperOverlay', `${document.documentElement.scrollHeight}px`);
        popperOverlay.classList.toggle('d-none', false);
      } else {
        const overlay = document.createElement("div");
        overlay.id = "popper-overlay";
        overlay.className = "popper-overlay";
        document.documentElement.style.setProperty('--popperOverlay', `${document.documentElement.scrollHeight}px`);
        document.getElementById('body')?.appendChild(overlay);
      }
    }
  }, [searchBarOpen, searchOptions.length]);

  useEffect(() => {
    if (width >= 500){
      handleSearchBarClose();
    }
  }, [handleSearchBarClose, width]);

  const setCategoryToSearch = (categoryValue?: string) => {
    if (categoryValue && props.categories.length > 0) {
      const category = props.categories.find((category: any) => category.value === categoryValue);
      setCategoryToSearchId(category?.id);
    } else {
      setCategoryToSearchId(undefined);
    }
  }

  const getCategoryValue = (categoryId: string | undefined): string => {
    if (props.categories?.length > 0 && categoryId) {
      const category = props.categories.find((category: any) => category.id === categoryId)
      return category ? category.value : '';
    }

    return ''
  }

  const categorySearchRoute = (categoryValue: string) => {
    if (categoryValue === env.offer.mainCategory.automoto) {
      return t('Routes.ResultsAutomoto');
    } else if (categoryValue === env.offer.mainCategory.spareParts) {
      return t('Routes.ResultsSpareParts');
    } else if (categoryValue === env.offer.mainCategory.marketplace) {
      return t('Routes.ResultsMarketplace');
    }

    return t('Routes.SearchResults');
  };

  const getSearchRedirectRoute = (categoryId: string | undefined) => {
    return categorySearchRoute(getCategoryValue(categoryId))
  };

  const createUrlSearchParamsString = (object: {}) => {
    return qs.stringify(object, {
      encode: false,
      addQueryPrefix: true,
    });
  }

  const handleSearchButtonClick = () => {
    if (searchText !== null && searchText.length >= 1) {

      const categoryId = categoryToSearchId;

      setCategoryToSearchId(undefined);
      if (isSearchBarVisible) {
        handleSearchBarClose();
      }
      localStorage.removeItem('automotoFilter');
      localStorage.removeItem('commonFilter');

      history.push({
        pathname: getSearchRedirectRoute(categoryId),
        search: createUrlSearchParamsString({
          k: searchText ? searchText : '',
          limit: 20
        }),
      });

    } else if (searchText === null || searchText.length === 0) {
      setCategoryToSearchId(undefined);

      history.push({
        pathname: t('Routes.LandingPage'),
      })

      if (isSearchBarVisible) {
        handleSearchBarClose();
      }
    }
  }

  const handleEnter = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSearchButtonClick();
      headerSearchBarInputRef?.current?.blur();
    }
  };

  const handleSearch = useCallback((value: string) => {
    const language = i18n.language;

    setSearchText(value);
    togglePopper(value);
    localStorage.removeItem('automotoFilter');
    localStorage.removeItem('commonFilter');
    sendFBStandardEvent('Search');

    if (value && value.length >= 1) {
      props.searchForOffersSearchBar({
        search: value,
        limit: width < 500 ? 15 : 9,
        recommendation: true,
        language,
        categories: categoryToSearchId ? [categoryToSearchId] : [],
        currency: selectedCurrency
      });
    }
  }, [categoryToSearchId, props, selectedCurrency, togglePopper, width]);

  const handleBlur = useCallback(() => {
    togglePopper('');
  }, [togglePopper]);

  const handleAutocompleteFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    handleSearch(event.target.value);
  }, [handleSearch]);

  const renderOption = (option: any) => {
    const lang = i18n.language;

    const indexOfOption = searchOptions.indexOf(option);

    return (
      <p className="search-filter__options" onClick={() => handleClickOnSearch(
        option.mainCategory,
        option.category,
        option.name,
        indexOfOption < 3 && indexOfOption >= 0
      )}>
        {` `}
        <span className={'name'}>
          {option.nameHighlighted && option.nameHighlighted !== '' ? parse(option.nameHighlighted) : option.name}
        </span>
        { indexOfOption < 3 && indexOfOption >= 0 && (
          <span className={'category'}>
            {` ${t('Header.InCategory')}`}
            <span className={'category__main'}>
              {` ${option.mainCategory.nameLocalised[lang === 'cs' ? 'cz' : lang]} >`}
            </span>
            <span className={'category__sub'}>
              {` ${option.category.nameLocalised[lang === 'cs' ? 'cz' : lang]}`}
            </span>
          </span>
        )}
      </p>
    )
  }

  const handleClickOnSearch = (mainCategory: any, category: any, offerName: string, useCategory: boolean = false) => {
    const mainCategoryValue = useCategory ? getCategoryValue(mainCategory.id) : undefined;

    let pathname = '';
    switch (mainCategoryValue) {
    case env.offer.mainCategory.marketplace: {
      pathname = 'Routes.ResultsMarketplace'
      break;
    }
    case env.offer.mainCategory.automoto: {
      pathname = 'Routes.ResultsAutomoto'
      break;
    }
    case env.offer.mainCategory.spareParts: {
      pathname = 'Routes.ResultsSpareParts'
      break;
    }
    default: {
      pathname = 'Routes.SearchResults'
    }
    }

    if (isSearchBarVisible) {
      handleSearchBarClose();
    }

    setSearchText(offerName);
    localStorage.removeItem('automotoFilter');
    localStorage.removeItem('commonFilter');

    history.push({
      pathname: t(pathname),
      search: '?k=' +
        offerName +
        `${useCategory && category?.id && category.parentId ? "&category=" + category.id + '&parentId=' + category.parentId : ''}`
    });

  };

  const fetchCategories = () => {
    if (props.categories.length === 0) {
      props.getCategories();
    }
  }

  const fetchMyOffersCount = () => {
    if (props.isUserLogged) {
      props.categories.forEach((category: any) => {
        props.getMyOffersCountsByCategory({
          category: category.id
        })
      })
    }
  }

  const getCategoryCount = (categoryValue: string) => { //as defined in env
    const category = props.categories.find((category: any) => category.value === categoryValue)
    const value = props.myOffersCount[category?.id]
    return value ? value : 0
  }

  const handleShowMyOffersCategory = (value?: string) => {
    const userActivated = !!(props.settings && props.settings.adminVerificationStatus === 1 && props.settings.isVerifiedByStripe && !props.settings.stripeDeclinedDate);
    const category = props.categories.find((category: CategoryModel) => category.value === value);

    const categorySlug = category?.nameLocalised[i18n.language === 'cs' ? 'czSlug' : i18n.language + 'Slug']

    history.push({
      pathname: t('Routes.AccountMyOffers'),
      search: qs.stringify({
        type: !userActivated || categorySlug === 'auto-moto' ? 'free-offer' : 'buy-now',
        category: categorySlug ? categorySlug : undefined,
        limit: 20
      }, {
        encode: false
      }),
    })

    headerDropdownRef?.current?.handleLeave();
  }

  const handleOffersCategory = (value: string) => {
    let category = null;

    if (value === t('Routes.ResultsAutomoto')) {
      localStorage.removeItem('automotoFilter');
      category = props.categories.find((category: CategoryModel) => category.value === env.offer.mainCategory.automoto);
    } else if (value === t('Routes.ResultsMarketplace')) {
      localStorage.removeItem('commonFilter');
      category = props.categories.find((category: CategoryModel) => category.value === env.offer.mainCategory.marketplace);
    } else {
      localStorage.removeItem('commonFilter');
      category = props.categories.find((category: CategoryModel) => category.value === env.offer.mainCategory.spareParts);
    }

    history.push({
      pathname: value,
      search: category ? `?category=${category.id}` : "",
    });
  }

  const getTotalMyOffersCount = () => {
    let totalCount = 0;
    for (const property in props.myOffersCount) {
      totalCount += props.myOffersCount[property];
    }
    return totalCount
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    document.documentElement.style.setProperty('--popperOverlay', `${document.documentElement.scrollHeight}px`);
  }

  const getCategoryName = (categoryId: string): string => {
    const category = props.categories.find((category: any) => category.id === categoryId)

    if(category) {
      return category.nameLocalised[i18n.language === 'cs' ? 'cz' : i18n.language]
    } else {
      return ''
    }
  }

  const getCategoryCssPropName = (categoryId: string): string => {
    const category = props.categories.find((category: any) => category.id === categoryId)

    if (category){
      if (category.value === env.offer.mainCategory.automoto) {
        return 'motor-vehicles';
      } else if (category.value === env.offer.mainCategory.spareParts) {
        return 'spare-parts';
      } else if (category.value === env.offer.mainCategory.marketplace) {
        return 'marketplace';
      } else if (category.value === env.offer.mainCategory.properties) {
        return 'properties';
      } else {
        return '';
      }
    }
    return ''
  }

  const handlePageChange = () => {
    setCategoryToSearchId(undefined);
  }

  const handleQueryParameterChange = () => {
    const queryParams = qs.parse(location.search.slice(1))
    if (!queryParams.k) {
      setSearchText('');
    }
  }

  const navigateToLogin = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    event.preventDefault();

    history.push({
      ...location,
      pathname: t('Routes.Login'),
      search: undefined,
    });

  }

  const fetchWatchLists = () => {
    if (props.isUserLogged) {
      if (props.watchListDetail && props.watchListDetail.offers?.total > -1) {
        setWatchListId(props.watchListDetail.watchListId);
      } else {
        props.getWatchLists();
      }
    } else {
      const offerIds = getWatchListFromStorage();
      props.setWatchedOffersIds(offerIds);
    }
  }

  const handleWatchListsChange = () => {
    const currentWatchListId = watchListId;

    if (props.watchLists && props.watchLists.length) {

      const defaultWatchList = props.watchLists.find((watchList: WatchList) => watchList.isDefault)

      const watchListId = defaultWatchList ? defaultWatchList.id : props.watchLists[0].id;

      setWatchListId(watchListId);

      if (currentWatchListId !== watchListId) {

        props.getWatchListItems({
          watchListId,
          params: {
            '$with': [
              'offer'
            ],
          }
        });

      }
    }
  }

  const handleLanguageChange = () => {
    if (props.user && props.user.language && props.user.language !== i18n.language) {
      i18n.changeLanguage(props.user.language);
    }
  }

  const showSettingsPopUp = () => {
    showEditPopUp(
      <CookieSettingsPopUp />,
      {
        showCloseButton: true,
        customClass: "cookies-bar__pop-up"
      }
    );
  };

  const sendAnalyticsEvents = () => {
    sendGAEvent('Header', "New redirect to register page");
    sendFBCustomEvent(`Header new redirect to register page`);
  }

  const handleMobileHeaderRegister = () => {
    toggleHamburger();
  }

  useEffect(fetchCategories, []);
  useEffect(fetchWatchLists, []);
  useEffect(fetchMyOffersCount, [props.categories, props.isUserLogged]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(handlePageChange, [location.pathname]);
  useEffect(handleQueryParameterChange, [location.search]);
  useEffect(() => {
    const body = document.getElementById('root');

    if (body && body.style.position !== 'fixed' && (searchBarOpen || canBePopperVisible) && width < 500) {
      const currentScrollPosition = window.scrollY;
      body.style.top = `-${currentScrollPosition}px`;
      body.style.position = 'fixed';
      body.style.maxWidth = '100vw';
      setScrollPosition(currentScrollPosition);
    } else if (body && body.style.position === 'fixed' && !searchBarOpen && !canBePopperVisible){
      body.style.top = '';
      body.style.position = '';
      window.scrollTo(0, scrollPosition);
    }
  }, [searchBarOpen, width, scrollPosition, canBePopperVisible]);
  useEffect(handleWatchListsChange, [props.watchLists]);
  useEffect(fetchWatchLists, [props.isUserLogged]);

  useEffect(() => {
    if (canBePopperVisible) {
      togglePopper(searchText);
    }
  }, [searchText, togglePopper, canBePopperVisible, searchOptions]);

  useEffect(handleLanguageChange, [props.user]);

  const toLangListItem = (option: LangButton) => (
    <ListItem button onClick={() => changeLanguage(option.lang)}>
      <ListItemIcon>
        <img src={option.imgSrc} alt={option.imgAlt} />
      </ListItemIcon>
      <ListItemText primary={t(option.key)} />
    </ListItem>
  );

  const toHamburgerItem = (option: LangButton) => (
    <ListItem button onClick={() => handleLangChange(option.lang)} disabled={!canLanguageBeChanged}>
      <img src={option.imgSrc} alt={option.imgAlt} width="21px" className={'hamburger-menu__menu-icon'} />
      <ListItemText primary={t(option.key)} />
    </ListItem>
  );

  return (
    // <div className={`header ${isSearchBarVisible ? 'header__sticky header_content_overlay' : ''}`}>
    <div className={'header'}>
      <Container>
        <Grid container spacing={0} direction="column" alignItems="center">
          <Grid container item xs={12} spacing={3} direction="row-reverse" className={'m-xs-0'}>
            <Grid container item xs={12} md={3} justifyContent="flex-end" className={'pr-0 d-xs-none'}>
              <Dropdown
                disabled={!canLanguageBeChanged}
                button={
                  <Button id={'1'} className={'header-button'}
                    startIcon={<img src={langs[0].imgSrc} alt={langs[0].imgAlt} />}
                    endIcon={<KeyboardArrowDownIcon fontSize="small" />}
                    onClick={() => changeLanguage(langs[0].lang)}
                    disabled={!canLanguageBeChanged}
                  >
                    <Typography variant="body2" component="span">
                      {t(langs[0].key)}
                    </Typography>
                  </Button>
                }
                contentClassName="dropdown-list dropdown-list--lang"
                content={
                  <List component="nav" aria-label="main mailbox folders">
                    {langs.slice(1,4).map(toLangListItem)}
                  </List>
                } />
              <Dropdown
                button={
                  <Button id={'2'} className={'header-button'}
                    endIcon={<KeyboardArrowDownIcon fontSize="small" />}
                    onClick={() => changeLanguage(langs[0].lang)}
                  >
                    <Typography variant="body2" component="span">
                      {selectedCurrency}
                    </Typography>
                  </Button>
                }
                contentClassName="dropdown-list dropdown-list--currency"
                content={
                  <List component="nav" aria-label="main mailbox folders">
                    {props.currencies && props.currencies.map((currency: any) =>
                      <ListItem key={currency.id} button onClick={() => handleCurrencyChange(currency)}>
                        <ListItemText primary={currency.name + ', ' + currency.symbol} />
                      </ListItem>
                    )}
                  </List>
                } />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justifyContent="space-between" className={'m-xs-0'}>
            <Grid item className={'pr-0 d-md-none'}>
              <Link
                to={location => ({
                  ...location,
                  search: undefined,
                  pathname: t('Routes.LandingPage')
                })
                }
                className={'MuiLink-root headerLink'}>
                <img src={logo} alt="Company logo" className={'company-logo'} />
              </Link>
            </Grid>
            <Grid container item alignItems="center" justifyContent="space-between" className={'logo-container'}>
              <Grid item className={'logo-container__logo-item'}>
                <Link
                  to={location => ({
                    ...location,
                    search: undefined,
                    pathname: t('Routes.LandingPage')
                  })
                  }
                  className={'logo-container__link'}>
                  <img src={logo} alt="Company logo" className={'company-logo'} />
                </Link>
              </Grid>
              <Grid container item direction="row" xs={7} sm={8} spacing={1} alignItems="center" justifyContent="flex-end">
                <Grid item className={'d-xs-none'}>
                  <Dropdown
                    button={
                      <Link
                        to={location => ({
                          ...location,
                          search: undefined,
                          pathname: props.isUserLogged ? t('Routes.AccountMyOffers') : t('Routes.Login')
                        })
                        }
                        className={'MuiLink-root headerLink'}>
                        <Button id="3" className={'account-button header-button'}
                          endIcon={<KeyboardArrowDownIcon fontSize="small" className={'d-sm-none'} />}>
                          <div className={'account-container d-sm-none'}>
                            <span
                              className={'account-container__login'}>{props.isUserLogged && props.user ? t('Header.Account.Hello') + (props.user.name ? props.user.name?.split(' ')[0] : '') : t('Header.Account.HelloLogin')}</span>
                            <Badge badgeContent={4} color="secondary" className={'account-container__account'}>
                              {t('Header.Account.TitleWithOrders')}
                            </Badge>
                          </div>
                          <img src={userFilled} alt="User icon" height="27" className={'d-sm-block'} />
                        </Button>
                      </Link>
                    }
                    contentClassName="account-dropdown"
                    content={
                      props.isUserLogged ?
                        <List component="nav" aria-label="main mailbox folders">
                          <Link
                            to={location => ({
                              ...location,
                              search: undefined,
                              pathname: t('Routes.AccountInfoAdresses')
                            })
                            }
                            className={'MuiLink-root headerLink'}>
                            <ListItem button>
                              <ListItemIcon>
                                <UserIcon />
                              </ListItemIcon>
                              <ListItemText primary={t('Header.Account.Profile')} />
                            </ListItem>
                          </Link>
                          <Link
                            to={location => ({
                              ...location,
                              search: undefined,
                              pathname: t('Routes.AccountMyOrders')
                            })
                            }
                            className={'MuiLink-root headerLink'}>
                            <ListItem button>
                              <ListItemIcon>
                                <OrderIcon />
                              </ListItemIcon>
                              <ListItemText primary={t('Header.Account.Orders')} />
                            </ListItem>
                          </Link>
                          <Divider />
                          <Link
                            to={location => ({
                              ...location,
                              search: undefined,
                              pathname: t('Routes.AccountMyOffers')
                            })
                            }
                            className={'MuiLink-root headerLink'}>
                            <ListItem button className={'account-dropdown__my-listings listings-container'}>
                              <div className={'listings-container__label'}>
                                <ListIcon />
                                <span>{t('Header.Account.Offers')}</span>
                                <Badge badgeContent={getTotalMyOffersCount()} color="secondary" className={'listings-container__badge'} />
                              </div>
                              <List component="nav" aria-label="main mailbox folders" className={'account-dropdown__options'}>
                                <ListItem button onClick={(e) => { e.preventDefault(); handleShowMyOffersCategory(env.offer.mainCategory.automoto)}}>
                                  <Box className={'motor-vehicles'} m={0}><span>{t('Header.Account.Automoto')}<b>{' '}({
                                    getCategoryCount(env.offer.mainCategory.automoto)
                                  })</b></span></Box>
                                </ListItem>
                                <ListItem button onClick={(e) => { e.preventDefault(); handleShowMyOffersCategory(env.offer.mainCategory.spareParts)}}>
                                  <Box className={'spare-parts'} m={0}><span>{t('Header.Account.SpareParts')}<b>{' '}({
                                    getCategoryCount(env.offer.mainCategory.spareParts)
                                  })</b></span></Box>
                                </ListItem>
                                <ListItem button onClick={(e) => { e.preventDefault(); handleShowMyOffersCategory(env.offer.mainCategory.marketplace)}}>
                                  <Box className={'marketplace'} m={0}><span>{t('Header.Account.Market')}<b>{' '}({
                                    getCategoryCount(env.offer.mainCategory.marketplace)
                                  })</b></span></Box>
                                </ListItem>
                                {/* <ListItem button onClick={(e) => { e.preventDefault(); handleShowMyOffersCategory(env.offer.mainCategory.properties)}}>
                                  <Box className={'properties'} m={0}><span>{t('Header.Account.RealEstate')}<b>{' '}({
                                    getCategoryCount(env.offer.mainCategory.properties)
                                  })</b></span></Box>
                                </ListItem> */}
                              </List>
                            </ListItem>
                          </Link>
                          <Divider />
                          <ListItem button onClick={handleLogout}>
                            <ListItemIcon>
                              <img src={logout} alt="Logout logo" />
                            </ListItemIcon>
                            <ListItemText primary={t('Header.Account.Logout')} />
                          </ListItem>
                        </List>
                        :
                        <List component="nav" aria-label="main mailbox folders" className={'NoLoginDropdown'}>
                          <Grid container direction={"column"} alignItems="center" justifyContent={"center"}>
                            <h6>{t('Header.NoLoginDropdown.Text')}</h6>
                            <Button
                              variant="contained"
                              className="buttonLogin"
                              href={t('Routes.Login').toString()}
                              onClick={navigateToLogin}
                            >
                              <span className={'MuiLink-root loginLink'}>
                                {t('Header.NoLoginDropdown.Button')}
                              </span>
                            </Button>
                            <Grid container item xs={12} spacing={2} alignItems="center" className={'lineHolder'}>
                              <Grid item xs className={"border"}>
                              </Grid>
                              <Grid item xs={"auto"}>
                                <h6 className='text-center'>{t('Header.NoLoginDropdown.NoAccount')}</h6>
                              </Grid>
                              <Grid item xs className={"border"}>
                              </Grid>
                            </Grid>
                            <Link
                              to={location => ({
                                ...location,
                                search: undefined,
                                pathname: t('Routes.Register')
                              })
                              }
                              onClick={sendAnalyticsEvents}
                              className={'MuiLink-root customLink'}>{t('Header.NoLoginDropdown.Register')}</Link>
                            <OauthButtons />
                          </Grid>
                        </List>
                    } />
                </Grid>
                <Grid item className={'d-xs-none'}>
                  <Link to={t('Routes.WatchList').toString()}>
                    <Button id="4" className={'listings header-button'}>
                      <img src={star} alt="Star icon" className={'listings__icon'} />
                      <div className={'listings__labels d-sm-none'}>
                        <span className={'listings__spotted'}>{t('Header.Account.Subscribed')}</span>
                        <span className={'listings__ads'}>
                          {t('Header.Account.SubscribedOffers')}
                          <span className={'listings__count'}>({props.watchedOffersIds.length})</span>
                        </span>
                      </div>
                    </Button>
                  </Link>
                </Grid>
                {!props.isUserLogged && (
                  <Grid item className={'d-xs-block'}>
                    <Link
                      to={location => ({
                        ...location,
                        search: undefined,
                        pathname: t('Routes.Login')
                      })
                      }
                      className={'MuiLink-root headerLink'}>
                      <Button className={'login-button'}>
                        {t('Header.Account.HelloLogin')}
                      </Button>
                    </Link>
                  </Grid>
                )}
                <Grid item className={'d-xs-none'}>
                  <Link to={t('Routes.Cart').toString()}>
                    <Button className={'cart header-button'}>
                      <span className={`cart__items-count ${itemsCount > 9 && 'two-digits'} ${itemsCount > 99 && 'three-digits'}`}>{parseInt(itemsCount) > 99 ? '99+' : itemsCount}</span>
                      <img src={cart} alt="Cart icon" className={'cart__icon'} />
                      <span className={'d-sm-none'}>
                        {t('Header.Account.Cart')}
                      </span>
                    </Button>
                  </Link>
                </Grid>
                <Grid item className={'d-xs-block'}>
                  <Button className={'hamburger-button'} onClick={toggleHamburger}>
                    <img src={hamburger} alt="Hamburger menu icon" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={'search-container'}>
              <Grid container spacing={0} direction="row" alignItems="center" className={"search-filter"}>
                <Dropdown
                  button={
                    <Button
                      size="large"
                      className={`search-filter__all-categories d-xs-none ${categoryToSearchId ? getCategoryCssPropName(categoryToSearchId) : 'noCategory'}`}
                      onClick={(_e) => setCategoryToSearch()}
                      endIcon={<KeyboardArrowDownIcon fontSize="small" />}
                    >
                      { (categoryToSearchId && props.categories)
                        ? getCategoryName(categoryToSearchId)
                        : t('Header.Account.AllCategories')
                      }
                    </Button>
                  }
                  content={
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.automoto)}>
                        <Box className={'motor-vehicles'} m={1}>{t('Header.Account.Automoto')}</Box>
                      </ListItem>
                      <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.spareParts)}>
                        <Box className={'spare-parts'} m={1}>{t('Header.Account.SpareParts')}</Box>
                      </ListItem>
                      <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.marketplace)}>
                        <Box className={'marketplace'} m={1}>{t('Header.Account.Market')}</Box>
                      </ListItem>
                      {/* <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.properties)}>
                        <Box className={'properties'} m={1}>{t('Header.Account.RealEstate')}</Box>
                      </ListItem> */}
                    </List>
                  }
                  contentClassName={'search-filter__categories'} />
                <div className={`search-filter__container ${!searchOptions.length && 'borderUnset'}`}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    blurOnSelect
                    disableClearable
                    options={searchText && searchText.length ? searchOptions : []}
                    getOptionLabel={() => searchText}
                    renderOption={renderOption}
                    inputValue={searchText}
                    PopperComponent={(popperParams) =>
                      <Popper {...popperParams} className={'searchBar-popper'} />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={'search-filter__input'}
                        placeholder={t('Header.Account.SearchPlaceholder')}
                        margin="none"
                        value={searchText}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search'
                        }}
                        onKeyDown={handleEnter}
                        onChange={(event) => handleSearch(event.target.value)}
                        onFocus={handleAutocompleteFocus}
                        onBlur={handleBlur}
                        inputRef={headerSearchBarInputRef}
                      />
                    )}
                  />
                  <Button onClick={handleSearchButtonClick} variant={"contained"} size="large" className={'search-filter__button'}>
                    <img src={zoom} alt="Magnifier" width="19" height="19" />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container item spacing={0} direction="row" className={'px-0 d-md-none account-buttons'}>
              <Dropdown
                button={
                  <Link
                    to={location => ({
                      ...location,
                      search: undefined,
                      pathname: props.isUserLogged ? t('Routes.AccountMyOffers') : t('Routes.Login')
                    })
                    }
                    className={'MuiLink-root headerLink'}>
                    <Button id="3" className={'account-button header-button'}
                      endIcon={<KeyboardArrowDownIcon fontSize="small" />}>
                      <div className={'account-container'}>
                        <span
                          className={'account-container__login'}>{props.isUserLogged && props.user ? t('Header.Account.Hello') + (props.user.name ? props.user.name?.split(' ')[0] : '') : t('Header.Account.HelloLogin')}</span>
                        <Badge color="secondary" className={'account-container__account'}>
                          {t('Header.Account.TitleWithOrders')}
                        </Badge>
                      </div>
                    </Button>
                  </Link>
                }
                contentClassName="account-dropdown"
                content={
                  props.isUserLogged ?
                    <List component="nav" aria-label="main mailbox folders">
                      <Link
                        to={location => ({
                          ...location,
                          search: undefined,
                          pathname: t('Routes.AccountInfoAdresses')
                        })
                        }
                        className={'MuiLink-root headerLink'}>
                        <ListItem button>
                          <ListItemIcon>
                            <UserIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('Header.Account.Profile')} />
                        </ListItem>
                      </Link>
                      <Link
                        to={location => ({
                          ...location,
                          search: undefined,
                          pathname: t('Routes.AccountMyOrders')
                        })
                        }
                        className={'MuiLink-root headerLink'}>
                        <ListItem button>
                          <ListItemIcon>
                            <OrderIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('Header.Account.Orders')} />
                        </ListItem>
                      </Link>
                      <Divider />
                      <ListItem
                        button
                        className={'account-dropdown__my-listings listings-container'}
                        onClick={() => handleShowMyOffersCategory()}
                      >
                        <div className={'listings-container__label'}>
                          <ListIcon />
                          <span>{t('Header.Account.Offers')}</span>
                          <Badge badgeContent={getTotalMyOffersCount()} color="secondary" className={'listings-container__badge'} />
                        </div>
                        <List component="nav" aria-label="main mailbox folders" className={'account-dropdown__options'}>
                          <ListItem button onClick={(e) => { e.stopPropagation(); handleShowMyOffersCategory(env.offer.mainCategory.automoto)}}>
                            <Box className={'motor-vehicles'} m={0}><span>{t('Header.Account.Automoto')}<b>{' '}({
                              getCategoryCount(env.offer.mainCategory.automoto)
                            })</b></span></Box>
                          </ListItem>
                          <ListItem button onClick={(e) => { e.stopPropagation(); handleShowMyOffersCategory(env.offer.mainCategory.spareParts)}}>
                            <Box className={'spare-parts'} m={0}><span>{t('Header.Account.SpareParts')}<b>{' '}({
                              getCategoryCount(env.offer.mainCategory.spareParts)
                            })</b></span></Box>
                          </ListItem>
                          <ListItem button onClick={(e) => { e.stopPropagation(); handleShowMyOffersCategory(env.offer.mainCategory.marketplace)}}>
                            <Box className={'marketplace'} m={0}><span>{t('Header.Account.Market')}<b>{' '}({
                              getCategoryCount(env.offer.mainCategory.marketplace)
                            })</b></span></Box>
                          </ListItem>
                          {/* <ListItem button onClick={(e) => { e.stopPropagation(); handleShowMyOffersCategory(env.offer.mainCategory.properties)}}>
                            <Box className={'properties'} m={0}><span>{t('Header.Account.RealEstate')}<b>{' '}({
                              getCategoryCount(env.offer.mainCategory.properties)
                            })</b></span></Box>
                          </ListItem> */}
                        </List>
                      </ListItem>
                      <Divider />
                      <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                          <img src={logout} alt="Logout logo" />
                        </ListItemIcon>
                        <ListItemText primary={t('Header.Account.Logout')} />
                      </ListItem>
                    </List>
                    :
                    <List component="nav" aria-label="main mailbox folders" className={'NoLoginDropdown'}>
                      <Grid container direction={"column"} alignItems="center" justifyContent={"center"}>
                        <h6>{t('Header.NoLoginDropdown.Text')}</h6>
                        <Button
                          variant="contained"
                          className="buttonLogin"
                          href={t('Routes.Login').toString()}
                          onClick={navigateToLogin}
                        >
                          <span className={'MuiLink-root loginLink'}>
                            {t('Header.NoLoginDropdown.Button')}
                          </span>
                        </Button>
                        <Grid container item xs={12} spacing={2} alignItems="center" className={'lineHolder'}>
                          <Grid item xs className={"border"}>
                          </Grid>
                          <Grid item xs={"auto"}>
                            <h6 className='text-center'>{t('Header.NoLoginDropdown.NoAccount')}</h6>
                          </Grid>
                          <Grid item xs className={"border"}>
                          </Grid>
                        </Grid>
                        <Link
                          to={location => ({
                            ...location,
                            search: undefined,
                            pathname: t('Routes.Register')
                          })}
                          onClick={sendAnalyticsEvents}
                          className={'MuiLink-root customLink'}>{t('Header.NoLoginDropdown.Register')}</Link>
                        <OauthButtons />
                      </Grid>
                    </List>
                }
                ref={headerDropdownRef}
              />
              <Link to={t('Routes.WatchList').toString()}>
                <Button id="4" className={'listings header-button'}>
                  <img src={star} alt="Star icon" />
                  <div className={'listings__labels'}>
                    <span className={'listings__spotted'}>{t('Header.Account.Subscribed')}</span>
                    <span className={'listings__ads'}>
                      {t('Header.Account.SubscribedOffers')}
                      <span className={'listings__count'}>({props.watchedOffersIds.length})</span>
                    </span>
                  </div>
                </Button>
              </Link>
              <Link to={t('Routes.Cart').toString()}>
                <Button className={'cart header-button'}>
                  <span
                    className={`cart__items-count ${itemsCount > 9 && 'two-digits'} ${itemsCount > 99 && 'three-digits'}`}>{itemsCount > 99 ? '99+' : itemsCount}</span>
                  <img src={cart} alt="Cart icon" className={'cart__icon'} />
                  {t('Header.Account.Cart')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} className={'search-area-container'}>
            {/* <Grid item className={'search-area'}>
              <Dropdown
                button={
                  <Button id={'5'} className={'county header-button'}>
                    <img src={pin} alt="Company logo" />
                    <div className={'county__container'}>
                      <span className={'county__searcharea'}>{t('Header.Account.SearchInArea')}</span>
                      <span className={'county__county-name'}>Prešovský kraj <KeyboardArrowDownIcon fontSize="small" /></span>
                    </div>
                  </Button>
                }
                contentClassName="dropdown-list"
                content={
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                      <ListItemText primary="Bratislavský kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Trnavský kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Trenčiansky kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Nitriansky kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Žilinský kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Banskobystrický kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Prešovský kraj" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Košický kraj" />
                    </ListItem>
                  </List>
                } />
            </Grid> */}
            {/* uncomment to use categories along with search-area */}
            {/* <Grid container item xs={12} sm={12} md={7} alignItems="center" className="categories"> */}
            <Grid container item xs={12} sm={12} md={12} alignItems="center" className="categories">
              <Box className={'motor-vehicles'} m={2} onClick={() => handleOffersCategory(t('Routes.ResultsAutomoto'))}>{t('Header.Account.Automoto')}</Box>
              <Box className={'marketplace'} m={2} onClick={() => handleOffersCategory(t('Routes.ResultsMarketplace'))}>{t('Header.Account.Market')}</Box>
              <Box className={'spare-parts'} m={2} onClick={() => handleOffersCategory(t('Routes.ResultsSpareParts'))}>{t('Header.Account.SpareParts')}</Box>
              { !props.isUserLogged &&
                <Link
                  to={location => ({
                    ...location,
                    pathname: t('Routes.Register'),
                    search: ""
                  })}
                  onClick={sendAnalyticsEvents}
                >
                  <Box className={'otherOptions'} m={2}>{t('Header.Account.Register')}</Box>
                </Link>
              }
              <a
                className="categories_link"
                href={env.landingPageHowItWorksUrl}
                target='_new'
              >
                <Box className={'otherOptions'} m={2}>{t('Header.Account.HowItWorks')}</Box>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Drawer className={'searchBarDrawer'} anchor={'top'} open={searchBarOpen} onClose={handleSearchBarClose}>
        <Grid container spacing={0} direction="column" alignItems="center">
          <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justifyContent="space-between" className={'m-xs-0'}>
            <Grid container item alignItems="center" justifyContent="space-between" className={'logo-container'}>
              <Grid item className={'logo-container__logo-item'}>
                <Link
                  to={location => ({
                    ...location,
                    search: undefined,
                    pathname: t('Routes.LandingPage')
                  })
                  }
                  className={'logo-container__link'}>
                  <img src={logo} alt="Company logo" className={'company-logo'} />
                </Link>
              </Grid>
              <Grid container item direction="row" xs={7} sm={8} spacing={1} alignItems="center" justifyContent="flex-end">
                <Grid item className={'d-xs-block'}>
                  <Button className={'hamburger-button'} onClick={toggleHamburger}>
                    <img src={hamburger} alt="Hamburger menu icon" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={'search-container'}>
              <Grid container spacing={0} direction="row" alignItems="center" className={"search-filter"}>
                <Dropdown
                  button={
                    <Button
                      size="large"
                      className={`search-filter__all-categories d-xs-none ${categoryToSearchId ? getCategoryCssPropName(categoryToSearchId) : 'noCategory'}`}
                      onClick={(_e) => setCategoryToSearch()}
                      endIcon={<KeyboardArrowDownIcon fontSize="small" />}
                    >
                      { (categoryToSearchId && props.categories)
                        ? getCategoryName(categoryToSearchId)
                        : t('Header.Account.AllCategories')
                      }
                    </Button>
                  }
                  content={
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.automoto)}>
                        <Box className={'motor-vehicles'} m={1}>{t('Header.Account.Automoto')}</Box>
                      </ListItem>
                      <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.spareParts)}>
                        <Box className={'spare-parts'} m={1}>{t('Header.Account.SpareParts')}</Box>
                      </ListItem>
                      <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.marketplace)}>
                        <Box className={'marketplace'} m={1}>{t('Header.Account.Market')}</Box>
                      </ListItem>
                      {/* <ListItem button onClick={(_e) => setCategoryToSearch(env.offer.mainCategory.properties)}>
                          <Box className={'properties'} m={1}>{t('Header.Account.RealEstate')}</Box>
                      </ListItem> */}
                    </List>
                  }
                  contentClassName={'search-filter__categories'} />
                <div className={`search-filter__container ${!searchOptions.length && 'borderUnset'}`}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    blurOnSelect
                    disableClearable
                    options={searchText && searchText.length ? searchOptions : []}
                    getOptionLabel={() => searchText}
                    renderOption={renderOption}
                    inputValue={searchText}
                    PopperComponent={(popperParams) =>
                      <Popper {...popperParams} className={'searchBar-popper'} />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={'search-filter__input'}
                        placeholder={t('Header.Account.SearchPlaceholder')}
                        margin="none"
                        value={searchText}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          autoFocus: true
                        }}
                        onKeyDown={handleEnter}
                        onChange={(event) => handleSearch(event.target.value)}
                        onFocus={handleAutocompleteFocus}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                  <Button onClick={handleSearchButtonClick} variant={"contained"} size="large" className={'search-filter__button'}>
                    <img src={zoom} alt="Magnifier" width="19" height="19" />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>

      <Drawer anchor={'right'} open={hamburgerOpen} onClose={toggleHamburger}>
        <div className={"hamburger-menu"} role={'presentation'}>
          <List component="nav" aria-label="main mailbox folders" className={'main-menu ' + (settingsOpen ? 'main-menu--hidden' : 'main-menu--visible')}>
            {/*<ListItem className={'hamburger-menu__header'}>
              <div className={'hamburger-menu__close'} onClick={toggleHamburger}>
                <img src={close} alt="Close icon" />
              </div>
              </ListItem>*/}
            {props.isUserLogged && props.user ? (
              <ListItem button disabled className={'hamburger-menu__account'}>
                <img src={ptIcon} alt="PredamTi icon" width="45px" className={'hamburger-menu__menu-icon logo'} />
                <ListItemText primary={t('Header.Account.Hello') + (props.user.name ? props.user.name?.split(' ')[0] : '')} />
              </ListItem>
            ) : (
              <Link
                to={location => ({
                  ...location,
                  search: undefined,
                  pathname: t('Routes.Login')
                })
                }
                className={'MuiLink-root headerLink'}
                onClick={toggleHamburger}>
                <ListItem button disabled className={'hamburger-menu__account login'}>
                  <ListItemText primary={t('Header.Account.HelloLogin')} />
                  <img src={loginWhite} alt="Login icon" width="20px" className={'icon'} />
                </ListItem>
              </Link>
            )}
            <Link
              to={location => ({
                ...location,
                search: undefined,
                pathname: t('Routes.LandingPage')
              })
              }
              className={'MuiLink-root headerLink'}
              onClick={toggleHamburger}>
              <ListItem button>
                <img src={start} alt="PredamTi icon" width="21px" className={'hamburger-menu__menu-icon'} />
                <ListItemText primary={t('Header.Homepage')} />
              </ListItem>
            </Link>
            <Link
              to={location => ({
                ...location,
                search: undefined,
                pathname: t('Routes.AccountMyOrders')
              })
              }
              className={'MuiLink-root headerLink'}
              onClick={toggleHamburger}>
              <ListItem button onClick={toggleHamburger}>
                <img src={cart} alt="PredamTi icon" width="21px" className={'hamburger-menu__menu-icon'} />
                <ListItemText primary={t('MyAccount.MenuItems.MyOrders')} />
              </ListItem>
            </Link>
            <Link
              to={location => ({
                ...location,
                search: undefined,
                pathname: t('Routes.WatchList')
              })
              }
              className={'MuiLink-root headerLink'}
              onClick={toggleHamburger}>
              <ListItem button onClick={toggleHamburger}>
                <img src={star} alt="PredamTi icon" width="21px" className={'hamburger-menu__menu-icon'} />
                <ListItemText primary={t('Header.SubscribedOffers')} />
              </ListItem>
            </Link>
            <Link
              to={location => ({
                ...location,
                search: undefined,
                pathname: t('Routes.AccountMyOffers')
              })
              }
              className={'MuiLink-root headerLink'}
              onClick={toggleHamburger}>
              <ListItem button>
                <img src={offers} alt="PredamTi icon" width="21px" className={'hamburger-menu__menu-icon'} />
                <ListItemText primary={t('MyAccount.MenuItems.MyOffer')} />
              </ListItem>
            </Link>
            <Link
              to={location => ({
                ...location,
                search: undefined,
                pathname: t('Routes.AccountInfoAdresses')
              })
              }
              className={'MuiLink-root headerLink'}
              onClick={toggleHamburger}>
              <ListItem button>
                <img src={userFilled} alt="PredamTi icon" width="21px" className={'hamburger-menu__menu-icon'} />
                <ListItemText primary={t('Header.Account.Profile')} />
              </ListItem>
            </Link>
            {!props.isUserLogged && (
              <>
                <Divider />
                <Link
                  to={location => ({
                    ...location,
                    search: undefined,
                    pathname: t('Routes.Register')
                  })}
                  className={'MuiLink-root headerLink'}
                  onClick={handleMobileHeaderRegister}>
                  <ListItem button>
                    <img src={plus} alt="Plus icon" width="16px" className={'hamburger-menu__menu-icon plus'} />
                    <ListItemText primary={t('Header.Account.CreateAccount')} />
                  </ListItem>
                </Link>
                <OauthButtons mobile />
              </>
            )}
            <Divider />
            <ListItem button>
              <ListItemText primary={<span className={'hamburger-menu__categories'}>{t('Header.Account.Categories')}</span>} />
            </ListItem>
            <ListItem button onClick={toggleHamburger}>
              <Box className={'motor-vehicles'} m={0} onClick={() => handleOffersCategory(t('Routes.ResultsAutomoto'))}>
                <ListItemText primary={t('Header.Account.Automoto')} />
              </Box>
            </ListItem>
            <ListItem button onClick={toggleHamburger}>
              <Box className={'marketplace'} m={0} onClick={() => handleOffersCategory(t('Routes.ResultsMarketplace'))}>
                <ListItemText primary={t('Header.Account.Market')} />
              </Box>
            </ListItem>
            <ListItem button onClick={toggleHamburger}>
              <Box className={'spare-parts'} m={0} onClick={() => handleOffersCategory(t('Routes.ResultsSpareParts'))}>
                <ListItemText primary={t('Header.Account.SpareParts')} />
              </Box>
            </ListItem>
            {/* <ListItem button onClick={toggleHamburger}>
              <Link
                to={location => ({
                  ...location,
                  pathname: t('Routes.SearchResults')
                })}
              >
                <Box className={'properties'} m={0}>
                  <ListItemText primary={t('Header.Account.RealEstate')} />
                </Box>
              </Link>
            </ListItem> */}
            <Divider />
            <ListItem button onClick={toggleSettings}>
              <img src={langs[0].imgSrc} alt={langs[0].imgAlt} width="21px" className={'hamburger-menu__menu-icon'} />
              <ListItemText primary={t('Header.Account.Settings')} />
              {settingsOpen ? <ChevronLeft /> : <ChevronRight />}
            </ListItem>
          </List>

          <List component="nav" aria-label="main mailbox folders" className={'settings ' + (settingsOpen ? 'settings--visible' : 'settings--hidden')}>
            <ListItem button onClick={toggleSettings} className={'settings__back-button'}>
              {settingsOpen ? <ChevronLeft /> : <ChevronRight />}
              <ListItemText primary={t('Header.Account.MainMenu')} />
            </ListItem>
            <Divider />
            <ListItem button className={'hamburger-menu__settings'}>
              <ListItemText primary={t('Header.Account.Settings')} />
            </ListItem>
            <ListItem button onClick={collapseHamburgerLang} disabled={!canLanguageBeChanged}>
              <img src={langs[0].imgSrc} alt={langs[0].imgAlt} width="21px" className={'hamburger-menu__menu-icon'} />
              <ListItemText primary={t(langs[0].key)} />
              {hamburgerLangOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={hamburgerLangOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {langs.slice(1,4).map(toHamburgerItem)}
              </List>
            </Collapse>
            <ListItem button onClick={collapseCurrency}>
              <ListItemText primary={t('Header.Account.Currency') + ' - ' + selectedCurrency} />
              {currencyOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={currencyOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {props.currencies && props.currencies.map((currency: any) =>
                  <ListItem key={currency.id} button onClick={() => handleCurrencyChange(currency, true)}>
                    <ListItemText
                      primary={currency.symbol + ' - ' + currency.name + ' - ' + (currency.name === 'EUR' ? 'Euro' : t('Header.Account.CzechCrown'))} />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />
            <ListItem button onClick={toggleHamburger}>
              <a
                className="redirect"
                href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Mobile.Hamburger.Settings.Link1"))}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemText primary={t('Mobile.Hamburger.Settings.Link1')} />
              </a>
            </ListItem>
            <ListItem button onClick={toggleHamburger}>
              <a
                className="redirect"
                href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Mobile.Hamburger.Settings.Link2"))}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemText primary={t('Mobile.Hamburger.Settings.Link2')} />
              </a>
            </ListItem>
            <ListItem button onClick={toggleHamburger}>
              <a
                className="redirect"
                href={getHelpdeskUrl(t("HelpdeskUrlConfigName.Mobile.Hamburger.Settings.Link3"))}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemText primary={t('Mobile.Hamburger.Settings.Link3')} />
              </a>
            </ListItem>
            <ListItem button onClick={toggleHamburger}>
              <span
                className="pop-up redirect"
                onClick={showSettingsPopUp}
              >
                <ListItemText primary={t('CookieBar.PopUp.Title')} />
              </span>
            </ListItem>
            {props.isUserLogged && (
              <ListItem button onClick={handleLogout}>
                <ListItemText primary={t('Header.Account.Logout')} />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

interface StateToProps {
  user: User | null;
  settings: any;
  currencies: any;
  cartItemsCount: number;
  offersSearchResult: any;
  myOffersCount: any;
  categories: any;
  isUserLogged: boolean;
  isSearchBarVisible: boolean;
  watchLists: WatchList[] | null,
  watchListDetail: WatchListDetail | null,
  watchedOffersIds: string[],
  canLanguageBeChanged: boolean,
}

interface DispatchToProps {
  logout: () => void;
  getCurrencies: () => void;
  getUser: () => void;
  searchForOffersSearchBar: (payload: any) => void;
  updateShoppingCartItemsCount: (count: number) => void;
  changeCurrency: (payload: any) => void;
  initCurrencyChange: (payload: string) => void;
  getMyOffersCountsByCategory: (payload: { category: string }) => void;
  getCategories: () => void;
  setSearchBarVisible: (state: boolean) => void;
  getWatchLists: (payload?: any) => void,
  getWatchListItems: (payload: { watchListId: string, params?: any }) => void,
  setWatchedOffersIds: (payload: string[]) => void,
  changeLanguage: (payload: { userId: string, body: { language: string } }) => void,
}

const mapStateToProps = (state: AppState): StateToProps => ({
  user: selectUser(state),
  settings: selectUserSettings(state),
  currencies: selectCurrencies(state),
  offersSearchResult: selectOffersSearchResultSearchBar(state),
  cartItemsCount: selectCartItemsCount(state),
  myOffersCount: selectMyOffersCountsByCategory(state),
  categories: selectCategories(state),
  isUserLogged: selectIsLogged(state),
  isSearchBarVisible: selectSearchBarVisible(state),
  watchLists: selectWatchLists(state),
  watchListDetail: selectWatchListDetail(state),
  watchedOffersIds: selectWatchedOffersIds(state),
  canLanguageBeChanged: selectCanLanguageBeChanged(state),
});

const mapDispatchToProps: DispatchToProps = {
  logout: logoutActions.request,
  getCurrencies: getCurrenciesActions.request,
  getUser: getUserActions.request,
  searchForOffersSearchBar: searchForOffersSearchBarActions.request,
  updateShoppingCartItemsCount: updateCartItemsCount.request,
  changeCurrency: changeCurrencyActions.request,
  initCurrencyChange: handleCurrencyChanged.request,
  getMyOffersCountsByCategory: getMyOffersCountsByCategoryActions.request,
  getCategories: getCategoriesActions.request,
  setSearchBarVisible: setSearchBarVisibleActions,
  getWatchLists: getWatchListsActions.request,
  getWatchListItems: getWatchListItemsActions.request,
  setWatchedOffersIds: setWatchedOffersIdsAction,
  changeLanguage: changeLanguageActions.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Header));
