import { combineReducers } from 'redux';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { AppState } from 'helpers/store/models/AppState';
import { api } from './api';
import {
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer,
  RequestActionTypes,
  LoadingState,
} from 'helpers/redux/redux-helpers';
import { UserActionTypes } from '../ducks';
import i18n from 'i18n';

import { errorResponseActions } from 'saga/response/ducks';
import { successToastActions } from 'saga/toast/ducks';

// models
import { ReturnTOCList, ReturnPolicyList, ReturnPolicy, ReturnTOC } from '../../../model/ReturnConditions';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';


/* STATE */
export interface UserReturnConditionsState {
  returnTOCList: ReturnTOCList,
  returnTOCDetail: ReturnTOC | null,
  returnTOCRequestState: LoadingState,
  returnPolicyList: ReturnPolicyList,
  returnPolicyDetail: ReturnPolicy | null,
  returnPolicyRequestState: LoadingState,
}

/* ACTION TYPES */
export enum UserReturnConditionsActionTypes {
  GetReturnTOCList = '@@UserReturnConditions/GET_RETURN_TOC_LIST',
  GetReturnTOCDetail = '@@UserReturnConditions/GET_RETURN_TOC_DETAIL',
  CreateReturnTOC = '@@UserReturnConditions/CREATE_RETURN_TOC',
  UpdateReturnTOC = '@@UserReturnConditions/UPDATE_RETURN_TOC',
  DeleteReturnTOC = '@@UserReturnConditions/DELETE_RETURN_TOC',

  GetReturnPolicyList = '@@UserReturnConditions/GET_RETURN_POLICY_LIST',
  GetReturnPolicyDetail = '@@UserReturnConditions/GET_RETURN_POLICY_DETAIL',
  CreateReturnPolicy = '@@UserReturnConditions/CREATE_RETURN_POLICY',
  UpdateReturnPolicy = '@@UserReturnConditions/UPDATE_RETURN_POLICY',
  DeleteReturnPolicy = '@@UserReturnConditions/DELETE_RETURN_POLICY',
}

/* ACTIONS */
export const getReturnTOCListActions = createApiActionCreators(UserReturnConditionsActionTypes.GetReturnTOCList);
export const getReturnTOCDetailActions = createApiActionCreators(UserReturnConditionsActionTypes.GetReturnTOCDetail);
export const createReturnTOCActions = createApiActionCreators(UserReturnConditionsActionTypes.CreateReturnTOC);
export const updateReturnTOCActions = createApiActionCreators(UserReturnConditionsActionTypes.UpdateReturnTOC);
export const deleteReturnTOCActions = createApiActionCreators(UserReturnConditionsActionTypes.DeleteReturnTOC);

export const getReturnPolicyListActions = createApiActionCreators(UserReturnConditionsActionTypes.GetReturnPolicyList);
export const getReturnPolicyDetailActions = createApiActionCreators(UserReturnConditionsActionTypes.GetReturnPolicyDetail);
export const createReturnPolicyActions = createApiActionCreators(UserReturnConditionsActionTypes.CreateReturnPolicy);
export const updateReturnPolicyActions = createApiActionCreators(UserReturnConditionsActionTypes.UpdateReturnPolicy);
export const deleteReturnPolicyActions = createApiActionCreators(UserReturnConditionsActionTypes.DeleteReturnPolicy);

/* REDUCERS */
const initialState: UserReturnConditionsState = {
  returnTOCList: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0
  },
  returnTOCDetail: null,
  returnTOCRequestState: LoadingState.success,
  returnPolicyList: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0
  },
  returnPolicyDetail: null,
  returnPolicyRequestState: LoadingState.success,
};

const returnTOCList = createReducer(initialState.returnTOCList, {
  [UserReturnConditionsActionTypes.GetReturnTOCList]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnTOCList, payload: ReturnTOCList) => payload,
    [RequestActionTypes.FAILURE]: (_state: ReturnTOCList) => {
      return ({
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      });
    },
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnTOCList) => {
      return ({
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      });
    },
  },
  [UserReturnConditionsActionTypes.CreateReturnTOC]: {
    [RequestActionTypes.SUCCESS]: (state: ReturnTOCList, payload: ReturnTOC) => {
      const newData = [...state.data];
      newData.push(payload);
      return ({
        ...state,
        data: newData,
        total: newData.length
      })
    }
  },
  [UserReturnConditionsActionTypes.DeleteReturnTOC]: {
    [RequestActionTypes.SUCCESS]: (state: ReturnTOCList, payload: ReturnTOC) => {
      const newData = [...state.data];

      const elemmentToDelete = newData.find((returnTOC: ReturnTOC) => returnTOC.id === payload.id)

      if (elemmentToDelete) {
        const indexOfElementToRemove = newData.indexOf(elemmentToDelete);

        if (indexOfElementToRemove > -1) {
          newData.splice(indexOfElementToRemove, 1);
        }

        return ({
          ...state,
          data: newData,
          total: newData.length
        });
      }

      return ({
        ...state
      });
    }
  },
});

const returnTOCDetail = createReducer(initialState.returnTOCDetail, {
  [UserReturnConditionsActionTypes.GetReturnTOCDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnTOC, payload: ReturnTOC) => payload,
    [RequestActionTypes.FAILURE]: (_state: ReturnTOC) => null
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnTOC) => null,
  },
});

const returnPolicyList = createReducer(initialState.returnPolicyList, {
  [UserReturnConditionsActionTypes.GetReturnPolicyList]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnPolicyList, payload: ReturnPolicyList) => payload,
    [RequestActionTypes.FAILURE]: (_state: ReturnPolicyList) => {
      return ({
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      });
    },
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnPolicyList) => {
      return ({
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      });
    },
  },
  [UserReturnConditionsActionTypes.CreateReturnPolicy]: {
    [RequestActionTypes.SUCCESS]: (state: ReturnPolicyList, payload: ReturnPolicy) => {
      const newData = [...state.data];
      newData.push(payload);
      return ({
        ...state,
        data: newData,
        total: newData.length
      })
    }
  },
  [UserReturnConditionsActionTypes.DeleteReturnPolicy]: {
    [RequestActionTypes.SUCCESS]: (state: ReturnPolicyList, payload: ReturnPolicy) => {
      const newData = [...state.data];

      const elemmentToDelete = newData.find((returnPolicy: ReturnPolicy) => returnPolicy.id === payload.id)

      if (elemmentToDelete) {
        const indexOfElementToRemove = newData.indexOf(elemmentToDelete);

        if (indexOfElementToRemove > -1) {
          newData.splice(indexOfElementToRemove, 1);
        }

        return ({
          ...state,
          data: newData,
          total: newData.length
        });
      }

      return ({
        ...state
      });
    }
  },
});

const returnPolicyDetail = createReducer(initialState.returnPolicyDetail, {
  [UserReturnConditionsActionTypes.GetReturnPolicyDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnPolicy, payload: ReturnPolicy) => payload,
    [RequestActionTypes.FAILURE]: (_state: ReturnPolicy) => null
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: ReturnPolicy) => null,
  },
});

const returnTOCRequestState = createLoadingStateReducer(initialState.returnTOCRequestState, {
  [UserReturnConditionsActionTypes.GetReturnTOCList]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserReturnConditionsActionTypes.CreateReturnTOC]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserReturnConditionsActionTypes.UpdateReturnTOC]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserReturnConditionsActionTypes.DeleteReturnTOC]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const returnPolicyRequestState = createLoadingStateReducer(initialState.returnPolicyRequestState, {
  [UserReturnConditionsActionTypes.GetReturnPolicyList]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserReturnConditionsActionTypes.CreateReturnPolicy]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserReturnConditionsActionTypes.UpdateReturnPolicy]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserReturnConditionsActionTypes.DeleteReturnPolicy]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<UserReturnConditionsState>({
  returnTOCList,
  returnTOCDetail,
  returnPolicyList,
  returnPolicyDetail,
  returnTOCRequestState,
  returnPolicyRequestState,
});

/* SELECTORS */
export const selectUserReturnConditionsState = (state: AppState): UserReturnConditionsState => state.userReturnConditions;

export const selectReturnTOCList = (state: AppState): ReturnTOCList => selectUserReturnConditionsState(state).returnTOCList;
export const selectReturnTOCDetail = (state: AppState): ReturnTOC | null => selectUserReturnConditionsState(state).returnTOCDetail;
export const selectReturnTOCRequestState = (state: AppState): LoadingState => selectUserReturnConditionsState(state).returnTOCRequestState;

export const selectReturnPolicyList = (state: AppState): ReturnPolicyList => selectUserReturnConditionsState(state).returnPolicyList;
export const selectReturnPolicyDetail = (state: AppState): ReturnPolicy | null => selectUserReturnConditionsState(state).returnPolicyDetail;
export const selectReturnPolicyRequestState = (state: AppState): LoadingState => selectUserReturnConditionsState(state).returnPolicyRequestState;

/* SAGAS */
function* getReturnTOCList({ payload }: any) {
  const params = {
    '$limit': 10,
    '$sort': {
      'createdAt': -1,
    },
    ...payload
  }

  const resp: ExtendedAxiosResponse = yield call(api.getReturnTOC, params);

  if (resp.ok) {
    if (params.$limit === -1) {
      yield put(getReturnTOCListActions.success({
        data: resp.data,
        limit: -1,
        skip: 0,
        total: resp.data.length
      }));
    } else {
      yield put(getReturnTOCListActions.success(resp.data));
    }
  } else {
    yield put(getReturnTOCListActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* getReturnTOCDetail({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getReturnTOCDetail, payload);

  if (resp.ok) {
    yield put(getReturnTOCDetailActions.success(resp.data));
  } else {
    yield put(getReturnTOCDetailActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* createReturnTOC({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.createReturnTOC, payload);

  if (resp.ok) {
    yield put(createReturnTOCActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.NewReturnTOCWasSuccessfullyCreated')));
  } else {
    yield put(createReturnTOCActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* updateReturnTOC({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateReturnTOC, payload.id, payload.body);

  if (resp.ok) {
    yield put(updateReturnTOCActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.ReturnTOCWasSuccessfullyUpdated')));

    const returnTOCs: ReturnTOCList = yield select(selectReturnTOCList);
    yield call(getReturnTOCList, {
      payload: {
        '$limit': returnTOCs.limit > 0 ? returnTOCs.limit : 10,
        '$skip': 0
      }
    });

  } else {
    yield put(updateReturnTOCActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* deleteReturnTOC({ payload }: any) {
  const responses: ExtendedAxiosResponse[] = yield all<ExtendedAxiosResponse>(payload?.map((id: string) => call(api.deleteReturnTOC, id)))

  if (payload?.length > 1 && responses.length > 1) {
    // if more than 1 id in request
    const successResponses = responses.filter((resp: any) => resp.ok);
    const errorResponses = responses.filter((resp: any) => !resp.ok);

    // successfuly deleted return policy
    if (successResponses.length !== 0) {
      yield all(successResponses.map((resp: any) => put(deleteReturnTOCActions.success(resp.data))));
      yield put(successToastActions(i18n.t('SuccessToasts.ReturnTOCsWereSuccessfullyDeleted')));
    }

    // error occured while deleting return policy
    if (errorResponses.length !== 0) {
      yield all(errorResponses.map((resp: any) => put(errorResponseActions(resp.response.data))));
      yield put(deleteReturnTOCActions.failure());
    }

  } else {
    // if only one id was provided in request
    if (responses[0]?.ok) {
      yield put(deleteReturnTOCActions.success(responses[0].data));
      yield put(successToastActions(i18n.t('SuccessToasts.ReturnTOCWasSuccessfullyDeleted')));
    } else {
      yield put(deleteReturnTOCActions.failure());
      yield put(errorResponseActions(responses[0].response.data));
    }
  }  
}

function* getReturnPolicyList({ payload }: any) {
  const params = {
    '$limit': 10,
    '$sort': {
      'createdAt': -1,
    },
    ...payload
  }

  const resp: ExtendedAxiosResponse = yield call(api.getReturnPolicies, params);

  if (resp.ok) {
    if (params.$limit === -1) {
      yield put(getReturnPolicyListActions.success({
        data: resp.data,
        limit: -1,
        skip: 0,
        total: resp.data.length
      }));
    } else {
      yield put(getReturnPolicyListActions.success(resp.data));
    }
  } else {
    yield put(getReturnPolicyListActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* getReturnPolicyDetail({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getReturnPolicyDetail, payload);

  if (resp.ok) {
    yield put(getReturnPolicyDetailActions.success(resp.data));
  } else {
    yield put(getReturnPolicyDetailActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* createReturnPolicy({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.createReturnPolicy, payload);

  if (resp.ok) {
    yield put(createReturnPolicyActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.NewReturnPolicyWasSuccessfullyCreated')));
  } else {
    yield put(createReturnPolicyActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* updateReturnPolicy({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateReturnPolicy, payload.id, payload.body);

  if (resp.ok) {
    yield put(updateReturnPolicyActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.ReturnPolicyWasSuccessfullyUpdated')));

    const returnPolicies: ReturnPolicyList = yield select(selectReturnPolicyList);
    yield call(getReturnPolicyList, {
      payload: {
        '$limit': returnPolicies.limit > 0 ? returnPolicies.limit : 10,
        '$skip': 0
      }
    });

  } else {
    yield put(updateReturnPolicyActions.failure());
    yield put(errorResponseActions(resp.response.data));
  }
}

function* deleteReturnPolicy({ payload }: any) {
  const responses: ExtendedAxiosResponse[]  = yield all(payload?.map((id: string) => call(api.deleteReturnPolicy, id)))

  if (payload?.length > 1 && responses.length > 1) {
    // if more than 1 id in request
    const successResponses = responses.filter((resp: any) => resp.ok);
    const errorResponses = responses.filter((resp: any) => !resp.ok);

    // successfuly deleted return policy
    if (successResponses.length !== 0) {
      yield all(successResponses.map((resp: any) => put(deleteReturnPolicyActions.success(resp.data))));
      yield put(successToastActions(i18n.t('SuccessToasts.ReturnPoliciesWereSuccessfullyDeleted')));
    }

    // error occured while deleting return policy
    if (errorResponses.length !== 0) {
      yield all(errorResponses.map((resp: any) => put(errorResponseActions(resp.response.data))));
      yield put(deleteReturnPolicyActions.failure());
    }

  } else {
    // if only one id was provided in request
    if (responses[0]?.ok) {
      yield put(deleteReturnPolicyActions.success(responses[0].data));
      yield put(successToastActions(i18n.t('SuccessToasts.ReturnPolicyWasSuccessfullyDeleted')));
    } else {
      yield put(deleteReturnPolicyActions.failure());
      yield put(errorResponseActions(responses[0].response.data));
    }
  }  

}

/* EXPORT */
export function* userReturnConditionsSaga() {
  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.GetReturnTOCList, RequestActionTypes.REQUEST),
    getReturnTOCList,
  );

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.GetReturnTOCDetail, RequestActionTypes.REQUEST),
    getReturnTOCDetail,
  );

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.CreateReturnTOC, RequestActionTypes.REQUEST),
    createReturnTOC,
  )

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.UpdateReturnTOC, RequestActionTypes.REQUEST),
    updateReturnTOC,
  )

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.DeleteReturnTOC, RequestActionTypes.REQUEST),
    deleteReturnTOC,
  )

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.GetReturnPolicyList, RequestActionTypes.REQUEST),
    getReturnPolicyList,
  );

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.GetReturnPolicyDetail, RequestActionTypes.REQUEST),
    getReturnPolicyDetail,
  );

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.CreateReturnPolicy, RequestActionTypes.REQUEST),
    createReturnPolicy,
  )

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.UpdateReturnPolicy, RequestActionTypes.REQUEST),
    updateReturnPolicy,
  )

  yield takeLatest(
    createActionType(UserReturnConditionsActionTypes.DeleteReturnPolicy, RequestActionTypes.REQUEST),
    deleteReturnPolicy,
  )
}
