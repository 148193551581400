import React from 'react';
import i18n from '../../../i18n';
import { Route, Redirect } from 'react-router';
import { warnToastActions } from 'saga/toast/ducks';
import { store } from '../../../helpers/store/configure-store';

const renderMergedProps = (component: any, ...rest: any) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};

const redirectToLogin = (props: any) => {
  store.dispatch(warnToastActions(i18n.t('Errors.NeedAuth')));
  return (
    <Redirect to={{ pathname: i18n.t('Routes.Login'),
      state: { from: props.location } }} />
  );
};

export const ProtectedRoute = ({ component, ...rest } : any) => (
  <Route {...rest} render={(props) => (
    store.getState().user.isLogged ?
      renderMergedProps(component, props, rest)
      :
      redirectToLogin(props)
  )} />
);
