import { combineReducers } from 'redux';

import { AppState } from '../../helpers/store/models/AppState';
import {
  createActionCreator,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

/* STATE */
export interface LayoutState {
  // mobile footer menu
  isSearchBarVisible: boolean,
  saveDraftRequest: boolean,
  canSaveDraftBeRequested: boolean,
  canLanguageBeChanged: boolean
}

/* ACTION TYPES */
export enum LayoutActionTypes {
  SetSearchBarVisible = '@@Layout/SET_SEARCH_BAR_VISIBLE',
  SetSaveDraftRequest = '@@Layout/SET_SAVE_DRAFT_REQUEST',
  SetCanSaveDraftBeRequested = '@@Layout/SET_CAN_SEVE_DRAFT_BE_REQUESTED',
  SetCanLanguageBeChanged = '@@Layout/SET_CAN_LANGUAGE_BE_CHANGED',
}

/* ACTIONS */
export const setSearchBarVisibleActions = createActionCreator(LayoutActionTypes.SetSearchBarVisible);
export const setSaveDraftRequestActions = createApiActionCreators(LayoutActionTypes.SetSaveDraftRequest);
export const setCanSaveDraftBeRequestedActions = createActionCreator(LayoutActionTypes.SetCanSaveDraftBeRequested);
export const setCanLanguageBeChangedActions = createActionCreator(LayoutActionTypes.SetCanLanguageBeChanged);


/* REDUCERS */
const initialState: LayoutState = {
  isSearchBarVisible: false,
  saveDraftRequest: false,
  canSaveDraftBeRequested: false,
  canLanguageBeChanged: true,
};

const isSearchBarVisible = createReducer(initialState.isSearchBarVisible, {
  [LayoutActionTypes.SetSearchBarVisible]: (state: boolean, payload: boolean) => payload,
});

const saveDraftRequest = createReducer(initialState.saveDraftRequest, {
  [LayoutActionTypes.SetSaveDraftRequest]: {
    [RequestActionTypes.REQUEST]: (state: boolean) => true,
    [RequestActionTypes.SUCCESS]: (state: boolean) => false,
    [RequestActionTypes.FAILURE]: (state: boolean) => false,
  }
});

const canSaveDraftBeRequested = createReducer(initialState.canSaveDraftBeRequested, {
  [LayoutActionTypes.SetCanSaveDraftBeRequested]: (state: boolean, payload: boolean) => payload,
});

const canLanguageBeChanged = createReducer(initialState.canLanguageBeChanged, {
  [LayoutActionTypes.SetCanLanguageBeChanged]: (state: boolean, payload: boolean) => payload,
});

export default combineReducers<LayoutState>({
  isSearchBarVisible,
  saveDraftRequest,
  canSaveDraftBeRequested,
  canLanguageBeChanged
});

/* SELECTORS */
export const selectLayoutState = (state: AppState) => state.layout;
export const selectSearchBarVisible = (state: AppState) => selectLayoutState(state).isSearchBarVisible;
export const selectSaveDraftRequest = (state: AppState) => selectLayoutState(state).saveDraftRequest;
export const selectCanSaveDraftBeRequested = (state: AppState) => selectLayoutState(state).canSaveDraftBeRequested;
export const selectCanLanguageBeChanged = (state: AppState) => selectLayoutState(state).canLanguageBeChanged;


/* SAGAS */

/* EXPORT */
export function* layoutSaga() {}
