import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CheckIcon from '../../../assets/Icons/CheckIcon';
import CloseIcon from '../../../assets/Icons/CloseIcon';
import CircleCheckIcon from '../../../assets/Icons/CircleCheckIcon';
import InfoCircleIcon from '../../../assets/Icons/InfoCircleIcon';
import WarningIcon from '../../../assets/Icons/WarningIcon';
import {
  closeNotificationActions,
  selectBankAccountAdded,
  selectUserSettings,
  selectUserState,
  UserState,
} from '../../../saga/user/ducks';
import {
  OfferState,
  selectOfferState,
} from "../../../saga/offer/ducks";
import { MarketTypesModel } from "../../../model/MarketTypesModel";
import { selectMarketTypes } from 'saga/enumerations/ducks';
import { AppState } from '../../../helpers/store/models/AppState';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Setting } from "../../../types/User";
import { environment as env } from "../../../environments/environment";
import moment from 'moment';
import qs from 'qs';
import { getHelpdeskUrl } from 'helpers/other/getHelpdeskUrl';
import { sendGAEvent } from "helpers/other/googleAnalytics";
import { sendFBCustomEvent } from "helpers/other/facebookPixel";

//accountStatus?: string;
//TODO: edit links (fe. to helpdesk)

const NotificationBar = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [userStatus, setUserStatus] = useState('');
  const [width, setWidth] = useState(1600)


  const getDeclinedDate = () => {
    let date = '';
    if (props?.userSettings?.stripeDeclineDate) {
      date = moment(props?.userSettings?.stripeDeclineDate).format('MM.DD.YYYY')
    }

    return date;
  };

  const sendAnalyticsEvent = (key: string) => {
    if (key === 'NotificationBar.NotFullActivatedBusiness' || key === 'NotificationBar.NotFullActivatedUser') {
      sendGAEvent('NotificationBar', 'User redirected to activation page');
      sendFBCustomEvent('User redirected to activation page');
    }
  };

  const notifications = [
    {
      key: `NotificationBar.NotFullActivatedBusiness`,
      type: "info",
      icon: InfoCircleIcon,
      canClose: false,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: null,
      toUser: env.user.roles.companyOwner,
      visible: true,
      allPages: ["Routes.NewOffer"],
      search: ['marketplace=1', 'category=marketplace', 'spare-parts=1', 'category=spare-parts'],
      except: []
    },
    {
      key: `NotificationBar.NotFullActivatedBusiness`,
      type: "info",
      icon: InfoCircleIcon,
      canClose: true,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: null,
      toUser: "user",
      visible: true,
      allPages: ["Routes.NewOffer"],
      search: ['marketplace=1', 'category=marketplace', 'spare-parts=1', 'category=spare-parts'],
      except: []
    },
    {
      key: `NotificationBar.NotFullActivatedUser`,
      type: "info",
      icon: InfoCircleIcon,
      canClose: false,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: null,
      toUser: env.user.roles.companyOwner,
      visible: true,
      allPages: ["/"],
      except: ["Routes.NewOffer"],
      search: ['marketplace=1', 'category=marketplace', 'spare-parts=1', 'category=spare-parts'],
    },
    {
      key: `NotificationBar.ActivationRequest`,
      type: "done",
      icon: CircleCheckIcon,
      canClose: true,
      link: "Routes.NewOffer",
      helpDeskLink: null,
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.AccountActivated`,
      type: "success",
      icon: CheckIcon,
      canClose: true,
      link: "Routes.NewOffer",
      helpDeskLink: null,
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.AccountActivationFail`,
      type: "error",
      icon: CloseIcon,
      canClose: true,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.ActualizeInfo`,
      type: "warning",
      icon: WarningIcon,
      canClose: false,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: getDeclinedDate(),
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.ActivationRequestSuspended`,
      type: "info",
      icon: InfoCircleIcon,
      canClose: true,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.ActualizeRequestSend`,
      type: "done",
      icon: CircleCheckIcon,
      canClose: true,
      link: null,
      helpDeskLink: null,
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.AccountDeactivated`,
      type: "error",
      icon: CloseIcon,
      canClose: true,
      link: "Routes.AccountActivation",
      helpDeskLink: null,
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
    {
      key: `NotificationBar.ActivationRequestSuspendedBankAccount`,
      type: "info",
      icon: InfoCircleIcon,
      canClose: true,
      link: "Routes.AccountActivation",
      helpDeskLink: getHelpdeskUrl(),
      variable: null,
      toUser: "both",
      visible: true,
      allPages: ["/"],
      except: []
    },
  ];
  const [closedNotificationsKeys, setClosedNotificationsKeys] = useState<any>(null);

  useEffect(() => {
    if (props.user && props.user?.loggedUser) {
      setUserStatus(props.user?.loggedUser?.role?.value);
      if (props.user?.loggedUser && props.user?.loggedUser?.closedNotifications) {
        const keys = props.user?.loggedUser?.closedNotifications.map((item: any) => (item.key))
        setClosedNotificationsKeys(keys);
      }
    }
  }, [props.user])

  useEffect(() => {
    const lastChildElementWidth = document.getElementById("body")?.lastElementChild?.clientWidth;
    if (lastChildElementWidth) {
      setWidth(lastChildElementWidth);
    }
  }, [userStatus])

  const handleCloseNotification = (key: string) => {
    props.closeNotification({ key });
  };

  const isNotificationShownInCurrentPage = (notification: any) => {
    const { pathname, search } = location;

    const searchParams = search !== '' ? JSON.parse(JSON.stringify(qs.parse(search, { ignoreQueryPrefix: true }))) : {};

    let isPageMatched = false;

    isPageMatched = notification.allPages.reduce((isMatched: boolean, page: string) => {
      if (pathname.includes(t(page))) {
        return true;
      }

      return isMatched;
    }, false);
  
    if (isPageMatched && notification.search && notification.search.length) {
      const atLeastOneParamContained = notification.search.reduce((contains: boolean, param: string) => {
        const [attribute, value] = param.split('=');

        if (searchParams[attribute]) {
          if (value && searchParams[attribute] === value) {
            return true;
          } else if (value) {
            return contains || false;
          }

          return true;
        }

        return contains;
      }, false);

      isPageMatched = atLeastOneParamContained;
    }

    const isShownOnAllPages = notification.allPages.length === 1 && notification.allPages[0] === '/';
    const isPageInExcept = notification.except.some((item: string) => pathname.includes(t(item)));



    return !!(!isPageInExcept && (isShownOnAllPages || isPageMatched));
  }

  const shownByUserStatus = (notification: any) => (notification.toUser === "both" || notification.toUser === userStatus);

  const isNotificationVisible = (notification: any) => {
    const {
      userSettings,
      bankAccountAdded,
    } = props;
    let caseCondition;

    const shownOnPage = isNotificationShownInCurrentPage(notification);

    const stripeFinishedCase = (
      userSettings
      && userSettings.stripeAccountId
      && userSettings.accountLinkUrl
      && userSettings.stripeOnboardFinished
    );

    switch (notification.key) {
      case 'NotificationBar.NotFullActivatedBusiness': {
        caseCondition = !(
          userSettings
          && (
            userSettings.stripeAccountId
            && userSettings.accountLinkUrl
            && (bankAccountAdded
              || (
                userSettings.stripeOnboardFinished
                && userSettings.isVerifiedByStripe
              )
            )
          )
        );
        const freeOfferType = props.marketTypes.find((t: MarketTypesModel) => t.value === env.offer.marketType.freeOffer);
        if (caseCondition && (!props.offer || (!props.offer.marketTypeId || (freeOfferType && props.offer.marketTypeId === freeOfferType.id)))) {
          caseCondition = false;
        }
        break;
      }
      case 'NotificationBar.NotFullActivatedUser': {
        caseCondition = !(
          userSettings
          && (
            userSettings.stripeAccountId
            && userSettings.accountLinkUrl
            && (bankAccountAdded
              || (
                userSettings.stripeOnboardFinished
                && userSettings.isVerifiedByStripe
              )
            )
          )
        );
        break;
      }
      case 'NotificationBar.ActivationRequest': {
        caseCondition = (
          userSettings
          && userSettings.stripeAccountId
          && userSettings.accountLinkUrl
          && !userSettings.verificationInformationNeeded
          && !userSettings.documentsUploadNeeded
          && bankAccountAdded
          && !userSettings.informationUpdated
          && userSettings.adminVerificationStatus === 0
        );
        break;
      }
      case 'NotificationBar.ActualizeInfo': {
        caseCondition = (
          userSettings
          && userSettings.verificationInformationNeeded
        );
        break;
      }
      case 'NotificationBar.AccountActivated': {
        caseCondition = (
          stripeFinishedCase
          && bankAccountAdded
          && userSettings.adminVerificationStatus === 1
        );
        break;
      }
      case 'NotificationBar.AccountActivationFail': {
        caseCondition = (
          userSettings
          && userSettings.adminVerificationStatus === 4
        );
        break;
      }
      case 'NotificationBar.ActivationRequestSuspended': {
        caseCondition = (
          userSettings
          && (userSettings.adminVerificationStatus === 2 || userSettings.documentsUploadNeeded)
        );
        break;
      }
      case 'NotificationBar.ActualizeRequestSend': {
        caseCondition = (
          userSettings
          && props.isBankAccountAdded
          && userSettings.adminVerificationStatus !== 1
          && userSettings.adminVerificationStatus !== 3
          && !userSettings.isVerifiedByStripe
          && userSettings.informationUpdated
        );
        break;
      }
      case 'NotificationBar.ActivationRequestSuspendedBankAccount': {
        caseCondition = (
          userSettings
          && userSettings.adminVerificationStatus === 3
        );
        break;
      }
      default: {
        caseCondition = false;
      }
    }

    return shownOnPage && caseCondition;
  }

  const shownNotifications = notifications.filter((item: any) => shownByUserStatus(item) && closedNotificationsKeys && !closedNotificationsKeys.includes(item.key));

  return (
    <>
      {shownNotifications.map((notification: any, index: number) => isNotificationVisible(notification) && (
        <div key={index}>
          <div id="NotificationBar" className={notification.type} style={{
            maxWidth: width,
            padding: `5px ${width === 1600 ? '4px' : '10px'}`,
          }}>
            <div className="notificationsBackground">
              <div className={"iconHolder"}>
                <notification.icon />
              </div>
              <h5>
                {notification.link !== null ? (
                  <Trans
                    i18nKey={notification.key}
                    components={notification.helpDeskLink !== null ?
                      // When there are more links (max. 2)
                      {
                        a: <a href={t(notification.link, { activationRouteParam: props.user.loggedUser.id })} className="link">&nbsp</a>,
                        Link: <a href={notification.helpDeskLink} className="link">&nbsp</a>
                      }
                      :
                      {
                        a: <a href={t(notification.link, { activationRouteParam: props.user.loggedUser.id })} onClick={() => sendAnalyticsEvent(notification.key)} className="link">&nbsp</a>
                      }
                    }
                    tOptions={{ variable: notification.variable }}
                  />) : t(notification.key, { variable: notification.variable })
                }
              </h5>
              {notification.canClose &&
                <div className="closeIcon" onClick={() => handleCloseNotification(notification.key)}>
                  <CloseIcon />
                </div>
              }
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

interface DispatchToProps {
  closeNotification: () => void;
}

interface StateToProps {
  user: UserState;
  userSettings: Setting | null;
  bankAccountAdded: boolean;
  offer: OfferState;
  marketTypes: MarketTypesModel[];
}

const mapStateToProps = (state: AppState): StateToProps => ({
  user: selectUserState(state),
  userSettings: selectUserSettings(state),
  bankAccountAdded: selectBankAccountAdded(state),
  offer: selectOfferState(state),
  marketTypes: selectMarketTypes(state)
});

const mapDispatchToProps: DispatchToProps = {
  closeNotification: closeNotificationActions.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);