import { all, fork } from 'redux-saga/effects';

import { userSaga } from 'saga/user/ducks';
import { toastSaga } from '../../saga/toast/ducks';
import { offerSaga } from "../../saga/offer/ducks";
import { enumSaga } from '../../saga/enumerations/ducks';
import { shoppingCartSaga } from '../../saga/shoppingCart/ducs';
import { orderSaga } from '../../saga/order/ducks';
import { userPaymentMethodSaga } from '../../saga/user/payment-method/ducks';
import { userAddressSaga } from '../../saga/user/address/ducks';
import { checkoutSaga } from '../../saga/checkout/ducks';
import { offerPhotoSaga } from '../../saga/offer/photo/ducks';
import { usersSaga } from '../../saga/user/users/ducks';
import { layoutSaga } from 'saga/layout/ducks';
import { publishOfferSaga } from 'saga/offer/publishOffer/ducks';
import { userTransactionsSaga } from '../../saga/user/transactions/ducks';
import { userInvoicesSaga } from '../../saga/user/invoices/ducks';
import { userCreditsSaga } from 'saga/user/credit/ducks';
import { userReturnConditionsSaga } from 'saga/user/returnConditions/ducks';
import { watchListSaga } from 'saga/watchList/ducks';
import { ratingsSaga } from 'saga/ratings/ducks';
import { chatSaga } from 'saga/chat/ducks';
import { importOffersSaga } from '../../saga/offer/import/ducks';
import { tablesSaga } from 'saga/tables/ducks';
import { prolongOfferSaga } from 'saga/offer/prolongOffer/ducks';


export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(userPaymentMethodSaga),
    fork(userAddressSaga),
    fork(userTransactionsSaga),
    fork(userInvoicesSaga),
    fork(userCreditsSaga),
    fork(userReturnConditionsSaga),
    fork(toastSaga),
    fork(offerSaga),
    fork(publishOfferSaga),
    fork(prolongOfferSaga),
    fork(offerPhotoSaga),
    fork(importOffersSaga),
    fork(enumSaga),
    fork(shoppingCartSaga),
    fork(orderSaga),
    fork(checkoutSaga),
    fork(usersSaga),
    fork(layoutSaga),
    fork(watchListSaga),
    fork(ratingsSaga),
    fork(chatSaga),
    fork(tablesSaga),
  ]);
}
