import { apiClient, axiosBadRequestSkip } from '../../helpers/api-client';

export interface CreateOrderRequest {
  checkoutResponseId: string;
  deliveryAddressId: string;
  invoiceAddressId: string;
  deliveryGroups: any;
}

export interface ConfirmPaymentRequest {
  paymentId: string;
}

export const api = {
  getCheckoutResponse: () =>
    apiClient.get('/checkout', axiosBadRequestSkip),

  createOrder: (body: CreateOrderRequest) =>
    apiClient.post('/orders/create', body, axiosBadRequestSkip),

  confirmOrder: (body: ConfirmPaymentRequest) =>
    apiClient.post('/payments/confirm', body),
};
