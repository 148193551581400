import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "./api";
import { AppState } from "helpers/store/models/AppState";
import { successResponseActions } from '../response/ducks';
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
} from "helpers/redux/redux-helpers";
import { combineReducers } from "redux";
import { UserActionTypes } from "saga/user/ducks";
import { TopOption } from 'model/Enums';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';
import { CategoryModel } from "model/CategoryModel";

/* STATE */
export interface Enumerations {
  categories: any;
  allCategories: CategoryModel[],
  subCategories: any;
  currencies: any;
  deliveryTypes: any;
  itemStatuses: any;
  marketTypes: any;
  fuelTypes: any;
  characteristics: any;
  basicCharacteristics: any;
  detailCharacteristics: any;
  vehicleVariants: any;
  vehicleVariant: any;
  brands: any;
  models: any;
  carEquipment: any;
  publishOptions: any;
  offerEnumerations: any;
  allCustomEnums: any;
  returnItemExceptions: any;
  topOptions: TopOption[];
}

/* ACTION TYPES */
export enum EnumerationsActionTypes {
  GetCategories = "@@Enum/GET_CATEGORIES",
  GetAllCategories = "@@Enum/GET_ALL_CATEGORIES",
  GetSubCategories = "@@Enum/GET_SUBCATEGORIES",
  GetCurrencies = "@@Enum/GET_CURRENCIES",
  GetDeliveryTypes = "@@Enum/GET_DELIVERY_TYPES",
  GetItemStatuses = "@@Enum/GET_ITEM_STATUSES",
  GetMarketTypes = "@@Enum/GET_MARKET_TYPES",
  GetFuelTypes = '@@Enum/GET_FUEL_TYPES',
  GetCarCharacteristics = '@@Enum/GET_CAR_CHARACTERISTICS',
  GetBasicCharacteristics = '@@Enum/GET_BASIC_CHARACTERISTICS',
  GetDetailCharacteristics = '@@Enum/GET_DETAIL_CHARACTERISTICS',
  GetVehicleVariants = '@@Enum/GET_VEHICLE_VARIANTS',
  GetVehicleVariant = '@@Enum/GET_VEHICLE_VARIANT',
  GetBrands = '@@Enum/GET_BRANDS',
  GetModels = '@@Enum/GET_MODELS',
  GetCarEquipment = '@@Enum/GET_CAR_EQUIPMENT',
  GetPublishOptions = '@@Enum/GET_PUBLISH_OPTIONS',
  GetOfferEnumerations = '@@Enum/GET_OFFER_ENUMERATIONS',
  GetAllCustomEnums = '@@Enum/GET_ALL_CUSTOM_ENUMS',
  GetReturnItemExceptions = '@@Enum/GET_RETURN_ITEM_EXCEPTIONS',
  GetTopOptions = '@@Enum/GET_TOP_OPTIONS',
}

/* ACTIONS */
export const getCategoriesActions = createApiActionCreators(
  EnumerationsActionTypes.GetCategories,
);
export const getAllCategoriesActions = createApiActionCreators(
  EnumerationsActionTypes.GetAllCategories,
);
export const getSubCategoriesActions = createApiActionCreators(
  EnumerationsActionTypes.GetSubCategories,
);
export const getCurrenciesActions = createApiActionCreators(
  EnumerationsActionTypes.GetCurrencies,
);
export const getDeliveryTypesActions = createApiActionCreators(
  EnumerationsActionTypes.GetDeliveryTypes,
);
export const getItemStatusesActions = createApiActionCreators(
  EnumerationsActionTypes.GetItemStatuses,
);
export const getMarketTypesActions = createApiActionCreators(
  EnumerationsActionTypes.GetMarketTypes,
);
export const getFuelTypesActions = createApiActionCreators(
  EnumerationsActionTypes.GetFuelTypes,
);
export const getCarCharacteristicsActions = createApiActionCreators(
  EnumerationsActionTypes.GetCarCharacteristics,
);
export const getVehicleVariantsActions = createApiActionCreators(
  EnumerationsActionTypes.GetVehicleVariants,
);
export const getVehicleVariantActions = createApiActionCreators(
  EnumerationsActionTypes.GetVehicleVariant,
);
export const getBasicCharacteristicsActions = createApiActionCreators(
  EnumerationsActionTypes.GetBasicCharacteristics,
);
export const getDetailCharacteristicsActions = createApiActionCreators(
  EnumerationsActionTypes.GetDetailCharacteristics,
);
export const getBrandsActions = createApiActionCreators(
  EnumerationsActionTypes.GetBrands,
);
export const getModelsActions = createApiActionCreators(
  EnumerationsActionTypes.GetModels,
);
export const getCarEquipmentActions = createApiActionCreators(
  EnumerationsActionTypes.GetCarEquipment,
);
export const getPublishOptionsActions = createApiActionCreators(
  EnumerationsActionTypes.GetPublishOptions,
);
export const getOfferEnumerationsActions = createApiActionCreators(
  EnumerationsActionTypes.GetOfferEnumerations,
);
export const getAllCustomEnumsActions = createApiActionCreators(
  EnumerationsActionTypes.GetAllCustomEnums,
);
export const getReturnItemExceptionsActions = createApiActionCreators(
  EnumerationsActionTypes.GetReturnItemExceptions,
);
export const getTopOptionsActions = createApiActionCreators(
  EnumerationsActionTypes.GetTopOptions,
);


/* REDUCERS */
const initialState: Enumerations = {
  categories: [],
  allCategories: [],
  subCategories: [],
  currencies: [],
  deliveryTypes: [],
  itemStatuses: [],
  marketTypes: [],
  fuelTypes: [],
  characteristics: [],
  vehicleVariants: [],
  vehicleVariant: null,
  basicCharacteristics: [],
  detailCharacteristics: [],
  brands: [],
  models: [],
  carEquipment: [],
  publishOptions: [],
  offerEnumerations: [],
  allCustomEnums: [],
  returnItemExceptions: [],
  topOptions: [],
};

const categories = createReducer(initialState.categories, {
  [EnumerationsActionTypes.GetCategories]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const allCategories = createReducer(initialState.allCategories, {
  [EnumerationsActionTypes.GetAllCategories]: {
    [RequestActionTypes.SUCCESS]: (_state: CategoryModel[], payload: CategoryModel[]) => payload,
  },
});

const subCategories = createReducer(initialState.subCategories, {
  [EnumerationsActionTypes.GetSubCategories]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const currencies = createReducer(initialState.currencies, {
  [EnumerationsActionTypes.GetCurrencies]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const deliveryTypes = createReducer(initialState.deliveryTypes, {
  [EnumerationsActionTypes.GetDeliveryTypes]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const itemStatuses = createReducer(initialState.itemStatuses, {
  [EnumerationsActionTypes.GetItemStatuses]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload && payload.data ? payload.data : payload,
  },
});

const marketTypes = createReducer(initialState.marketTypes, {
  [EnumerationsActionTypes.GetMarketTypes]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const fuelTypes = createReducer(initialState.fuelTypes, {
  [EnumerationsActionTypes.GetFuelTypes]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const characteristics = createReducer(initialState.characteristics, {
  [EnumerationsActionTypes.GetCarCharacteristics]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const vehicleVariants = createReducer(initialState.vehicleVariants, {
  [EnumerationsActionTypes.GetVehicleVariants]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const vehicleVariant = createReducer(null, {
  [EnumerationsActionTypes.GetVehicleVariant]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const basicCharacteristics = createReducer(initialState.basicCharacteristics, {
  [EnumerationsActionTypes.GetBasicCharacteristics]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const detailCharacteristics = createReducer(initialState.detailCharacteristics, {
  [EnumerationsActionTypes.GetDetailCharacteristics]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const brands = createReducer(initialState.brands, {
  [EnumerationsActionTypes.GetBrands]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const models = createReducer(initialState.models, {
  [EnumerationsActionTypes.GetModels]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const carEquipment = createReducer(initialState.carEquipment, {
  [EnumerationsActionTypes.GetCarEquipment]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload.options,
  },
});

const publishOptions = createReducer(initialState.publishOptions, {
  [EnumerationsActionTypes.GetPublishOptions]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const offerEnumerations = createReducer(initialState.offerEnumerations, {
  [EnumerationsActionTypes.GetOfferEnumerations]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
});

const allCustomEnums = createReducer(initialState.allCustomEnums, {
  [EnumerationsActionTypes.GetAllCustomEnums]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
})

const returnItemExceptions = createReducer(initialState.returnItemExceptions, {
  [EnumerationsActionTypes.GetReturnItemExceptions]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: any) => {
      return [];
    },
  },
})

const topOptions = createReducer(initialState.topOptions, {
  [EnumerationsActionTypes.GetTopOptions]: {
    [RequestActionTypes.SUCCESS]: (_state: TopOption[], payload: TopOption[]) => payload,
    [RequestActionTypes.FAILURE]: (_state: TopOption[]) => [],
  },
});

export default combineReducers<Enumerations>({
  categories,
  allCategories,
  subCategories,
  currencies,
  deliveryTypes,
  itemStatuses,
  marketTypes,
  fuelTypes,
  characteristics,
  vehicleVariants,
  vehicleVariant,
  basicCharacteristics,
  detailCharacteristics,
  brands,
  models,
  carEquipment,
  publishOptions,
  offerEnumerations,
  allCustomEnums,
  returnItemExceptions,
  topOptions,
});

/* SELECTORS */
export const selectEnumState = (state: AppState) => state.enumerations;

export const selectCategories = (state: AppState) => 
  selectEnumState(state).categories;

export const selectAllCategories = (state: AppState): CategoryModel[] => 
  selectEnumState(state).allCategories;

export const selectSubCategories = (state: AppState) => 
  selectEnumState(state).subCategories;

export const selectCurrencies = (state: AppState) => 
  selectEnumState(state).currencies;

export const selectDeliveryTypes = (state: AppState) => 
  selectEnumState(state).deliveryTypes;

export const selectItemStatuses = (state: AppState) => 
  selectEnumState(state).itemStatuses;

export const selectMarketTypes = (state: AppState) => 
  selectEnumState(state).marketTypes;

export const selectFuelTypes = (state: AppState) => 
  selectEnumState(state).fuelTypes;

export const selectCarCharacteristics = (state: AppState) => 
  selectEnumState(state).characteristics;

export const selectVehicleVariants = (state: AppState) => 
  selectEnumState(state).vehicleVariants;

export const selectVehicleVariant = (state: AppState) => 
  selectEnumState(state).vehicleVariant;

export const selectBasicCharacteristics = (state: AppState) => 
  selectEnumState(state).basicCharacteristics;

export const selectDetailCharacteristics = (state: AppState) => 
  selectEnumState(state).detailCharacteristics;

export const selectBrands = (state: AppState) => 
  selectEnumState(state).brands;

export const selectModels = (state: AppState) => 
  selectEnumState(state).models;

export const selectCarEquipment = (state: AppState) => 
  selectEnumState(state).carEquipment;

export const selectPublishOptions = (state: AppState) => 
  selectEnumState(state).publishOptions;

export const selectOfferEnumerations = (state: AppState) =>
  selectEnumState(state).offerEnumerations;

export const selectAllCustomEnums = (state: AppState) =>
  selectEnumState(state).allCustomEnums;

export const selectReturnItemExceptions = (state: AppState) =>
  selectEnumState(state).returnItemExceptions;

export const selectTopOptions = (state: AppState): TopOption[] =>
  selectEnumState(state).topOptions;

/* SAGAS */
function* getCategories() {
  const params = {
    $with: [
      'subCategories'
    ],
    $limit: -1,
    parentId: 'null'
  }

  const resp: ExtendedAxiosResponse = yield call(api.getCategories, params);
  //
  if (resp.ok) {
    yield put(getCategoriesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getCategoriesActions.failure());
  }
}

function* getAllCategories() {
  const params = {
    $with: [
      'subCategories'
    ],
    $limit: -1,
  }

  const resp: ExtendedAxiosResponse = yield call(api.getCategories, params);
  
  if (resp.ok) {
    yield put(getAllCategoriesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getAllCategoriesActions.failure());
  }
}

function* getSubCategories({ payload }: any) {
  const params = {
    parentId: payload,
    $limit: -1
  }

  const resp: ExtendedAxiosResponse = yield call(api.getCategories, params);
  //
  if (resp.ok) {
    yield put(getSubCategoriesActions.success(resp.data));
  } else {
    yield put(getSubCategoriesActions.failure());
  }
}

function* getCurrencies() {
  const resp: ExtendedAxiosResponse = yield call(api.getCurrencies);
  //
  if (resp.ok) {
    yield put(getCurrenciesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getCurrenciesActions.failure());
  }
}

function* getDeliveryTypes() {
  const resp: ExtendedAxiosResponse = yield call(api.getDeliveryTypes);
  //
  if (resp.ok) {
    yield put(getDeliveryTypesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getDeliveryTypesActions.failure());
  }
}

function* getItemStatuses({ payload }: any) {
  const params = {
    '$limit': -1,
    ...payload
  };

  const resp: ExtendedAxiosResponse = yield call(api.getItemStatuses, params);
  //
  if (resp.ok) {
    yield put(getItemStatusesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getItemStatusesActions.failure());
  }
}

function* getMarketTypes() {
  const resp: ExtendedAxiosResponse = yield call(api.getMarketTypes);
  //
  if (resp.ok) {
    yield put(getMarketTypesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getMarketTypesActions.failure());
  }
}

function* getFuelTypes() {
  const resp: ExtendedAxiosResponse = yield call(api.getFuelTypes);
  //
  if (resp.ok) {
    yield put(getFuelTypesActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getFuelTypesActions.failure());
  }
}

function* getCarCharacteristics() {
  const resp: ExtendedAxiosResponse = yield call(api.getCarCharacteristics);
  //
  if (resp.ok) {
    yield put(getCarCharacteristicsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getCarCharacteristicsActions.failure());
  }
}


function* getVehicleVariants({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getVehicleVariants, payload);
  //
  if (resp.ok) {
    yield put(getVehicleVariantsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getVehicleVariantsActions.failure());
  }
}

function* getVehicleVariant({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getVehicleVariant, payload);
  //
  if (resp.ok) {
    yield put(getVehicleVariantActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getVehicleVariantActions.failure());
  }
}

function* getBasicCharacteristics() {
  const resp: ExtendedAxiosResponse = yield call(api.getCarBasicCharacteristics);
  //
  if (resp.ok) {
    yield put(getBasicCharacteristicsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getBasicCharacteristicsActions.failure());
  }
}

function* getDetailCharacteristics() {
  const resp: ExtendedAxiosResponse = yield call(api.getCarDetailCharacteristics);
  //
  if (resp.ok) {
    yield put(getDetailCharacteristicsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getDetailCharacteristicsActions.failure());
  }
}

function* getBrands({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getBrands, payload);
  //
  if (resp.ok) {
    yield put(getBrandsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getBrandsActions.failure());
  }
}

function* getModels({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getModels, payload);
  //
  if (resp.ok) {
    yield put(getModelsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getModelsActions.failure());
  }
}

function* getCarEquipment({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getCarEquipment, payload);
  //
  if (resp.ok) {
    yield put(getCarEquipmentActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getCarEquipmentActions.failure());
  }
}

function* getPublishOptions({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getPublishOptions, {
    '$limit': -1,
    ...payload
  });
  //
  if (resp.ok) {
    yield put(getPublishOptionsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getItemSuccess" }, { data: resp.data }]));
  } else {
    yield put(getPublishOptionsActions.failure());
  }
}

function* getOfferEnumerations({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOfferEnumerations, payload.id);

  if (resp.ok) {
    yield put(getOfferEnumerationsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getOfferEnumerationsSuccess" }, { data: resp.data }]));
  } else {
    yield put(getOfferEnumerationsActions.failure());
  }
}

function* getAllCustomEnums({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getAllCustomEnums, payload?.categoryId);

  if (resp.ok) {
    yield put(getAllCustomEnumsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getAllCustomEnumsSuccess" }, { data: resp.data }]));
  } else {
    yield put(getAllCustomEnumsActions.failure());
  }
}

function* getReturnItemExceptions({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getReturnItemExceptions, payload);

  if (resp.ok) {
    yield put(getReturnItemExceptionsActions.success(resp.data));
    yield put(successResponseActions([{ message: "getReturnItemExceptionsSuccess" }, { data: resp.data }]));
  } else {
    yield put(getReturnItemExceptionsActions.failure());
  }
}

function* getTopOptions({ payload }: any) {
  const params = {
    '$limit': -1,
    ...payload
  }

  const resp: ExtendedAxiosResponse = yield call(api.getTopOptions, params);

  if (resp.ok) {
    yield put(getTopOptionsActions.success(resp.data as TopOption[]));
    yield put(successResponseActions([{ message: "getTopOptionsSuccess" }, { data: resp.data }]));
  } else {
    yield put(getTopOptionsActions.failure());
  }
}

/* EXPORT */
export function* enumSaga() {
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetCategories, RequestActionTypes.REQUEST),
    getCategories
  );
  yield takeLeading(
    createActionType(EnumerationsActionTypes.GetAllCategories, RequestActionTypes.REQUEST),
    getAllCategories
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetSubCategories, RequestActionTypes.REQUEST),
    getSubCategories
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetCurrencies, RequestActionTypes.REQUEST),
    getCurrencies
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetDeliveryTypes, RequestActionTypes.REQUEST),
    getDeliveryTypes
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetItemStatuses, RequestActionTypes.REQUEST),
    getItemStatuses
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetMarketTypes, RequestActionTypes.REQUEST),
    getMarketTypes
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetFuelTypes, RequestActionTypes.REQUEST),
    getFuelTypes
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetCarCharacteristics, RequestActionTypes.REQUEST),
    getCarCharacteristics
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetVehicleVariants, RequestActionTypes.REQUEST),
    getVehicleVariants
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetVehicleVariant, RequestActionTypes.REQUEST),
    getVehicleVariant
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetBasicCharacteristics, RequestActionTypes.REQUEST),
    getBasicCharacteristics
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetDetailCharacteristics, RequestActionTypes.REQUEST),
    getDetailCharacteristics
  );  
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetBrands, RequestActionTypes.REQUEST),
    getBrands
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetModels, RequestActionTypes.REQUEST),
    getModels
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetCarEquipment, RequestActionTypes.REQUEST),
    getCarEquipment
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetPublishOptions, RequestActionTypes.REQUEST),
    getPublishOptions
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetOfferEnumerations, RequestActionTypes.REQUEST),
    getOfferEnumerations
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetAllCustomEnums, RequestActionTypes.REQUEST),
    getAllCustomEnums
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetReturnItemExceptions, RequestActionTypes.REQUEST),
    getReturnItemExceptions
  );
  yield takeLatest(
    createActionType(EnumerationsActionTypes.GetTopOptions, RequestActionTypes.REQUEST),
    getTopOptions
  );
}
