import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { connect } from 'react-redux';
import {
  loginActions,
  logoutActions,
  confirmEmailActions,
  mergeShoppingCartActions,
  selectIsLogged,
} from 'saga/user/ducks';
import {
  selectSuccessResponse
} from 'saga/response/ducks';
import { AppState } from 'helpers/store/models/AppState';
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import OauthButtons from '../../components/OauthButtons/OauthButtons';
import { Link } from "react-router-dom";
import { Button, TextField, Grid, FormControlLabel } from "@material-ui/core";
import Container from "../../components/Container/Container";
import StyledCheckbox from "../../components/Checkbox/Checkbox";
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { StaticContext, RouteComponentProps } from 'react-router';
import { history } from 'helpers/store/root-reducer';
import { getHelpdeskUrl } from 'helpers/other/getHelpdeskUrl';
import Icon from "@material-ui/core/Icon";
import { 
  emailConfirmSuccessToastActions, errorToastActions
} from 'saga/toast/ducks';
import { store } from 'helpers/store/configure-store';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RecaptchaV3 from 'views/components/Recaptcha/RecaptchaV3';
import { environment as env } from 'environments/environment';
import { Helmet } from "react-helmet";

const arrowForward = require('../../../assets/arrow-forward-white.svg');
const plus = require('../../../assets/plus.svg');

interface LoginForm {
  email: string;
  password: string;
  recaptchaResponseToken: string;
}

const Login = (props: RouteComponentProps<{}, StaticContext, { from: { pathname: string; search: string; }; }> & DispatchToProps & StateToProps) => {
  const formRules = yup
    .object()
    .shape({
      email: yup
        .string()
        .email(i18n.t('Validation.Email'))
        .lowercase()
        .required(i18n.t('Validation.Required')),
      password: yup
        .string()
        .required(i18n.t('Validation.Required')),
    });
  const { register, handleSubmit, getValues, formState: { errors }, control } = useForm<LoginForm>({
    mode: "onChange",
    resolver: yupResolver(formRules),
  });
  const { t } = useTranslation();
  const confirmToken = props.confirm;
  const [loginRecaptchaToken, setLoginRecaptchaToken] = useState<string | null>(null);
  const loginRecaptchaRef = useRef<any>();
  const activationToken = new URLSearchParams(window.location.search).get("token");
  const { successMessage, location } = props;

  useEffect(() => {
    if (props.authenticated) {
      const cart = JSON.parse(localStorage.getItem('shoppingCart') || '[]');
      if (cart.length) {
        const data = cart.map((item: any) => ({
          quantity: item.quantity,
          offerId: item.details.id,
          checked: item.checked
        }));
        props.mergeShoppingCart(data);
      }
      const { from } = location.state || { from: { pathname: '/' } };
      if (from.pathname) {
        history.push({
          pathname: from.pathname,
          search: from.search
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authenticated, location.state]);

  useEffect(() => {
    if (activationToken) {
      confirmToken(activationToken);
    }
  }, [activationToken, confirmToken]);

  useEffect(() => {
    if (successMessage && successMessage === 'Company email confirmed!') {
      store.dispatch(emailConfirmSuccessToastActions(i18n.t('Login.CompanyVerified')));
    }
    if (successMessage && successMessage[0] && successMessage[0].message === 'shoppingCartMerged') {
      localStorage.removeItem('shoppingCart');
    }
  }, [successMessage]);
// eslint-disable-next-line react-hooks/exhaustive-deps

  const onSubmit = (data: LoginForm) => {
    // execute recaptcha verification if reference exists
    if (loginRecaptchaRef?.current) {
      loginRecaptchaRef.current.execute();
    }
  };

  // submit form, executed on recapcha verifyCallback execution, so on function 'handleRecaptchaVerificationResponse'
  const submitForm = (data: LoginForm, recaptchaResponseToken: string) => {
    props.login({
      ...data,
      recaptchaResponseToken
    });
  }

  const handleRecaptchaVerificationResponse = (response: string) => {
    setLoginRecaptchaToken(response);
  }

  const handleRecaptchaExpiredCallback = () => {
    props.showErrorToast(t('Errors.RecaptchaVerificationFailed'));
    setLoginRecaptchaToken(null);
  }

  const handleLoginRecaptchaTokenChange = () => {
    if (loginRecaptchaToken !== null) {
      const token = loginRecaptchaToken;
      setLoginRecaptchaToken(null);
      submitForm(getValues(), token);
    }
  }

  useEffect(handleLoginRecaptchaTokenChange, [loginRecaptchaToken]);

  return (
    <Container body>
      <Helmet>
        <title lang={i18n.language}>{t("Routes.Title.Login")}</title>
        <meta property="og:title" content={t("Routes.Title.Login")} />
        <meta property="og:url" content={`${env.metaUrl}${t('Routes.Login')}`} />
        <meta property="og:description" content={t("Routes.Description.Login")} />
        <meta name="description" content={t("Routes.Description.Login")} />
      </Helmet>
      <Grid container justifyContent="center" alignItems="center" direction={"column"} className={"pb-20"}>

        <Grid container item xs={12} sm={10} md={6} lg={5} justifyContent="center" className={'loginHolder'}>
          <RecaptchaV3
            ref={loginRecaptchaRef}
            elementID={'login-recaptcha'}
            className={'recaptcha'}
            verifyCallback={handleRecaptchaVerificationResponse}
            expiredCallback={handleRecaptchaExpiredCallback}
          />

          <Grid container item xs={12} justifyContent="flex-start">
            <h2>
              {t('Login.Login')}
            </h2>
          </Grid>
          <Grid container item xs={12} sm={8} md={8} lg={8}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid item xs={12}>
                <TextField
                  label={t('Login.LabelLogin')}
                  type="email"
                  fullWidth
                  autoComplete="off"
                  {...register('email')}
                  error={errors.email?.message ? true : false}
                  variant={"outlined"}
                />
                <p className="validation">
                  {errors.email && errors.email.message}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue={''}
                  render={({ field: { value, onChange }, fieldState: {invalid} }) => (
                    <PasswordInput
                      autoComplete="off"
                      value={value}
                      handleChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                      label={t('Login.LabelPassword')}
                      error={invalid}
                    />
                  )}
                />
                <p className="validation">
                  {errors.password && errors.password.message}
                </p>
              </Grid>
              <Grid container item xs={12} alignItems="center" className={'buttonsHolder'}>
                <Grid item xs={6} className={'text-left link'}>
                  <Link
                    to={location => ({
                      ...location,
                      pathname: t('Routes.PasswordReset')
                    })
                    }
                    className={'customLink'}>{t('Login.ForgetPassword')}</Link>
                  <FormControlLabel
                    control={
                      <StyledCheckbox name="checked" />
                    }
                    label={t('Login.SaveLogin')}
                    className={'savePassword'}
                  />
                </Grid>
                <Grid item xs={6} className={'text-right button'}>
                  <Button variant="contained" type="submit">
                    {t('Login.LoginButton')}
                    <img src={arrowForward} className={'keyIcon'} alt=''/>
                  </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2} alignItems="center" className={'lineHolder'}>
                <Grid item xs className={"border"}>
                </Grid>
                <Grid item xs={"auto"}>
                  <h6 className='text-center'>{t('Login.NoAccount')}</h6>
                </Grid>
                <Grid item xs className={"border"}>
                </Grid>
              </Grid>
              <Link
                to={location => ({
                  ...location,
                  pathname: t('Routes.Register')
                })
                }
                className={'link'}>
                <h5 className="onlyDesktop">{t('Login.Register')}</h5>
              </Link>
              <div className='onlyDesktop'>
                <OauthButtons />
              </div>
            </form>
          </Grid>
          <Grid item xs={12} className={"mobile registerButtonMobile"}>
            <Grid container item xs={12} spacing={2} alignItems="center" className={'lineHolder'}>
              <Grid item xs className={"border"}>
              </Grid>
              <Grid item xs={"auto"}>
                <h6 className='text-center'>{t('Login.NoAccount')}</h6>
              </Grid>
              <Grid item xs className={"border"}>
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              startIcon={<Icon><img src={plus} alt=''/></Icon>}
              endIcon={<NavigateNextIcon/>}
              component={Link}
              to={t('Routes.Register')}
            >
              {t('PasswordReset.Registration')}
            </Button>
            <OauthButtons />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={10} md={6} lg={5} justifyContent="center" className={'textHolder'}>
          <h6>
            <Trans
                i18nKey="Login.Agreement"
                components={{
                  a1: <a target="_blank" rel="noopener noreferrer" href={getHelpdeskUrl(t("HelpdeskUrlConfigName.TermsConditions"))} className="link"> </a>,
                  a2: <a target="_blank" rel="noopener noreferrer" href={getHelpdeskUrl(t("HelpdeskUrlConfigName.PrivacyPolicy"))} className="link"> </a>
                }}
              />
          </h6>
          <h6>
            <Trans
              i18nKey="Login.RecaptchaPolicies"
              components={{
                a1: <a target="_blank" rel="noopener noreferrer" href={env.googlePrivacyUrl} className="link"> </a>,
                a2: <a target="_blank" rel="noopener noreferrer" href={env.googleTermsUrl} className="link"> </a>
              }}
            />
          </h6>
        </Grid>
      </Grid>
    </Container>
  );
};

interface StateToProps {
  authenticated: boolean;
  successMessage: any;
}

interface DispatchToProps {
  login: (payload: LoginForm) => void;
  logout: () => void;
  confirm: (payload: string) => void;
  mergeShoppingCart: (payload: string) => void;
  showErrorToast: (payload: string) => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  authenticated: selectIsLogged(state),
  successMessage: selectSuccessResponse(state),
});

const mapDispatchToProps: DispatchToProps = {
  login: loginActions.request,
  logout: logoutActions.request,
  confirm: confirmEmailActions.request,
  mergeShoppingCart: mergeShoppingCartActions.request,
  showErrorToast: errorToastActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
