import ReactPixel from 'react-facebook-pixel';
import { getAnalyticsCookieConsentStateFromStorage } from "./cookies";
import { environment as env } from 'environments/environment';

export const initFB = () => {
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  //@ts-ignore
  ReactPixel.init(env.facebookPixelCode, {}, options);
  ReactPixel.grantConsent();
  ReactPixel.pageView();
};

export const sendFBStandardEvent = (eventLabel: string) => {
  if (getAnalyticsCookieConsentStateFromStorage()) {
    ReactPixel.track(eventLabel);
  }
};

export const sendFBCustomEvent = (eventLabel: string) => {
  if (getAnalyticsCookieConsentStateFromStorage()) {
    ReactPixel.trackCustom(eventLabel);
  }
};
