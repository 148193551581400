import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router';
import { Provider } from 'react-redux';

import { persistor, store } from 'helpers/store/configure-store';
import { history } from 'helpers/store/root-reducer';
import { PersistGate } from 'redux-persist/integration/react';
import routes from 'helpers/routes';
import Header from './views/components/Header/Header';
import Footer from './views/components/Footer/Footer';
import CookiesBar from './views/components/CookiesBar/CookiesBar';
import NetworkDetector from './views/components/NetworkDetector/NetworkDetector';
import { Shadows } from "@material-ui/core/styles/shadows"
import {
  createTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import i18n from './i18n';
import 'App.scss';
import { useTranslation } from "react-i18next";
import ToastMessages from './views/components/ToastMessages/ToastMessages';
import { ProtectedRoute } from './views/components/ProtectedRoute/ProtectedRoute';
import { SnackbarProvider } from 'notistack';
import ScrollToTop from 'helpers/other/scrollToTop';
import { Helmet } from "react-helmet";
import qs from 'qs';
import Swal from 'sweetalert2';
import { environment as env } from './environments/environment';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { getUserActions } from './saga/user/ducks';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const logoSk = require(`./assets/logos/sk/logo-blackwhite.svg`);
const logoCz = require(`./assets/logos/cz/logo-blackwhite.svg`);

LogRocket.init('jjvts4/predamti');
setupLogRocketReact(LogRocket);

function loading() {
  return (
    <div className="loader">
      <div className="loader__progress-bar" />
    </div>
  );
}

const theme = createTheme({
  shadows: Array(25).fill("none") as Shadows,
  typography: {
    fontFamily: 'OpenSans, sans-serif',
    button: {
      textTransform: "none"
    }
  },
  palette: {
    primary: {
      main: "#FF8080",
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1200,
      xl: 1920
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true  // Should prevent need to double click on some elements/links
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#767676',
          '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#767676',
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#767676'
        }
      }
    },
  },
});

function App() {
  const { t } = useTranslation();

  const getReauthLinkWithoutParam = () => {
    const reauthPageRoute = t('Routes.AccountLinkReAuth', { reAuthLinkParam: ':refreshing?' });
    const arr = reauthPageRoute.split('/');
    return `${arr[0]}/${arr[1]}/${arr[2]}`;
  };

  const reauthLink = getReauthLinkWithoutParam();
  const isReauthPageShown = history.location.pathname.includes(reauthLink);

  history.listen((newLocation, action) => {
    // when navigate using browser interaction and history includes popup location, it will be replaced
    if (Swal && !Swal.isVisible() && newLocation?.search?.includes('popUp=true') && action === 'POP') {
      const params = {
        ...(newLocation.search !== '' ? JSON.parse(JSON.stringify(qs.parse(newLocation.search.slice(1)))) : {}),
      }
      delete params.popUp;

      history.replace({
        search: qs.stringify(params, { addQueryPrefix: true }),
      });
    } else if (Swal && Swal.isVisible() && !newLocation?.search?.includes('popUp=true') && action === 'POP') {
      // close popUp when navigate back using browser navigation (or programatically)
      try {
        Swal.close();
      } catch (e) {
        console.log(e);
      }
    }
  });

  const getLocale = () => {
    switch (i18n.language) {
      case 'sk': return "sk_SK";
      case 'cs': return "cs_CZ";
      default: return "en_GB";
    }
  };

  useEffect(() => {
    store.dispatch(getUserActions.request());
  }, [])

  const logo = env.topLevelDomain === 'cz' ? logoCz : logoSk;

  return (
    <Provider store={store}>
      <PersistGate loading={<div />} persistor={persistor}>
        <ConnectedRouter history={history}>
          {!isReauthPageShown && <Header />}
          <React.Suspense fallback={loading()}>
            <Helmet
              defaultTitle={env.appUrl}
              titleTemplate={`%s | ${env.appUrl}`}
            >
              <meta charSet="utf-8" />
              <meta name="rating" content="General" />
              <meta property="og:title" content={env.appUrl} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content={getLocale()} />
              <meta property="og:url" content={env.metaUrl} />
              <meta property="og:image" content={`${env.metaUrl}${logo}`} />
              <meta property="og:description" content={t("Routes.Description.General")} />
              <meta name="description" content={t("Routes.Description.General")} />
            </Helmet>
            <SnackbarProvider maxSnack={5}>
              <ToastMessages />
            </SnackbarProvider>
            <MuiThemeProvider theme={theme}>
              <ScrollToTop />
              <Switch>
                {routes.map((route, i) => {
                  let path = t(route.path);
                  if (route.path === 'Routes.AccountActivation') {
                    path = t(route.path, { activationRouteParam: ':userId?' });
                  } else if (route.path === 'Routes.AccountLinkReAuth') {
                    path = t(route.path, { reAuthLinkParam: ':refreshing?' });
                  } else if (route.path === 'Routes.ProductDetail' || route.path === 'Routes.ProductDetailAutomoto') {
                    path = t(route.path, { offerSlug: ':offerSlug' });
                  } else if (route.path === 'Routes.OrderDetailPage' || route.path === 'Routes.ReceivedOrderDetailPage' || route.path === 'Routes.NewReturnRequest') {
                    path = t(route.path, { orderId: ':orderId' });
                  } else if (route.path === 'Routes.OrderAddReview') {
                    path = t(route.path, { orderId: ':orderId' });
                  } else if (route.path === 'Routes.OrderReviewThank') {
                    path = t(route.path, { orderId: ':orderId' });
                  } else if (route.path === 'Routes.Store' || route.path === 'Routes.UserRatingPage') {
                    path = t(route.path, { userSlug: ':userSlug' });
                  } else if (route.path === 'Routes.MyRefundRequestDetailPage' || route.path === 'Routes.ReceivedRefundRequestDetailPage') {
                    path = t(route.path, { requestId: ':requestId' });
                  } else if (route.path === 'Routes.ChatDetail') {
                    path = t(route.path, { conversationId: ':conversationId' });
                  } else if (route.path === 'Routes.ChatNewMessage') {
                    path = t(route.path, { token: ':token' });
                  } else if (route.path === 'Routes.AccountMyOffersProlong') {
                    path = t(route.path, { token: ':token' });
                  }  else if (route.path === '*') {
                    return <Redirect key={i} to={t('Routes.LandingPage')} />;
                  }

                  return (
                    route.protected ?
                      <ProtectedRoute
                        key={i + i18n.language}
                        exact={route.exact}
                        path={path}
                        component={route.component}
                      />
                      :
                      <Route
                        key={i + i18n.language}
                        exact={route.exact}
                        path={path}
                        render={(props) => <route.component {...props} />}
                      />
                  )
                })}
              </Switch>
            </MuiThemeProvider>
          </React.Suspense>
          <CookiesBar />
          {!isReauthPageShown && <Footer />}
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

const AppComponent = NetworkDetector(App);

const rollbarConfig = {
  accessToken: env.rollbar.accessToken,
  enabled: !!env.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: env.rollbar.environment,
    client: {
      javascript: {
        code_version: env.rollbar.version,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

export default () => {
  const FallbackUI = () => <div>... máme menší problém, skús to neskôr ...</div>;

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={FallbackUI}>
        <AppComponent/>
      </ErrorBoundary>
    </RollbarProvider>
  )
};
