import { combineReducers } from 'redux';

import { AppState } from '../../helpers/store/models/AppState';
import {
  createActionCreator,
  createReducer
} from '../../helpers/redux/redux-helpers';
import {TableState} from 'model/TableModel';

/* STATE */
export interface TablesState {
  myOffers: TableState;
  myOrders: TableState;
}

/* ACTION TYPES */
export enum LayoutActionTypes {
  SetMyOffersLimit = '@@Layout/SET_MY_OFFERS_LIMIT',
  SetMyOffersSkip = '@@Layout/SET_MY_OFFERS_SKIP',
  SetMyOrdersLimit = '@@Layout/SET_MY_ORDERS_LIMIT',
  SetMyOrdersSkip = '@@Layout/SET_MY_ORDERS_SKIP',
}

/* ACTIONS */
export const setMyOffersLimitActions = createActionCreator(LayoutActionTypes.SetMyOffersLimit);
export const setMyOffersSkipActions = createActionCreator(LayoutActionTypes.SetMyOffersSkip);
export const setMyOrdersLimitActions = createActionCreator(LayoutActionTypes.SetMyOrdersLimit);
export const setMyOrdersSkipActions = createActionCreator(LayoutActionTypes.SetMyOrdersSkip);

/* REDUCERS */
const initialState: TablesState = {
  myOffers: {
    limit: 10,
    skip: 0,
  },
  myOrders: {
    limit: 10,
    skip: 0,
  },
};

const myOffers = createReducer(initialState.myOffers, {
  [LayoutActionTypes.SetMyOffersLimit]: (state: TableState, payload: number) => {
    return {...state, limit: payload};
  },
  [LayoutActionTypes.SetMyOffersSkip]: (state: TableState, payload: number) => {
    return {...state, skip: payload};
  }
});

const myOrders = createReducer(initialState.myOrders, {
  [LayoutActionTypes.SetMyOrdersLimit]: (state: TableState, payload: number) => {
    return {...state, limit: payload};
  },
  [LayoutActionTypes.SetMyOrdersSkip]: (state: TableState, payload: number) => {
    return {...state, skip: payload};
  }
});

export default combineReducers<TablesState>({
  myOffers,
  myOrders,
});

/* SELECTORS */
export const selectTablesState = (state: AppState) => state.tables;
export const selectMyOffersTable = (state: AppState) => selectTablesState(state).myOffers;
export const selectMyOrdersTable = (state: AppState) => selectTablesState(state).myOrders;


/* SAGAS */

/* EXPORT */
export function* tablesSaga() {}
