import { combineReducers } from 'redux';

import { AppState } from '../../helpers/store/models/AppState';
import { ErrorResponse } from '../../types/Error';
import {
  createActionCreator,
  createReducer,
} from '../../helpers/redux/redux-helpers';

/* STATE */
export interface ResponseState {
  success: string;
  warn: string;
  error: ErrorResponse;
}

/* ACTION TYPES */
export enum ResponseActionTypes {
  SuccessResponse = '@@Response/SUCCESS',
  WarnResponse = '@@Response/WARN',
  ErrorResponse = '@@Response/ERROR',
}

/* ACTIONS */
export const successResponseActions = createActionCreator(
  ResponseActionTypes.SuccessResponse,
);

export const warnResponseActions = createActionCreator(ResponseActionTypes.WarnResponse);

export const errorResponseActions = createActionCreator(
  ResponseActionTypes.ErrorResponse,
);

/* REDUCERS */
const initialState: ResponseState = {
  success: '',
  warn: '',
  error: {
    className: '',
    code: 0,
    data: null,
    errors: [],
    message: '',
    name: ''
  },
};

const success = createReducer(initialState.success, {
  [ResponseActionTypes.SuccessResponse]: (state: string, payload: string) => payload,
});

const warn = createReducer(initialState.warn, {
  [ResponseActionTypes.WarnResponse]: (state: string, payload: string) => payload,
});

const error = createReducer(initialState.error, {
  [ResponseActionTypes.SuccessResponse]: (state: string) => null,
  [ResponseActionTypes.ErrorResponse]: (state: string, payload: ErrorResponse) => payload,
});

export default combineReducers<ResponseState>({
  success,
  warn,
  error,
});

/* SELECTORS */
export const selectResponseState = (state: AppState) => state.response;

export const selectSuccessResponse = (state: AppState) =>
  selectResponseState(state).success;

export const selectWarnResponse = (state: AppState) =>
  selectResponseState(state).warn;

export const selectErrorResponse = (state: AppState) =>
  selectResponseState(state).error;

export const selectErrorDetail = (state: AppState) =>
  selectErrorResponse(state) && selectErrorResponse(state).errors[0];

export const selectErrorMessage = (state: AppState) => {
  if (selectErrorDetail(state)) {
    return selectErrorResponse(state) && selectErrorDetail(state).message;
  }
  else {
    return selectErrorResponse(state) && selectErrorResponse(state).message;
  }
}

/* SAGAS */

/* EXPORT */
export function* responseSaga() {}
