import { Price } from './Price';


export enum PaymentStatus {
  created = 'CREATED',
  actionRequired = 'ACTION_REQUIRED',
  success = 'SUCCESS',
  partiallyRefunded = 'PARTIALLY_REFUNDED',
  refunded = 'REFUNDED',
  refundPending = 'REFUND_PENDING',
  error = 'ERROR',
}

interface PaymentResultError {
  type: string;
  code: string;
  declineCode: string;
}

export interface PaymentResult {
  paymentId: string;
  status: PaymentStatus;
  clientSecret: string | null;
  paymentError?: PaymentResultError;
}

export interface PaymentRequiredResponse {
  credit: {
    required: number,
    available: number,
    canBeUsed: false
  }
  items: PaymentRequiredItem[],
  price: Price,
  message?: string,
  payment?: PaymentResult,
}

export interface PaymentRequiredItem {
  name: string,
  price: Price
}
