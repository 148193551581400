import { call, put, takeLatest, all } from 'redux-saga/effects';
import { api } from './api';
import { AppState } from '../../helpers/store/models/AppState';
import { UserActionTypes } from '../user/ducks';
import { errorResponseActions, successResponseActions } from '../response/ducks';
import { successToastActions } from '../toast/ducks';
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  LoadingState,
  createLoadingStateReducer,
} from '../../helpers/redux/redux-helpers';
import { combineReducers } from 'redux';
import { CompleteOrder, Order, OrderRefundsList, OrdersList, OrderRefund, OrderStatusChangeLogPaginated } from 'model/Order';
import i18n from 'i18n';
import { push } from 'connected-react-router';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

export interface OrderState {
  order: CompleteOrder | null;
  orderStatusChangeLog: OrderStatusChangeLogPaginated | null;
  orders: OrdersList;
  soldOrdersCount: number;
  changeRequestState: LoadingState;
  ordersRefundsRequests: OrderRefundsList;
  ordersRefundsCount: number;
  orderRefundRequest: OrderRefund | null;
  getRefundRequestLoadingState: LoadingState;
  getOrderStatusChangeLogRequestState: LoadingState;
}

/* ACTION TYPES */
export enum OrderActionsTypes {
  PostOrder = "@@Order/POST_ORDER",
  GetOrders = "@@Order/GET_ORDERS",
  GetOrderDetail = "@@Order/GET_ORDER_DETAIL",
  GetSoldOrders = "@@Order/GET_SOLD_ORDERS",
  GetSoldOrderDetail = "@@Order/GET_SOLD_ORDER_DETAIL",
  GetSoldOrdersCount = "@@Order/GET_SOLD_ORDERS_COUNT",
  ChangeSoldOrderStatus = "@@Order/CHANGE_SOLD_ORDER_STATUS",
  StornoOrder = "@@Order/STORNO_ORDER",
  ConfirmOrderReceived = "@@Order/CONFIRM_ORDER_RECEIVED",
  PostOrderRefundRequest = "@@Order/POST_ORDER_REFUND_REQUEST",
  GetMyOrdersRefundsRequests = "@@Order/GET_MY_ORDER_REFUNDS_REQUESTS",
  GetReceivedOrdersRefundsRequests = "@@Order/GET_RECEIVED_ORDER_REFUNDS_REQUESTS",
  GetOrdersRefundsCount = "@@Order/GET_ORDERS_REFUNDS_COUNT",
  GetMyOrderRefundRequestDetail = "@@Order/GET_MY_ORDER_REFUND_REQUEST_DETAIL",
  GetReceivedOrderRefundRequestDetail = "@@Order/GET_RECEIVED_ORDER_REFUND_REQUEST_DETAIL",
  AcceptOrderRefundRequest = "@@Order/ACCEPT_ORDER_REFUND_REQUEST",
  RejectOrderRefundRequest = "@@Order/REJECT_ORDER_REFUND_REQUEST",
  GetOrderStatusChangeLog = "@@Order/GET_ORDER_STATUS_CHANGE_LOG",
}

/* ACTIONS */
export const getOrdersActions = createApiActionCreators(OrderActionsTypes.GetOrders);
export const getSoldOrdersActions = createApiActionCreators(OrderActionsTypes.GetSoldOrders);
export const getOrderDetailActions = createApiActionCreators(OrderActionsTypes.GetOrderDetail);
export const getSoldOrderDetailActions = createApiActionCreators(OrderActionsTypes.GetSoldOrderDetail);
export const postOrderActions = createApiActionCreators(OrderActionsTypes.PostOrder);
export const getSoldOrdersCountActions = createApiActionCreators(OrderActionsTypes.GetSoldOrdersCount);
export const changeSoldOrderStatusActions = createApiActionCreators(OrderActionsTypes.ChangeSoldOrderStatus);
export const stornoOrderActions = createApiActionCreators(OrderActionsTypes.StornoOrder);
export const confirmOrderReceivedActions = createApiActionCreators(OrderActionsTypes.ConfirmOrderReceived);
export const postOrderRefundRequestActions = createApiActionCreators(OrderActionsTypes.PostOrderRefundRequest);
export const getMyOrdersRefundsRequestsActions = createApiActionCreators(OrderActionsTypes.GetMyOrdersRefundsRequests);
export const getReceivedOrdersRefundsRequestsActions = createApiActionCreators(OrderActionsTypes.GetReceivedOrdersRefundsRequests);
export const getOrdersRefundsCountActions = createApiActionCreators(OrderActionsTypes.GetOrdersRefundsCount);
export const getMyOrderRefundRequestDetailActions = createApiActionCreators(OrderActionsTypes.GetMyOrderRefundRequestDetail);
export const getReceivedOrderRefundRequestDetailActions = createApiActionCreators(OrderActionsTypes.GetReceivedOrderRefundRequestDetail);
export const acceptOrderRefundRequestActions = createApiActionCreators(OrderActionsTypes.AcceptOrderRefundRequest);
export const rejectOrderRefundRequestActions = createApiActionCreators(OrderActionsTypes.RejectOrderRefundRequest);
export const getOrderStatusChangeLogActions = createApiActionCreators(OrderActionsTypes.GetOrderStatusChangeLog);

/* REDUCERS */
const initialState: OrderState = {
  order: null,
  orderStatusChangeLog: null,
  orders: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0
  },
  soldOrdersCount: 0,
  changeRequestState: LoadingState.success,
  ordersRefundsRequests: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0
  },
  ordersRefundsCount: 0,
  orderRefundRequest: null,
  getRefundRequestLoadingState: LoadingState.success,
  getOrderStatusChangeLogRequestState: LoadingState.success,
}

const order = createReducer(initialState.order, {
  [OrderActionsTypes.PostOrder]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null, payload: CompleteOrder) => payload,
  },
  [OrderActionsTypes.GetOrderDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null, payload: CompleteOrder) => payload,
  },
  [OrderActionsTypes.GetSoldOrderDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null, payload: CompleteOrder) => payload,
    [RequestActionTypes.FAILURE]: (_state: CompleteOrder | null) => null,
  },
  [OrderActionsTypes.GetOrders]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null) => null,
  },
  [OrderActionsTypes.GetSoldOrders]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null) => null,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null) => null,
  },
  [OrderActionsTypes.ChangeSoldOrderStatus]: {
    [RequestActionTypes.SUCCESS]: (_state:  CompleteOrder | null, payload: CompleteOrder) => {
      return ({
        ..._state,
        status: payload.status
      })
    }
  },
  [OrderActionsTypes.PostOrderRefundRequest]: {
    [RequestActionTypes.SUCCESS]: (_state: CompleteOrder | null) => null,
  },
});

const orders = createReducer(initialState.orders, {
  [OrderActionsTypes.GetOrders]: {
    [RequestActionTypes.SUCCESS]: (_state: OrdersList, payload: OrdersList) => payload,
  },
  [OrderActionsTypes.GetSoldOrders]: {
    [RequestActionTypes.SUCCESS]: (_state: OrdersList, payload: OrdersList) => payload,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: OrdersList) => {
      return {
        data: [],
        limit: 0,
        skip: 0,
        total: 0
      };
    }
  },
  [OrderActionsTypes.ChangeSoldOrderStatus]: {
    [RequestActionTypes.SUCCESS]: (_state: OrdersList, payload: CompleteOrder) => {
      if (_state.data && _state.data.length !== 0) {
        const currentData = _state.data.map((order: Order) => {
          return order.id !== payload.id ? order : { ...order, status: payload.status }
        });
        return {
          ..._state,
          data: currentData,
        }
      } else {
        return {
          data: [],
          limit: 0,
          skip: 0,
          total: 0
        };
      }
    }
  },
  [OrderActionsTypes.StornoOrder]: {
    [RequestActionTypes.SUCCESS]: (_state: OrdersList, payload: CompleteOrder) => {
      if (_state.data && _state.data.length !== 0) {
        const currentData = _state.data.map((order: Order) => {
          return order.id !== payload.id ? order : { ...order, status: payload.status }
        });
        return {
          ..._state,
          data: currentData,
        }
      } else {
        return {
          data: [],
          limit: 0,
          skip: 0,
          total: 0
        };
      }
    }
  },
  [OrderActionsTypes.ConfirmOrderReceived]: {
    [RequestActionTypes.SUCCESS]: (_state: OrdersList, payload: CompleteOrder) => {
      if (_state.data && _state.data.length !== 0) {
        const currentData = _state.data.map((order: Order) => {
          return order.id !== payload.id ? order : { ...order, status: payload.status }
        });
        return {
          ..._state,
          data: currentData,
        }
      } else {
        return {
          data: [],
          limit: 0,
          skip: 0,
          total: 0
        };
      }
    }
  }
});

const soldOrdersCount = createReducer(initialState.soldOrdersCount, {
  [OrderActionsTypes.GetSoldOrdersCount]: {
    [RequestActionTypes.SUCCESS]: (_state: number, payload: OrdersList) => {
      return payload.total
    },
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: number) => 0
  },
});

const changeRequestState = createLoadingStateReducer(initialState.changeRequestState, {
  [OrderActionsTypes.ChangeSoldOrderStatus]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OrderActionsTypes.StornoOrder]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OrderActionsTypes.ConfirmOrderReceived]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OrderActionsTypes.PostOrderRefundRequest]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OrderActionsTypes.AcceptOrderRefundRequest]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OrderActionsTypes.RejectOrderRefundRequest]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

const ordersRefundsRequests = createReducer(initialState.ordersRefundsRequests, {
  [OrderActionsTypes.GetMyOrdersRefundsRequests]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderRefundsList, payload: OrderRefundsList) => payload
  },
  [OrderActionsTypes.GetReceivedOrdersRefundsRequests]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderRefundsList, payload: OrderRefundsList) => payload
  },
});

const ordersRefundsCount = createReducer(initialState.ordersRefundsCount, {
  [OrderActionsTypes.GetOrdersRefundsCount]: {
    [RequestActionTypes.SUCCESS]: (_state: number, payload: OrderRefundsList) => {
      return payload.total
    },
    [RequestActionTypes.FAILURE]: (_state: number) => 0
  },
});

const orderRefundRequest = createReducer(initialState.orderRefundRequest, {
  [OrderActionsTypes.GetMyOrderRefundRequestDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderRefund | null, payload: OrderRefund) => payload,
    [RequestActionTypes.FAILURE]: (_state: OrderRefund | null) => null,
  },
  [OrderActionsTypes.GetReceivedOrderRefundRequestDetail]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderRefund | null, payload: OrderRefund) => payload,
    [RequestActionTypes.FAILURE]: (_state: OrderRefund | null) => null,
  },
  [OrderActionsTypes.AcceptOrderRefundRequest]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderRefund | null, payload: OrderRefund) => {
      return ({
        ..._state,
        ...payload
      });
    }
  },
  [OrderActionsTypes.RejectOrderRefundRequest]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderRefund | null, payload: OrderRefund) => {
      return ({
        ..._state,
        ...payload
      });
    }
  }
});

const getRefundRequestLoadingState = createLoadingStateReducer(initialState.getRefundRequestLoadingState, {
  [OrderActionsTypes.GetMyOrderRefundRequestDetail]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [OrderActionsTypes.GetReceivedOrderRefundRequestDetail]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ]
});

const orderStatusChangeLog = createReducer(initialState.orderStatusChangeLog, {
  [OrderActionsTypes.GetOrderStatusChangeLog]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderStatusChangeLogPaginated | null, payload: OrderStatusChangeLogPaginated) => payload,
    [RequestActionTypes.REQUEST]: (_state: OrderStatusChangeLogPaginated | null) => null,
    [RequestActionTypes.FAILURE]: (_state: OrderStatusChangeLogPaginated | null) => null,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: OrderStatusChangeLogPaginated | null) => null,
  }
});

const getOrderStatusChangeLogRequestState = createLoadingStateReducer(initialState.getOrderStatusChangeLogRequestState, {
  [OrderActionsTypes.GetOrderStatusChangeLog]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<OrderState>({
  order,
  orders,
  soldOrdersCount,
  changeRequestState,
  ordersRefundsRequests,
  ordersRefundsCount,
  orderRefundRequest,
  getRefundRequestLoadingState,
  orderStatusChangeLog,
  getOrderStatusChangeLogRequestState
});

/* SELECTORS */
export const selectOrderState = (state: AppState): OrderState => state.order;
export const selectOrderDetail = (state: AppState): CompleteOrder | null => selectOrderState(state).order;
export const selectOrders = (state: AppState): OrdersList => selectOrderState(state).orders;
export const selectSoldOrdersCount = (state: AppState): number => selectOrderState(state).soldOrdersCount;
export const selectChangeRequestState = (state: AppState): LoadingState => selectOrderState(state).changeRequestState;
export const selectOrdersRefundsRequests = (state: AppState): OrderRefundsList => selectOrderState(state).ordersRefundsRequests;
export const selectOrdersRefundsCount = (state: AppState): number => selectOrderState(state).ordersRefundsCount;
export const selectOrderRefundRequestDetail = (state: AppState): OrderRefund | null => selectOrderState(state).orderRefundRequest;
export const selectGetRefundRequestLoadingState = (state: AppState): LoadingState => selectOrderState(state).getRefundRequestLoadingState;
export const selectOrderStatusChangeLog = (state: AppState): OrderStatusChangeLogPaginated | null => selectOrderState(state).orderStatusChangeLog;
export const selectGetOrderStatusChangeLogRequestState = (state: AppState): LoadingState => selectOrderState(state).getOrderStatusChangeLogRequestState;

/* SAGAS */
function* getOrders({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrders, payload);

  if (resp.ok) {
    yield put(getOrdersActions.success(resp.data));
  } else {
    yield put(getOrdersActions.failure());
  }
}

function* getSoldOrders({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getSoldOrders, payload);

  if (resp.ok) {
    yield put(getSoldOrdersActions.success(resp.data));
  } else {
    yield put(getSoldOrdersActions.failure());
  }
}

function* getSoldOrdersCount({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getSoldOrders, payload);

  if (resp.ok) {
    yield put(getSoldOrdersCountActions.success(resp.data));
  } else {
    yield put(getSoldOrdersCountActions.failure());
  }
}

function* getOrderDetail({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderDetail, payload.orderId, payload.params);

  if (resp.ok) {
    yield put(getOrderDetailActions.success(resp.data));
  } else {
    yield put(getOrderDetailActions.failure());
  }
}

function* getSoldOrderDetail({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getSoldOrderDetail, payload);

  if (resp.ok) {
    yield put(getSoldOrderDetailActions.success(resp.data));
  } else {
    yield put(getSoldOrderDetailActions.failure());
  }
}

function* postOrder({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.postOrder, payload);
  if (resp.ok) {
    yield put(postOrderActions.success(resp.data));
    yield put(successResponseActions([{ message: "postOrderSuccess" }, { data: resp.data }]));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(postOrderActions.failure());
  }
}

function* changeSoldOrderStatus({ payload }: any) {
  const responses: ExtendedAxiosResponse[] = yield all(payload.ids.map((id: string) => call(api.changeSoldOrderStatus, id, { status: payload.status })))

  if (payload.ids.length > 1 && responses.length > 1) {
    // if more than 1 id in request
    const successResponses = responses.filter((resp: any) => resp.ok);
    const errorResponses = responses.filter((resp: any) => !resp.ok);

    // successfuly changed orders statuses
    if (successResponses.length !== 0) {
      yield all(successResponses.map((resp: any) => put(changeSoldOrderStatusActions.success(resp.data))));
      yield put(successToastActions(i18n.t('SuccessToasts.ChangeOrdersStatusesSuccess')));
    }

    // error occured while orders statuses changing
    if (errorResponses.length !== 0) {
      yield all(errorResponses.map((resp: any) => put(errorResponseActions(resp.response.data))));
      yield put(changeSoldOrderStatusActions.failure());
    }

  } else {
    // if 1 id in request
    if (responses[0]?.ok) {
      yield put(changeSoldOrderStatusActions.success(responses[0].data));
      yield put(successToastActions(i18n.t('SuccessToasts.ChangeOrderStatusSuccess')));
    } else {
      yield put(errorResponseActions(responses[0].response.data));
      yield put(changeSoldOrderStatusActions.failure());
    }
  }  
}

function* stornoOrder({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.stornoOrder, payload.id);
  if (resp.ok) {
    yield put(stornoOrderActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.OrderSuccessfullyCanceled')));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(stornoOrderActions.failure());
  }
}

function* confirmOrderReceived({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.confirmOrderReceived, payload.id);
  if (resp.ok) {
    yield put(confirmOrderReceivedActions.success(resp.data));
    yield put(successToastActions(i18n.t('SuccessToasts.OrderConfirmReceivedSuccessfully')));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(confirmOrderReceivedActions.failure());
  }
}

function* postOrderRefundRequest({ payload }: any) {
  const responses: ExtendedAxiosResponse[] = yield all(payload.requests.map((params: string) => call(api.postOrderRefundRequest, params)));

  if (responses.length > 0) {
    // if more than 1 item in request
    const successResponses = responses.filter((resp: any) => resp.ok);
    const errorResponses = responses.filter((resp: any) => !resp.ok);

    // successfuly posted refund requests
    if (successResponses.length !== 0) {
      if (errorResponses.length === 0) {
        yield all(successResponses.map((resp: any) => put(postOrderRefundRequestActions.success())));
      }
      yield put(successToastActions(i18n.t('SuccessToasts.OrderRefundRequestCreatedSuccessfully')));
      if (errorResponses.length === 0 && payload.redirectSuccess) {
        yield put(push(payload.redirectSuccess));
      }
    }

    // error occured while orders statuses changing
    if (errorResponses.length !== 0) {
      yield all(errorResponses.map((resp: any) => put(errorResponseActions(resp.response.data))));
      yield put(postOrderRefundRequestActions.failure());
      if (payload.redirectFailure) {
        yield put(push(payload.redirectFailure));
      }
    }
  } 
}

function* getMyOrdersRefundsRequests({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getMyOrdersRefunds, payload.params);
  if (resp.ok) {
    yield put(getMyOrdersRefundsRequestsActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getMyOrdersRefundsRequestsActions.failure());
  }
}

function* getReceivedOrdersRefundsRequests({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getReceivedOrdersRefunds, payload.params);
  if (resp.ok) {
    yield put(getReceivedOrdersRefundsRequestsActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getReceivedOrdersRefundsRequestsActions.failure());
  }
}


function* getOrdersRefundsCount({ payload }: any) {
  const { type } = payload;

  const params = {
    '$limit': 0,
    ...payload.params
  }

  let resp: ExtendedAxiosResponse;
  if (type === 'my') {
    resp = yield call(api.getMyOrdersRefunds, params);
  } else {
    resp = yield call(api.getReceivedOrdersRefunds, params);
  }

  if (resp.ok) {
    yield put(getOrdersRefundsCountActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getOrdersRefundsCountActions.failure());
  }
}

function* getMyOrderRefundRequestDetail({ payload }: any) {
  const params = {}

  const resp: ExtendedAxiosResponse = yield call(api.getMyRefundRequestDetail, payload.id, params);
  if (resp.ok) {
    yield put(getMyOrderRefundRequestDetailActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getMyOrderRefundRequestDetailActions.failure());
  }
}

function* getReceivedOrderRefundRequestDetail({ payload }: any) {
  const params = {}

  const resp: ExtendedAxiosResponse = yield call(api.getReceivedRefundRequestDetail, payload.id, params);
  if (resp.ok) {
    yield put(getReceivedOrderRefundRequestDetailActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getReceivedOrderRefundRequestDetailActions.failure());
  }
}

function* acceptOrderRefundRequest({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.acceptOrderRefundRequest, payload.id, payload.params);
  if (resp.ok) {
    yield put(acceptOrderRefundRequestActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(acceptOrderRefundRequestActions.failure());
  }
}

function* rejectOrderRefundRequest({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.rejectOrderRefundRequest, payload.id, payload.params);
  if (resp.ok) {
    yield put(rejectOrderRefundRequestActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(rejectOrderRefundRequestActions.failure());
  }
}

function* getOrderStatusChangeLog({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderStatusChangeLog, payload.orderId, payload.params);
  if (resp.ok) {

    if (payload.params && payload.params.$limit === -1) {
      yield put(getOrderStatusChangeLogActions.success({
        total: resp.data.length || 0,
        limit: -1,
        skip: 0,
        data: resp.data,
      }));
    } else {
      yield put(getOrderStatusChangeLogActions.success(resp.data));
    }
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getOrderStatusChangeLogActions.failure());
  }
}


/* EXPORT */
export function* orderSaga() {
  yield takeLatest(
    createActionType(OrderActionsTypes.GetOrders, RequestActionTypes.REQUEST),
    getOrders,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetSoldOrders, RequestActionTypes.REQUEST),
    getSoldOrders,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetSoldOrdersCount, RequestActionTypes.REQUEST),
    getSoldOrdersCount,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetOrderDetail, RequestActionTypes.REQUEST),
    getOrderDetail,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetSoldOrderDetail, RequestActionTypes.REQUEST),
    getSoldOrderDetail,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.PostOrder, RequestActionTypes.REQUEST),
    postOrder,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.ChangeSoldOrderStatus, RequestActionTypes.REQUEST),
    changeSoldOrderStatus,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.StornoOrder, RequestActionTypes.REQUEST),
    stornoOrder,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.ConfirmOrderReceived, RequestActionTypes.REQUEST),
    confirmOrderReceived,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.PostOrderRefundRequest, RequestActionTypes.REQUEST),
    postOrderRefundRequest,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetMyOrdersRefundsRequests, RequestActionTypes.REQUEST),
    getMyOrdersRefundsRequests,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetReceivedOrdersRefundsRequests, RequestActionTypes.REQUEST),
    getReceivedOrdersRefundsRequests,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetOrdersRefundsCount, RequestActionTypes.REQUEST),
    getOrdersRefundsCount,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetMyOrderRefundRequestDetail, RequestActionTypes.REQUEST),
    getMyOrderRefundRequestDetail,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetReceivedOrderRefundRequestDetail, RequestActionTypes.REQUEST),
    getReceivedOrderRefundRequestDetail,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.AcceptOrderRefundRequest, RequestActionTypes.REQUEST),
    acceptOrderRefundRequest,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.RejectOrderRefundRequest, RequestActionTypes.REQUEST),
    rejectOrderRefundRequest,
  );

  yield takeLatest(
    createActionType(OrderActionsTypes.GetOrderStatusChangeLog, RequestActionTypes.REQUEST),
    getOrderStatusChangeLog,
  );
}