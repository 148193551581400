import { combineReducers } from "redux";
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AppState } from "helpers/store/models/AppState";
import { api } from "./api";
import { User, Setting } from "../../types/User";
import { errorResponseActions, successResponseActions } from '../response/ducks';
import {
  createActionType,
  createApiActionCreators,
  createReducer,
  LoadingState,
  createLoadingStateReducer,
  RequestActionTypes
} from "helpers/redux/redux-helpers";
import i18n from '../../i18n';
import Swal from "sweetalert2";
import { ErrorResponse } from "types/Error";
import { store } from 'helpers/store/configure-store';
import { successToastActions } from "saga/toast/ducks";
import { ShoppingCartItem } from '../../types/ShoppingCartItem';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
export interface UserState {
  isLogged: boolean;
  loggedUser: User | null;
  userSettings: Setting | null;
  bankAccountAdded: boolean;
  userCurrency: string,
  checkoutGroups: any,
  userGDPRConsent: any,
  requestState: LoadingState
}

/* ACTION TYPES */
export enum UserActionTypes {
  Login = "@@User/LOGIN",
  Logout = "@@User/LOGOUT",
  Register = "@@User/REGISTER",
  Forgotten = "@@User/FORGOTTEN",
  Reset = "@@User/RESET",
  ConfirmEmail = "@@User/CONFIRM_EMAIL",
  ChangeEmail = "@@User/CHANGE_EMAIL",
  ChangeEmailConfirm = "@@User/CHANGE_EMAIL_CONFIRM",
  ValidateEmail = "@@User/VALIDATE_EMAIL",
  ValidateCompany = "@@User/VALIDATE_COMPANY",
  ChangePassword = "@@User/CHANGE_PASSWORD",
  ChangeName = "@@User/CHANGE_NAME",
  ChangePhoneNumber = "@@User/CHANGE_PHONE_NUMBER",
  ResendConfirmEmail = "@@User/RESEND_CONFIRM_EMAIL",
  AddStripeAccount = "@@USER/ADD_STRIPE_ACCOUNT",
  RefreshAccountLink = "@@USER/REFRESH_ACCOUNT_LINK",
  UpdateSettings = '@@USER/UPDATE_SETTINGS',
  AddBankAccount = '@@USER/ADD_BANK_ACCOUNT',
  GetUser = '@@USER/GET_USER',
  CloseNotification = '@@USER/CLOSE_NOTIFICATION',
  ShoppingCartUpdated = '@@USER/SHOPPING_CART_UPDATED',
  ShoppingCartItemRemoved = '@@USER/SHOPPING_CART_ITEM_REMOVED',
  MergeShoppingCart = '@@USER/MERGE_SHOPPING_CART',
  ChangeCurrency = '@@USER/CHANGE_CURRENCY',
  HandleCurrencyChanged = '@@USER/HANDLE_CURRENCY_CHANGED',
  GetCheckoutGroups = '@@USER/GET_CHECKOUT_GROUPS',
  ChangeLanguage = '@@USER/CHANGE_LANGUAGE',
  CreateGDPRConsent = '@@USER/CREATE_GDPR_CONSENT',
  UpdateGDPRConsent = '@@USER/UPDATE_GDPR_CONSENT',
  DisconnectSocialAuth = '@@USER/DISCONNECT_SOCIAL_AUTH'
}

/* ACTIONS */
export const loginActions = createApiActionCreators(UserActionTypes.Login);
export const logoutActions = createApiActionCreators(UserActionTypes.Logout);
export const registerActions = createApiActionCreators(
  UserActionTypes.Register
);
export const forgottenActions = createApiActionCreators(
  UserActionTypes.Forgotten
);
export const resetActions = createApiActionCreators(
  UserActionTypes.Reset
);
export const confirmEmailActions = createApiActionCreators(
  UserActionTypes.ConfirmEmail
);
export const changeEmailActions = createApiActionCreators(
  UserActionTypes.ChangeEmail
);
export const changeEmailConfirmActions = createApiActionCreators(
  UserActionTypes.ChangeEmailConfirm
);
export const validateEmailActions = createApiActionCreators(
  UserActionTypes.ValidateEmail
);
export const validateCompanyActions = createApiActionCreators(
  UserActionTypes.ValidateCompany
);
export const changePasswordActions = createApiActionCreators(
  UserActionTypes.ChangePassword
);
export const resendConfirmEmailActions = createApiActionCreators(
  UserActionTypes.ResendConfirmEmail,
);
export const addStripeAccountActions = createApiActionCreators(
  UserActionTypes.AddStripeAccount,
);
export const refreshAccountLinkActions = createApiActionCreators(
  UserActionTypes.RefreshAccountLink,
);
export const updateSettingsActions = createApiActionCreators(
  UserActionTypes.UpdateSettings,
);
export const addBankAccountActions = createApiActionCreators(
  UserActionTypes.AddBankAccount,
);
export const changeNameActions = createApiActionCreators(
  UserActionTypes.ChangeName
);
export const changePhoneNumberActions = createApiActionCreators(
  UserActionTypes.ChangePhoneNumber
);
export const getUserActions = createApiActionCreators(
  UserActionTypes.GetUser
);
export const closeNotificationActions = createApiActionCreators(
  UserActionTypes.CloseNotification
);
export const shoppingCartUpdatedActions = createApiActionCreators(
  UserActionTypes.ShoppingCartUpdated
);
export const shoppingCartItemRemovedActions = createApiActionCreators(
  UserActionTypes.ShoppingCartItemRemoved
);
export const mergeShoppingCartActions = createApiActionCreators(
  UserActionTypes.MergeShoppingCart,
);
export const changeCurrencyActions = createApiActionCreators(
  UserActionTypes.ChangeCurrency,
);
export const handleCurrencyChanged = createApiActionCreators(
  UserActionTypes.HandleCurrencyChanged,
);
export const getCheckoutGroupsActions = createApiActionCreators(
  UserActionTypes.GetCheckoutGroups,
);
export const changeLanguageActions = createApiActionCreators(
  UserActionTypes.ChangeLanguage,
);
export const createGDPRConsentActions = createApiActionCreators(
  UserActionTypes.CreateGDPRConsent,
);
export const updateGDPRConsentActions = createApiActionCreators(
  UserActionTypes.UpdateGDPRConsent,
);
export const disconnectSocialAuthActions = createApiActionCreators(
  UserActionTypes.DisconnectSocialAuth,
);

/* REDUCERS */
const initialState: UserState = {
  isLogged: false,
  loggedUser: null,
  userSettings: null,
  bankAccountAdded: false,
  userCurrency: '',
  checkoutGroups: [],
  userGDPRConsent: null,
  requestState: LoadingState.success
};

const isLogged = createReducer(initialState.isLogged, {
  [UserActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => true,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => false,
  },
});

const loggedUser = createReducer(initialState.loggedUser, {
  [UserActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: User) => payload,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: User) => null,
  },
  [UserActionTypes.ChangeName]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: User) => payload,
  },
  [UserActionTypes.ChangePhoneNumber]: {
    [RequestActionTypes.SUCCESS]: (state: User, payload: any) => {
      if ((payload.refType === 'User' && state.id === payload.refId) || (state && !state.phone)) {
        const newState = {
          ...state,
          phone: '+' + payload.phoneNumber
        }

        return newState;
      }

      return state;
    },
  },
  [UserActionTypes.CloseNotification]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: User) => payload,
  },
  [UserActionTypes.ShoppingCartUpdated]: {
    [RequestActionTypes.REQUEST]: (_state: User, payload: ShoppingCartItem) => {
      const stateCopy = { ..._state }
      const { shoppingCart } = stateCopy;
      if (shoppingCart && shoppingCart.findIndex((item) => item.offerId === payload.offerId) === -1) {
        return {
          ...stateCopy,
          shoppingCart: [...shoppingCart, payload],
        };
      }

      return stateCopy;
    },
  },
  [UserActionTypes.ShoppingCartItemRemoved]: {
    [RequestActionTypes.REQUEST]: (_state: User, payload: any) => {
      const stateCopy = { ..._state }
      const { shoppingCart } = stateCopy;
      const index = shoppingCart ? shoppingCart.findIndex((item) => item.id === payload.id) : -1;
      if (shoppingCart && index !== -1) {
        const copy = [...shoppingCart];
        copy.splice(index, 1);
        return {
          ...stateCopy,
          shoppingCart: [...copy],
        };
      }

      return stateCopy;
    },
  },
  [UserActionTypes.MergeShoppingCart]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: any) => {
      const stateCopy = { ..._state };
      return {
        ...stateCopy,
        shoppingCart: payload,
      }
    }
  },
  [UserActionTypes.ChangeCurrency]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: any) => ({ ..._state,
      currency: payload.currency }),
  },
  [UserActionTypes.ConfirmEmail]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: any) => ({ ..._state,
      verifiedAt:  payload.verifiedAt }),
  },
  [UserActionTypes.UpdateSettings]: {
    [RequestActionTypes.SUCCESS]: (_state: User, payload: Setting) => ({ ..._state,
      settings:  payload }),
  },
  [UserActionTypes.ChangeLanguage]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, language: string) => {
      if (state !== null) {
        return({
          ...state,
          language
        });
      }

      return null;
    }
  },
  [UserActionTypes.DisconnectSocialAuth]: {
    [RequestActionTypes.SUCCESS]: (_state: User, attribute: any) => ({ ..._state,
      [attribute]: null }),
  },
});

const userSettings = createReducer(initialState.userSettings, {
  [UserActionTypes.AddStripeAccount]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: Setting) => payload,
  },
  [UserActionTypes.RefreshAccountLink]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: Setting) => payload,
  },
  [UserActionTypes.UpdateSettings]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: Setting) => payload,
  },
  [UserActionTypes.AddBankAccount]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: User) => payload.settings,
  },
  [UserActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: User) => payload.settings,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => null,
  },
});

const bankAccountAdded = createReducer(initialState.bankAccountAdded, {
  [UserActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: User) => (payload.bankAccounts && payload.bankAccounts.length > 0),
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => false,
  },
  [UserActionTypes.AddBankAccount]: {
    [RequestActionTypes.REQUEST]: (_state: boolean) => false,
    [RequestActionTypes.SUCCESS]: (_state: boolean) => true,
    [RequestActionTypes.FAILURE]: (_state: boolean, payload: any) => payload?.errors[0]?.path === 'bankAccountAlreadyAdded',
  },
});

const userCurrency = createReducer(initialState.userCurrency, {
  [UserActionTypes.HandleCurrencyChanged]: {
    [RequestActionTypes.REQUEST]: (_state: any, currency: string) => currency,
  }
});

const checkoutGroups = createReducer(initialState.checkoutGroups, {
  [UserActionTypes.GetCheckoutGroups]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: any) => payload,
  }
});

const userGDPRConsent = createReducer(initialState.userGDPRConsent, {
  [UserActionTypes.CreateGDPRConsent]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: any) => payload,
  },
  [UserActionTypes.UpdateGDPRConsent]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: any) => payload,
  }
});

const requestState = createLoadingStateReducer(initialState.requestState, {
  [UserActionTypes.Register]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserActionTypes.Reset]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<UserState>({
  isLogged,
  loggedUser,
  userSettings,
  bankAccountAdded,
  userCurrency,
  checkoutGroups,
  userGDPRConsent,
  requestState,
});

/* SELECTORS */
export const selectUserState = (state: AppState) => state.user;

export const selectUser = (state: AppState) =>
  selectUserState(state).loggedUser;

export const selectIsLogged = (state: AppState) =>
  selectUserState(state).isLogged;

export const selectUserSettings = (state: AppState) =>
  selectUserState(state).userSettings;

export const selectBankAccountAdded = (state: AppState) =>
  selectUserState(state).bankAccountAdded;

export const selectUserCurrency = (state: AppState) =>
  selectUserState(state).userCurrency;

export const selectCheckoutGroups = (state: AppState) =>
  selectUserState(state).checkoutGroups;

export const selectUserGDPRConsent = (state: AppState) =>
  selectUserState(state).userGDPRConsent;

export const selectUserRequestState = (state: AppState): LoadingState =>
  selectUserState(state).requestState;

let forbiddenError: ErrorResponse =
  {
    className: '',
    code: 0,
    data: null,
    errors: [],
    message: '',
    name: ''
  };

i18n.on('loaded', function () {
  forbiddenError.message = i18n.t('Errors.Connection');
});

/* SAGAS */
function* login({ payload }: any) {
  // Example call

  const params = {
    ...payload,
    strategy: 'local'
  }

  const resp: ExtendedAxiosResponse = yield call(api.login, params);

  if (resp.ok) {
    yield put(loginActions.success(resp.data.user));
    yield put(successToastActions(i18n.t('SuccessToasts.Login')));
  } else {
    yield put(loginActions.failure());
  }
}

function* logout({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.logout);
  if (resp.ok) {
    yield put(push(i18n.t('Routes.LandingPage')));
    yield put(logoutActions.success());
    if (!localStorage.getItem('logoutAfter')) {
      yield put(successToastActions(i18n.t('SuccessToasts.Logout')));
    } else if (!payload) {
      localStorage.removeItem('logoutAfter');
      yield put(successToastActions(i18n.t('SuccessToasts.OfferSavedLogout')));
    } else {
      localStorage.removeItem('logoutAfter');
      yield put(successToastActions(i18n.t('SuccessToasts.OfferRemovedLogout')));
    }
  }
}

function* register({ payload }: any) {
  const { currency } = payload;
  delete payload.currency;

  const resp: ExtendedAxiosResponse = yield call(api.register, payload, currency);
  if (resp.ok) {
    yield put(successResponseActions('Success'));
    yield put(registerActions.success());
    yield put(successToastActions(i18n.t('SuccessToasts.Register')));
  } else {
    yield put(registerActions.failure());
  }
}

function* forgotten({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.forgotten, payload);
  //
  if (resp.ok) {
    yield put(successResponseActions('Reset email sent'));
    yield put(forgottenActions.success(resp.data));
  } else {
    yield put(forgottenActions.failure());
  }
}

function* reset({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.reset, {
    token: localStorage.getItem('jwtToken'),
    payload: payload
  });
  if (resp.ok) {
    yield put(resetActions.success(resp.data));
    yield put(successResponseActions(resp.data));

    yield put(loginActions.success(resp.data.user));
    yield put(successToastActions(i18n.t('SuccessToasts.PasswordReset')));
    yield put(successToastActions(i18n.t('SuccessToasts.Login')));
    yield put(push(i18n.t('Routes.LandingPage')));

  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else if (resp.response.status === 404) {
      yield put(resetActions.failure());
      yield put(push('/'));
    } else {
      yield put(resetActions.failure());
    }
  }
}

function* confirmEmail({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.confirmEmail, payload);
  if (resp.ok) {
    yield put(confirmEmailActions.success(resp.data));
    if (resp.data.companyId === null) {
      yield put(successToastActions(i18n.t('Login.Verified')));
    } else {
      yield put(successResponseActions('Company email confirmed!'));
    }
    yield put(push(i18n.t('Routes.Login')));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(confirmEmailActions.failure());
  }
}

function* validateEmail({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.validateEmail, payload);
  //
  if (resp.ok) {
    yield put(validateEmailActions.success(resp.data));
    yield put(successResponseActions('Email unique'));
  } else {
    if (resp.response.status === 403) {
      const errorMessage = {
        type: '',
        path: '',
        message: forbiddenError.message
      }
      forbiddenError.errors.push(errorMessage);
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(validateEmailActions.failure());
  }
}

function* validateCompany({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.validateCompany, payload);
  //
  if (resp.ok) {
    yield put(validateCompanyActions.success(resp.data));
    yield put(successResponseActions('Company unique'));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      if (resp.response.data.errors[0].type !== 'companies.uniqueName') {
        yield put(successResponseActions('Username unique'));
      }
    }
    yield put(validateCompanyActions.failure());
  }
}

function* changePassword({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.changePassword, payload);
  //
  if (resp.ok) {
    yield put(changePasswordActions.success(resp.data));
    yield put(successResponseActions('Password Changed'));
    yield put(successToastActions(i18n.t('SuccessToasts.PasswordChange')));
    yield put(push(i18n.t('Routes.AccountInfoAdresses')));
  } else {
    yield put(changePasswordActions.failure());
  }
}

function* resendConfirmEmail({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.resendConfirmEmail, payload);

  if (resp.ok) {
    yield put(successResponseActions('resendConfirmEmailSuccess'));
    yield put(resendConfirmEmailActions.success(resp.data));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(resendConfirmEmailActions.failure());
  }
}

function* addStripeAccount({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.addStripeAccount, payload.userIp);

  if (resp.ok) {
    yield put(successResponseActions('success'));
    yield put(addStripeAccountActions.success(resp.data));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(addStripeAccountActions.failure());
  }
}

function* refreshAccountLink() {
  const resp: ExtendedAxiosResponse = yield call(api.refreshAccountLink);

  if (resp.ok) {
    yield put(successResponseActions('success'));
    yield put(refreshAccountLinkActions.success(resp.data));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(refreshAccountLinkActions.failure());
  }
}

function* updateSettings({ payload: data }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateSettings, data.id, data.payload);

  if (resp.ok) {
    yield put(successResponseActions('success'));
    yield put(updateSettingsActions.success(resp.data));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(updateSettingsActions.failure());
  }
}

function* addBankAccount({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.addBankAccount, payload);

  if (resp.ok) {
    yield put(successResponseActions('success'));
    yield put(addBankAccountActions.success(resp.data));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(addBankAccountActions.failure(resp.response.data));
  }
}

function* changeEmail({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.changeEmail, payload);
  //
  if (resp.ok) {
    yield put(changeEmailActions.success(resp));
    store.dispatch(successToastActions(i18n.t('MyAccount.PersonalInfo.EmailSent')));
    Swal.close();
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(changeEmailActions.failure());
  }
}

function* changeEmailConfirm({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.changeEmailConfirm, payload);
  //
  if (resp.ok) {
    yield put(changeEmailConfirmActions.success(resp));
    yield call(api.logout);
    yield put(logoutActions.success());
    yield put(push(i18n.t('Routes.Login')));
    store.dispatch(successToastActions(i18n.t('MyAccount.PersonalInfo.EmailChanged')));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(changeEmailConfirmActions.failure());
  }
}

function* changeName({ payload }: any) {
  const user: User = yield select(selectUser);

  const resp: ExtendedAxiosResponse = yield call(api.changeName, user.id, payload);

  if (resp.ok) {
    yield put(changeNameActions.success(resp.data));
    yield put(successResponseActions(resp));
    yield put(successToastActions(i18n.t('MyAccount.PersonalInfo.SuccessCall')));
    yield put(push(i18n.t('Routes.AccountInfoAdresses')));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(changeNameActions.failure());
  }
}

function* changePhoneNumber({ payload }: any) {
  // Example call
  const resp: ExtendedAxiosResponse = yield call(api.changePhoneNumber, payload.smsRequestId, payload.body);
  //
  if (resp.ok) {
    localStorage.removeItem('smsId');
    yield put(changePhoneNumberActions.success(resp.data));
    yield put(successToastActions(i18n.t('MyAccount.PersonalInfo.PhoneVerified')));
    Swal.close();
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(changePhoneNumberActions.failure());
  }
}

function* getUser() {
  const resp: ExtendedAxiosResponse = yield call(api.getUser);

  if (resp.ok) {
    yield put(loginActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(loginActions.failure());
  }
}

function* closeNotification({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.closeNotification, payload.key);

  if (resp.ok) {
    yield put(closeNotificationActions.success(resp.data));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(closeNotificationActions.failure());
  }
}

function* mergeShoppingCart({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.mergeShoppingCart, payload);

  if (resp.ok) {
    yield put(mergeShoppingCartActions.success(resp.data));
    yield put(successResponseActions([{ message: 'shoppingCartMerged' }, { data: resp.data }]));
  } else {
    yield put(mergeShoppingCartActions.failure());
  }
}

function* changeCurrency({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.changeCurrency, {
    userId: payload.userId,
    payload: { currencyId: payload.currencyId },
  });

  if (resp.ok) {
    yield put(changeCurrencyActions.success(resp.data));
    yield put(successResponseActions([{ message: 'changeCurrencySuccess' }, { data: resp.data }]));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(changeCurrencyActions.failure());
  }
}

function* getCheckoutGroups() {
  const resp: ExtendedAxiosResponse = yield call(api.getCheckoutGroups);
  if (resp.ok) {
    yield put(getCheckoutGroupsActions.success(resp.data));
    yield put(successResponseActions([{ message: 'getCheckoutGroupsSuccess' }, { data: resp.data.data }]));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(getCheckoutGroupsActions.failure());
  }
}

function* setGDPRConsent({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.setGDPRConsent, payload);
  if (resp.ok) {
    yield put(createGDPRConsentActions.success(resp.data));
    yield put(successResponseActions([{ message: 'setGDPRConsentSuccess' }, { data: resp.data.data }]));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(createGDPRConsentActions.failure());
  }
}

function* updateGDPRConsent({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateGDPRConsent, payload.id, payload.body);
  if (resp.ok) {
    yield put(updateGDPRConsentActions.success(resp.data));
    yield put(successResponseActions([{ message: 'updateGDPRConsentSuccess' }, { data: resp.data.data }]));
  } else {
    yield put(errorResponseActions(resp.response.data));
    yield put(updateGDPRConsentActions.failure());
  }
}

function* changeLanguage({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.changeLanguage, payload.userId, payload.body);

  if (resp.ok) {
    if (payload?.body?.language) {
      yield put(changeLanguageActions.success(payload.body.language));
    }

    yield put(successResponseActions([{ message: 'changeLanguageSuccess' }, { data: resp.data }]));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(changeLanguageActions.failure());
  }
}

function* disconnectSocialAuth({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.disconnectSocialAuth, payload);
  //
  if (resp.ok) {
    yield put(disconnectSocialAuthActions.success(payload.includes('google') ? "googleId" : "facebookId"));
  } else {
    yield put(disconnectSocialAuthActions.failure());
  }
}


/* EXPORT */
export function* userSaga() {
  yield takeLatest(
    createActionType(UserActionTypes.Login, RequestActionTypes.REQUEST),
    login
  );

  yield takeLatest(
    createActionType(UserActionTypes.Logout, RequestActionTypes.REQUEST),
    logout
  );

  yield takeLatest(
    createActionType(UserActionTypes.Register, RequestActionTypes.REQUEST),
    register
  );

  yield takeLatest(
    createActionType(UserActionTypes.Forgotten, RequestActionTypes.REQUEST),
    forgotten
  );

  yield takeLatest(
    createActionType(UserActionTypes.Reset, RequestActionTypes.REQUEST),
    reset
  );

  yield takeLatest(
    createActionType(UserActionTypes.ConfirmEmail, RequestActionTypes.REQUEST),
    confirmEmail
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangeEmail, RequestActionTypes.REQUEST),
    changeEmail
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangeEmailConfirm, RequestActionTypes.REQUEST),
    changeEmailConfirm
  );

  yield takeLatest(
    createActionType(UserActionTypes.ValidateEmail, RequestActionTypes.REQUEST),
    validateEmail
  );

  yield takeLatest(
    createActionType(UserActionTypes.ValidateCompany, RequestActionTypes.REQUEST),
    validateCompany
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangePassword, RequestActionTypes.REQUEST),
    changePassword
  );

  yield takeLatest(
    createActionType(UserActionTypes.ResendConfirmEmail, RequestActionTypes.REQUEST),
    resendConfirmEmail
  );

  yield takeLatest(
    createActionType(UserActionTypes.AddStripeAccount, RequestActionTypes.REQUEST),
    addStripeAccount
  );

  yield takeLatest(
    createActionType(UserActionTypes.RefreshAccountLink, RequestActionTypes.REQUEST),
    refreshAccountLink,
  );

  yield takeLatest(
    createActionType(UserActionTypes.UpdateSettings, RequestActionTypes.REQUEST),
    updateSettings,
  );

  yield takeLatest(
    createActionType(UserActionTypes.AddBankAccount, RequestActionTypes.REQUEST),
    addBankAccount,
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangeName, RequestActionTypes.REQUEST),
    changeName
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangePhoneNumber, RequestActionTypes.REQUEST),
    changePhoneNumber
  );

  yield takeLatest(
    createActionType(UserActionTypes.GetUser, RequestActionTypes.REQUEST),
    getUser
  );

  yield takeLatest(
    createActionType(UserActionTypes.CloseNotification, RequestActionTypes.REQUEST),
    closeNotification
  );

  yield takeLatest(
    createActionType(UserActionTypes.MergeShoppingCart, RequestActionTypes.REQUEST),
    mergeShoppingCart,
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangeCurrency, RequestActionTypes.REQUEST),
    changeCurrency,
  );

  yield takeLatest(
    createActionType(UserActionTypes.GetCheckoutGroups, RequestActionTypes.REQUEST),
    getCheckoutGroups,
  );

  yield takeLatest(
    createActionType(UserActionTypes.CreateGDPRConsent, RequestActionTypes.REQUEST),
    setGDPRConsent,
  );

  yield takeLatest(
    createActionType(UserActionTypes.UpdateGDPRConsent, RequestActionTypes.REQUEST),
    updateGDPRConsent,
  );

  yield takeLatest(
    createActionType(UserActionTypes.ChangeLanguage, RequestActionTypes.REQUEST),
    changeLanguage,
  );

  yield takeLatest(
    createActionType(UserActionTypes.DisconnectSocialAuth, RequestActionTypes.REQUEST),
    disconnectSocialAuth,
  );
}
