import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Box, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { environment as env } from 'environments/environment';
import { disconnectSocialAuthActions } from "saga/user/ducks";

const fbIcon = require('../../../assets/fbIcon.jpg');

interface OauthButtonsProps {
  mobile?: boolean;
  connect?: boolean;
  googleId?: string;
  facebookId?: string;
}

const OauthButtons = (props: OauthButtonsProps & DispatchToProps) => {
  const { t } = useTranslation();
  const current = window.screen.width > window.innerWidth ? window.innerWidth : window.screen.width;
  const [isMobile, setIsMobile] = useState(current < 500);

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
        window.removeEventListener('resize', updateWindowDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWindowDimensions = () => {
    const current = window.screen.width > window.innerWidth ? window.innerWidth : window.screen.width;
    setIsMobile(current < 500);
  }

  const renderButtonLabel = (strategy: string) => {
    if (props.connect) {
      return `${t("Header.Account.ConnectWith")} ${strategy}`;
    } else {
      if (isMobile) {
        return t("Header.Account.SignInMobile")
      }
      return `${t("Header.Account.SignIn")} ${strategy}`;
    }
  }

  const renderGoogleButton = () => (
    <Box className={`oauth__button oauth__button--google ${props.mobile ? "mobile" : ""}`}>
      {!props.googleId &&
        <a className="oauth__link oauth__link--google" href={`${env.protocol}://${env.host}${env.port ? ':' + env.port : ''}/oauth/google-${env.topLevelDomain}`}>
          <img
            width={20}
            className="oauth__logo"
            alt="Google sign-in"
            src="https://developers.google.com/static/identity/images/g-logo.png"
          />
          {renderButtonLabel("Google")}
        </a>
      }
      {props.googleId &&
        <div className="oauth__link oauth__link--google" onClick={() => props.disconnectSocialAuth(`google-${env.topLevelDomain}`)}>
          <img
            width={20}
            className="oauth__logo"
            alt="Google sign-in"
            src="https://developers.google.com/static/identity/images/g-logo.png"
          />
          {`${t("Header.Account.DisconnectFrom")} Google`}
        </div>
      }
    </Box>
  );

  const renderFacebookButton = () => (
    <Box className="oauth__button oauth__button--facebook">
      {!props.facebookId &&
        <a className="oauth__link oauth__link--facebook" href={`${env.protocol}://${env.host}${env.port ? ':' + env.port : ''}/oauth/facebook-${env.topLevelDomain}`}>
          <img
            width={20}
            className="oauth__logo"
            alt="Facebook sign-in"
            src={fbIcon}
          />
          {renderButtonLabel("Facebook")}
        </a>
      }
      {props.facebookId &&
        <div className="oauth__link oauth__link--facebook" onClick={() => props.disconnectSocialAuth(`facebook-${env.topLevelDomain}`)}>
          <img
            width={20}
            className="oauth__logo"
            alt="Facebook sign-in"
            src={fbIcon}
          />
          {`${t("Header.Account.DisconnectFrom")} Facebook`}
        </div>
      }
    </Box>
  );

  return (
    !props.mobile ? 
    <Box className={`oauth`}>
      {renderGoogleButton()}
      {renderFacebookButton()}
    </Box>
    :
    <>
      <ListItem button>
        {renderGoogleButton()}
      </ListItem>
      <ListItem button className="oauth__wrapper--facebook">
        {renderFacebookButton()}
      </ListItem>
    </>
    );
};

interface DispatchToProps {
  disconnectSocialAuth: (strategy: string) => void;
}

const mapDispatchToProps: DispatchToProps = {
  disconnectSocialAuth: disconnectSocialAuthActions.request
};

export default connect(null, mapDispatchToProps)(OauthButtons);
