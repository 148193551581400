import { call, put, takeLatest, select } from 'redux-saga/effects';
import { api } from './api';
import { AppState } from 'helpers/store/models/AppState';
import {
  errorResponseActions,
  successResponseActions,
} from '../response/ducks';
import {
  createReducer,
  createActionType,
  createApiActionCreators,
  RequestActionTypes,
  createLoadingStateReducer,
  LoadingState,
} from 'helpers/redux/redux-helpers';
import { ShoppingCartItem } from '../../types/ShoppingCartItem';
import { combineReducers } from 'redux';
import { ErrorResponse } from '../../types/Error';
import i18n from '../../i18n';
import { errorToastActions, warnToastActions } from '../toast/ducks';
import { getCheckoutResponseActions } from '../checkout/ducks';
import { push } from 'connected-react-router';
import { UpdatedCartItem } from 'model/Checkout';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

export interface ShoppingCartState {
  shoppingCart: ShoppingCartItem[],
  shoppingCartItemsCount: number,
  fastCheckoutItemRequestState: LoadingState,
  createShoppingCartItemRequestState: LoadingState,
}

export enum ShoppingCartActionsTypes {
  GetShoppingCart = '@@ShoppingCart/Get',
  CreateShoppingCartItem = '@@ShoppingCart/CreateItem',
  UpdateShoppingCartItem = '@@ShoppingCart/UpdateItem',
  DeleteShoppingCartItem = '@@ShoppingCart/DeleteItem',
  UpdateCartItemsCount = '@@ShoppingCart/UpdateItemsCount',
  FastCheckoutItem = '@@ShoppingCart/FastCheckoutItem',
}

export const getShoppingCartActions = createApiActionCreators(
  ShoppingCartActionsTypes.GetShoppingCart,
);
export const createShoppingCartItemActions = createApiActionCreators(
  ShoppingCartActionsTypes.CreateShoppingCartItem,
);
export const updateShoppingCartItemActions = createApiActionCreators(
  ShoppingCartActionsTypes.UpdateShoppingCartItem,
);
export const deleteShoppingCartItemActions = createApiActionCreators(
  ShoppingCartActionsTypes.DeleteShoppingCartItem,
);
export const updateCartItemsCount = createApiActionCreators(
  ShoppingCartActionsTypes.UpdateCartItemsCount,
);
export const fastCheckoutItemActions = createApiActionCreators(
  ShoppingCartActionsTypes.FastCheckoutItem,
);

const initialState: ShoppingCartState = {
  shoppingCart: [],
  shoppingCartItemsCount: 0,
  fastCheckoutItemRequestState: LoadingState.success,
  createShoppingCartItemRequestState: LoadingState.success,
};

const shoppingCart = createReducer(initialState.shoppingCart, {
  [ShoppingCartActionsTypes.GetShoppingCart]: {
    [RequestActionTypes.SUCCESS]: (_state: ShoppingCartItem[], payload: { items: ShoppingCartItem[], updatedCartItems: UpdatedCartItem[] }) => {

      if (payload?.items && payload?.updatedCartItems && payload.updatedCartItems.length) {

        const items = payload.items?.map((shoppingCartItem) => {
          
          const updatedItemData = payload.updatedCartItems.find((updatedCartItem) => updatedCartItem.cartItemId === shoppingCartItem.id);

          return ({
            ...shoppingCartItem,
            isAvailable: updatedItemData?.isAvailable,
            qunatityWasUpdated: updatedItemData?.quantityWasUpdated,
            quantity:(updatedItemData?.quantityWasUpdated && updatedItemData?.quantity !== undefined) ? updatedItemData.quantity : shoppingCartItem.quantity,
          });
        });

        return items;
      } else if (payload?.items) {
        return payload.items;
      }

      return [];
    },
  },
  [ShoppingCartActionsTypes.UpdateShoppingCartItem]: {
    [RequestActionTypes.SUCCESS]:  (state: ShoppingCartItem[], payload: Partial<ShoppingCartItem>) => {
      if (state && payload) {
        const currentState = [...state];

        const itemToUpdate = payload.id !== undefined && currentState.find((cartItem) => cartItem.id === payload.id);

        if (itemToUpdate) {
          const indexOfItemToUpdate = currentState.indexOf(itemToUpdate);

          if (indexOfItemToUpdate > -1) {
            currentState.splice(indexOfItemToUpdate, 1, {
              ...itemToUpdate,
              ...payload
            });
          }         
        }
      }

      return state;
    }
  }
});

const shoppingCartItemsCount = createReducer(initialState.shoppingCartItemsCount, {
  [ShoppingCartActionsTypes.UpdateCartItemsCount]: {
    [RequestActionTypes.REQUEST]: (_state: any, payload: number) => payload,
  },
  [ShoppingCartActionsTypes.GetShoppingCart]: {
    [RequestActionTypes.SUCCESS]: (_state: ShoppingCartItem[], payload: { items: ShoppingCartItem[], updatedCartItems: UpdatedCartItem[] }) => {
      return payload?.items?.reduce((sum: number, item: any) => sum + item.quantity, 0) || 0;
    }, 
  },
  [ShoppingCartActionsTypes.CreateShoppingCartItem]: {
    [RequestActionTypes.SUCCESS]: (state: number, payload: { quantityToAdd: number, responseData: any }) => {
      return state + payload.quantityToAdd;
    }
  },
  [ShoppingCartActionsTypes.DeleteShoppingCartItem]: {
    [RequestActionTypes.SUCCESS]: (state: ShoppingCartItem[], payload: ShoppingCartItem[]) => {
      return payload?.reduce((sum: number, item: any) => sum + item.quantity, 0) || state;
    }
  },
});

const fastCheckoutItemRequestState = createLoadingStateReducer(initialState.fastCheckoutItemRequestState, {
  [ShoppingCartActionsTypes.FastCheckoutItem]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ]
});

const createShoppingCartItemRequestState = createLoadingStateReducer(initialState.createShoppingCartItemRequestState, {
  [ShoppingCartActionsTypes.CreateShoppingCartItem]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ]
});

export default combineReducers<ShoppingCartState>({
  shoppingCart,
  shoppingCartItemsCount,
  fastCheckoutItemRequestState,
  createShoppingCartItemRequestState
});

export const selectShoppingCartState = (state: AppState) => state.shoppingCart;

export const selectShoppingCart = (state: AppState) => selectShoppingCartState(state).shoppingCart;

export const selectCartItemsCount = (state: AppState) => selectShoppingCartState(state).shoppingCartItemsCount;

export const selectFastChckoutItemRequestState = (state: AppState): LoadingState => selectShoppingCartState(state).fastCheckoutItemRequestState;

export const selectCreateShoppingCartItemRequestState = (state: AppState): LoadingState => selectShoppingCartState(state).createShoppingCartItemRequestState;

let forbiddenError: ErrorResponse =
  {
    className: '',
    code: 0,
    data: null,
    errors: [],
    message: '',
    name: ''
  };

i18n.on('loaded', function () {
  forbiddenError.message = i18n.t('Errors.Connection');
});

/* SAGAS */
function* getShoppingCart() {
  const resp: ExtendedAxiosResponse = yield call(api.getShoppingCart);

  if (resp.ok) {
    yield put(getShoppingCartActions.success(resp.data));
    yield put(successResponseActions([{ message: 'getShoppingCartSuccess' }, { data: resp.data.items }]));

    if (resp.data.wasUpdated) {
      yield put(warnToastActions(i18n.t('Cart.Updated')));
    }
  } else {
    if (resp.response.status === 400) {
      yield put(errorToastActions(resp.response.data.errors[0].message));
    } else if (resp.response.status !== 403) {
      yield put(errorToastActions(resp.response.data.message));
    }
    yield put(errorResponseActions(resp.response.data));
    yield put(getShoppingCartActions.failure());
    console.error('Get shopping cart request failed', JSON.stringify(resp));
  }
}

function* createShoppingCartItem({ payload }: any) {
  const { quantity } = payload;

  const params = {
    ...payload
  }
  delete params.pushSuccess;

  const resp: ExtendedAxiosResponse = yield call(api.createShoppingCartItem, params);

  if (resp.ok) {
    yield put(createShoppingCartItemActions.success({
      quantityToAdd: quantity,
      responseData: resp.data
    }));
    if (payload.pushSuccess === undefined || payload.pushSuccess) {
      yield put(successResponseActions([{ message: 'addedNewItemToCart' }, { data: resp.data }]));
    }
  } else {
    if (resp.response.status === 400) {
      yield put(errorToastActions(resp.response.data.errors[0].message));
    } else if (resp.response.status !== 403) {
      yield put(errorToastActions(resp.response.data.message));
    }
    yield put(errorResponseActions(resp.response.data));
    yield put(createShoppingCartItemActions.failure());
    console.error('Create shopping cart request failed', JSON.stringify(resp));
  }
}

function* updateShoppingCartItem({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateShoppingCartItem, payload.id, payload.data);
  if (resp.ok) {
    yield put(updateShoppingCartItemActions.success({
      id: payload.id,
      ...resp.data
    }));
    yield put(successResponseActions([{ message: 'shoppingCartItemUpdated' }, { data: resp.data }]));

    if (payload.updateCheckout) {
      yield put(getCheckoutResponseActions.request());
    }
  } else {
    if (resp.response.status === 400) {
      yield put(errorToastActions(resp.response.data.errors[0].message));
    } else if (resp.response.status !== 403) {
      yield put(errorToastActions(resp.response.data.message));
    }
    yield put(errorResponseActions(resp.response.data));
    yield put(updateShoppingCartItemActions.failure());
    console.error('Update shopping cart item request failed', JSON.stringify(resp));
  }
}

function* deleteShoppingCartItem({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteShoppingCartItem, payload.id);
  if (resp.ok) {
    yield put(deleteShoppingCartItemActions.success(resp.data));
    yield put(successResponseActions([{ message: 'shoppingCartItemDeleted' }, { data: resp.data }]));

    if (payload.updateCheckout) {
      yield put(getCheckoutResponseActions.request());
    }
  } else {
    if (resp.response.status === 400) {
      yield put(errorToastActions(resp.response.data.errors[0].message));
    } else if (resp.response.status !== 403) {
      yield put(errorToastActions(resp.response.data.message));
    }
    yield put(errorResponseActions(resp.response.data));
    yield put(deleteShoppingCartItemActions.failure());
    console.error('Delete shopping cart item request failed', JSON.stringify(resp));
  }
}

function* fastCheckoutItem({ payload }: any) {
  const { offerId, updateCheckout } = payload;
  delete payload.updateCheckout

  if (payload.quantity) {
    yield call(createShoppingCartItem, { payload: {
      ...payload,
      pushSuccess: false
    } });

    const createShoppingCartItemRequestState: LoadingState = yield select(selectCreateShoppingCartItemRequestState);

    if (createShoppingCartItemRequestState === LoadingState.failure) {
      yield put(fastCheckoutItemActions.failure());
      return;
    }
  }

  yield call(getShoppingCart);
  
  const currentShoppingCartItems: ShoppingCartItem[] = yield select(selectShoppingCart);

  for (const cartItem of currentShoppingCartItems) {
    // change state of each item to unchecked
    const payload = {
      id: cartItem.id,
      data: {
        checked: cartItem.offerId === offerId
      }
    }
    yield call(updateShoppingCartItem, { payload });
  }

  yield put(fastCheckoutItemActions.success());
  if (updateCheckout){
    yield put(getCheckoutResponseActions.request());
  } else if (payload.quantity) {
    yield put(push(i18n.t('Routes.Checkout')));
  }
}
  
/* EXPORT */
export function* shoppingCartSaga() {
  yield takeLatest(
    createActionType(ShoppingCartActionsTypes.GetShoppingCart, RequestActionTypes.REQUEST),
    getShoppingCart,
  );
  yield takeLatest(
    createActionType(ShoppingCartActionsTypes.CreateShoppingCartItem, RequestActionTypes.REQUEST),
    createShoppingCartItem,
  );
  yield takeLatest(
    createActionType(ShoppingCartActionsTypes.UpdateShoppingCartItem, RequestActionTypes.REQUEST),
    updateShoppingCartItem,
  );
  yield takeLatest(
    createActionType(ShoppingCartActionsTypes.DeleteShoppingCartItem, RequestActionTypes.REQUEST),
    deleteShoppingCartItem,
  );
  yield takeLatest(
    createActionType(ShoppingCartActionsTypes.FastCheckoutItem, RequestActionTypes.REQUEST),
    fastCheckoutItem,
  );
}
