import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AppState } from 'helpers/store/models/AppState';
import { api, PurchaseUserCreditsRequest } from './api';
import {
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer,
  RequestActionTypes,
  LoadingState,
} from 'helpers/redux/redux-helpers';
import {
  selectUser,
  getUserActions
} from '../../user/ducks';
import { successToastActions, errorToastActions } from "saga/toast/ducks";

// models
import { PaymentResult, PaymentStatus } from 'model/Payment';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';
import { User } from "types/User";

// others
import { environment as env } from 'environments/environment';
import i18n from '../../../i18n';
import { Stripe, PaymentIntentResult, loadStripe } from '@stripe/stripe-js';
import { ConfirmPaymentRequest } from '../../checkout/api';
import Swal from 'sweetalert2';

/* STATE */
export interface UserCreditsState {
  purchaseUserCreditsResponse: PaymentResult | null,
  purchaseCreditsRequestState: LoadingState,
}

/* ACTION TYPES */
export enum UserCreditsActionTypes {
  PurchaseUserCredits = '@@UserCredits/PURCHASE_USERS_CREDITS',
  ConfirmCreditPurchase = '@@UserCredits/CONFIR_CREDIT_PURCHASE',
}

/* ACTIONS */
export const purchaseUserCreditsActions = createApiActionCreators(UserCreditsActionTypes.PurchaseUserCredits);
export const confirmCreditPurchaseActions = createApiActionCreators(UserCreditsActionTypes.ConfirmCreditPurchase);


/* REDUCERS */
const initialState: UserCreditsState = {
  purchaseUserCreditsResponse: null,
  purchaseCreditsRequestState: LoadingState.success,
};

const purchaseUserCreditsResponse = createReducer(initialState.purchaseUserCreditsResponse, {
  [UserCreditsActionTypes.PurchaseUserCredits]: {
    [RequestActionTypes.SUCCESS]: (_state: PaymentResult | null, payload: PaymentResult) => payload,
    [RequestActionTypes.FAILURE]: (_state: PaymentResult | null) => null,
    [RequestActionTypes.REQUEST]: (_state: PaymentResult | null) => null,
  },
});

const purchaseCreditsRequestState = createLoadingStateReducer(initialState.purchaseCreditsRequestState, {
  [UserCreditsActionTypes.PurchaseUserCredits]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
});

export default combineReducers<UserCreditsState>({
  purchaseUserCreditsResponse,
  purchaseCreditsRequestState,
});


/* SELECTORS */
export const selectUserCreditsState = (state: AppState): UserCreditsState => state.userCredits;
export const selectPurchaseCreditsRequestState = (state: AppState): LoadingState => selectUserCreditsState(state).purchaseCreditsRequestState;
export const selectPurchaseCreditsResponse = (state: AppState): PaymentResult | null => selectUserCreditsState(state).purchaseUserCreditsResponse;

/* SAGAS */
function* purchaseUserCredits({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.purchaseUserCredits, payload as PurchaseUserCreditsRequest);

  if (resp.ok) {
    yield call(processCreditPayment, resp.data as PaymentResult);
  } else {
    yield put(purchaseUserCreditsActions.failure());
  }
}

function* processCreditPayment(data: PaymentResult) {
  if (data.status === PaymentStatus.success) {
    yield put(purchaseUserCreditsActions.success(data));
    yield put(getUserActions.request());
    yield put(successToastActions(i18n.t('SuccessToasts.CreditSuccessfullyToppedUp')));
    Swal.close();
  } else if (data.status === PaymentStatus.actionRequired) {
    const user: User | null = yield select(selectUser);

    const stripe: Stripe | null = yield call(
      loadStripe,
      env.stripe[`publishableKey${user?.country || ''}`] || env.stripe.publishableKeySK
    );

    if (stripe && data?.clientSecret) {
      const result: PaymentIntentResult = yield call(stripe.confirmCardPayment, data.clientSecret);

      if (result.error) {
        yield put(purchaseUserCreditsActions.failure());
        yield put(errorToastActions(i18n.t('Checkout.Errors.SecureFailed')));
      } else {
        yield put(confirmCreditPurchaseActions.request({
          paymentId: data.paymentId,
        }));
      }
    } else {
      yield put(purchaseUserCreditsActions.failure());
      yield put(errorToastActions(i18n.t('MyAccount.CreditTransactionsCards.Errors.PurchaseCreditsFailed')));
    }
  } else {
    yield put(purchaseUserCreditsActions.failure());
    yield put(errorToastActions(i18n.t('MyAccount.CreditTransactionsCards.Errors.PurchaseCreditsFailed')));
  }
}

function* confirmCreditPurchase({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.confirmPayment, payload as ConfirmPaymentRequest);

  if (resp.ok) {
    const { data } = resp;
    yield put(confirmCreditPurchaseActions.success(data));
    yield call(processCreditPayment, data as PaymentResult);
  } else {
    yield put(purchaseUserCreditsActions.failure());
    yield put(errorToastActions(i18n.t('MyAccount.CreditTransactionsCards.Errors.PurchaseCreditsFailed')));
  }
}


/* EXPORT */
export function* userCreditsSaga() {
  yield takeLatest(
    createActionType(UserCreditsActionTypes.PurchaseUserCredits, RequestActionTypes.REQUEST),
    purchaseUserCredits,
  );
  yield takeLatest(
    createActionType(UserCreditsActionTypes.ConfirmCreditPurchase, RequestActionTypes.REQUEST),
    confirmCreditPurchase,
  );
}
