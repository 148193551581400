import ReactGA from 'react-ga4';
import { getAnalyticsCookieConsentStateFromStorage } from "./cookies";
import { environment as env } from 'environments/environment';

export const initGA = () => {
  ReactGA.initialize(env.googleAnalyticsConfigId);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};

export const sendGAEvent = (catName: string, actionLabel: string) => {
  if (getAnalyticsCookieConsentStateFromStorage()) {
    ReactGA.event({
      category: catName,
      action: actionLabel
    });
  }
};
