import React, { Component } from 'react';

class ListIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 16.686" {...this.props}>
        <path stroke="currentColor"  fill="none" className="a"
              d="M4.5,19.912H7.167V16.775H4.5Zm0,6.275H7.167V23.049H4.5Zm0-12.549H7.167V10.5H4.5Zm5.333,6.275H28.5V16.775H9.833Zm0,6.275H28.5V23.049H9.833Zm0-15.686v3.137H28.5V10.5Z"
              transform="translate(-4 -10)"/>
      </svg>
    );
  }
}

export default ListIcon;
