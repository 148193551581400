import { combineReducers } from 'redux';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { AppState } from 'helpers/store/models/AppState';
import { api } from './api';
import {
  createActionType,
  createApiActionCreators, createLoadingStateReducer,
  createReducer, LoadingState,
  RequestActionTypes,
} from 'helpers/redux/redux-helpers';
import i18n from '../../../i18n';
import { UserActionTypes } from '../ducks';
import { successToastActions } from 'saga/toast/ducks';
import { UserAddress } from '../../../model/UserAddress';
import { ErrorResponse } from 'types/Error';
import { errorResponseActions, successResponseActions } from 'saga/response/ducks';
import { ExtendedAxiosResponse } from 'model/ExtendedAxiosResponse';

/* STATE */
interface UserAddressAddress {
  delivery: UserAddress[];
  invoicing: UserAddress[];
  defaultDelivery: UserAddress | null;
  defaultInvoicing: UserAddress | null;
}

export interface UserAddressState {
  addresses: UserAddressAddress;
  requestState: LoadingState;
}

/* ACTION TYPES */
export enum UserAddressActionTypes {
  GetAddresses = '@@UserAddress/GET_ADDRESSES',
  CreateAddress = '@@UserAddress/CREATE_ADDRESS',
  UpdateAddress = '@@UserAddress/UPDATE_ADDRESS',
  DeleteAddress = '@@UserAddress/DELETE_ADDRESS',
  ValidateAddress = '@@UserAddress/VALIDATE_ADDRESS',
}

/* ACTIONS */
export const getAddressesActions = createApiActionCreators(UserAddressActionTypes.GetAddresses);
export const createAddressActions = createApiActionCreators(UserAddressActionTypes.CreateAddress);
export const updateAddressActions = createApiActionCreators(UserAddressActionTypes.UpdateAddress);
export const deleteAddressActions = createApiActionCreators(UserAddressActionTypes.DeleteAddress);
export const validateAddressActions = createApiActionCreators(UserAddressActionTypes.ValidateAddress);

/* REDUCERS */
const initialState: UserAddressState = {
  addresses: {
    delivery: [],
    invoicing: [],
    defaultDelivery: null,
    defaultInvoicing: null,
  },
  requestState: LoadingState.success,
};

const addresses = createReducer(initialState.addresses, {
  [UserAddressActionTypes.GetAddresses]: {
    [RequestActionTypes.SUCCESS]: (_state: UserAddressAddress, payload: UserAddress[]) => ({
      delivery: payload.filter(address => !address.isInvoicing),
      invoicing: payload.filter(address => address.isInvoicing),
      defaultDelivery: payload.find(address => !address.isInvoicing && address.isDefault),
      defaultInvoicing: payload.find(address => address.isInvoicing && address.isDefault),
    }),
    [RequestActionTypes.FAILURE]: (_state: UserAddressAddress) => initialState.addresses,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: UserAddressAddress) => initialState.addresses,
  },
});

const requestState = createLoadingStateReducer(initialState.requestState, {
  [UserAddressActionTypes.GetAddresses]: [
    RequestActionTypes.REQUEST, RequestActionTypes.SUCCESS, RequestActionTypes.FAILURE
  ],
  [UserAddressActionTypes.CreateAddress]: [RequestActionTypes.REQUEST, RequestActionTypes.FAILURE],
  [UserAddressActionTypes.UpdateAddress]: [RequestActionTypes.REQUEST, RequestActionTypes.FAILURE],
});

export default combineReducers<UserAddressState>({
  addresses,
  requestState,
});

/* SELECTORS */
const selectUserAddressState = (state: AppState) => state.userAddress;

const selectUserAddresses = (state: AppState): UserAddressAddress =>
  selectUserAddressState(state).addresses;

export const selectUserDeliveryAddresses = (state: AppState): UserAddress[] =>
  selectUserAddresses(state).delivery;

export const selectUserInvoicingAddresses = (state: AppState): UserAddress[] =>
  selectUserAddresses(state).invoicing;

export const selectAllUserAddresses = (state: AppState): UserAddress[] =>
  [
    ...(selectUserInvoicingAddresses(state) || []),
    ...(selectUserDeliveryAddresses(state) || []),
  ];

export const selectUserDefaultDeliveryAddress = (state: AppState): UserAddress | null =>
  selectUserAddresses(state).defaultDelivery;

export const selectUserDefaultInvoicingAddress = (state: AppState): UserAddress | null =>
  selectUserAddresses(state).defaultInvoicing;

export const selectUserAddressRequestState = (state: AppState): LoadingState =>
  selectUserAddressState(state).requestState;

let forbiddenError: ErrorResponse =
  {
    className: '',
    code: 0,
    data: null,
    errors: [],
    message: '',
    name: ''
  };

i18n.on('loaded', function () {
  forbiddenError.message = i18n.t('Errors.Connection');
});

/* SAGAS */
function* getAddresses() {
  const resp: ExtendedAxiosResponse = yield call(api.getAddresses);

  if (resp.ok) {
    yield put(getAddressesActions.success(resp.data));
  } else {
    yield put(getAddressesActions.failure());
  }
}

function* createAddress({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.createAddress, payload.data);

  if (resp.ok) {
    const invoicingAddresses: UserAddress[] = yield select(selectUserInvoicingAddresses);

    if (payload.useAsInvoicing && invoicingAddresses.length === 0) {
      const innerResp: ExtendedAxiosResponse = yield call(
        api.createAddress,
        {
          ...payload.data,
          isInvoicing: !payload.data.isInvoicing,
          ...(payload.data.isInvoicing && { isDefault: true })
        }
      );

      if (!innerResp.ok) {
        yield put(createAddressActions.failure());
      }
    }

    yield put(getAddressesActions.request());
    yield put(successToastActions(i18n.t('MyAccount.Adresses.SuccessToast')));
  } else {
    yield put(createAddressActions.failure());
  }
}

function* updateAddress({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.updateAddress, payload.id, payload.data);

  if (resp.ok) {
    yield put(getAddressesActions.request());
    yield put(successToastActions(i18n.t('MyAccount.Adresses.SuccessToastEdit')));
  } else {
    yield put(updateAddressActions.failure());
  }
}

function* deleteAddress({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteAddress, payload);

  if (resp.ok) {
    yield put(getAddressesActions.request());
    yield put(successToastActions(i18n.t('MyAccount.Adresses.SuccessToastDelete')));
  } else {
    yield put(deleteAddressActions.failure());
  }
}

function* validateAddress({ payload }: any) {
  const resp: ExtendedAxiosResponse = yield call(api.validateAddress, payload);

  if (resp.ok) {
    yield put(validateAddressActions.success(resp.data));
    yield put(successResponseActions('User address unique'));
  } else {
    if (resp.response.status === 403) {
      yield put(errorResponseActions(forbiddenError));
    } else {
      yield put(errorResponseActions(resp.response.data));
    }
    yield put(validateAddressActions.failure());
  }
}

/* EXPORT */
export function* userAddressSaga() {
  yield takeLatest(
    createActionType(UserAddressActionTypes.GetAddresses, RequestActionTypes.REQUEST),
    getAddresses,
  );

  yield takeLatest(
    createActionType(UserAddressActionTypes.CreateAddress, RequestActionTypes.REQUEST),
    createAddress,
  );

  yield takeLatest(
    createActionType(UserAddressActionTypes.UpdateAddress, RequestActionTypes.REQUEST),
    updateAddress,
  );

  yield takeLatest(
    createActionType(UserAddressActionTypes.DeleteAddress, RequestActionTypes.REQUEST),
    deleteAddress,
  );

  yield takeLatest(
    createActionType(UserAddressActionTypes.ValidateAddress, RequestActionTypes.REQUEST),
    validateAddress,
  );
}
