import React, { memo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, CheckboxProps } from '@material-ui/core';

const checkIcon = require('../../../assets/check.svg');

const useStyles = makeStyles({
  root: {
    height: 20,
    width: 24,
    color: '#FF8080',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    border: '1px solid #C3C3C3',
    borderRadius: 1,
    width: 18,
    height: 18,
    backgroundColor: '#ffffff',
    '$root.Mui-focusVisible &': {
      outline: '1px auto rgba(19,124,189,.6)',
      outlineOffset: 1,
    },
    'input:hover:enabled ~ &': {
      border: '2px solid #C3C3C3',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      opacity: 0.6,
    },
    '@media all and (max-width: 499.98px)': {
      backgroundColor: 'transparent',
    },
  },
  checkedIcon: {
    border: '1px solid #FF8080',
    backgroundColor: '#ffffff',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        `${'url(' + checkIcon + ')'}`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
      zIndex: 2,
    },
    'input:hover:enabled ~ &': {
      backgroundColor: '#ffffff',
      border: '2px solid #FF8080',
    },
    '@media all and (max-width: 499.98px)': {
      webkitApperance: 'none',
      backgroundColor: 'transparent',
      'input:hover ~ &': {
        backgroundColor: 'transparent',
      },
    },
  },
});

function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      checked={props.checked}
      {...props}
    />
  );
}

export default memo(StyledCheckbox);
