import { apiClient } from 'helpers/api-client';

export interface CreateUserAddressRequest {
  name: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  phone: string;
  isDefault: boolean;
  isInvoicing: boolean;
  ico?: string;
  dic?: string;
  icdph?: string;
}

export interface ValidateUserAddressRequest {
  ico?: string,
  dic?: string,
  icdph?: string
}

export const api = {
  getAddresses: () =>
    apiClient.get('/users/addresses?$limit=-1'),

  createAddress: (body: CreateUserAddressRequest) =>
    apiClient.post('/users/addresses', body),

  updateAddress: (id: string, body: CreateUserAddressRequest) =>
    apiClient.put(`/users/addresses/${id}`, body),

  deleteAddress: (id: string) =>
    apiClient.delete(`/users/addresses/${id}`),

  validateAddress: (body: ValidateUserAddressRequest) =>
    apiClient.post('validate/user-address', body),
};
