import i18n from 'i18next';
import keys from 'helpers/locations-keys';
import { initReactI18next } from 'react-i18next';
import { history } from 'helpers/store/root-reducer';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';

import 'moment/locale/cs';
import 'moment/locale/sk';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import { environment } from './environments/environment';

const languages = ['en', 'cs', 'sk', 'pl'];

const i18nXHR = new Backend(null, {
  crossDomain: true,
  withCredentials: true,
  customHeaders: {
    Authorization: `Basic ${environment.basicAuthToken}`,
  },
  loadPath: () => `${environment.protocol}://${environment.host}${environment.port ? ':' + environment.port : ''}/cms?app=FE&language={{lng}}`,
  parse: data => JSON.parse(data),
});

i18n
  .use(initReactI18next)
  .use(i18nXHR)
  .init({
    lng: environment.defaultLang,
    fallbackLng: environment.defaultLang,
    whitelist: languages,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
  // similar fuctions can be found in file root-reducer.ts
  // function to get params from path defined by key
  const getPathParamsIndexes = (path: string) => {
    const arrayOfPathParts = path.split('/');
    return arrayOfPathParts.reduce<Array<number>>((acc: Array<number>, pathPart: string, index: number) => {
      if (pathPart.includes('{{')) {
        acc.push(index)
      }
      return acc;
    }, [])
  }

  // function to get object of parameters applied to current path, based on key path......key path and current path have to be equal
  const getParametersObject = (currentPath: string, keyPath: string, indexesOfParams: Array<number>) => {
    const arrayOfCurrentPathParts = currentPath.split('/');
    const arrayOfKeyPathParameters = keyPath.split('/');

    const objectOfParameters = indexesOfParams.reduce((acc: any, value: number) => {
      const varName = arrayOfKeyPathParameters[value].replace(/{/g, '').replace(/}/g, '');
      const varValue = arrayOfCurrentPathParts[value]
      acc[varName] = varValue
      return acc;
    }, {})

    return objectOfParameters
  }

  const findCurrentPathKey = (currentPath: string) => {
    return keys.find(key => {
      const currentPathPartsArray = currentPath.split('/');

      return key.pathname.find(path => {
        const keyPathPartsWithoutParamsArray = path.split('/')
        const keyPathPartsWithoutParams = keyPathPartsWithoutParamsArray.reduce((acc: string, value: string) => value.includes('{{') ? acc : acc + value, '');

        const currentPathPartsWithoutParamsArray = path.split('/')
        const currentPathPartsWithoutParams = currentPathPartsWithoutParamsArray.reduce((acc: string, value: string, index: number) => value.includes('{{') ? acc : acc + currentPathPartsArray[index], '');

        return (keyPathPartsWithoutParams === currentPathPartsWithoutParams && keyPathPartsWithoutParamsArray.length === currentPathPartsArray.length ) ? true : false
      })
    })
  }

  const key = findCurrentPathKey(history.location.pathname);

  if (key) {
    let link = '';
    const indexesOfParams = getPathParamsIndexes(key.pathname[0])
    if (indexesOfParams) {
      const pathParameters = getParametersObject(history.location.pathname, key.pathname[0], indexesOfParams);
      link = i18n.t(key.key, pathParameters);
    } else {
      link = i18n.t(key.key);
    }
    const search = history.location.search;
    const { location: { state } } = history;

    // psuh new path in changed language to history
    history.replace({
      pathname: link,
      search: search,
      state,
    });
  }
});

export default i18n;
