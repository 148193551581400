import { apiClient } from '../../helpers/api-client';
import { ConversationType } from '../../model/Conversation';

export const api = {
  createConversation: (type: ConversationType, recipientId?: string, ref?: string) =>
    apiClient.post(
      `/conversations/start`,
      {
        recipientId,
        type,
        ref,
      }
    ),

  createConversationFromToken: (token: string) =>
    apiClient.post(
      `/conversations/create`,
      {
        token,
      }
    ),

  getConversationList: (page: number, pageSize: number) =>
    apiClient.get(
      '/conversations',
      {
        params: {
          $limit: pageSize,
          $skip: page * pageSize,
        }
      }
    ),

  getConversationDetail: (id: string) =>
    apiClient.get(`/conversations/${id}`),

  sendMessage: (conversationId: string, text: string, extId: string) =>
    apiClient.post(
      `/conversations/${conversationId}/messages`,
      {
        extId,
        text
      }
    ),

  getConversationMessages: (conversationId: string, page: number, pageSize: number) =>
    apiClient.get(
      `/conversations/${conversationId}/messages`,
      {
        params: {
          $limit: pageSize,
          $skip: page * pageSize,
        }
      }
    ),

  addMessageImage: (conversationId: string, messageId: string, image: string, name: string, isLast: boolean) =>
    apiClient.put(
      `/conversations/${conversationId}/messages/${messageId}`,
      {
        image,
        name,
        isLast
      }
    ),

  reportConversation: (conversationId: string) =>
    apiClient.patch(
      `/conversations/${conversationId}`,
      {
        flags: {
          adminRequested: true,
        }
      }
    ),

  markConversationRead: (conversationId: string) =>
    apiClient.post(`/conversations/${conversationId}/mark-read`, {}),
};
