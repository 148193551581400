import React, { ReactElement } from 'react';
import NotificationBar from "../NotificationBar/NotificationBar";

// redux
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../helpers/store/models/AppState";
import {
  selectIsLogged
} from '../../../saga/user/ducks';

type ContainerProps = PropsFromRedux & {
  children: ReactElement | ReactElement[];
  body?: boolean;
  className?: string;
}

const Container = (props: ContainerProps) => {

  return (
    <div className={`container ${props.body ? 'body' : ''} ${props.className ? props.className : ''}`} id={props.body ? 'body' : ''}>
      {props.body && props.isUserLogged && <NotificationBar/>}
      {props.children}
    </div>
  );
};

// Get state
interface StateToProps {
  isUserLogged: boolean,
}
const mapStateToProps = (state: AppState): StateToProps => ({
  isUserLogged: selectIsLogged(state),
});

// Dispatch actions
interface DispatchToProps {
}
const mapDispatchToProps: DispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector> 

export default connector(Container)
